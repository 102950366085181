import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { WCRexportreport } from "../../Services/WI-services";
import { toast } from "react-toastify";

const WCR_PDF = (props) => {
  const [pdfData, setPdfData] = useState([]);
  const pdfRef = useRef();

  const exportWcr = async (data) => {
    try {
      // console.log("dataumm", data)
      console.log("dataumm", props.rowdata)
      console.log("data2", props.widata)
      // let result = [];
      // data.WI_Process.forEach(async elem =>
      //   result.push(...await WCRexportreport({...data, WI_Process: elem}))
      // )
      const result = await WCRexportreport(data);
      console.log("resulttttttttt", result)
      if (props.rowdata?.WI_Last_Weld) {
        const latestSlipDates = {};

        // props.widata?.forEach((entry) => {
          result?.forEach((entry) => {

          const welderId = entry.WI_Welder_Id;
          const slipDate = new Date(entry.WI_SlipDate);

          if (
            !(welderId in latestSlipDates) ||
            slipDate > latestSlipDates[welderId]
          ) {
            latestSlipDates[welderId] = slipDate;
          }
        });

        // const latestData = props.widata?.filter((entry) => {
          const latestData = result?.filter((entry) => {
          const welderId = entry.WI_Welder_Id;
          const slipDate = new Date(entry.WI_SlipDate);
          return slipDate.getTime() === latestSlipDates[welderId].getTime();
        });

        console.log("Filtered Data:", latestData);
        setPdfData(latestData)
      } else {
        console.log("Result Data:", result);
        setPdfData(result);
      }
    } catch (error) {
      console.error("Error exporting WCR report:", error);
    }
  };

  const createPDF = () => {
    exportWcr(props?.rowdata);
  };

  useEffect(() => {
    createPDF();
  }, []);

  useEffect(() => {
    if (pdfData.length > 0) {
      generatePdf();
    } else {
      console.log("No data to generate PDF", pdfData.length);
    }
  }, [pdfData]);

  // const generatePdf = () => {
  //   if (pdfData.length > 0) {
  //     const doc = new jsPDF();
  //     const groupedData = groupBy(pdfData, "WI_Welder_Id");

  //     Object.keys(groupedData).forEach((welderId, index) => {
  //       if (index > 0) {
  //         doc.addPage();
  //       }

  //       const heading = "WELDER CONTINUITY RECORD";
  //       doc.setFontSize(10);
  //       doc.setFont("bold");
  //       doc.text(90, 20, heading);

  //       const Companyname = "Godrej & Boyce Mfg. Co. Ltd";
  //       doc.setFontSize(10);
  //       doc.text(15, 20, Companyname);

  //       const Companyaddress = "Pirojshnagar, Vikhroli,";
  //       doc.setFontSize(10);
  //       doc.text(15, 30, Companyaddress);

  //       const Companyaddress1 = "Mumbai-400079, India";
  //       doc.setFontSize(10);
  //       doc.text(15, 35, Companyaddress1);

  //       const CurrentDate = `As on : ${new Date().toLocaleDateString("en-GB")}`;
  //       doc.setFontSize(10);
  //       doc.text(167, 35, CurrentDate);

  //       const header = [
  //         "Sr No",
  //         "Welder Code",
  //         "Welder Name",
  //         "Process / Type",
  //         "Plant / Slip No",
  //         "Weld Date",
  //         "Valid Upto",
  //       ];

  //       const uniqueWelderInfo = {
  //         [welderId]: {
  //           srNo: index * 1 + 1,
  //           id: groupedData[welderId][0].WI_Welder_Id,
  //           name: groupedData[welderId][0].WI_Welder_Name,
  //         },
  //       };

  //       const tableData = groupedData[welderId].map((data, dataIndex) => {
  //         const slipDate = new Date(data.WI_SlipDate);
  //         const formattedSlipDate = slipDate.toLocaleDateString("en-GB");
  //         const validUptoDate = new Date(
  //           slipDate.getTime() + 178 * 24 * 60 * 60 * 1000
  //         );
  //         const formattedValidUpto = validUptoDate.toLocaleDateString("en-GB");

  //         return [
  //           dataIndex === 0 ? uniqueWelderInfo[welderId].srNo + dataIndex : "", 
  //           dataIndex === 0 ? uniqueWelderInfo[welderId].id : "",
  //           dataIndex === 0 ? uniqueWelderInfo[welderId].name : "", 
  //           `${data.WI_Process} / ${data.WI_Type || ""}` , 
  //           `${data.WI_plant} / ${data.WI_Slip}`, 
  //           formattedSlipDate,
  //           formattedValidUpto,
  //         ];
  //       });

  //       const startYPosition = 43;

  //       doc.autoTable({
  //         head: [header],
  //         body: tableData,
  //         theme: "plain",
  //         startY: startYPosition,
  //         styles: {
  //           halign: "center",
  //           valign: "middle",
  //           fontSize: 10,
  //           cellPadding: { top: 0, right: 2, bottom: 0, left: 2 },
  //           lineWidth: 0.1,
  //           font: "bold",
  //           textColor: "#000",
  //         },
  //       });
      

  //     const pageCount = doc.internal.getNumberOfPages();
  //     console.log("pageeeef",pageCount)

  //     for (let i = 1; i <= pageCount; i++) {
        
  //       console.log("pageeeef",pageCount, i , pageCount - i)

  //       doc.setPage(i);
  //       doc.setFontSize(10);

  //       doc.text("____________________________________________________________________________________________________", 15, doc.internal.pageSize.height - 35);
  //       doc.text(" Note : The Qualification of above welder will expire & will have to requalified if he has not welded with the above", 15, doc.internal.pageSize.height - 30);
  //       doc.text(" process during the period of next six months", 15, doc.internal.pageSize.height - 25);
  //       doc.text(" Maintained By : Welding Engineering Department", 15, doc.internal.pageSize.height - 20);
  //       doc.text(" Doc Ref No : Weld/Work Inst/F015, Rev. 1, 01-01-2006", 15, doc.internal.pageSize.height - 15);

  //       doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 10);
  //     }

  //   });
    

  //     toast.success("Successfully Exported.", {
  //       position: toast.POSITION.TOP_LEFT,
  //       className: "toast-message",
  //     });

  //     doc.save("WCR_Report.pdf");

    
       
      
  //   } else {
  //     toast.info("There are no records matching to filters.", {
  //       position: toast.POSITION.TOP_LEFT,
  //       className: "toast-message",
  //     });
  //   }
  // };

  // Utility function to group data by a specific key
  

  const generatePdf = () => {
    if (pdfData.length > 0) {
      const doc = new jsPDF();
      const groupedData = groupBy(pdfData, "WI_Welder_Id");
  
      Object.keys(groupedData).forEach((welderId, index) => {
        if (index > 0) {
          doc.addPage();
        }
  
        const heading = "WELDER CONTINUITY RECORD";
        doc.setFontSize(10);
        doc.setFont("bold");
        doc.text(heading, 90, 20);
  
        const Companyname = "Godrej & Boyce Mfg. Co. Ltd";
        doc.setFontSize(10);
        doc.text(Companyname, 15, 20);
  
        // const Companyaddress = "Pirojshnagar, Vikhroli,";
        // doc.setFontSize(10);
        // doc.text(Companyaddress, 15, 30);
  
        // const Companyaddress1 = "Mumbai-400079, India";
        // doc.setFontSize(10);
        // doc.text(Companyaddress1, 15, 35);
  
        const CurrentDate = `As on : ${new Date().toLocaleDateString("en-GB")}`;
        doc.setFontSize(10);
        doc.text(CurrentDate, 167, 35);
  
        const header = [
          "Sr No",
          "Welder Code",
          "Welder Name",
          "Process / Type",
          "Plant / Slip No",
          "Weld Date",
          "Valid Upto",
        ];
  
        const uniqueWelderInfo = {
          [welderId]: {
            srNo: index + 1,
            id: groupedData[welderId][0].WI_Welder_Id,
            name: groupedData[welderId][0].WI_Welder_Name,
          },
        };
        for(let welderId in groupedData) {
          // sorted according to Process. When record has same process then 
          // most recent weld date should be on top.
          groupedData[welderId].sort((a,b) => 
            a.WI_Process.localeCompare(b.WI_Process)
          || new Date(b.WI_SlipDate) - new Date(a.WI_SlipDate));
        }
        const tableData = groupedData[welderId].map((data, dataIndex) => {
          const slipDate = new Date(data.WI_SlipDate);
          const formattedSlipDate = slipDate.toLocaleDateString("en-GB");
          const validUptoDate = new Date(
            slipDate.getTime() + 178 * 24 * 60 * 60 * 1000
          );
          const formattedValidUpto = validUptoDate.toLocaleDateString("en-GB");
          let processcat = (data.WI_ProcessCategory || "").split("#")
          let category = processcat[0]
  
          return [
            dataIndex === 0 ? uniqueWelderInfo[welderId].srNo + dataIndex : "", 
            dataIndex === 0 ? uniqueWelderInfo[welderId].id : "",
            dataIndex === 0 ? uniqueWelderInfo[welderId].name : "", 
            `${data.WI_Process === "GTAW" ? data.WI_Process : category} / ${data.WI_Type || ""}`, 
            `${data.WI_plant} / ${data.WI_Slip}`, 
            formattedSlipDate,
            formattedValidUpto,
          ];
        });
  
        const startYPosition = 43;
  
        doc.autoTable({
          head: [header],
          body: tableData,
          theme: "plain",
          startY: startYPosition,
          styles: {
            halign: "center",
            valign: "middle",
            fontSize: 10,
            cellPadding: { top: 0, right: 2, bottom: 0, left: 2 },
            lineWidth: 0.1,
            font: "bold",
            textColor: "#333333",
          },
          didDrawPage: (data) => {
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text("____________________________________________________________________________________________________", 15, doc.internal.pageSize.height - 35);
            doc.text("Note : The Qualification of above welder will expire & will have to requalified if he has not welded with the above", 15, pageHeight - 30);
            doc.text("process during the period of next six months", 15, pageHeight - 25);
            doc.text("Maintained By : Welding Engineering Department", 15, pageHeight - 20);
          }
        });
      });
  
      // Fetch the total number of pages once all pages have been added
      const pageCount = doc.internal.getNumberOfPages();
      console.log("Total pages:", pageCount); // Debugging log
  
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
  
        // Add the page number
        doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.width - 40, doc.internal.pageSize.height - 10);
      }
  
      toast.success("Successfully Exported.", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
  
      doc.save("WCR_Report.pdf");
  
    } else {
      toast.info("There are no records matching to filters.", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
  };
  
  
  const groupBy = (arr, key) => {
    return arr.reduce((acc, obj) => {
      const groupKey = obj[key];
      (acc[groupKey] = acc[groupKey] || []).push(obj);
      return acc;
    }, {});
  };

  return <div ref={pdfRef}>{/* Your PDF content here */}</div>;
};

export default WCR_PDF;
