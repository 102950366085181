import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";

import SuccessAlertModal from "../Modals/successAlertModal";
import { useLocation, useNavigate } from "react-router-dom";
import GolbalSearch from "../utility/GolbalSearch";
import { handleExport1 } from "../utility/GlobalExport";
import { Row, Col, Container } from "react-bootstrap";
import Export from "../../icons/Export.svg";
import { Status } from "./PQRObjects/PQR_entity";
import {
  getGridHeaderData,
  getPresignedURLgrid,
  getPresignedURLgriddelete,
  UpdateRecord,
  DeletePQR,
} from "../../Services/pqr-header-service";

import GlobalColumnDefinations from "../utility/AgGridDefination";
import { getHeader_FieldName } from "../utility/AgGridDefination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GridMaster from "../utility/GridMaster";
import Toaster from "../utility/Toaster";
import ActionRendererPQR from "../../Components/utility/ActionRendererPQR";
import BarLoader from "react-spinners/BarLoader";

import Pdf_DownloadPQR from "./PDF/Pdf_DownloadPQR";

const PQRGroove_Overlay = forwardRef((props, ref) => {
  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);

  const [columnDefs, setColumnDefs] = useState([]);
  const [Groove, setGroove] = useState(null);

  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState("");

  const [reload, setReload] = useState(false);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [excelData1, setExcelData] = useState([]);


  const navigate = useNavigate();

  const location = useLocation();


  let Band = sessionStorage.getItem("Band");


  let userprofile = sessionStorage.getItem("UserProfile");

  const [WPStype, setWPStype] = useState(props.type);

  const [userObj, setuserObj] = useState("");


  const [loading, setLoading] = useState(true);


  const [cancel, setCancel] = useState(false);

  const childRef = useRef();

  const year = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(year);



  const tmpcolumnDefs =
    WPStype === "Tubesheet"
      ? [
        {
          headerName: "Sr No.",
          cellRenderer: "PQRSrno",
          suppressMenu: true,
          pinned: "left",
          sortable:false,

        },

          {
            headerName: "Status",
            field: "WPSH_Status",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "WPSCellRenderer",
            pinned: "left",
            minWidth:200

          },
          {
            headerName: "Year",
            field: "Wld_createdon_year",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
            pinned: "left",
          },
          {
            headerName: "PQR No.",
            field: "PQR_RecordNo",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
            pinned: "left",
          },
          {
            headerName: "Tubesheet Material",
            field: "PQR_BM_BM_Spec",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "P.NO.",
            field: "PQR_BM_BM_Pno",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Tube Material",
            field: "PQR_BM_WTBM_Spec",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "P No.",
            field: "PQR_BM_WTBM_Pno",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Diameter",
            field: "PQR_BM_WTBM_Dia",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Tube Thk",
            field: "PQR_BM_WTBM_Thk",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Joint Type",
            field: "PQR_JD_JointType",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Tube Pitch mm",
            field: "PQR_JD_TubePitch",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Tube Projection mm",
            field: "PQR_JD_TubeProj",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Pattern",
            field: "PQR_JD_Pattern",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Hole ID (mm)",
            field: "PQR_JD_Pattern",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Groove Depth (mm)",
            field: "PQR_JD_Pattern",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Width of the Ligament (mm)",
            field: "PQR_JD_Pattern",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Groove Angle/Radius",
            field: "PQR_JD_GrooveAngle",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Process",
            field: "WPSH_PQR_Process",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "PWHT",
            field: "PWHT",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "PWHTcheckbox",
          },
        ]
      : [
          {
            headerName: "Sr No.",
            cellRenderer: "PQRSrno",
            sortable:false,
            suppressMenu: true,

            pinned: "left",
          },
          {
            headerName: "Status",
            field: "WPSH_Status",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "WPSCellRenderer",
            pinned: "left",
          },
          {
            headerName: "Year",
            field: "Wld_createdon_year",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
            pinned: "left",
          },
          {
            headerName: "PQR No.",
            field: "PQR_RecordNo",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
            pinned: "left",
          },
          {
            headerName: "Material 1",
            field: "PQR_BM_BM_Spec",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "P No.",
            field: "PQR_BM_BM_Pno",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "G No.",
            field: "PQR_BM_BM_Gno",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Base Material Condition 1",
            field: "PQR_BM_BM_ProductForm",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Material 2",
            field: "PQR_BM_WTBM_Spec",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "P No.",
            field: "PQR_BM_WTBM_Pno",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "G No.",
            field: "PQR_BM_WTBM_Gno",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Base Material Condition  2",
            field: "PQR_BM_WTBM_ProductForm",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Joint",
            field: "wld_WPSType",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Thickness",
            field: "PQR_BM_BM_Thk",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Process",
            field: "WPSH_PQR_Process",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Consumable",
            field: "WPSH_PQR_Classification",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Consumable Brand",
            field: "WPSH_PQR_TradeName",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "PWHT",
            field: "PWHT",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Temp",
            field: "PQR_PWHeatTre_Temperature",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Time (Minutes)",
            field: "PQR_PWHeatTre_Time",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Impact",
            field: "Impact",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Hardness",
            field: "Hardess",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
          {
            headerName: "Code",
            field: "PQR_ReferenceCode",
            type: "Text",
            isCheckbox: false,
            cellRenderer: "",
          },
        ];


  const headerName = (field) => {
    let name = getHeader_FieldName(field, tmpcolumnDefs, "getHeader");
    return name;
  };

  const fieldName = (header) => {
    let name = getHeader_FieldName(header, tmpcolumnDefs, "getField");
    return name;
  };

  const fetchGroove = async () => {
    let gridPrameter = {
      type: WPStype,
      band: sessionStorage.getItem("Band"),
      user: sessionStorage.getItem("User"),
      year: selectedYear,
    };
    var result = await getGridHeaderData(gridPrameter);

    if (result !== undefined) {
      result = result.sort(function (a, b) {
        if (a.WPSH_Status === Status.StatusTempSaved) return -1;
        else return 1;
      });
    }
    setGroove(result);
    setLoading(false);
    setFilteredResult(result);
  };

  const fetchColumnDefns = (profileOriginal) => {
    const result = GlobalColumnDefinations(tmpcolumnDefs);
    result.push({
      headerName: "",
      cellRenderer: actionRenderer,
      minWidth: 250,
      suppressMenu: true,
      sortable:false,
    });
    setColumnDefs(result);
  };

  useEffect(() => {
    fetchGroove();
    fetchColumnDefns();
  }, [location.key, selectedYear]);

  function handleClick() {
    if (WPStype === "Groove") {
      navigate("/pqr/Groove/Groovetrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    } else if (WPStype === "Overlay") {
      navigate("/pqr/Overlay/Overlaytrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    } else if (WPStype === "Tubesheet") {
      navigate("/pqr/TubetoTubesheet/TubetoTubesheettrans", {
        state: { data: "", action: "Add", WPStype: WPStype },
      });
    }
  }

  function handleClickedit(row) {

    if (row.WPSH_Status === "Approved") {
      row.WPS_Change_No = String(Number(row.WPS_Change_No) + 1);
    }
    if (WPStype === "Groove") {
      navigate("/pqr/Groove/Groovetrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    } else if (WPStype === "Overlay") {
      navigate("/pqr/Overlay/Overlaytrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    } else if (WPStype === "Tubesheet") {
      navigate("/pqr/TubetoTubesheet/TubetoTubesheettrans", {
        state: {
          data: row,
          action: "Edit",
          WPStype: WPStype,
          WPS_Change_No: row.WPS_Change_No,
        },
      });
    }
  }

  function handleClickcopy(row) {
    navigate("/pqr/Groove/Groovetrans", {
      state: { data: row, action: "Copy", WPStype: WPStype },
    });
  }

  function handleClickTemp(row) {
    navigate("/pqr/Groove/Groovetrans", {
      state: { data: row, action: "Temp", WPStype: WPStype },
    });
  }
  useImperativeHandle(ref, () => ({
    handleAdd: () => {
      handleClick();
    },
  }));

  
  const [showSuccess, setShowSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const history = useNavigate();
  const [show1, setShow1] = useState(false);

  const modalOnClick = () => {
    history.push({
      pathname: "/",
      data: "setGroove",
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  const handleGlobalSearch = (text) => {
    childRef.current.setSearch(text);
  };

  const handleGlobalExport = () => {
    if (WPStype === "Groove") {
      let filename = "PQRGrooveTransaction";
      let result = handleExport1(filteredResult, filename, columnDefs);
    } else if (WPStype === "Overlay") {
      let filename = "PQROverlayTransaction";
      let result = handleExport1(filteredResult, filename, columnDefs);
    } else {
    }
  };


  const handlepdf = (row) => {
    if(row?.WPSH_Status === "Freezed"){

    setAction("pdf");
    setRowData(row);
    setShowForm(true);
    setuserObj(userprofile);
    }
    else{
      alert("Cannot Print PDF.Please Approve the Record")
    
  };
}

  const actionRenderer = (params) => {
    return (
      <ActionRendererPQR
        selectedData={params}
        handleEditEvent={(params) => handleClickedit(params.data)}
        handleCopyEvent={(params) => handleClickcopy(params.data)}
        handleTempEvent={(params) => handleClickTemp(params.data)}
        handlePdfEvent={(params) => handlepdf(params.data)}
        handleDeleteEvent={(params) => handleDeleteEvent(params.data)}
      />
    );
  };
 

  const handleimage = (row) => {
    setRowData(row);
    setShowForm(true);
  };

  const handleDeleteEvent = async (row) => {
    try {
      setRowData(row);
      let gridDetail = {
        PK: row.PK,
        SK: row.SK,
        WPSH_PQR_Process: row.WPSH_PQR_Process,
        WPSH_PQR_Type: row.WPSH_PQR_Type,
      };
      await DeletePQR(gridDetail);
      toast.success(`Selected DetailGrid is Deleted`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      setCancel(true);
    } catch (error) {
    }
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.value);
  };

  return (
    <Container fluid>
      <SuccessAlertModal
        clickFunction={modalOnClick}
        msg={msg}
        show={showSuccess}
        handleClose={handleCloseSuccess}
      />
      <Row className="RowHeight">
        <Col md={2} sm={12} xs={12} className="ListTitle">
          PQR {WPStype === "Tubesheet" ? "Tube to Tubesheet" : WPStype} list
        </Col>

        <Col>
          <div className="global-search-container">
            <GolbalSearch
              searchplaceholdertext={
                "Search for " +
                WPStype +
                " WelderId, Welder Name ,Record No. , Revision No."
              }
              withYear={true}
              filterFunc={handleGlobalSearch}
              YearHandleChange={handleYearChange}
              YearSelected={selectedYear}
            />
            <img
              className="allexport"
              src={Export}
              alt="Export"
              onClick={handleGlobalExport}
            />
          </div>
        </Col>
      </Row>
      {loading && (
        <BarLoader height={5} width={195} className="gridloader" />
      )}

      <GridMaster
        rowDetails={filteredResult}
        colDetails={columnDefs}
        fieldNames={fieldName}
        headerNames={headerName}
        getDataEvent={(getFilteredData) =>
          setFilteredResulttemp(getFilteredData)
        }
        getFilterColLength={(getLength) => setFilterColLength(getLength)}
        setExcelData={setExcelData}
        ref={childRef}
      />

      {showForm && (
        <Pdf_DownloadPQR
          show={showForm}
          action={action}
          rowdata={rowData}
          onHide={() => setShowForm(false)}
          fetchUserMaster={fetchGroove}
          setReload={setReload}
          reload={reload}
          userObj={userObj}
          allRecs={Groove}
          backdrop="static"
          Band={Band}
        />
      )}
      <Toaster />
    </Container>
  );
});

export default PQRGroove_Overlay;
