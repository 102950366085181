
import {
  // isAlphanumericWithDash,
    isAlphanumericAllCharacters
  } from "../../../functions/validations";
export const WPS_Input = (({valuefield,maxLength,isReadonly,handleChange,status,errObj,id,required,action,setErrorObj}) =>
{  
  let err = status.filter(item => item.id === id)
  return(<>

  {required === "None" ? 
                       <span>-</span>
                      
                      : 
                      
                     
                        <input
                          type="text"
                          className={
                            required === "Required"
                              ? (err.length != 0)
                                ? "error-input"
                                : ""
                              : ""
                          }
                          id={id}
                          readOnly={
                            action === "Copy"
                              ? isReadonly
                              : false
                          }
                          name={id}
                          maxLength={maxLength}
                          placeholder=" "
                          value={
                            required === "None"
                              ? "-"
                              : valuefield
                          }
                          onChange={handleChange}
                        
                          onInput={(e) => {
                            let error = isAlphanumericAllCharacters(e.target.value);
                              var className = "border radioInput"
                              var msg = ""
                            if (error.status) {
                             
                               className = "border radioInput error"
                               msg = error.message  
                               console.log("hello 1",msg)

                               let changeError = status
                               let found = false
                               changeError.map((i) =>{
                                  if(i.id == id){
                                    i.msg = msg
                                    found = true
                                  }

                               }
                               )
                               if (!found)
                                changeError.push({id:id,msg:msg})
                               //setErrorObj((err) => [...err.filter(i => i.id===id), {id: id ,msg: msg,className:className}]);
                               setErrorObj(changeError)
                              }
                              
                            else{
                              
                               setErrorObj((status) => status.filter(i => i.id !== id));
                              }
                              
                            } 

                          }
                        
                        
                        />
}</>)
})