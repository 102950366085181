export const emptyError = (err, seterr) => {
  var entries = Object.keys(err);
  //console.log("new error", entries);

  entries.map((i) => {
    // console.log("new error", i);
    err[i] = {
      status: false,
      message: ``,
    };
    //console.log("new error", err[i], i);
  });
  console.log("new_error", err);
  seterr((prevState) => ({
    ...prevState,
    ...err,
  }));
};

export const 
createDetailObjPQR = (start, end) => {
  let temparray = []
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;
  ////console.log("new in create", start);
  for (let i = start; i <= end; i++) {
    temparray.push({
      SK: "",
      PK: "",
      wld_createdon: formattedDate,
      PQR_RecordNo: "",
      PQR_Process: "",
      PQR_Layer: "",
      // PQR_new:"",
      // PQR_Change: 0,
      PQR_Type: "",
      PQRD_FM_SFA: "",
      PQRD_FM_Classification: "",
      PQRD_FM_FNo: "",
      PQRD_FM_Ano: "",
      PQRD_FM_Nominal: "",
      PQRD_FM_TradeName: "",

      PQRD_FM_ProductForm: "",
      PQRD_FM_FillerMetalSize: [],
      PQRD_FM_DepositedThickness: "",
      PQRD_FM_MaxPassThickness: "",
      PQRD_FM_WeldDepositChemistry: "",
      PQRD_FM_FluxAWS: "",
      PQRD_FM_FluxNominalComposition: "",
      PQRD_FM_FluxTradeName: "",
      PQRD_FM_FluxType: "",
      PQRD_FM_FluxRecrushed: "",
      PQRD_FM_Supplement: "",
      PQRD_FM_FillerMetalOthers: "",

      PQRD_Position_PositionofGroove: [],
      PQRD_Position_WeldProgression: [],

      PQRD_Preheat_MinimumPreheatTemp: "",
      PQRD_Preheat_MaximumInterpassTemp: "",

      PQRD_Electrical_FillerMetalSize: [],
      PQRD_Electrical_Amperes: [],
      PQRD_Electrical_Volts: [],
      PQRD_Electrical_TravelSpeed: [],
      PQRD_Electrical_minBeadLength: [],
      PQRD_Electrical_ArcTransferMode: [],
      PQRD_Electrical_MaxHeatInput: [],
      PQRD_Electrical_TungstenType: [],
      PQRD_Electrical_CurrentPolarity: [],
      PQRD_Electrical_DCPulsatingCurrent: [],
      PQRD_Electrical_WireFeedType: [],
      PQRD_Electrical_WireFeedSpeed: [],

      PQRD_Gas_ShieldingType: "",
      PQRD_Gas_ShieldingFlowRate: "",
      PQRD_Gas_BackingType: "",
      PQRD_Gas_BackingFlowRate: "",
      PQRD_Gas_TrailingType: "",
      PQRD_Gas_TrailingFlowRate: "",
      PQRD_Technique_StringOrWeave:"",
      PQRD_Technique_Orifice_GasCupSize:"",
      PQRD_Technique_CTWD:"",
      PQRD_Technique_Oscillation:"",
      PQRD_Technique_Multi_SingleElectrode:"",
      PQRD_Technique_ElectrodeAngle:"",
      PQRD_Technique_SinglePassPerSide:"",
      PQRD_Technique_Multiple_SingleLayer:"",
      PQRD_Technique_Peening:"",
      PQRD_Technique_InitialInterpassCleaning:"",
      PQRD_Technique_BackGaugingMethod:"",
      PQRD_Technique_Technique_Other:"",
      PQRD_Technique_GroovePreparation:"",
      PQRD_Technique_ElectrodesSamePuddle:"",
      PQRD_Technique_SupplimentalDevice:"",
      PQRD_Technique_Overlaping:"",
    });
  }
  return temparray;
}

export const Tensile_Test_Obj = (start, end) =>{
  let Tensile_Test = []
  for (let i = start; i <= end; i++) {
    Tensile_Test.push({
      PQR_Tensile_SpecimenNo: "",
      PQR_Tensile_Width: "",
      PQR_Tensile_Thickness: "",
      PQR_Tensile_Area: "",
      PQR_Tensile_UltimateLoad: "",
      PQR_Tensile_UltimateStress: "",
      PQR_Tensile_FailureLocation: "",
    });
  }
  console.log("headerObj_PQR2ndPage3",Tensile_Test)
  return Tensile_Test;
}

export const GuidedBend_Test_Obj = (start, end) => {
  let GuidedBend_Test = []
  for (let i = start; i <= end; i++) {
    GuidedBend_Test.push({
      PQR_GB_TypeofTest: "",
      PQR_GB_Acceptance_Criteria: "",
      PQR_GB_Results: "",
      PQR_GB_Remarks: "",
    });
  }
  return GuidedBend_Test;
}



export const Toughness_Test_Obj = (start, end) =>{
  let Toughness_Test = []
  for (let i = start; i <= end; i++) {
    Toughness_Test.push({
      PQR_TT_SpecimenNo: "",
      PQR_TT_NotchLocation: "",
      PQR_TT_NotchType: "",
      PQR_TT_SpecimenSize: "",
      PQR_TT_TestTemp: "",
      PQR_TT_ImpactValues: "",
      PQR_TT_Avg: "",
      PQR_TT_LateralExpansion: "",
      PQR_TT_Shear: ""
    });
  }
  return Toughness_Test;
}

export const  Hardness_Test_Groove_Obj = ( start, end) => {
  let Hardness_Test_Groove = []
  for (let i = start; i <= end; i++) {
    Hardness_Test_Groove.push({
      PQR_HT_Type: "",
      PQR_HT_Location: "",
      PQR_HT_ParentMetal_Left: "",
      PQR_HT_HAZ_Left: "",
      PQR_HT_Weld: "",
      PQR_HT_HAZ_Right: "",
      PQR_HT_ParentMetal_Right: "",
      PQR_HT_Remarks: "",
    });
  }
  return Hardness_Test_Groove;
}

export const  Other_Test_Obj = (start, end) => {
  let Other_Test = []
  for (let i = start; i <= end; i++) {
    Other_Test.push({
      PQR_OT_TypeofTest: "",
      PQR_OT_Acceptance_Criteria: "",
      PQR_OT_Results: "",
      PQR_OT_Remarks: "",
    });
  }
  return Other_Test;
}
export const  Other_Test_overlay_Obj = (start, end) => {
  let Other_Test_overlay = []
  for (let i = start; i <= end; i++) {
    Other_Test_overlay.push({
      PQR_OT_TypeofTest: "",
      PQR_OT_Acceptance_Criteria: "",
      PQR_OT_Results: "",
      PQR_OT_Remarks: "",
    });
  }
  return Other_Test_overlay;
}
export const  Certification_Obj = (start, end) => {
  let Certification = []
  for (let i = start; i <= end; i++) {
    Certification.push({
      PQR_Certification_Welder_Name: "",
      PQR_Certification_ID: "",
      PQR_Certification_Process: "",
    });
  }
  return Certification;
}
export const  CertificationLabRef_Obj = (start, end) => {
  let CertificationLabRef = []
  for (let i = start; i <= end; i++) {
    CertificationLabRef.push({
      PQR_Certification_Lab_RefNo: ""
    });
  }
  return CertificationLabRef;
}

export const  GuidedBend_Test_Overlay_Obj = (start, end) =>{
  let GuidedBend_Test_Overlay = []
  for (let i = start; i <= end; i++) {
    GuidedBend_Test_Overlay.push({
      PQR_GBV_SpecimenNo: "",
      PQR_GBV_Thickness: "",
      PQR_GBV_BM_WeldOverlay: "",
      PQR_GBV_FormerDiam: "",
      PQR_GBV_BendAngle: "",
      PQR_GBV_Acceptance_Criteria: "",
      PQR_GBV_Remarks: "",
    });
  }

  return GuidedBend_Test_Overlay;
}