export function validDecimals(e) {
  if (e.indexOf(".") >= 0) {
    var n = e.split(".");
    if (n[1].length > 2) {
      return { status: true, message: "Enter only 2 digits after decimal" };
    } else return { status: false, message: "" };
  }
  return { status: false, message: "" };
}

export function validINRCurrency(e) {
  if (e != null) {
    var str = e.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    }
    console.log(str.join("."));
    return str.join(".");
  } else {
    return "";
  }

  //return { value: z, status: false, message: "" };
}

export function getFormatedAmount(amount, currency) {
  if (currency === "USD") {
    return validUSDCurrency(amount);
  } else {
    return validINRCurrency(amount);
  }
}
export function validUSDCurrency(e) {
  if (e !== null) {
    var str = e.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    console.log(str.join("."));
    return str.join(".");
  } else {
    return "";
  }
}

export function validDecimalDigits(e) {
  if (e.indexOf(".") >= 0) {
    var n = e.split(".");
    if (n[1].length > 4) {
      return { status: true, message: "Enter only 4 digits after decimal" };
    } else return { status: false, message: "" };
  }
  return { status: false, message: "" };
}

export function checkNull(e) {
  if (!e) {
    return { status: true, message: "" };
  } else return { status: false, message: "" };
}

export function twodecimal(e) {
  var regExp = /^[0-9]*(\.[0-9]{0,2})?$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Only two digits allowed after the decimal point",
    };
  else return { status: false, message: "" };
}

export function numberMax10Digits(e) {
  debugger
  console.log("numberMax10Digits_eeeeee", e);
  var regExp = /^\d{1,8}(\.\d{1,2})?$/; // Allows 10 digits with up to 2 decimal places
  
  if (!e.match(regExp)) {
    console.log("numberMax10Digits_if", {
      status: true,
      message: "Enter a number up to 10 digits with up to 2 decimal places",
    });
    return {
      status: true,
      message: "Enter a number up to 10 digits with up to 2 decimal places",
    };
  } else {
    console.log("numberMax10Digits_else", { status: false, message: "" });
    return { status: false, message: "" };
  }
}

export function isAlphanumeric(e) {
  var regExp = /^[A-Za-z0-9]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}
export function isAlpha(e) {
  var regExp = /^[A-Za-z]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Numbers and Special Character not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpace(e) {
  var regExp = /^[A-Za-z0-9\s]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceandot(e) {
  var regExp = /^[A-Za-z0-9\s.]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceandDash(e) {
  var regExp = /^[A-Za-z0-9\s-]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSlash(e) {
  var regExp = /^[0-9\sa-zA-Z/°!@#$%^&*()_./-]+$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpacehyphenUs(e) {
  var regExp = /^[A-Za-z0-9\s\-\_]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithAtRate(e) {
  var regExp = /^[A-Za-z0-9@.]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDot(e) {
  var regExp = /^[A-Za-z0-9.]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWeb(e) {
  var regExp = /^([\w,:,@,.,//,\s/]*)$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Alphanumeric with only (@ . : /) special characters allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDash(e) {
  var regExp = /^[A-Za-z0-9\s-.()/]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}
export function isAlphanumericWithSpecialChars(e) {
  var regExp = /^[A-Za-z0-9\s- .()/|,!#_;@$%*&:\[\]]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Characters not allowed" };
  else return { status: false, message: "" };
}

export function isNumber(e) {
  var regExp = /^[0-9\s]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter only Numeric Digits" };
  else return { status: false, message: "" };
}

export function isNumberwithdash(e) {
  var regExp = /^[0-9\s-]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Enter only Numeric Digits with Only Dash",
    };
  else return { status: false, message: "" };
}

export function isNumberwithdashdot(e) {
  var regExp = /^[0-9\s-.]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Enter only Numeric Digits with Only Dash or Dot",
    };
  else return { status: false, message: "" };
}

// export function islessthenMaxlength(e, fieldnm) {
//   if (e.target.value.length < e.target.maxLength)
//     return {
//       status: true,
//       message: fieldnm + " length should be  " + e.target.maxLength,
//     };
//   }
export function isExperience(e) {
  var regExp = /^[0-9\+.s]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter only Numeric Digits" };
  else return { status: false, message: "" };
}

// export function isExperience(e) {
//   var regExp = /^[0-9\+.s]*$/;
//   if (!e.match(regExp))
//     return { status: true, message: "Enter only Numeric Digits"};
//   else return { status: false, message: "" };
// }

export function islessthenMaxlength(e, fieldnm) {
  if (e.target.value.length < e.target.maxLength)
    return {
      status: true,
      message: fieldnm + " length should be  " + e.target.maxLength,
    };
  else return { status: false, message: "" };
}

export function isNumbernrange(e) {
  var regExp = /^[0-9\s+]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter only Positive digits" };
  else if (e < 1 || e > 366)
    return { status: true, message: "Enter within range i.e., 1-366" };
  else return { status: false, message: "" };
}

export function isNumberRange(e) {
  var regExp = /^[0-9\s+]*$/;
  if (e && !e.match(regExp))
    return { status: true, message: "Enter only Numberic digits." };
  // else if (e && (e < 1 || e > 5))
  //   return { status: true, message: "Enter within range i.e., 1-5" };
  else return { status: false, message: "" };
}

export function number24(e) {
  var regExp = /^((?:[0-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|24(?:\.00?)?)$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter Value between 0 - 24" };
  else return { status: false, message: "" };
}

export function number9999(e) {
  var regExp = /^(?:[0-9]{1,4}|9999)$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter Value between 0 - 9999" };
  else return { status: false, message: "" };
}

export function isMobNumber(e) {
  var regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter country code with mobile no." };
  else return { status: false, message: "" };
}

export function isNumberWithDecimal(e) {
  // var regExp = /^\d+(\.\d)?\d*$/;
  var regExp = /^[0-9.]*$/;
  if (!e.match(regExp)) return { status: true, message: "Enter only digits" };
  else return { status: false, message: "" };
}
export function isNumberWithDecimalPM(e) {
  // var regExp = /^\d+(\.\d)?\d*$/;
  var regExp = /^[0-9.,]*$/;
  if (!e.match(regExp)) return { status: true, message: "Enter only digits" };
  else return { status: false, message: "" };
}

export function isEmail(e) {
  var regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  if (!e.match(regExp))
    return { status: true, message: "Enter valid Email Id" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDotSpaceDashCommaBrackets(e) {
  var regExp = /^[-A-Za-z0-9\s.,()]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}
export function isAlphanumericWithSpaceDotAmpsDashBrackets(e) {
  var regExp = /^[-A-Za-z0-9\s .,&-()]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters space &(),.- allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericampspacedotdashbrackets(e) {
  var regExp = /^[-A-Za-z0-9\s., -&()]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDotSpaceDashCommaBracketsemail(e) {
  var regExp = /^[-A-Za-z0-9\s.,()@]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDash(e) {
  var regExp = /^[a-zA-Z0-9-]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDashSlash(e) {
  var regExp = /^[a-zA-Z0-9-/]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}
export function isAlphanumericWithDashDot(e) {
  console.log("isAlphanumericWithDashDot_just,", e);
  var regExp = /^[a-zA-Z0-9-.]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}
export function isAlphanumericWithDashDotPercentageComma(e) {
  var regExp = /^[a-zA-Z0-9-.,%]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDashComma(e) {
  var regExp = /^[-A-Za-z0-9\s,]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDash(e) {
  var regExp = /^[-A-Za-z0-9\s-.]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}
export function isAlphanumericWithSpaceDot(e) {
  var regExp = /^[-A-Za-z0-9\s.]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}
export function isAlphanumericWithSpaceDotDashSlash(e) {
  var regExp = /^[-A-Za-z0-9\s./]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashSlashComma(e) {
  var regExp = /^[-A-Za-z0-9\s.,/]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashSlashBracketColonAmpersand(e) {
  var regExp = /^[A-Za-z0-9\s./()[\]{}:;&-]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashSlashColon(e) {
  var regExp = /^[A-Za-z0-9\s./()[\]:-]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDashSlashDot(e) {
  var regExp = /^[-A-Za-z0-9\./]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDashCommaSlash(e) {
  var regExp = /^[-A-Za-z0-9\s.,/]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDotSpaceDashCommaBracketsColonSlash(e) {
  var regExp = /^[-A-Za-z0-9\s.,()/:]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}


export function isAlphanumericWithDotSpaceDashCommaBracketsColonSlashampersand(e) {
  var regExp = /^[-A-Za-z0-9\s.,()/:&]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}


export function isAlphanumericWithDotSpaceDashCommaBracketsSlashAmpersand(e) {
  var regExp = /^[-A-Za-z0-9\s.,()/&]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message:
        "Special Characters other than space,.,(),comma,&,-,/ are not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDotSpaceDashCommaBracketsSlash(e) {
  var regExp = /^[-A-Za-z0-9\s.,()/]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDashdotSlashcommabracket(e) {
  var regExp = /^[a-zA-Z0-9-.,()/]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashPlus(e) {
  var regExp = /^[-A-Za-z0-9\s.+]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}
export function isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e) {
  var regExp = /^[-A-Za-z0-9\s.+-/_()*@!,;:#$&°]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashBracket(e) {
  var regExp = /^[-A-Za-z0-9\s.()]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashBracketPercentPlus(e) {
  var regExp = /^[-A-Za-z0-9\s.()%+]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashComma(e) {
  var regExp = /^[-A-Za-z0-9\s.,]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashCommaBracket(e) {
  var regExp = /^[-A-Za-z0-9\s.,()]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDotDashAmpersand(e) {
  var regExp = /^[-A-Za-z0-9\s.&-]*$/;

  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDashDotSlashSpaceBrackets(e) {
  var regExp = /^[-A-Za-z0-9\s.()/]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(e) {
  var regExp = /^[-A-Za-z0-9\s,.()&]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message:
        "Special Characters other than space,.,(),comma,&,-,/ are not allowed",
    };
  else return { status: false, message: "" };
}

export function isNumberWith2digits(e) {
  var regExp = /^\d{1,2}?$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter Value between 0 - 99" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDashSlashSpace(e) {
 // var regExp = /^[a-zA-Z0-9-\s.]*$/; 
 var regExp = /^[-A-Za-z0-9\s/]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function numberMax6Digits(e) {
  var regExp = /^\d{1,6}(\.\d{1,2})?$/;
  var regExp2 = /^\d{1,4}(\.\d{1,2})?$/;

  console.log("lengthhh", e.toString().length);
  if (e.toString().length > "6") {
    console.log("enter_if", e.toString().length);
    return {
      status: true,
      message:
        "Enter a number up to 6 digits with up to 2 decimal places including decimal",
    };
  } else {
    console.log("enter_else");
     // Allows 6 digits with up to 2 decimal places
    if (!e.toString().match(regExp))
      return {
        status: true,
        message: "Enter a number up to 6 digits with up to 2 decimal places",
      };
    else return { status: false, message: "" };
  }
}

export function twodecimalReal(e) {
  var regExp = /^([0-9]{0,3})*(\.[0-9]{0,2})?$/;
  if (!e.toString().match(regExp))
    return {
      status: true,
      message: "Only two digits allowed after the decimal point",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceSlashPlusMinusDashTolerance(e) {
  var regExp = /^[-A-Za-z0-9\s+/-±]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function isRealNumber(e) {
  
  console.log("ttsee", e);
  var regExp = /^[0-9\s]*$/;
  if (!e.match(regExp) || e <= 0)
    return {
      status: true,
      message: "Enter only numeric digits greater than 0 ",
    };
  else return { status: false, message: "" };
}

export function isDecimalIncluded(e) {
  // var regExp = /^\d+(\.\d)?\d*$/;
  var regExp = /^[0-9.]*$/;
  if (!e.toString().match(regExp))
    return { status: true, message: "Enter only digits" };
  else return { status: false, message: "" };
}

export function getNatural(num) {
  return parseFloat(num.toString().split(".")[0]);
}

export function getDecimal(num) {
  console.log("numd", num);
  return parseFloat(num.toString().split(".")[1]);
}

export function finalErrorFunc(a, b, c, d) {
  console.log("finalErrorFunc_just,", a, b, c, d);
  // var regExp = /^[a-zA-Z0-9-.]*$/;
  if (
    a?.status === true ||
    b?.status === true ||
    c?.status === true ||
    d?.status === true
  )
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

export function callFunctionbyName(a, b, c, d) {
  console.log("finalErrorFunc_just,", a, b, c, d);
  // var regExp = /^[a-zA-Z0-9-.]*$/;
  if (
    a?.status === true ||
    b?.status === true ||
    c?.status === true ||
    d?.status === true
  )
    return {
      status: true,
      message: "Special Characters not allowed",
    };
  else return { status: false, message: "" };
}

// const handleOnInput = (e, i, name, TypeofCheck, detailObj) => {
export function handleOnInput(e, i, name, TypeofCheck, detailObj) {
  
  let hasErr;
  hasErr = { status: false, message: "" };
  let err,
    count = 0;
  console.log("handleOnInput_eee_TypeofCheck", TypeofCheck);

  detailObj.forEach((obj, index) => {
    console.log("handleOnInput_eee_filterObj_index", i, index);
    let value = obj[name];
    if (i === index) value = e.target.value;
    console.log("handleOnInput_eee_filterObj_value", value);
    err = TypeofCheck(value);
    console.log("handleOnInput_eee_err", err);

    if (err.status === true) count = 1;
  });
  console.log("handleOnInput_eee_count", count);
  if (count > 0)
    hasErr = {
      status: true,
      message: "Special Characters not allowed",
    };
  else
    hasErr = {
      status: false,
      message: "",
    };
  return hasErr;
}


export function isAlphanumericAllCharacters(e) {
  var regExp = /^[-A-Za-z0-9\s,+-:;.@#$&*°%()_!/]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message:
        "Special Characters other than space ,comma, +, -, colon, semicolon, dot, @, #, $, &,(),*,!,_,/ are not allowed",
    };
  else return { status: false, message: "" };}

  export function isAlphanumericAllCharactersextra(e) {
    var regExp = /^[-A-Za-z0-9\s,+-:;.@#$&*()_!°C%<>/]*$/;
    if (!e.match(regExp))
      return {
        status: true,
        message:
          "Special Characters other than space ,comma, +, -, colon, semicolon, dot, @, #, $, &,(),*,!,_,/ are not allowed",
      };
    else return { status: false, message: "" };}
    

  export function isAlphanumericAllCharactersextraBackslash(e) {
    var regExp = /^[-A-Za-z0-9\s,+-:;.@#$&*()\_!%<>/]*$/;
    if (!e.match(regExp))
      return {
        status: true,
        message:
          "Special Characters not allowed",
      };
    else return { status: false, message: "" };}
    
  export function isAlphanumericAllSpecialCharacters(e) {
    var regExp = /^[-A-Za-z0-9\s,^={}+?/'"|\-\]\[\\:;°.@#$&*()_!%<>/]*$/;
    if (!e.match(regExp))
      return {
        status: true,
        message:
          "Special Characters not allowed",
      };
    else return { status: false, message: "" };}