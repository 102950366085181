// ---------------------------------------------------------------------------------------------------------------//

//Date - 28/3/2024.
//Author - Omkar More.
//Session - WPS Transaction(Groove, Overlay) - Page_1.
//Contains - ADD / EDIT / GET API'S of WPS Groove and Overlay Session.
//Workflow - 1) P & T band can Approve Records which are Pending for Approval Raised by other bands(Initiator).
//         - 2) Initiator Cannot Edit Record which is Pending For Approval.
//         - 3) Readonly and setReadonly are Variables used to control Readonly action.
//         - 4) Record can be Tempsaved (Project Number , WPS No. , Atleast one Process Mandtory).

// ---------------------------------------------------------------------------------------------------------------//
import { Row, Col, Form, Table } from "react-bootstrap";
import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import {
  isAlphanumericAllCharacters,
} from "../../functions/validations";
import {
  Backing,
  company_name,
  Flux_type,
  maxlength_WPS,
  MessageTypes,
  TypeData,
  WeldLayer,
  WPSPage1,
  WPSPage1_Overlay,
  yesno,
} from "./WPSObjects/WPSGroove_entity";
import { SearchSelect, SearchSelectProject } from "../utility/search-select";
import { AddNewCount, AddNewGroove } from "../../Services/wps_groove-services";
import { AddLogs } from "../../Services/wps-logs-services";
import { getAllOnlyProject } from "../../Services/project-services";
import { useLocation } from "react-router-dom";
import { getPNOActive } from "../../Services/pcategory-service";
import { getActiveAno } from "../../Services/ano-services";
import { getActiveProcess } from "../../Services/weldingprocess-services";
import { getActiveSfa } from "../../Services/sfa-service";
import { getActiveFno } from "../../Services/fno-services";
import { WPS_Thickness_Groove } from "./WPSObjects/WPS_thickness_groove";
import {
  checkProcessAdded,
  checkduplicateProcess,
  checkFirstPageValidation,
} from "./WPSObjects/WPS_Validations";
import { WPS_Thickness_overlay } from "./WPSObjects/WPS_thickness_overlay";
import {
  AddNewDetail,
  DeleteWPS,
  getdetailsById,
} from "../../Services/wps_details-service";

import Tooltip from "../utility/Tooltip";
import Error from "../../icons/ErrorIcon.svg";

import { isDuplicate } from "./WPSObjects/WPS_Validations";
import { WPS_Input } from "./WPSObjects/WPS_Input";
import {
  emptyError,
  DisableFields,
  AddRowsDetailsoBj,
} from "./WPSObjects/WPS_common";
import { Action, Status } from "./WPSObjects/WPSGroove_entity";
import { todayDate, maxDate } from "../utility/CommonComponents/TodayDate";
import {
  General_Single_Input_select,
  General_Single_Input_select_green,
  Single_Input_WPS,
} from "./WPSObjects/WPS_Process_Grid";

import { getActiveItemForProject } from "../../Services/item-services";
import { Date_Input } from "../utility/CommonComponents/Date_Component";
import Wld_Consolelog from "../utility/CommonComponents/ConsoleLog";

const General = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      WPStype,
      setIsEditedPage1,
      logObj,
    },
    ref
  ) => {
    const location = useLocation();
    const [wld_TRQ_Type, setwld_TRQ_Type] = useState("welded");
    const [WPSH_fluxRadio, setFluxRadio] = useState();
    const [ProjectDetails, setProjectDetails] = useState([]);
    const [AnoActive, setAnoActive] = useState([]);
    const [FnoActive, setFnoActive] = useState([]);
    const [SfaActive, setSfaActive] = useState([]);
    const [ProcessActive, setProcessActive] = useState([]);
    const [PNoActive, setPNoActive] = useState([]);
    const [ItemActive, setItemActive] = useState([]);
    const [IsEdited, setIsEdited] = useState(false);

    const [consumError, setConsumError] = useState([
      { id: "", msg: "", className: "border radioInput" },
    ]);
    const [fluxError, setFluxError] = useState([
      { id: "", msg: "", className: "border radioInput" },
    ]);
    const [supfilterError, setSupFilterError] = useState([
      { id: "", msg: "", className: "border radioInput" },
    ]);

    // added comment
    const [classError, setClassError] = useState([]);
    const [chemicalAnaError, setChemicalAnaError] = useState([]);
    const [FMMinError, setFMMinError] = useState([]);
    const [FMMaxError, setFMMaxError] = useState([]);

    const [action, setAction] = useState(location.state?.action);

    const [Readonly, setReadonly] = useState("");

    var isoverlay = WPStype === "Groove" ? false : true;

    var isgroove = WPStype !== "Groove" ? true : false;

    var grooveclass = WPStype === "Groove" ? "row-margin" : "d-none";
    var overclass = WPStype !== "Groove" ? "row-margin" : "d-none";

    const [isOverlay, setOverlay] = useState(isoverlay);
    const [Grooveclass, setoverlayclass] = useState(grooveclass);
    const [overlaytext, setoverlaytext] = useState(overclass);

    Wld_Consolelog("Demo123", headerObj);

    const [greenstyle, setgreen] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "green" : "green",
      }),
    });

    const [normalstyle, setNormal] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "grey",
      }),
    });
    const [errorstyle, seterrorstyle] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "red" : "red",
      }),
    });

    const message = (errobj) => {
      var msg = "",
        allmsg = "";
      errobj.map((i) => {
        if (i.id !== "" && i.id !== "all") msg = i.msg + ", ";
        if (i.id === "all") allmsg = i.msg;
      });
      msg = msg + allmsg;
      return msg;
    };

    const [error, setError] = useState({
      WPSH_Project_No: {},
      WPSH_WPS_No: {},
      WPSH_WPS_Revi_No: {},
      WPS_QualifiedTo: {},
      WPSH_SupportingPQR: {},
      WPSH_WPS_Date: {},
      WPSH_RefDoc: {},
      WPSH_Scope: {},
      WPSH_JointDesc: {},
      WPSH_BM_Type_Type: {},
      WPSH_BM_WeldingTo_Type: {},
      WPSH_BM_Type_GNo: {},
      WPSH_BM_Type_PNo: {},
      WPSH_BM_WeldingTo_PNo: {},
      WPSH_BM_WeldingTo_GNo: {},
      WPSH_BM_Backing_Backing: {},
      WPSH_BM_Backing_GNo: {},
      WPSH_BM_Backing_PNo: {},
      WPSH_BM_Retainer: {},
      WPSH_WPS_WeldLayer: {},
      WPSH_BM_Backing_Material: {},
      WPSH_ConsuInsert_SFA: {},
      WPSH_ConsuInsert_Classification: {},
      WPSH_ConsuInsert_FNo: {},
      WPSH_ConsuInsert_ANo: {},
      WPSH_ConsuInsert_ChemicalAnalysis_TradeName: {},
      WPSH_Flux_SFA: {},
      WPSH_Flux_Classification: {},
      WPSH_Flux_FNo: {},
      WPSH_Flux_ANo: {},
      WPSH_Flux_ChemicalAnalysis_TradeName: {},
      WPSH_SupFiller_SFA: {},
      WPSH_SupFiller_Classification: {},
      WPSH_SupFiller_FNo: {},
      WPSH_SupFiller_ANo: {},
      WPSH_SupFiller_ChemicalAnalysis_TradeName: {},

      WPSH_BM_Notes: {},
      WPSH_TRQ_GroovewithoutImpact_Min: {},
      WPSH_TRQ_GroovewithoutImpact_Max: {},
      WPSH_TRQ_GroovewithImpact_Min: {},
      WPSH_TRQ_GroovewithImpact_Max: {},
      WPSH_TRQ_FilletwithoutImpact_Min: {},
      WPSH_TRQ_FilletwithoutImpact_Max: {},
      WPSH_TRQ_FilletwithImpact_Min: {},
      WPSH_TRQ_FilletwithImpact_Max: {},
      WPSH_FM_FluxType: {},
      WPSH_FM_AlloyFlux: {},
      WPSH_FM_AlloyElement: {},
      WPSH_FM_Add_Del_Filler: {},
      WPSH_FM_Add_Del_Flux: {},
      WPSH_FM_Add_Del_orchange: {},
      WPSH_FM_NominalFlux: {},
      WPSH_FM_FluxItemBatch: {},
      WPSH_FM_FluxFromRecrush: {},
      WPSH_FM_FluxItemCode: {},
      WPSH_FM_FilerMetal: {},
      WPSD_FM_Classification: {},
      WPSH_BM_Type_HT_Con: {},
      WPSH_BM_WeldingTo_HT_Con: {},
      WPSD_FM_ChemicalAnalysis_TradeName: {},
      WPSD_FM_TRQ_Min: {},
      WPSD_FM_TRQ_Max: {},
      WPSH_ConsuInsert: {},
      // WPSD_FM_Make: {},
      WPSH_Flux: [],
      WPSH_SupFiller: [],
      WPSH_MinOvlFinThick: {},

      WPSD_Process: {},
      WPSH_TRQ_BaseMetalCRO_Min: {},
      WPSH_TRQ_BaseMetalCRO_Max: {},
      WPSH_TRQ_BaseMetalHRO_Min: {},
      WPSH_TRQ_BaseMetalHRO_Max: {},
    });

    const handleChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage1(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleSelectChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage1(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };
    console.log("detailda", detailObj);
    const handleChangedetailsarray = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      setIsEditedPage1(true);
      const { name, value } = e.target;
      console.log("detailda", detailObj);
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
    };

    const validate = async () => {
      console.log("called validate");
      let entries;
      let entries1;

      if (WPStype === "Groove") {
        entries = Object.entries(WPSPage1);
      }
      if (WPStype === "Overlay") {
        entries1 = Object.entries(WPSPage1_Overlay);
        console.log("hiiiii", entries1);
      }

      var all = WPStype === "Groove" ? entries : entries1;

      var isError = false;

      if (headerObj.WPSH_WPS_Date > todayDate) {
        isError = true;
        error.WPSH_WPS_Date = {
          status: true,
          message: "No Future Date Allowed.",
        };
      }

      all.map(([key, val]) => {
        if (headerObj[key] === "") {
          console.log("errorroro", headerObj[key], key);
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
        return isError;
      });

      var norows = checkProcessAdded(detailObj);
      if (norows) {
        detailObj[0].errormessage = "Atleast one Process and type is required";
        isError = true;
        detailObj[0].error = true;
      }

      var duplicateprocess = checkduplicateProcess(detailObj);
      if (duplicateprocess) isError = true;
      console.log("WOSdup", duplicateprocess);
      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        console.log(
          "result_checkDuplicate1",
          isDuplicate(headerObj?.WPSH_Project_No, headerObj?.WPSH_WPS_No)
        );
        if (
          await isDuplicate(headerObj?.WPSH_Project_No, headerObj?.WPSH_WPS_No)
        ) {
          isError = true;
          error.WPSH_Project_No.status = true;
          error.WPSH_Project_No.message =
            "Project Number & WPS Number already exists";
          console.log("condition", "error");
        } else {
          console.log("condition", "no error");
          error.WPSH_Project_No.status = false;
          error.WPSH_Project_No.message = "";
        }
      }

      /*required check box error */

      if (WPStype === "Groove") {
        checkFirstPageValidation(
          headerObj?.WPSH_consumRadio,
          headerObj?.WPSH_ConsuInsert_ANo,
          headerObj?.WPSH_ConsuInsert_ChemicalAnalysis_TradeName,
          headerObj?.WPSH_ConsuInsert_Classification,
          headerObj?.WPSH_ConsuInsert_FNo,
          headerObj?.WPSH_ConsuInsert_SFA,
          consumError,
          setConsumError
        );
      }
      checkFirstPageValidation(
        headerObj.WPSH_fluxRadio,
        headerObj.WPSH_Flux_ANo,
        headerObj.WPSH_Flux_ChemicalAnalysis_TradeName,
        headerObj.WPSH_Flux_Classification,
        headerObj.WPSH_Flux_FNo,
        headerObj.WPSH_Flux_SFA,
        fluxError,
        setFluxError
      );
      checkFirstPageValidation(
        headerObj.WPSH_filterRadio,
        headerObj.WPSH_SupFiller_ANo,
        headerObj.WPSH_SupFiller_ChemicalAnalysis_TradeName,
        headerObj.WPSH_SupFiller_Classification,
        headerObj.WPSH_SupFiller_FNo,
        headerObj.WPSH_SupFiller_SFA,
        supfilterError,
        setSupFilterError
      );

      if (
        (WPStype === "Groove" && consumError.length > 1) ||
        fluxError.length > 1 ||
        supfilterError.length > 1
      )
        isError = true;

      if (
        (WPStype === "Overlay" && fluxError.length > 1) ||
        supfilterError.length > 1
      )
        isError = true;

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const validateTemp = async () => {
      let isError = false;

      emptyError(error, setError);
      console.log("temp", headerObj, headerObj.WPSH_Project_No);
      if (headerObj.WPSH_Project_No.trim() === "") {
        headerObj.WPSH_Project_No = "";
        isError = true;
        error.WPSH_Project_No = {
          status: true,
          message: "This field is required",
        };
      }
      if (headerObj.WPSH_WPS_No.trim() === "") {
        headerObj.WPSH_WPS_No = "";
        isError = true;
        error.WPSH_WPS_No = {
          status: true,
          message: "This field is required",
        };
      }

      if (headerObj.WPSH_WPS_Date > todayDate) {
        isError = true;
        error.WPSH_WPS_Date = {
          status: true,
          message: "Future Date Not Allowed.",
        };
      }

      if (
        (action === Action.ActionCopy || action === Action.ActionAdd) &&
        !isError
      ) {
        console.log(
          "result_checkDuplicate1",
          isDuplicate(headerObj.WPSH_Project_No, headerObj.WPSH_WPS_No)
        );
        if (
          await isDuplicate(headerObj.WPSH_Project_No, headerObj.WPSH_WPS_No)
        ) {
          console.log("condition", "if");
          isError = true;
          error.WPSH_Project_No.status = true;
          error.WPSH_Project_No.message =
            "Project Number & WPS Number already exists";
        } else {
          console.log("condition", "inelse");
          error.WPSH_Project_No.status = false;
          error.WPSH_Project_No.message = "";
        }
      }

      var norows = checkProcessAdded(detailObj);

      if (norows) {
        detailObj[0].errormessage = "Atleast one Process and Type is required";
        isError = true;
        detailObj[0].error = true;

        if (
          detailObj[0].WPSD_Process !== "" &&
          detailObj[0].WPSD_FM_FNo !== "" &&
          detailObj[0].WPSD_Type !== ""
        ) {
          detailObj[0].error = false;
        }
      }

      var duplicateprocess = checkduplicateProcess(detailObj);
      if (duplicateprocess) isError = true;

      setError((prevState) => ({
        ...prevState,
        ...error,
      }));

      return isError;
    };

    const handleCount = () => {
      let result;

      console.log("no", location.state?.WPS_Change_No);

      headerObj.WPS_Change_No = location.state?.WPS_Change_No;
      result = AddNewCount(headerObj);
    };

 

    const handleAdd = async () => {
      console.log("In Save");
      let result = false;
      const status = headerObj.WPSH_Status;

      if (
        status === Status.StatusApproved ||
        status === Status.StatusRejected
      ) {
        if (status === Status.StatusApproved) {
          handleCount();
        }
        headerObj.WPSH_Status = Status.StatusPending;
        headerObj.wld_Approval_Type = Status.StatusPending;
      }

      if (action === Action.ActionCopy) {
        headerObj.WPSH_Status = Status.StatusTempSaved;
      }

      console.log("Header data:", headerObj);

      const filteredDetails = detailObj.filter(
        (item) => item.WPSD_Process !== "NA" && item.WPSD_Process !== ""
      );

      console.log("Filtered details:", filteredDetails);

      let WPSH_WPS_Process = "";
      let WPSH_WPS_Type = "";
      let WPSH_WPS_WeldLayer = "";

      for (const item of filteredDetails) {
        console.log("ieieieieieeeee", item);

        item.WPSH_Project_No = headerObj.WPSH_Project_No;
        item.WPSH_WPS_No = headerObj.WPSH_WPS_No;
        item.WPSH_WPS_Revi_No = headerObj.WPSH_WPS_Revi_No;
        item.PK = headerObj.WPSH_Project_No + "#" + headerObj.WPSH_WPS_No;

        WPSH_WPS_Process += item.WPSD_Process + ",";
        WPSH_WPS_Type += item.WPSD_Type + ",";
        WPSH_WPS_WeldLayer += item.WPSH_WeldLayer;
        item.WPSH_WPS_Process = headerObj.WPSH_WPS_Process;
        item.WPSH_WPS_Type = headerObj.WPSH_WPS_Type;
        item.SK = item.WPSD_Process + "#" + item.WPSD_Type;

        headerObj.WPSH_WPS_Process = WPSH_WPS_Process;
        headerObj.WPSH_WPS_Type = WPSH_WPS_Type;
        headerObj.WPSH_WPS_WeldLayer = WPSH_WPS_WeldLayer;
      }

      console.log("Header data after processing details:", headerObj);

      try {
        await AddNewGroove(headerObj);
        setAction(Action.ActionEdit);
        console.log("Header added successfully");
        ///delete old process
        let oldprocess = await getdetailsById(
          headerObj.WPSH_Project_No,
          headerObj.WPSH_WPS_No
        );
        console.log(
          "detaildata",
          oldprocess,
          headerObj.WPSH_Project_No,
          headerObj.WPSH_WPS_No
        );

        ///delete old process
        for (const item of oldprocess) {
          let NotFoundprocess = filteredDetails.filter(
            (i) =>
              i.PK.toString() == item.PK.toString() &&
              i.SK.toString() == item.SK.toString()
          );
          console.log("detaildata not found", NotFoundprocess);
          if (NotFoundprocess && NotFoundprocess.length <= 0) {
            await DeleteWPS(item.PK, item.SK).catch((e) => {
              console.log("detaildata", e);
            });
          }
        }

        for (const item of filteredDetails) {
          console.log("ieieieiei", item);
          try {
            const response = AddNewDetail(item);
            console.log("Detail added successfully:", response);
            setAction(Action.ActionEdit);
            result = true;
          } catch (error) {
            console.error("Error adding detail:", error);
          }
        }

        AddRowsDetailsoBj(filteredDetails, setdetailObj);

        if (action === Action.ActionEdit && status === Status.StatusApproved) {
          console.log("Approval log");
          await AddLogs(logObj);
          result = true;
        }
      } catch (error) {
        console.error("Error adding header:", error);
      }

      console.log("Operation complete:", result);
      return result;
    };

    const fetchProject = async () => {
      //Review use GetAllProjects
      var result = await getAllOnlyProject();
      setProjectDetails(result);
    };

    const fetchProcess = async () => {
      const result = await getActiveProcess();
      const process = [{ wld_code: "NA" }, ...result];
      console.log("proccess", process);
      setProcessActive(process);
    };

    const fetchSfa = async () => {
      const result = await getActiveSfa();
      setSfaActive(result);
    };
    const fetchPNo = async () => {
      const result = await getPNOActive();

      setPNoActive(result);
    };

    const fetchItem = async () => {
      const result = await getActiveItemForProject(headerObj.WPSH_Project_No);
      const FinalItem = [{ wld_itemcode: "NA" }, ...result];
      const filterResult = FinalItem.filter((e) => e.wld_itemcode);
      console.log("ItemmmmmmFInal", filterResult);
      setItemActive(filterResult);
    };

    const fetchfno = async () => {
      const result = await getActiveFno();
      setFnoActive(result);
    };

    const fetchAno = async () => {
      const result = await getActiveAno();
      setAnoActive(result);
    };

    useEffect(() => {
      fetchProcess();
      fetchSfa();
      fetchfno();
      fetchAno();
      if (action != Action.ActionEdit) fetchProject();
      fetchPNo();
    }, []);

    useEffect(() => {
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
    }, [action]);

    useEffect(() => {
      setReadonly(
        DisableFields(headerObj, Status, Action, action) ? "disabled" : ""
      );
    }, [headerObj]);

    useEffect(() => {
      fetchItem();
    }, [headerObj.WPSH_Project_No]);

    const handleSubmit = async () => {
      var result = false;
      setIsEdited(false);
      setIsEditedPage1(false);

      if (!(await validate())) {
        result = await handleAdd();
        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.Required;
      }

      return false;
    };

    const handleSubmitTemp = async () => {
      var result = false;
      var val = await validateTemp();
      // console.log("temp", val);
      console.log("dataobj", IsEdited, action, action === Action.ActionEdit);
      if (action === Action.ActionEdit) {
        console.log("dataobj1", val, IsEdited);
        if (IsEdited) {
          setIsEdited(false);
          setIsEditedPage1(false);
          console.log("dataobj2", val);
          if (!val) {
            console.log("dataobjAdd");
            result = handleAdd();

            if (result) {
              return MessageTypes.Temp;
            }
          }
          return MessageTypes.Required;
        }
        return MessageTypes.None;
      }
      if (!val) {
        result = handleAdd();

        if (result) {
          return MessageTypes.Temp;
        }
      } else {
        return MessageTypes.Required;
      }
    };
    useImperativeHandle(ref, () => ({
      funcOnNext: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp: async () => {
        console.log("handleSubmitTemp");
        var res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));

    const green = {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "green" : "green",
      }),
    };


    return (
      <Row className="font">
        <Row style={{padding: "20px 60px" }}  className="WPS-Page-row-padding">
          <Row className="mb-2" style={{ position: "relative", left: "15px" }}>
            {/* <Col md={3} style={{width:"202px"}}> */}
            <Col md={3} style={{ width: "20%" }}>
              <Form.Group className="pro-input-select">
                <div className="search-select">
                  <SearchSelectProject
                    // className="inspdrop"
                    id="WPSH_Project_No"
                    name="WPSH_Project_No"
                    className={error.WPSH_Project_No.status ? "err-input" : ""}
                    stylegreen={
                      error.WPSH_Project_No.status ? errorstyle : normalstyle
                    }
                    data={ProjectDetails}
                    stylenormal={normalstyle}
                    valueField={"wld_ProjectNumber"}
                    disabled={action === Action.ActionEdit ? true : false}
                    value={headerObj.WPSH_Project_No}
                    labelField={"wld_ProjectNumber"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setheaderObj((prev) => ({
                        ...prev,
                        WPSH_Project_No: ProjectList.value,
                      }));
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Project_No: {},
                      }));
                      for (var i in detailObj) {
                        detailObj[i].WPSH_Project_No = ProjectList.value;
                      }
                    }}
                    onInput={(e) => {
                      let error = isAlphanumericAllCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_Project_No: error,
                      }));
                    }}
                  ></SearchSelectProject>
                </div>
                <label>Project Number</label>
                <div className="required-text-select">
                  {error.WPSH_Project_No.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_Project_No.message}
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Single_Input_WPS
              divstyle="T-input w-100"
              width="201px"
              id="WPSH_WPS_No"
              name="WPSH_WPS_No"
              label="WPS No."
              disabled={action === Action.ActionEdit ? true : false}
              maxLength={maxlength_WPS.WPSH_WPS_No}
              status={error.WPSH_WPS_No.status}
              message={error.WPSH_WPS_No.message}
              value={headerObj.WPSH_WPS_No}
              setError={setError}
              error={error}
              onChange={(e) => {
                handleChange(e);
                for (var i in detailObj) {
                  detailObj[i].WPSH_WPS_No = e.target.value;
                }
              }}
              onInput={(e) => {
                let error = isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_WPS_No: error,
                }));
              }}
            />

            <Single_Input_WPS
              divstyle="T-input w-100"
              id="WPSH_WPS_Revi_No"
              name="WPSH_WPS_Revi_No"
              label="WPS Rev.No."
              maxLength={maxlength_WPS.WPSH_WPS_Revi_No}
              status={error.WPSH_WPS_Revi_No.status}
              message={error.WPSH_WPS_Revi_No.message}
              value={headerObj.WPSH_WPS_Revi_No}
              setError={setError}
              error={error}
              onChange={(e) => {
                handleChange(e);
                for (var i in detailObj) {
                  detailObj[i].WPSH_WPS_Revi_No = e.target.value;
                }
              }}
              onInput={(e) => {
                let error = isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_WPS_Revi_No: error,
                }));
              }}
            />

            <Col md={3} style={{ width: "255px" }}>
              <Date_Input
                divClass="T-input w-100"
                label="WPS Date"
                className="groove_datecolor"
                name="WPSH_WPS_Date"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
                maxDate={maxDate}
                disabled={Readonly}
                obj={headerObj}
                setobj={setheaderObj}
                setError={setError}
                status={error.WPSH_WPS_Date.status}
                message={error.WPSH_WPS_Date.message}
                // datevalue={datevalue}
              />
            </Col>

            <Single_Input_WPS
              divstyle="T-input w-100"
              md={3}
              style={{ position: "relative", left: "8px" }}
              id="WPS_QualifiedTo"
              name="WPS_QualifiedTo"
              label="Qualified To"
              // disabled={action === Action.ActionEdit ? true : false}
              maxLength={maxlength_WPS.WPS_QualifiedTo}
              status={error.WPS_QualifiedTo.status}
              message={error.WPS_QualifiedTo.message}
              value={headerObj.WPS_QualifiedTo}
              onChange={handleChange}
              setError={setError}
              error={error}
              onInput={(e) => {
                let error = isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPS_QualifiedTo: error,
                }));
              }}
            />
          </Row>
          <fieldset disabled={Readonly}>
            <Row className="row-margin">
              <Col>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_CompanyName"
                    name="WPSH_CompanyName"
                    value={company_name[0].value}
                    // value={headerObj.WPSH_CompanyName}
                    readOnly
                    placeholder=" "
                  />
                  <label for="WPSH_CompanyName">Company Name</label>
                </div>
              </Col>

              <Single_Input_WPS
                divstyle="T-input w-100"
                // md={3}
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_SupportingPQR"
                name="WPSH_SupportingPQR"
                label="Supporting PQR"
                maxLength={maxlength_WPS.WPSH_SupportingPQR}
                status={error.WPSH_SupportingPQR.status}
                message={error.WPSH_SupportingPQR.message}
                value={headerObj.WPSH_SupportingPQR}
                onChange={handleChange}
                setError={setError}
                error={error}
              />

              <Single_Input_WPS
                md={6}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_RefDoc"
                name="WPSH_RefDoc"
                label="Reference Docs"
                maxLength={maxlength_WPS.WPSH_RefDoc}
                status={error.WPSH_RefDoc.status}
                message={error.WPSH_RefDoc.message}
                value={headerObj.WPSH_RefDoc}
                onChange={handleChange}
                setError={setError}
                error={error}
              />
            </Row>
            <Row className="mb-2 mt-2">
              <Single_Input_WPS
                divstyle="T-input w-100"
                // md={6}
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_Scope"
                name="WPSH_Scope"
                label="Scopes"
                maxLength={maxlength_WPS.WPSH_Scope}
                status={error.WPSH_Scope.status}
                message={error.WPSH_Scope.message}
                value={headerObj.WPSH_Scope}
                onChange={handleChange}
                setError={setError}
                error={error}
              />

              <Single_Input_WPS
                md={12}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_JointDesc"
                name="WPSH_JointDesc"
                label="Joint Description"
                maxLength={maxlength_WPS.WPSH_JointDesc}
                status={error.WPSH_JointDesc.status}
                message={error.WPSH_JointDesc.message}
                value={headerObj.WPSH_JointDesc}
                onChange={handleChange}
                setError={setError}
                error={error}
              />
            </Row>
          </fieldset>
        </Row>
        <fieldset disabled={Readonly}>
          <Row className="md-2" style={{ padding: "20px 60px" }}>
            <span className="WPS-blue-text">Base Metals (QW-403)</span>
            <Row className="md-2">
              <Single_Input_WPS
                md={3}
                divstyle="green-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_BM_Type_Type"
                name="WPSH_BM_Type_Type"
                label="Type"
                maxLength={maxlength_WPS.WPSH_BM_Type_Type}
                status={error.WPSH_BM_Type_Type.status}
                message={error.WPSH_BM_Type_Type.message}
                value={headerObj.WPSH_BM_Type_Type}
                onChange={handleChange}
                setError={setError}
                error={error}
              />

              <Col md={3} className="mb-1">
                <Form.Group className="pno-input-select">
                  <div className="search-select">
                    <SearchSelect
                      required
                      data={PNoActive}
                      stylegreen={
                        error.WPSH_BM_Type_PNo.status ? errorstyle : greenstyle
                      }
                      valueField={"wld_code"}
                      value={headerObj.WPSH_BM_Type_PNo}
                      labelField={"wld_code"}
                      placeholder=""
                      className={
                        error.WPSH_BM_Type_PNo.status ? "err-input" : ""
                      }
                      onChange={(pnoList) => {
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_BM_Type_PNo: pnoList.value,
                        }));
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_BM_Type_PNo: {},
                        }));
                      }}
                    ></SearchSelect>
                  </div>
                  <label>P No.</label>
                </Form.Group>
                <div className="required-text-select">
                  {error.WPSH_BM_Type_PNo.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_BM_Type_PNo.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Single_Input_WPS
                md={3}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_BM_Type_GNo"
                name="WPSH_BM_Type_GNo"
                label="G No."
                maxLength={maxlength_WPS.WPSH_BM_Type_GNo}
                status={error.WPSH_BM_Type_GNo.status}
                message={error.WPSH_BM_Type_GNo.message}
                value={headerObj.WPSH_BM_Type_GNo}
                onChange={handleChange}
                setError={setError}
                error={error}
              />

              {!isoverlay ? (
                <>
                  <Single_Input_WPS
                    md={3}
                    divstyle="T-input w-100"
                    // style={{ position: "relative", left: "8px" }}
                    id="WPSH_BM_Type_HT_Con"
                    name="WPSH_BM_Type_HT_Con"
                    label="Base Metal HT Con"
                    maxLength={maxlength_WPS.WPSH_BM_Type_HT_Con}
                    status={error.WPSH_BM_Type_HT_Con.status}
                    message={error.WPSH_BM_Type_HT_Con.message}
                    value={headerObj.WPSH_BM_Type_HT_Con}
                    onChange={handleChange}
                    setError={setError}
                    error={error}
                  />
                </>
              ) : (
                ""
              )}
            </Row>
            <Row className="row-margin">
              <Single_Input_WPS
                md={3}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_BM_WeldingTo_Type"
                name="WPSH_BM_WeldingTo_Type"
                label="Welded To"
                maxLength={maxlength_WPS.WPSH_BM_WeldingTo_Type}
                status={error.WPSH_BM_WeldingTo_Type.status}
                message={error.WPSH_BM_WeldingTo_Type.message}
                value={headerObj.WPSH_BM_WeldingTo_Type}
                onChange={handleChange}
                setError={setError}
                error={error}
              />
              <Col md={3}>
                <Form.Group className="pno-input-select">
                  <div className="search-select">
                    <SearchSelect
                      required
                      data={PNoActive}
                      valueField={"wld_code"}
                      stylegreen={
                        error.WPSH_BM_WeldingTo_PNo.status
                          ? errorstyle
                          : greenstyle
                      }
                      value={headerObj.WPSH_BM_WeldingTo_PNo}
                      labelField={"wld_code"}
                      placeholder=""
                      className={
                        error.WPSH_BM_WeldingTo_PNo.status ? "err-input" : ""
                      }
                      onChange={(pnoList) => {
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_BM_WeldingTo_PNo: pnoList.value,
                        }));
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_BM_WeldingTo_PNo: {},
                        }));
                      }}
                    ></SearchSelect>
                  </div>
                  <label>P No.</label>
                </Form.Group>
                <div className="required-text-select">
                  {error.WPSH_BM_WeldingTo_PNo.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_BM_WeldingTo_PNo.message}
                    </Form.Text>
                  )}
                </div>
              </Col>

              <Single_Input_WPS
                md={3}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_BM_WeldingTo_GNo"
                name="WPSH_BM_WeldingTo_GNo"
                label="G No."
                maxLength={maxlength_WPS.WPSH_BM_WeldingTo_GNo}
                status={error.WPSH_BM_WeldingTo_GNo.status}
                message={error.WPSH_BM_WeldingTo_GNo.message}
                value={headerObj.WPSH_BM_WeldingTo_GNo}
                onChange={handleChange}
                setError={setError}
                error={error}
              />

              {!isoverlay ? (
                <>
                  <Single_Input_WPS
                    md={3}
                    divstyle="T-input w-100"
                    // style={{ position: "relative", left: "8px" }}
                    id="WPSH_BM_WeldingTo_HT_Con"
                    name="WPSH_BM_WeldingTo_HT_Con"
                    label="Base Metal HT Con"
                    maxLength={maxlength_WPS.WPSH_BM_WeldingTo_HT_Con}
                    status={error.WPSH_BM_WeldingTo_HT_Con.status}
                    message={error.WPSH_BM_WeldingTo_HT_Con.message}
                    value={headerObj.WPSH_BM_WeldingTo_HT_Con}
                    onChange={handleChange}
                    setError={setError}
                    error={error}
                  />
                </>
              ) : (
                ""
              )}
            </Row>
            <Row className={Grooveclass}>
              <Col md={6}>
                <General_Single_Input_select_green
                  name="WPSH_BM_Backing_Backing"
                  message={error.WPSH_BM_Backing_Backing.message}
                  value={headerObj.WPSH_BM_Backing_Backing}
                  status={error.WPSH_BM_Backing_Backing.status}
                  handleSelectChange={handleSelectChange}
                  label="Backing"
                  array={Backing}
                ></General_Single_Input_select_green>
              </Col>

              <Single_Input_WPS
                md={3}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_BM_Backing_PNo"
                name="WPSH_BM_Backing_PNo"
                label="P No."
                maxLength={maxlength_WPS.WPSH_BM_Backing_PNo}
                status={error.WPSH_BM_Backing_PNo.status}
                message={error.WPSH_BM_Backing_PNo.message}
                value={headerObj.WPSH_BM_Backing_PNo}
                onChange={handleChange}
                setError={setError}
                error={error}
              />

              <Single_Input_WPS
                md={3}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_BM_Backing_GNo"
                name="WPSH_BM_Backing_GNo"
                label="G No."
                maxLength={maxlength_WPS.WPSH_BM_Backing_GNo}
                status={error.WPSH_BM_Backing_GNo.status}
                message={error.WPSH_BM_Backing_GNo.message}
                value={headerObj.WPSH_BM_Backing_GNo}
                onChange={handleChange}
                setError={setError}
                error={error}
              />
            </Row>
            <Row className="mb-2 mt-2">
              <Single_Input_WPS
                md={6}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_BM_Backing_Material"
                name="WPSH_BM_Backing_Material"
                label="Backing Material"
                maxLength={maxlength_WPS.WPSH_BM_Backing_Material}
                status={error.WPSH_BM_Backing_Material.status}
                message={error.WPSH_BM_Backing_Material.message}
                value={headerObj.WPSH_BM_Backing_Material}
                onChange={handleChange}
                setError={setError}
                error={error}
              />

              {!isoverlay ? (
                <>
                  <Single_Input_WPS
                    md={6}
                    divstyle="T-input w-100"
                    // style={{ position: "relative", left: "8px" }}
                    id="WPSH_BM_Retainer"
                    name="WPSH_BM_Retainer"
                    label="Retainer"
                    maxLength={maxlength_WPS.WPSH_BM_Retainer}
                    status={error.WPSH_BM_Retainer.status}
                    message={error.WPSH_BM_Retainer.message}
                    value={headerObj.WPSH_BM_Retainer}
                    onChange={handleChange}
                    setError={setError}
                    error={error}
                  />
                </>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Single_Input_WPS
                // md={6}
                divstyle="T-input w-100"
                // style={{ position: "relative", left: "8px" }}
                id="WPSH_BM_Notes"
                name="WPSH_BM_Notes"
                label="Notes"
                maxLength={maxlength_WPS.WPSH_BM_Notes}
                status={error.WPSH_BM_Notes.status}
                message={error.WPSH_BM_Notes.message}
                value={headerObj.WPSH_BM_Notes}
                onChange={handleChange}
                setError={setError}
                error={error}
              />
            </Row>
          </Row>
          <Row style={{  padding: "20px 60px" }} className="WPS-Page-row-padding">
            <Row className="mb-1">
              <Col md={6}>
                <div className="d-flex mb-2">
                  <p className="WPS-blue-text">Thickness Range Qualified</p>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <input
                      type="radio"
                      name="wld_TRQ_Type"
                      value="Welded"
                      checked={headerObj.wld_TRQ_Type === "Welded"}
                      // onClick={() => {
                      //   resetForm();
                      // }}
                      // onChange={(e) => handleRadioChange(e)}
                      onChange={(e) => handleChange(e)}
                    />
                    <span
                      className={
                        headerObj.wld_TRQ_Type === "Welded"
                          ? "fontWeight ms-2"
                          : "ms-2"
                      }
                    >
                      As-Welded
                    </span>
                  </div>
                  <div className="ms-5">
                    <input
                      type="radio"
                      name="wld_TRQ_Type"
                      value="PWHT"
                      onChange={(e) => handleChange(e)}
                      checked={headerObj.wld_TRQ_Type === "PWHT"}
                    />
                    <span
                      className={
                        headerObj.wld_TRQ_Type === "PWHT"
                          ? "fontWeight ms-2"
                          : "ms-2"
                      }
                    >
                      With PWHT
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                {isoverlay ? (
                  <WPS_Thickness_overlay
                    headerObj={headerObj}
                    error={error}
                    handleChange={handleChange}
                    setError={setError}
                  ></WPS_Thickness_overlay>
                ) : (
                  <WPS_Thickness_Groove
                    headerObj={headerObj}
                    error={error}
                    handleChange={handleChange}
                    setError={setError}
                  ></WPS_Thickness_Groove>
                )}
              </Col>
            </Row>
            {isgroove ? (
              <Row className="mb-4">
                <Col md={12}>
                  <div class="T-input w-100">
                    <input
                      type="text"
                      id="WPSH_MinOvlFinThick"
                      name="WPSH_MinOvlFinThick"
                      maxLength={50}
                      placeholder=" "
                      className={
                        error.WPSH_MinOvlFinThick.status ? "err-input" : ""
                      }
                      value={headerObj.WPSH_MinOvlFinThick}
                      onChange={handleChange}
                      onInput={(e) => {
                        let error = isAlphanumericAllCharacters(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_MinOvlFinThick: error,
                        }));
                      }}
                    />
                    <label for="WPSH_MinOvlFinThick">
                      Minimum Overlay Finished Thickness
                    </label>
                  </div>
                  <div className="required-text">
                    {error.WPSH_MinOvlFinThick.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_MinOvlFinThick.message}
                      </Form.Text>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row className="mb-4">
              <Col>
                <p className="WPS-blue-text">Filler Metals (QW-404)</p>
                <Table className="text-center">
                  <thead>
                    <tr className="border thead-color">
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title" colSpan={3}>
                        <span style={{ position: "relative", left: "15px" }}>
                          Thickness Range Qualified
                        </span>
                      </th>
                    </tr>
                    <tr className="border thead-color">
                      <th className="thickness_title"></th>
                      <th className="thickness_title">Process</th>
                      <th className="thickness_title">Type</th>
                      <th className={`${overlaytext} thickness_title`}>
                        Weld Layer(s)
                      </th>
                      <th className="thickness_title">SFA</th>
                      <th className="thickness_title">Classification</th>
                      <th className="thickness_title">F. No.</th>
                      <th className="thickness_title">A. No.</th>
                      <th className="thickness_title">
                        Chemical Analysis or Trade Name
                      </th>
                      {/* <th className={`${Grooveclass} thickness_title`}>Make</th> */}
                      <th className="thickness_title">
                        {headerObj.wld_TRQ_Type === "PWHT"
                          ? "With PWHT Min."
                          : "As Welded Min."} 
                      </th>
                      <th className="thickness_title">
                        {headerObj.wld_TRQ_Type === "PWHT"
                          ? "With PWHT Max."
                          : "As Welded Max."}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailObj &&
                      detailObj.map((item, i) => {
                        return (
                          <tr
                            className={
                              detailObj[i]?.error
                                ? "border WPSSelect error"
                                : "border WPSSelect"
                            }
                          >
                            <td>
                              <Tooltip content={detailObj[i]?.errormessage}>
                                <img
                                  src={Error}
                                  alt="Error"
                                  style={{
                                    display: detailObj[i]?.error
                                      ? "block"
                                      : "none",
                                  }}
                                />
                              </Tooltip>
                            </td>
                            <td style={{ width: "100px" }}className="WPSSelectProcess" >
                              <SearchSelect
                                className="inspdrop "
                                data={ProcessActive}
                                key={i}
                                valueField={"wld_code"}
                                placeholder=""
                                // disabled={action === Action.ActionEdit ? true : false}
                                value={item.WPSD_Process}
                                labelField={"wld_code"}
                                onChange={(e) => {
                                  item.WPSD_Process = e.value;
                                  setIsEdited(true);
                                  if (e.value === "NA") {
                                    detailObj[i].WPSD_Process = "";
                                    detailObj[i].WPSD_Type = "";
                                    detailObj[i].WPSD_FM_SFA = "";
                                    detailObj[i].WPSD_FM_FNo = "";
                                    detailObj[i].WPSD_FM_Ano = "";
                                    detailObj[i].WPSD_FM_Classification = "";
                                    detailObj[
                                      i
                                    ].WPSD_FM_ChemicalAnalysis_TradeName = "";
                                    // detailObj[i].WPSD_FM_Make = "";
                                    detailObj[i].WPSD_FM_TRQ_Max = "";
                                    detailObj[i].WPSD_FM_TRQ_Min = "";
                                    detailObj[i].WPSH_WeldLayer = "";
                                  }

                                  // let a = ProcessActive.filter((b) => {
                                  //   console.log("dddaaattaaa", ProcessActive);
                                  //   return b.wld_code === e.value;
                                  // });

                                  // let c = a[0];

                                  setdetailObj((prevDetailObj) => {
                                    const updatedDetailObj = [...prevDetailObj];
                                    // const updatedItem = {
                                    //   ...item,
                                    //   WPSD_ProcessCategory: c.wld_p_category,
                                    // };
                                    // updatedDetailObj[i] = updatedItem;
                                    return updatedDetailObj;
                                  });
                                }}
                              ></SearchSelect>
                            </td>
                            <td className="WPSSelectProcess">
                              <SearchSelect
                                data={TypeData}
                                valueField={"wld_type"}
                                value={item.WPSD_Type}
                                labelField={"wld_type"}
                                placeholder=""
                                onChange={(e) => {
                                  setIsEdited(true);
                                  item.WPSD_Type = e.value;
                                  setdetailObj([...detailObj]);
                                  item.WPSD_Process !== "" ||
                                  item.WPSD_Type !== ""
                                    ? (detailObj[i].error = false)
                                    : (detailObj[i].error = true);
                                    let a = ProcessActive.filter((b) => {
                                      console.log("dddaaattaaa", ProcessActive);
                                      return b.wld_code === item.WPSD_Process;
                                    });
  
                                    let c = a[0];
  
                                    setdetailObj((prevDetailObj) => {
                                      const updatedDetailObj = [...prevDetailObj];
                                      const updatedItem = {
                                        ...item,
                                        WPSD_ProcessCategory: c.wld_p_category + "#" + e.value,
                                      };
                                      updatedDetailObj[i] = updatedItem;
                                      return updatedDetailObj;
                                    });
                                }}
                              ></SearchSelect>
                            </td>

                            <td className={overlaytext}>
                              <SearchSelect
                                data={WeldLayer}
                                valueField={"WeldLayer"}
                                value={item.WPSH_WeldLayer}
                                labelField={"WeldLayer"}
                                placeholder=""
                                onChange={(e) => {
                                  item.WPSH_WeldLayer = e.value;
                                  setdetailObj([...detailObj]);
                                  item.WPSD_Process !== "" ||
                                  item.WPSH_WeldLayer !== ""
                                    ? (detailObj[i].error = false)
                                    : (detailObj[i].error = true);
                                }}
                              ></SearchSelect>
                            </td>

                            <td>
                              <SearchSelect
                                className="inspdrop"
                                data={SfaActive}
                                valueField={"wld_code"}
                                value={item.WPSD_FM_SFA}
                                labelField={"wld_code"}
                                placeholder=""
                                onChange={(e) => {
                                  item.WPSD_FM_SFA = e.value;
                                  setdetailObj([...detailObj]);
                                  item.WPSD_Process !== ""
                                    ? item.WPSD_FM_SFA !== ""
                                      ? (detailObj[i].error = false)
                                      : (detailObj[i].error = true)
                                    : (detailObj[i].error = false);
                                }}
                              ></SearchSelect>
                            </td>
                            <td className="WpsInput">
                              <input
                                type="text"
                                className={
                                  classError[i]?.status ? "error-input" : ""
                                }
                                maxLength={item.wld_WPSType === "Groove" ? 15 : 13}
                                id="WPSD_FM_Classification"
                                name="WPSD_FM_Classification"
                                placeholder=" "
                                value={item.WPSD_FM_Classification}
                                onChange={(e) => handleChangedetailsarray(e, i)}
                                onInput={(e) => {
                                  let tempError = [...classError];
                                  let error = isAlphanumericAllCharacters(
                                    e.target.value
                                  );
                                  tempError[i] = error;
                                  setClassError([...tempError]);
                                  setError((prevState) => ({
                                    ...prevState,
                                    WPSD_FM_Classification: classError,
                                  }));
                                }}
                              />
                              {/* {classError[i]?.status && ( */}
                              <div
                                style={{
                                  position: "relative",
                                  top: "6px",
                                  right: "25px",
                                }}
                                className={
                                  classError[i]?.status ? "d-block" : "d-none"
                                }
                              >
                                {error.WPSD_FM_Classification.status || (
                                  <Tooltip
                                    content={
                                      error.WPSD_FM_Classification.message ||
                                      classError[i]?.message
                                    }
                                  >
                                    <img
                                      src={Error}
                                      alt="Error"
                                      className="ms-1 imgplace"
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </td>
                            <td>
                              <SearchSelect
                                className="inspdrop"
                                data={FnoActive}
                                valueField={"wld_code"}
                                value={item.WPSD_FM_FNo}
                                labelField={"wld_code"}
                                placeholder=""
                                onChange={(e) => {
                                  item.WPSD_FM_FNo = e.value;
                                  setdetailObj([...detailObj]);
                                  item.WPSD_Process !== ""
                                    ? item.WPSD_FM_FNo !== ""
                                      ? (detailObj[i].error = false)
                                      : (detailObj[i].error = true)
                                    : (detailObj[i].error = false);
                                }}
                              ></SearchSelect>
                            </td>
                            <td>
                              <SearchSelect
                                className="inspdrop"
                                data={AnoActive}
                                valueField={"wld_code"}
                                value={item.WPSD_FM_Ano}
                                labelField={"wld_code"}
                                placeholder=""
                                onChange={(e) => {
                                  item.WPSD_FM_Ano = e.value;
                                  setdetailObj([...detailObj]);
                                }}
                              ></SearchSelect>
                            </td>
                            <td className="WpsInput">
                              <input
                                type="text"
                                className={
                                  chemicalAnaError[i]?.status
                                    ? "error-input"
                                    : ""
                                }
                                maxLength={item.wld_WPSType === "Groove" ? 32 : 28}
                                id="WPSD_FM_ChemicalAnalysis_TradeName"
                                name="WPSD_FM_ChemicalAnalysis_TradeName"
                                placeholder=" "
                                value={item.WPSD_FM_ChemicalAnalysis_TradeName}
                                onChange={(e) => handleChangedetailsarray(e, i)}
                                onInput={(e) => {
                                  let tempError = [...chemicalAnaError];
                                  let error = isAlphanumericAllCharacters(
                                    e.target.value
                                  );
                                  tempError[i] = error;
                                  setChemicalAnaError([...tempError]);
                                  setError((prevState) => ({
                                    ...prevState,
                                    WPSD_FM_ChemicalAnalysis_TradeName:
                                      chemicalAnaError,
                                  }));
                                }}
                              />
                            </td>

                            <td className="WpsInput">
                              <input
                                type="text"
                                style={{position:"relative",width:"85px"}}
                                className={
                                  FMMinError[i]?.status ? "error-input" : ""
                                }
                                name={"WPSD_FM_TRQ_Min"}
                                maxLength={6}
                                value={item.WPSD_FM_TRQ_Min}
                                onChange={(e) => handleChangedetailsarray(e, i)}
                                onInput={(e) => {
                                  let tempError = [...FMMinError];
                                  let error = isAlphanumericAllCharacters(
                                    e.target.value
                                  );
                                  tempError[i] = error;
                                  setFMMinError([...tempError]);
                                  setError((prevState) => ({
                                    ...prevState,
                                    WPSD_FM_TRQ_Min: FMMinError,
                                  }));
                                }}
                              />
                            </td>
                            <td className="WpsInput">
                              <input
                                type="text"
                                style={{position:"relative",width:"85px"}}
                                className={
                                  FMMaxError[i]?.status ? "error-input" : ""
                                }
                                name={"WPSD_FM_TRQ_Max"}
                                maxLength={6}
                                value={item.WPSD_FM_TRQ_Max}
                                onChange={(e) => handleChangedetailsarray(e, i)}
                                onInput={(e) => {
                                  let tempError = [...FMMaxError];
                                  let error = isAlphanumericAllCharacters(
                                    e.target.value
                                  );
                                  tempError[i] = error;
                                  setFMMaxError([...tempError]);
                                  setError((prevState) => ({
                                    ...prevState,
                                    WPSD_FM_TRQ_Max: FMMaxError,
                                  }));
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Table className="text-center">
                  <thead>
                    <tr className="border thead-color">
                      <th className="thickness_title"></th>
                      <th className="thickness_title">SFA</th>
                      <th className="thickness_title">Classification</th>
                      <th className="thickness_title">F. No.</th>
                      <th className="thickness_title">A. No.</th>
                      <th className="thickness_title">
                        Chemical Analysis or Trade Name
                      </th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                      <th className="thickness_title"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isgroove ? (
                      ""
                    ) : (
                      <tr
                        className={
                          consumError.length > 1 !== ""
                            ? "border radioInput error"
                            : "border radioInput"
                        }
                      >
                        <th
                          className={
                            consumError.length > 1 !== "" ? "error-head" : ""
                          }
                        >
                          <div
                            style={{
                              textAlign: "left",
                              position: "relative",
                              left: "11px",
                            }}
                          >
                            Consumable Insert
                            {consumError.length > 1 && (
                              <Tooltip content={message(consumError)}>
                                <img src={Error} alt="Error" className="ms-1" />
                              </Tooltip>
                            )}
                          </div>
                        </th>
                        <td className="WpsInput">
                          <WPS_Input
                            valuefield={headerObj.WPSH_ConsuInsert_SFA}
                            handleChange={handleChange}
                            status={consumError}
                            setError={setError}
                            maxLength={3}
                            id="WPSH_ConsuInsert_SFA"
                            required={headerObj.WPSH_consumRadio}
                            action={action}
                            setErrorObj={setConsumError}
                          ></WPS_Input>
                        </td>
                        <td className="WpsInput">
                          <WPS_Input
                            valuefield={
                              headerObj.WPSH_ConsuInsert_Classification
                            }
                            handleChange={handleChange}
                            maxLength={12}
                            status={consumError}
                            setError={setError}
                            id="WPSH_ConsuInsert_Classification"
                            required={headerObj.WPSH_consumRadio}
                            action={action}
                            setErrorObj={setConsumError}
                          ></WPS_Input>
                        </td>
                        <td className="WpsInput">
                          <WPS_Input
                            valuefield={headerObj.WPSH_ConsuInsert_FNo}
                            handleChange={handleChange}
                            maxLength={3}
                            status={consumError}
                            setError={setError}
                            id="WPSH_ConsuInsert_FNo"
                            required={headerObj.WPSH_consumRadio}
                            action={action}
                            setErrorObj={setConsumError}
                          ></WPS_Input>
                        </td>
                        <td className="WpsInput">
                          <WPS_Input
                            valuefield={headerObj.WPSH_ConsuInsert_ANo}
                            handleChange={handleChange}
                            maxLength={3}
                            status={consumError}
                            setError={setError}
                            id="WPSH_ConsuInsert_ANo"
                            required={headerObj.WPSH_consumRadio}
                            action={action}
                            setErrorObj={setConsumError}
                          ></WPS_Input>
                        </td>

                        <td className="WpsInput">
                          <WPS_Input
                            valuefield={
                              headerObj.WPSH_ConsuInsert_ChemicalAnalysis_TradeName
                            }
                            handleChange={handleChange}
                            maxLength={30}
                            status={consumError}
                            setError={setError}
                            id="WPSH_ConsuInsert_ChemicalAnalysis_TradeName"
                            required={headerObj.WPSH_consumRadio}
                            action={action}
                            setErrorObj={setConsumError}
                          ></WPS_Input>
                        </td>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="WPSH_consumRadio"
                              value="Required"
                              checked={
                                headerObj.WPSH_consumRadio === "Required"
                              }
                              onChange={(e) => {
                                handleChange(e);
                                setheaderObj((prevState) => ({
                                  ...prevState,
                                  WPSH_ConsuInsert_SFA: "",
                                  WPSH_ConsuInsert_Classification: "",
                                  WPSH_ConsuInsert_FNo: "",
                                  WPSH_ConsuInsert_ANo: "",
                                  WPSH_ConsuInsert_ChemicalAnalysis_TradeName:
                                    "",
                                }));
                                setConsumError((result) => [
                                  ...result,
                                  {
                                    id: "all",
                                    msg: "All fields are Mandatory",
                                    className: "border radioInput error",
                                  },
                                ]);
                                setConsumError((result) =>
                                  result.filter((i) => i.id !== "all")
                                );
                              }}
                            />
                            <span
                              className={
                                headerObj.WPSH_consumRadio === "Required"
                                  ? "fontWeight ms-2"
                                  : "ms-2"
                              }
                            >
                              Required
                            </span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="WPSH_consumRadio"
                              value="Optional"
                              checked={
                                headerObj.WPSH_consumRadio === "Optional"
                              }
                              onChange={(e) => {
                                handleChange(e);
                                setConsumError((result) =>
                                  result.filter((i) => i.id !== "all")
                                );
                              }}
                            />
                            <span
                              className={
                                headerObj.WPSH_consumRadio === "Optional"
                                  ? "fontWeight ms-2"
                                  : "ms-2"
                              }
                            >
                              Optional
                            </span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="radio"
                              name="WPSH_consumRadio"
                              value="None"
                              checked={headerObj.WPSH_consumRadio === "None"}
                              onChange={(e) => {
                                handleChange(e);
                                setheaderObj((prevState) => ({
                                  ...prevState,
                                  WPSH_ConsuInsert_SFA: "-",
                                  WPSH_ConsuInsert_Classification: "-",
                                  WPSH_ConsuInsert_FNo: "-",
                                  WPSH_ConsuInsert_ANo: "-",
                                  WPSH_ConsuInsert_ChemicalAnalysis_TradeName:
                                    "-",
                                }));
                                setConsumError((result) =>
                                  result.filter((i) => i.id !== "all")
                                );
                              }}
                            />
                            <span
                              className={
                                headerObj.WPSH_consumRadio === "None"
                                  ? "fontWeight ms-2"
                                  : "ms-2"
                              }
                            >
                              None
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr
                      className={
                        fluxError.length > 1 !== ""
                          ? "border radioInput error"
                          : "border radioInput"
                      }
                    >
                      <th
                        className={fluxError.length !== "" ? "error-head" : ""}
                      >
                        <div>
                          <p className="radioheading">Flux</p>

                          {fluxError.length > 1 && (
                            <Tooltip content={message(fluxError)}>
                              <img src={Error} alt="Error" className="ms-1" />
                            </Tooltip>
                          )}
                        </div>
                      </th>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={headerObj.WPSH_Flux_SFA}
                          handleChange={handleChange}
                          status={fluxError}
                          maxLength={6}
                          setError={setError}
                          id="WPSH_Flux_SFA"
                          required={headerObj.WPSH_fluxRadio}
                          action={action}
                          setErrorObj={setFluxError}
                        ></WPS_Input>
                      </td>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={headerObj.WPSH_Flux_Classification}
                          handleChange={handleChange}
                          maxLength={12}
                          status={fluxError}
                          setError={setError}
                          id="WPSH_Flux_Classification"
                          required={headerObj.WPSH_fluxRadio}
                          action={action}
                          setErrorObj={setFluxError}
                        ></WPS_Input>
                      </td>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={headerObj.WPSH_Flux_FNo}
                          maxLength={3}
                          handleChange={handleChange}
                          status={fluxError}
                          setError={setError}
                          id="WPSH_Flux_FNo"
                          required={headerObj.WPSH_fluxRadio}
                          action={action}
                          setErrorObj={setFluxError}
                        ></WPS_Input>
                      </td>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={headerObj.WPSH_Flux_ANo}
                          handleChange={handleChange}
                          maxLength={3}
                          status={fluxError}
                          setError={setError}
                          id="WPSH_Flux_ANo"
                          required={headerObj.WPSH_fluxRadio}
                          action={action}
                          setErrorObj={setFluxError}
                        ></WPS_Input>
                      </td>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={
                            headerObj.WPSH_Flux_ChemicalAnalysis_TradeName
                          }
                          handleChange={handleChange}
                          maxLength={headerObj.wld_WPSType === "Groove" ? 30 : 28}
                          status={fluxError}
                          setError={setError}
                          id="WPSH_Flux_ChemicalAnalysis_TradeName"
                          required={headerObj.WPSH_fluxRadio}
                          action={action}
                          setErrorObj={setFluxError}
                        ></WPS_Input>
                      </td>
                      <td>
                        <div>
                          <input
                            type="radio"
                            name="WPSH_fluxRadio"
                            value="Required"
                            checked={headerObj.WPSH_fluxRadio === "Required"}
                            onChange={(e) => {
                              handleChange(e);
                              setheaderObj((prevState) => ({
                                ...prevState,
                                WPSH_Flux_SFA: "",
                                WPSH_Flux_Classification: "",
                                WPSH_Flux_FNo: "",
                                WPSH_Flux_ANo: "",
                                WPSH_Flux_ChemicalAnalysis_TradeName: "",
                              }));
                              setFluxError((result) => [
                                ...result,
                                {
                                  id: "all",
                                  msg: "All fields are Mandatory",
                                  className: "border radioInput error",
                                },
                              ]);
                              setFluxError((result) =>
                                result.filter((i) => i.id !== "all")
                              );
                            }}
                          />
                          <span
                            className={
                              headerObj.WPSH_fluxRadio === "Required"
                                ? "fontWeight ms-2"
                                : "ms-2"
                            }
                          >
                            Required
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="radio"
                            name="WPSH_fluxRadio"
                            value="Optional"
                            checked={headerObj.WPSH_fluxRadio === "Optional"}
                            onChange={(e) => {
                              handleChange(e);
                              setFluxError((result) =>
                                result.filter((i) => i.id !== "all")
                              );
                            }}
                          />
                          <span
                            className={
                              WPSH_fluxRadio === "Optional"
                                ? "fontWeight ms-2"
                                : "ms-2"
                            }
                          >
                            Optional
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="radio"
                            name="WPSH_fluxRadio"
                            value="None"
                            checked={headerObj.WPSH_fluxRadio === "None"}
                            onChange={(e) => {
                              handleChange(e);
                              setheaderObj((prevState) => ({
                                ...prevState,
                                WPSH_Flux_SFA: "-",
                                WPSH_Flux_Classification: "-",
                                WPSH_Flux_FNo: "-",
                                WPSH_Flux_ANo: "-",
                                WPSH_Flux_ChemicalAnalysis_TradeName: "-",
                              }));
                              setFluxError((result) =>
                                result.filter((i) => i.id !== "all")
                              );
                            }}
                          />
                          <span
                            className={
                              headerObj.WPSH_fluxRadio === "None"
                                ? "fontWeight ms-2"
                                : "ms-2"
                            }
                          >
                            None
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr
                      className={
                        supfilterError.length > 1 !== ""
                          ? "border radioInput error"
                          : "border radioInput"
                      }
                    >
                      <th
                        className={
                          supfilterError.length > 1 !== "" ? "error-head" : ""
                        }
                      >
                        <div>
                          <p className="radioheading">Sup Filler</p>

                          {supfilterError.length > 1 && (
                            <Tooltip content={message(supfilterError)}>
                              <img src={Error} alt="Error" className="ms-1" />
                            </Tooltip>
                          )}
                        </div>
                      </th>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={headerObj.WPSH_SupFiller_SFA}
                          maxLength={3}
                          handleChange={handleChange}
                          status={supfilterError}
                          setError={setError}
                          id="WPSH_SupFiller_SFA"
                          required={headerObj.WPSH_filterRadio}
                          action={action}
                          setErrorObj={setSupFilterError}
                        ></WPS_Input>
                      </td>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={headerObj.WPSH_SupFiller_Classification}
                          maxLength={12}
                          handleChange={handleChange}
                          status={supfilterError}
                          setError={setError}
                          id="WPSH_SupFiller_Classification"
                          required={headerObj.WPSH_filterRadio}
                          action={action}
                          setErrorObj={setSupFilterError}
                        ></WPS_Input>
                      </td>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={headerObj.WPSH_SupFiller_FNo}
                          handleChange={handleChange}
                          maxLength={3}
                          status={supfilterError}
                          setError={setError}
                          id="WPSH_SupFiller_FNo"
                          required={headerObj.WPSH_filterRadio}
                          action={action}
                          setErrorObj={setSupFilterError}
                        ></WPS_Input>
                      </td>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={headerObj.WPSH_SupFiller_ANo}
                          handleChange={handleChange}
                          maxLength={3}
                          status={supfilterError}
                          setError={setError}
                          id="WPSH_SupFiller_ANo"
                          required={headerObj.WPSH_filterRadio}
                          action={action}
                          setErrorObj={setSupFilterError}
                        ></WPS_Input>
                      </td>
                      <td className="WpsInput">
                        <WPS_Input
                          valuefield={
                            headerObj.WPSH_SupFiller_ChemicalAnalysis_TradeName
                          }
                          maxLength={headerObj.wld_WPSType === "Groove" ? 30 : 28}
                          handleChange={handleChange}
                          status={supfilterError}
                          setError={setError}
                          id="WPSH_SupFiller_ChemicalAnalysis_TradeName"
                          required={headerObj.WPSH_filterRadio}
                          action={action}
                          setErrorObj={setSupFilterError}
                        ></WPS_Input>
                      </td>
                      <td>
                        <div>
                          <input
                            type="radio"
                            name="WPSH_filterRadio"
                            value="Required"
                            checked={headerObj.WPSH_filterRadio === "Required"}
                            onChange={(e) => {
                              handleChange(e);
                              setheaderObj((prevState) => ({
                                ...prevState,
                                WPSH_SupFiller_SFA: "",
                                WPSH_SupFiller_Classification: "",
                                WPSH_SupFiller_FNo: "",
                                WPSH_SupFiller_ANo: "",
                                WPSH_SupFiller_ChemicalAnalysis_TradeName: "",
                              }));
                              setSupFilterError((result) => [
                                ...result,
                                {
                                  id: "all",
                                  msg: "All fields are Mandatory",
                                  className: "border radioInput error",
                                },
                              ]);
                              setSupFilterError((result) =>
                                result.filter((i) => i.id !== "all")
                              );
                            }}
                          />
                          <span
                            className={
                              headerObj.WPSH_filterRadio === "Required"
                                ? "fontWeight ms-2"
                                : "ms-2"
                            }
                          >
                            Required
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="radio"
                            name="WPSH_filterRadio"
                            value="Optional"
                            checked={headerObj.WPSH_filterRadio === "Optional"}
                            onChange={(e) => {
                              handleChange(e);
                              setSupFilterError((result) =>
                                result.filter((i) => i.id !== "all")
                              );
                            }}
                          />
                          <span
                            className={
                              headerObj.WPSH_filterRadio === "Optional"
                                ? "fontWeight ms-2"
                                : "ms-2"
                            }
                          >
                            Optional
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="radio"
                            name="WPSH_filterRadio"
                            value="None"
                            checked={headerObj.WPSH_filterRadio === "None"}
                            onChange={(e) => {
                              handleChange(e);
                              setheaderObj((prevState) => ({
                                ...prevState,
                                WPSH_SupFiller_SFA: "-",
                                WPSH_SupFiller_Classification: "-",
                                WPSH_SupFiller_FNo: "-",
                                WPSH_SupFiller_ANo: "-",
                                WPSH_SupFiller_ChemicalAnalysis_TradeName: "-",
                              }));
                              setSupFilterError((result) =>
                                result.filter((i) => i.id !== "all")
                              );
                            }}
                          />
                          <span
                            className={
                              headerObj.WPSH_filterRadio === "None"
                                ? "fontWeight ms-2"
                                : "ms-2"
                            }
                          >
                            None
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <General_Single_Input_select
                  name="WPSH_FM_FluxType"
                  value={headerObj.WPSH_FM_FluxType}
                  message={error.WPSH_FM_FluxType.message}
                  status={error.WPSH_FM_FluxType.status}
                  handleSelectChange={handleSelectChange}
                  label="Flux Type"
                  array={Flux_type}
                ></General_Single_Input_select>
              </Col>
              <Col>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_FM_FluxItemBatch"
                    name="WPSH_FM_FluxItemBatch"
                    maxLength={30}
                    placeholder=" "
                    value={headerObj.WPSH_FM_FluxItemBatch}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error = isAlphanumericAllCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_FM_FluxItemBatch: error,
                      }));
                    }}
                  />
                  <label for="WPSH_FM_FluxItemBatch">Flux batch</label>
                </div>
                <div className="required-text">
                  {error.WPSH_FM_FluxItemBatch.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_FM_FluxItemBatch.message}
                    </Form.Text>
                  )}
                </div>
              </Col>

              <Col>
                <div class="T-input w-100">
                  <select
                    id="WPSH_FM_FluxFromRecrush"
                    name="WPSH_FM_FluxFromRecrush"
                    placeholder=" "
                    value={headerObj.WPSH_FM_FluxFromRecrush}
                    className={
                      error.WPSH_FM_FluxFromRecrush.status ? "err-input" : ""
                    }
                    onChange={(e) => {
                      handleChange(e);
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_FM_FluxFromRecrush: {},
                      }));
                    }}
                  >
                    <option value=" " selected>
                      select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="NA">NA</option>
                  </select>
                  <label for="WPSH_FM_FluxFromRecrush">Flux from recrush</label>
                </div>
                <div className="required-text">
                  {error.WPSH_FM_FluxFromRecrush.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_FM_FluxFromRecrush.message}
                    </Form.Text>
                  )}
                </div>
              </Col>

              <Col>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                    <SearchSelectProject
                      // className="inspdrop"
                      id="WPSH_FM_FluxItemCode"
                      name="WPSH_FM_FluxItemCode"
                      className={
                        error.WPSH_FM_FluxItemCode.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_FM_FluxItemCode.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={ItemActive}
                      stylenormal={normalstyle}
                      valueField={"wld_itemcode"}
                      value={headerObj.WPSH_FM_FluxItemCode}
                      labelField={"wld_itemcode"}
                      placeholder=""
                      onChange={(fluxList) => {
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_FM_FluxItemCode: fluxList.value,
                        }));

                        setError((prevState) => ({
                          ...prevState,
                          WPSH_FM_FluxItemCode: {},
                        }));
                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Flux item code</label>
                  <div className="required-text-select">
                    {error.WPSH_FM_FluxItemCode.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_FM_FluxItemCode.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-2">
              {isgroove ? (
                ""
              ) : (
                <Col>
                  <General_Single_Input_select
                    name="WPSH_FM_AlloyFlux"
                    value={headerObj.WPSH_FM_AlloyFlux}
                    message={error.WPSH_FM_AlloyFlux.message}
                    status={error.WPSH_FM_AlloyFlux.status}
                    handleSelectChange={handleSelectChange}
                    label="Alloy Flux"
                    array={yesno}
                  ></General_Single_Input_select>
                </Col>
              )}
              <Col md={3}>
                <General_Single_Input_select
                  name="WPSH_FM_AlloyElement"
                  value={headerObj.WPSH_FM_AlloyElement}
                  message={error.WPSH_FM_AlloyElement.message}
                  status={error.WPSH_FM_AlloyElement.status}
                  handleSelectChange={handleSelectChange}
                  label="Alloy Element"
                  array={yesno}
                ></General_Single_Input_select>
              </Col>
              {isgroove ? (
                ""
              ) : (
                <Col>
                  <General_Single_Input_select
                    name="WPSH_FM_Add_Del_Filler"
                    value={headerObj.WPSH_FM_Add_Del_Filler}
                    message={error.WPSH_FM_Add_Del_Filler.message}
                    status={error.WPSH_FM_Add_Del_Filler.status}
                    handleSelectChange={handleSelectChange}
                    label="Addition/Deletion of Filler"
                    array={yesno}
                  ></General_Single_Input_select>
                </Col>
              )}
              {isgroove ? (
                ""
              ) : (
                <Col>
                  <General_Single_Input_select
                    name="WPSH_FM_Add_Del_Flux"
                    value={headerObj.WPSH_FM_Add_Del_Flux}
                    message={error.WPSH_FM_Add_Del_Flux.message}
                    status={error.WPSH_FM_Add_Del_Flux.status}
                    handleSelectChange={handleSelectChange}
                    label="Addition/Deletion of Flux(GTAW)"
                    array={yesno}
                  ></General_Single_Input_select>
                </Col>
              )}

              {isgroove ? (
                <Col md={6}>
                  <General_Single_Input_select
                    name="WPSH_FM_Add_Del_orchange"
                    value={headerObj.WPSH_FM_Add_Del_orchange}
                    message={error.WPSH_FM_Add_Del_orchange.message}
                    status={error.WPSH_FM_Add_Del_orchange.status}
                    handleSelectChange={handleSelectChange}
                    label="Addition/Deletion or Changes > 10% in suppl. filler metal"
                    array={yesno}
                  ></General_Single_Input_select>
                </Col>
              ) : (
                ""
              )}

              {isgroove ? (
                <Col md={3}>
                  {/* <General_Single_Input_select
                    name="WPSH_FM_NominalFlux"
                    value={headerObj.WPSH_FM_NominalFlux}
                    message={error.WPSH_FM_NominalFlux.message}
                    status={error.WPSH_FM_NominalFlux.status}
                    handleSelectChange={handleSelectChange}
                    label="Nominal Flux Comp."
                    array={yesno}
                  ></General_Single_Input_select> */}
                  <div class="T-input w-100">
                    <input
                      type="text"
                      id="WPSH_FM_NominalFlux"
                      name="WPSH_FM_NominalFlux"
                      maxLength={30}
                      placeholder=" "
                      value={headerObj?.WPSH_FM_NominalFlux}
                      onChange={handleChange}
                      onInput={(e) => {
                        let error = isAlphanumericAllCharacters(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_FM_NominalFlux: error,
                        }));
                      }}
                    />
                    <label for="WPSH_FM_NominalFlux">Nominal Flux Comp.</label>
                  </div>
                  <div className="required-text">
                    {error.WPSH_FM_NominalFlux.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_FM_NominalFlux.message}
                      </Form.Text>
                    )}
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <div class="T-input w-100">
                  <input
                    type="text"
                    id="WPSH_FM_FilerMetal"
                    name="WPSH_FM_FilerMetal"
                    maxLength={headerObj.wld_WPSType === "Groove" ? 25 : 23}
                    placeholder=" "
                    value={headerObj?.WPSH_FM_FilerMetal}
                    onChange={handleChange}
                    onInput={(e) => {
                      let error = isAlphanumericAllCharacters(e.target.value);
                      setError((prevState) => ({
                        ...prevState,
                        WPSH_FM_FilerMetal: error,
                      }));
                    }}
                  />
                  <label for="WPSH_FM_FilerMetal">Filler metal others</label>
                </div>
                <div className="required-text">
                  {error.WPSH_FM_FilerMetal.status && (
                    <Form.Text className="text-danger">
                      {error.WPSH_FM_FilerMetal.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
            </Row>
          </Row>
        </fieldset>
      </Row>
    );
  }
);

export default General;
