import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
// import SidebarItem from "./SidebarItem"
import NavItem from "./NavItem";
import NavImage from "./NavImage";

import BigLogo from "../../icons/Biglogo.png";
import Logo from "../../icons/LOGO.png";
import gLogo1 from "../../icons/glogo1.png";
import gLogo from "../../icons/glogo.png";

// import close from "../../icons/CloseIcon.svg"

import "../../Assests/Css/sidebar.css";
import Account from "../Account";
import { useLocation } from "react-router-dom";
import { getUserMenu, getMenuDetails } from "../../Services/menu-service";


const SideBar = forwardRef((props, ref) => {
  const pathname = useLocation().pathname;

  var mobile = window.matchMedia("(max-width: 600px)").matches;
  //console.log(mobile);

  const [isOpen, setIsOpen] = useState(false);
  const [mobileOpen, setmobileOpen] = useState(mobile);

  //console.log(mobileOpen);
  const [activeIndex, setactiveIndex] = useState(0);

  const toggle = () => {
    
    setIsOpen(!isOpen);
    var mobile = window.matchMedia("(max-width: 600px)").matches;
    if (mobile) setmobileOpen(!mobileOpen);
    // console.log("change...",isOpen)
    // console.log("change mobile",mobileOpen)
    props.closemenu();
    setactiveIndex(0);
    setLabel(getMenuDetails(JSON.stringify(getmenudata), window.location.pathname));
  };

  useEffect(() => {
    console.log("sidebar",  window.location.pathname  )
    setLabel(getMenuDetails(JSON.stringify(getmenudata), window.location.pathname));
    //   var mobile = window.matchMedia("(max-width: 600px)").matches;
    //  // console.log("Mobile open",!mobileOpen,"Open",!isOpen)
    //   console.log("Mobile open",mobileOpen,"Open",isOpen)
    //    if(mobile){
    //     console.log("sswwww",!mobileOpen,!isOpen)
    //     ifsetmobileOpen(!mobileOpen);

    //}
  }, [useLocation().pathname]);
  var c = 0;
  var d = 0;

  function onHide(count, menupath,children) {
  //alert(count)
    if (activeIndex !== count) setactiveIndex(count);
    else setactiveIndex(0);
   
    if(children==undefined || children == "")
      toggle()
  }
  const [getmenudata, setmenudata] = useState([]);
  const [Labels, setLabel] = useState({
    title: "",
    button: false,
    buttontext: "",
    currentMenu: "",
    path: "",
    component: "",
  });

  const fetchMenuMaster = async () => {
    
    let result1;
    if (sessionStorage.getItem("UserAccess") === null) {

      var webDisplay = "W"
      var mobileDisplay = "WM"
      if(mobileOpen) webDisplay =""
      result1 = await getUserMenu(sessionStorage.getItem("Role"),webDisplay);
      result1 = result1.filter((i) => (i.menu_display == webDisplay || i.menu_display == mobileDisplay)  )
    } else result1 = JSON.parse(sessionStorage.getItem("UserAccess"));

    
    setmenudata(result1);

    sessionStorage.setItem("UserAccess", JSON.stringify(result1));
  };

  useEffect(() => {
    if (getmenudata !== undefined) {
      fetchMenuMaster();
    }
  }, []);

  useImperativeHandle(ref, () => ({
    mobiletoggle: () => {
      setmobileOpen(!mobileOpen);
      setIsOpen(!isOpen);
    },
  }));

  useEffect(() => {

    setLabel(getMenuDetails(JSON.stringify(getmenudata), window.location.pathname));

  }, [getmenudata]);



  const handleScroll = (event) =>{
    const delta = Math.sign(event.deltaY)

    const sidebar = document.querySelector(".sidebar")

    sidebar.scrollTop += delta * 50;
  }
  //console.log("current",Labels.currentMenu)


  const handleHover = (isHovered) => {
    const sidebar = document.querySelector(".sidebar")
    sidebar.style.overflowY = isHovered ? "auto" : "hidden";
  }

  var currentMenu
  console.log("iiitttdddddd",currentMenu)

  // onWheel={handleScroll}

  return (
    <>
      <div className="sidebar"  >
        <div className={isOpen ? "menu-mobile" : "menu-mobile-close"}>
          <div className="profileimage"></div>
          <div
            style={{ display: isOpen ? "inline-block" : "none" }}
            className="profilemobile"
          >
            <Account />
          </div>
        </div>

        <div className={isOpen ? "sideopen" : "sideclose"} onMouseEnter={()=> handleHover(true)} onMouseLeave={() => handleHover(false)} style={{maxHeight:"90vh"}}>
          <div style={{ width: isOpen ? "100%" : "100%" }}>
            <div style={{ display: isOpen ? "none" : "block" }}>
              <img
                className="menu-toplogo"
                src={Logo}
                onClick={toggle}
                style={{ paddingTop: "40%" }}
                alt=""
              ></img>
            </div>
            <div style={{ display: isOpen ? "block" : "none" }}>
              <img
                className="menu-toplogo"
                src={BigLogo}
                onClick={toggle}
                style={{ paddingTop: "8%" }}
                alt=""
              ></img>
            </div>
          </div>

          <div className="mt-4">
            <div style={{ display: isOpen ? "block" : "none" }}>
              {getmenudata.filter((i)=> i.menu_parent_id == undefined).sort((a, b) => Number(a.menu_id) > Number(b.menu_id) ? 1 : -1).map((listmenu, index) => {
                {
                  
               //   console.log("side2",item,getmenudata.filter((i)=> {console.log("side3",i[0],i[0].menu_parent_id == undefined,i[0].menu_parent_id );return(i.menu_parent_id == undefined)}))
              var item = listmenu
              console.log("itrrrrmm",item.menu_group,)
              //console.log("side1",item,getmenudata.filter((c)=>c.menu_parent_id == item.menu_id))
              c = c + 1;
              var children = getmenudata.filter((c)=>c.menu_parent_id == item.menu_id)
              }
                return (
                  <NavItem
                    item={item}
                    children={children}
                    counter={c}
                    key={"Nav" + item.menu_id}
                    IsChildopen={activeIndex === item.menu_id}
                    onHide={() => onHide(item.menu_id, item.menu_path,children)}
                    toggle={() => toggle()}
                    currentMenu={currentMenu}
                  ></NavItem>
                );
              })}
            </div>

            <div style={{ display: isOpen || mobileOpen ? "none" : "block" }}>
            {getmenudata.filter((i)=> i.menu_parent_id == undefined).sort((a, b) => Number(a.menu_id) > Number(b.menu_id) ? 1 : -1).map((listmenu, index) => {
                {
                  d = d + 1;
                  var item = listmenu
                  currentMenu = Labels ? Labels.currentMenu : "DASHBOARD"

                  console.log("iiittt",currentMenu)
                }
                return (
                  
                  <NavImage
                    item={item}
                    isSelected={item.menu_group === currentMenu}
                    icon={
                      item.menu_group !== currentMenu
                        ? "/sidebar/" + item.menu_name + "_Default.svg"
                        : "/sidebar/" + item.menu_name + "_Active.svg"
                    }
                    counter={d}
                    setIsOpen={setIsOpen}
                    onHide={() => onHide(d, item.menu_path,0)}
                  >
                    <a href={item.menu_path}></a>
                  </NavImage>
                );
              })}
            </div>
            <div style={{ display: isOpen ? "none" : "block" }}>
              <img className="menu-gicons" src={gLogo} alt=""></img>
            </div>
            <div style={{ display: isOpen ? "block" : "none" }}>
              <img className="menu-gicons1" src={gLogo1} alt=""></img>
            </div>
          </div>
  
        </div>
      </div>

      {/* <div style={{ display: isOpen ? "block" : "none" }}>
              <img className="menu-gicons1" src={gLogo1} alt=""></img>
            </div> */}
    
    </>
  );
});
export default SideBar;
