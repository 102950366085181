// ---------------------------------------------------------------------------------------------------------------//

//Date - 28/3/2024.
//Author - Sagar Gawade.
//Session - PQR Transaction(Groove, Overlay) - Page_2.
//Contains - ADD / EDIT / GET API'S of PQR Groove and Overlay Session.
//Workflow - 1) There is no approval process,user can select Welding Engg Name and Witness ByClicks on save button to save the data.
//         - 2) Initiator Can Edit Record in both Freezed and Temp Saved Status.
//         - 3) In 2st Page API is called in such a way that  it will only update data in batches(UpdatePQRHeaderComplete)
//              and it will be save in Header of respective tables as there is no field on this page relataid to detail or PQR Process

// ---------------------------------------------------------------------------------------------------------------//
import { Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import defaultimg from "../../icons/noimage.png";
import { MessageTypes, Action, Status } from "./PQRObjects/PQR_entity";
import { Single_Input } from "./PQRObjects/PQR_Grid_Tubesheet";
import {
  isAlphanumericAllSpecialCharacters,
  
} from "../../functions/validations";
import { getPresignedURL } from "../../Services/Welder-services";
import { IsUserMasterBand } from "../../Services/user-service";
import { PQRHeader } from "./PQRObjects/PQRHeader";
import {
  PQR_Page3_Table_ColumnN,
} from "../utility/CommonComponents/wpq_Page3_table_components";
import {
  PQR_Page2_Tensile,
  PQR_Page2_Certification,
  PQR_Page2_CertificationLabRef,
  PQR_Page2_Guided,
  PQR_Groove_Page2_Hardness,
  PQR_Page2_Guided_Overlay,
  PQR_Page2_Other_test,
  UpdatePQRHeaderComplete,
  PQR_Page2_Toughness,
} from "./PQRObjects/PQR_Validations";
import {
  PQR_GroovePage2,
  PQR_OverlayPage2,
  PQR_GroovePage1,
  PQR_Detail_GroovePage1,
  PQR_Detail_OverlayPage1,
  PQR_OverlayPage1,
} from "./PQRObjects/PQR_entity";
import { SearchSelectProject } from "../utility/search-select";
import { getAllUserMaster } from "../../Services/user-service";
import { Date_Input } from "../utility/CommonComponents/Date_Component";
import { Date_InputUser } from "../utility/CommonComponents/Date_Component";


const PQR_Page2 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      copydata,
      WPStype,
    },
    ref
  ) => {
    const location = useLocation();
    const avatar = { defaultimg };

    let Band = sessionStorage.getItem("Band");

    const [users, setUsers] = useState(null);

    let folderwld = "wld_signatures";
    let folderwps = "WPS_Groove";
    let sessionName = "PQRGrooveOverlay";

    let initiator = Band !== "P" && Band !== "T";

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const todayDate = yyyy + "-" + mm + "-" + dd;
    const maxDate = today;

    var readonly =
      location.state?.action === Action.ActionCopy ||
      (initiator && headerObj.WPSH_Status === Status.StatusPending)
        ? true
        : false;
    const [isReadonly, setIsReadonly] = useState(readonly);

    const [IsEdited, setIsEdited] = useState(false);
    const [action, setaction] = useState(location.state?.action);

    const [previewUrl, setPreviewUrl] = useState([]);
    const [previewuser, setPreviewuser] = useState(
      headerObj?.PQR_SignaturePrepared
        ? headerObj?.PQR_SignaturePrepared
        : avatar.defaultimg
    );

    const [previewapproved, setPreviewapproved] = useState(
      headerObj?.PQR_SignatureApproved
        ? headerObj?.PQR_SignatureApproved
        : avatar.defaultimg
    );

    // console.log("headdddddcc",headerObj?.Certification)

    // console.log("headddddd",headerObj?.CertificationLabRef)

 
    let Tensile_Test = headerObj?.Tensile_Test;
    let GuidedBend_Test = headerObj?.GuidedBend_Test;
    let Toughness_Test = headerObj?.Toughness_Test;
    let Hardness_Test_Groove = headerObj?.Hardness_Test_Groove;
    let Other_Test = headerObj?.Other_Test;
    let Certification = headerObj?.Certification;
    let CertificationLabRef = headerObj?.CertificationLabRef;
    let GuidedBend_Test_Overlay = headerObj?.GuidedBend_Test_Overlay;

    const[Tensile_Test_Disabled,setTensile_Test_Disabled] = useState(false)

    const[Hardness_Test_Groove_Disabled, setHardness_Test_Groove_Disabled] = useState(false)

    const[GuidedBend_Test_Overlay_Disabled, setGuidedBend_Test_Overlay_Disabled] = useState(false)
    const[GuidedBend_Test_Disabled, setGuidedBend_Test_Disabled] = useState(false)
    const[Toughness_Test_Disabled, setToughness_Test_Disabled] = useState(false)
    const[Other_Test_Disabled, setOther_Test_Disabled] = useState(false)
    const[Certification_Disabled, setCertification_Disabled] = useState(false)
    

    const [userApproveSignature, setuserApproveSignature] = useState(null);


    const [todayFormat, setTodayFormat] = useState("");

    var isoverlay = WPStype === "Groove" ? false : true;

     

   
    const [normalstyle, setNormal] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "grey",
      }),
    });
    const [errorstyle, seterrorstyle] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "red" : "red",
      }),
    });

    const [fieldHardness, setFieldHardness] = useState(true);
    const [fieldToughness, setFieldToughness] = useState(true);

    const [error, setError] = useState({
      PQR_Tensile_SpecimenNo: {},
      PQR_Tensile_Width: {},
      PQR_Tensile_Thickness: {},
      PQR_Tensile_Area: {},
      PQR_Tensile_UltimateLoad: {},
      PQR_Tensile_UltimateStress: {},
      PQR_Tensile_FailureLocation: {},
      PQR_Tensile_RemarksHorizontal: {},
      PQR_Tensile_ReportNumHorizontal: {},

      PQR_GB_TypeofTest: {},
      PQR_GB_Acceptance_Criteria: {},
      PQR_GB_Results: {},
      PQR_GB_Remarks: {},
      PQR_GB_RemarksHorizontal: {},
      PQR_GB_ReportNumHorizontal: {},

      PQR_GBV_SpecimenNo: {},
      PQR_GBV_Thickness: {},
      PQR_GBV_BM_WeldOverlay: {},
      PQR_GBV_FormerDiam: {},
      PQR_GBV_BendAngle: {},
      PQR_GBV_Acceptance_Criteria: {},
      PQR_GBV_Remarks: {},

      PQR_TT_SpecimenNo: {},
      PQR_TT_NotchLocation: {},
      PQR_TT_NotchType: {},
      PQR_TT_SpecimenSize: {},
      PQR_TT_TestTemp: {},
      PQR_TT_ImpactValues: {},
      PQR_TT_Avg: {},
      PQR_TT_LateralExpansion: {},
      PQR_TT_Shear: {},
      PQR_TT_RemarksHorizontal: {},
      PQR_TT_ReportNumHorizontal: {},

      PQR_HT_Type: {},
      PQR_HT_Location: {},
      PQR_HT_ParentMetal_Left: {},
      PQR_HT_HAZ_Left: {},
      PQR_HT_Weld: {},
      PQR_HT_HAZ_Right: {},
      PQR_HT_ParentMetal_Right: {},
      PQR_HT_Remarks: {},
      PQR_HT_RemarksHorizontal: {},
      PQR_HT_ReportNumHorizontal: {},

      PQR_OT_TypeofTest: {},
      PQR_OT_Acceptance_Criteria: {},
      PQR_OT_Results: {},
      PQR_OT_Remarks: {},
      PQR_OT_RemarksHorizontal: {},
      PQR_OT_ReportNumHorizontal: {},

      PQR_Certification_Welder_Name: {},
      PQR_Certification_ID: {},
      PQR_Certification_Process: {},

      PQR_Certification_ConductedBy: {},
      PQR_Certification_Mechanical_TestsBy: {},
      PQR_Certification_Lab_RefNo: {},

      WPSH_PreparedBy_Name: {},
      WPSH_PreparedBy_Date: {},
      WPSH_PreparedBy_Remark: {},

      WPSH_ApprovedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_Approval_Status: {},
      WPSH_ApprovedBy_Remark: {},

      WPSH_WeldingEnggBy_Date: {},
      WPSH_WeldingEnggBy_Name: {},
      WPSH_WeldingEnggBy: {},
      WPSH_WitnessBy_Date: {},
      WPSH_WitnessBy_Name: {},
      WPSH_WitnessBy: {},
    });

   

    const fetchUserMaster = async () => {
      const result = await getAllUserMaster();
      const active = result.filter((item) => item.um_status === "Active")
      setUsers(active);

    };


    const fetchUserId = async () => {
      
      try {
          // Ensure users and headerObj are defined and have the necessary properties
          if (!headerObj || !headerObj.WPSH_ApprovedBy_Name) {
              // console.error("users or headerObj.WPSH_ApprovedBy_Name is not defined");
              return;
          }
  
          // Filter users to find the one matching the approved name
          const Approveduserid = users?.filter((item) => item?.um_name === headerObj?.WPSH_ApprovedBy_Name);
       
          // Check if the user was found
          if (Approveduserid.length === 0) {
              // console.error("No user found with the approved name");
              return;
          }
  
          // Update the user approval signature if data is returned
          if (Approveduserid && Approveduserid.length > 0) {
              setuserApproveSignature(Approveduserid[0]);
          } else {
              console.error("No data returned from getbyuserid");
          }
      } catch (error) {
          console.error("An error occurred:", error);
      }
  };

  useEffect(() => {
    fetchUserMaster();
  }, []);

  useEffect(() => {
    
    fetchUserId();
  }, [users, headerObj?.WPSH_ApprovedBy_Name]);

    useEffect(() =>{
      let filledval
      if(headerObj.Tensile_Test != undefined){
        filledval = headerObj.Tensile_Test.slice(0,4).filter((i) => {
      
        return( i.PQR_Tensile_SpecimenNo == "" ||
       i.PQR_Tensile_Width == "" ||  i.PQR_Tensile_Thickness == "" ||
       i.PQR_Tensile_Area == "" ||  i.PQR_Tensile_UltimateLoad == "" ||
       i.PQR_Tensile_UltimateStress == "" || i.PQR_Tensile_FailureLocation == "")
      } )
      setTensile_Test_Disabled(filledval.length > 0 ? true : false)
    }
      

       if(headerObj.Hardness_Test_Groove != undefined){
         filledval = headerObj.Hardness_Test_Groove.slice(0,1).filter((i) => {
        
          return(   i.PQR_HT_Type == "" ||
         i.PQR_HT_Location == "" ||
         i.PQR_HT_ParentMetal_Left == "" ||
         i.PQR_HT_HAZ_Left == "" ||
         i.PQR_HT_Weld == "" ||
          i.PQR_HT_HAZ_Right == "" ||
         i.PQR_HT_ParentMetal_Right == "" ||
          i.PQR_HT_Remarks == "")
        } )
         
         setHardness_Test_Groove_Disabled(filledval.length > 0 ? true : false)
      }

      if(headerObj.GuidedBend_Test_Overlay != undefined){
        filledval = headerObj.GuidedBend_Test_Overlay.slice(0,1).filter((i) => {
       
         return( i.PQR_GBV_SpecimenNo == "" ||
         i.PQR_GBV_Thickness == "" ||
         i.PQR_GBV_BM_WeldOverlay == "" ||
        i.PQR_GBV_FormerDiam == "" ||
        i.PQR_GBV_BendAngle == "" &&
         i.PQR_GBV_Acceptance_Criteria !== "" ||
         i.PQR_GBV_Remarks == "")
       } )
        
        setGuidedBend_Test_Overlay_Disabled(filledval.length > 0 ? true : false)
     }

     if(headerObj.GuidedBend_Test != undefined){
      filledval = headerObj.GuidedBend_Test.slice(0,1).filter((i) => {
     
       return(i.PQR_GB_TypeofTest == "" ||
      i.PQR_GB_Acceptance_Criteria == "" ||
       i.PQR_GB_Results == "" ||
       i.PQR_GB_Remarks == "")
     } )
      setGuidedBend_Test_Disabled(filledval.length > 0 ? true : false)
    }
      if(headerObj.Toughness_Test != undefined){
        filledval = headerObj.Toughness_Test.slice(0,2).filter((i) => {
       
         return( i.PQR_TT_SpecimenNo == "" ||
         i.PQR_TT_NotchLocation == "" ||
        i.PQR_TT_NotchType == "" ||
         i.PQR_TT_SpecimenSize == "" ||
         i.PQR_TT_TestTemp == "" ||
         i.PQR_TT_ImpactValues == "" ||
         i.PQR_TT_Avg == "" ||
         i.PQR_TT_LateralExpansion == "" ||
         i.PQR_TT_Shear == "" )
       } )
        
        setToughness_Test_Disabled(filledval.length > 0 ? true : false)
   }
   if(headerObj.Other_Test != undefined){
    filledval = headerObj.Other_Test.slice(0,4).filter((i) => {
   
     return(  i.PQR_OT_TypeofTest == "" ||
    i.PQR_OT_Acceptance_Criteria == "" ||
     i.PQR_OT_Remarks == "" ||
    i.PQR_OT_Results == "" )
   } )
    
    setOther_Test_Disabled(filledval.length > 0 ? true : false)
}

   if(headerObj.Certification != undefined){
    filledval = headerObj.Certification.slice(0,1).filter((i) => {
   
     return(  i.PQR_Certification_Welder_Name == "" ||
    i.PQR_Certification_ID == "" ||
     i.PQR_Certification_Process == "")
   } )
    
    setCertification_Disabled(filledval.length > 0 ? true : false)
}




    },[headerObj])

 
 
    const validate = async (detaildata) => {
      let isError = false;

      let entries;
      let GroovePage2;
      let errortype;

      let entries5 = Object.entries(detaildata);
      if (WPStype === "Groove") {
        entries = Object.entries(PQR_GroovePage1);
        GroovePage2 = Object.entries(PQR_GroovePage2);
        errortype = PQR_Detail_GroovePage1;
      }
      if (WPStype === "Overlay") {
        entries = Object.entries(PQR_OverlayPage1);
        GroovePage2 = Object.entries(PQR_OverlayPage2);
        errortype = PQR_Detail_OverlayPage1;
      }

      entries.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        } else {
          error[key] = {
            status: false,
            message: "",
          };
        }
        // console.log("isError_headerObj[key]", isError,error);
        return isError;
      });

  

      GroovePage2.map(([key, val]) => {
        if (headerObj[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }
      });

      errortype.map((cols) => {
        entries5.forEach(([key, val]) => {

          var value = "";
          var msg = "";

          if (cols.Type === "S") {
            value = detailObj[key][cols.Column];
            msg = `This field is required`;
          } else if (cols.Type === "A") {
            value =
              detailObj[key][cols.Column][0] ||
              detailObj[key][cols.Column][1] ||
              detailObj[key][cols.Column][2];
            msg = `At least one field is required`;
          }

          if (value === undefined || value === "" || value === null) {
            isError = true;

            setError((prevState) => ({
              ...prevState,
              [cols.Column]: {
                status: true,
                message: msg,
              },
            }));
          } else {
            setError((prevState) => ({
              ...prevState,
              [cols.Column]: {
                status: false,
                message: "",
              },
            }));
          }
        });
      });

      if (WPStype === "Groove") {
        var norows = PQR_Page2_Tensile(Tensile_Test, 8);

        if (norows < 2) {
          Tensile_Test[0].errormessage = "Minimum 2 Full Row is required";
          isError = true;
          Tensile_Test[0].error = true;
        }
        else{
  
          Tensile_Test[0].errormessage = ""
          Tensile_Test[0].error = false;
        }
        
        var norows = PQR_Page2_Guided(GuidedBend_Test, 4);

        if (norows < 1) {
          GuidedBend_Test[0].errormessage = "Atleast one Full Row is required";
          isError = true;
          GuidedBend_Test[0].error = true;

        
        }
        else
        {
          GuidedBend_Test[0].errormessage = ""
          GuidedBend_Test[0].error = false;
        }
        var norows = PQR_Page2_Toughness(Toughness_Test, 6);
        
        if(!fieldToughness || Toughness_Test[0].PQR_TT_SpecimenNo == "Not Applicable") norows=2
      
        if (norows < 2) {
          Toughness_Test[0].errormessage = "Minimum 2 Full Row is required";
          isError = true;
          Toughness_Test[0].error = true;

        }
        else{
         
            Toughness_Test[0].error = false;
            Toughness_Test[0].errormessage = "";
        }

        var norows = PQR_Groove_Page2_Hardness(Hardness_Test_Groove, 4);

        if (norows) {
          Hardness_Test_Groove[0].errormessage =
            "Atleast one Full Row is required";
          isError = true;
          Hardness_Test_Groove[0].error = true;
  
          if (
            Hardness_Test_Groove[0].PQR_HT_Type !== "" ||
            Hardness_Test_Groove[0].PQR_HT_Location !== "" ||
            Hardness_Test_Groove[0].PQR_HT_ParentMetal_Left !== "" ||
            Hardness_Test_Groove[0].PQR_HT_HAZ_Left !== "" ||
            Hardness_Test_Groove[0].PQR_HT_Weld !== "" ||
            Hardness_Test_Groove[0].PQR_HT_HAZ_Right !== "" ||
            Hardness_Test_Groove[0].PQR_HT_ParentMetal_Right !== "" ||
            Hardness_Test_Groove[0].PQR_HT_Remarks !== ""
          ) {
            Hardness_Test_Groove[0].error = false;
          }
        }
  
      }

      let Other_testCount = WPStype === "Overlay" ? 10:6;
      var norows = PQR_Page2_Other_test(Other_Test, Other_testCount);

      if (norows < 1) {
        Other_Test[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Other_Test[0].error = true;

        if (
          Other_Test[0].PQR_OT_TypeofTest !== "" &&
          Other_Test[0].PQR_OT_Acceptance_Criteria !== "" &&
          Other_Test[0].PQR_OT_Results !== "" &&
          Other_Test[0].PQR_OT_Remarks !== ""
        ) {
          Other_Test[0].error = false;
        }
      }
    
      var norows = PQR_Page2_Certification(Certification, 6);

      if (norows < 1) {
        Certification[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        Certification[0].error = true;
      }
        else
        {
          Certification[0].errormessage = ""
          Certification[0].error = false;
        }
      var norows = PQR_Page2_CertificationLabRef(CertificationLabRef, 4);

      if (norows < 1) {
        CertificationLabRef[0].errormessage = "Atleast one Full Row is required";
        isError = true;
        CertificationLabRef[0].error = true;
      }
        else
        {
          CertificationLabRef[0].errormessage = ""
          CertificationLabRef[0].error = false;
        }
    

      if (WPStype === "Overlay") {
        var norows = PQR_Page2_Guided_Overlay(GuidedBend_Test_Overlay, 4);

        if (norows) {
          GuidedBend_Test_Overlay[0].errormessage =
            "Atleast one Full Row is required";
          isError = true;
          GuidedBend_Test_Overlay[0].error = true;

          if (
            GuidedBend_Test_Overlay[0].PQR_GBV_SpecimenNo !== "" &&
            GuidedBend_Test_Overlay[0].PQR_GBV_Thickness !== "" &&
            GuidedBend_Test_Overlay[0].PQR_GBV_BM_WeldOverlay !== "" &&
            GuidedBend_Test_Overlay[0].PQR_GBV_FormerDiam !== "" &&
            GuidedBend_Test_Overlay[0].PQR_GBV_BendAngle !== "" &&
            GuidedBend_Test_Overlay[0].PQR_GBV_Acceptance_Criteria !== "" &&
            GuidedBend_Test_Overlay[0].PQR_GBV_Remarks !== ""
          ) {
            GuidedBend_Test_Overlay[0].error = false;
          }
        }
      }

    
      setError((prevState) => ({
        ...prevState,
        ...error,
      }));
      return isError;
    };

    const handleCheckboxChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      if(e.target.checked === true){

        setheaderObj((header) => ({
          ...header,
          Impact: "Yes",

        }));    
      }
      if(e.target.checked === false){

        setheaderObj((header) => ({
          ...header,
          Impact: "No",

        }));       }
      setFieldToughness(e.target.checked)
   
    
    }
    const handleCheckboxChangeHardness = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      if(e.target.checked === true){

        setheaderObj((header) => ({
          ...header,
          Hardess: "Yes",

        }));    
      }
      if(e.target.checked === false){

        setheaderObj((header) => ({
          ...header,
          Hardess: "No",

        }));       }
    setFieldHardness(e.target.checked)
   
    
    }

  ;

   
    let TT = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      Tensile_Test: headerObj.Tensile_Test,
    };

    let GB1 = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      GuidedBend_Test_Overlay: headerObj.GuidedBend_Test_Overlay,
    };

    let GB2 = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      GuidedBend_Test: headerObj.GuidedBend_Test,
    };

    let TOUGH = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      Toughness_Test: headerObj.Toughness_Test,
    };

    let HARD = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      Hardness_Test_Groove: headerObj.Hardness_Test_Groove,
    };

    let OTHER = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      Other_Test: headerObj.Other_Test,
    };

    let CERTI = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      PQR_Certification_Welder_Name: headerObj.PQR_Certification_Welder_Name,
      PQR_Certification_ID: headerObj.PQR_Certification_ID,
      PQR_Certification_Process: headerObj.PQR_Certification_Process,
      PQR_Certification_ConductedBy: headerObj.PQR_Certification_ConductedBy,
      PQR_Certification_Mechanical_TestsBy:
        headerObj.PQR_Certification_Mechanical_TestsBy,
        Certification: headerObj.Certification,
      };
      let Lab_refno ={
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        PQR_Certification_Lab_RefNo: headerObj.PQR_Certification_Lab_RefNo,
        CertificationLabRef: headerObj.CertificationLabRef,

      };

    let OTHERData = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      PQR_Tensile_RemarksHorizontal: headerObj.PQR_Tensile_RemarksHorizontal,
      PQR_Tensile_ReportNumHorizontal: headerObj.PQR_Tensile_ReportNumHorizontal,
      PQR_GB_RemarksHorizontal: headerObj.PQR_GB_RemarksHorizontal,
      PQR_GB_ReportNumHorizontal: headerObj.PQR_GB_ReportNumHorizontal,
      PQR_TT_RemarksHorizontal: headerObj.PQR_TT_RemarksHorizontal,
      PQR_TT_ReportNumHorizontal: headerObj.PQR_TT_ReportNumHorizontal,
    };

    let PAGE2_NOTES = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      PQR_HT_RemarksHorizontal: headerObj.PQR_HT_RemarksHorizontal,
      PQR_HT_ReportNumHorizontal: headerObj.PQR_HT_ReportNumHorizontal,
      PQR_OT_RemarksHorizontal: headerObj.PQR_OT_RemarksHorizontal,
      PQR_OT_ReportNumHorizontal: headerObj.PQR_OT_ReportNumHorizontal,

    };

  
    let statuss

    let REMAIN = {
      Wld_createdon_year: headerObj.Wld_createdon_year,
      wld_WPSType: headerObj.wld_WPSType,
      PQR_RecordNo: headerObj.PQR_RecordNo,
      WPSH_PreparedBy_Remark: headerObj.WPSH_PreparedBy_Remark,
      WPSH_ApprovedBy_Remark: headerObj.WPSH_ApprovedBy_Remark,
      WPSH_WeldingEnggBy_Date: headerObj.WPSH_WeldingEnggBy_Date,
      WPSH_WeldingEnggBy_Name: headerObj.WPSH_WeldingEnggBy_Name,
      WPSH_WeldingEnggBy: headerObj.WPSH_WeldingEnggBy,
      WPSH_WitnessBy_Date: headerObj.WPSH_WitnessBy_Date,
      WPSH_WitnessBy_Name: headerObj.WPSH_WitnessBy_Name,
      WPSH_WitnessBy: headerObj.WPSH_WitnessBy,
      PQR_SignatureApproved: headerObj.PQR_SignatureApproved,
      WPSH_ApprovedBy_Name: headerObj.WPSH_ApprovedBy_Name,
      WPSH_ApprovedBy_Date: headerObj.WPSH_ApprovedBy_Date,
      WPSH_ApprovedBy: sessionStorage.getItem("User"),
      WPSH_PreparedBy_Name: headerObj.WPSH_PreparedBy_Name,
      WPSH_PreparedBy_Date: headerObj.WPSH_PreparedBy_Date,
      PQR_SignaturePrepared: headerObj.PQR_SignaturePrepared,
      WPSH_PreparedBy: sessionStorage.getItem("User"),
      WPSH_Approval_Status: headerObj.WPSH_Approval_Status,
      Impact: headerObj.Impact,
      Hardess: headerObj.Hardess

    };

    const postList = [
      TT,
      GB1,
      GB2,
      TOUGH,
      HARD,
      OTHER,
      CERTI,
      Lab_refno,
      OTHERData,
      PAGE2_NOTES,
      REMAIN,
    ];

    

    const handleAdd = async (approvalstatus) => {
      let result = false;
      
      headerObj.PQR_SignatureApproved = headerObj.PQR_SignatureApproved || "";
      headerObj.WPSH_ApprovedBy_Name = headerObj.WPSH_ApprovedBy_Name || "";
      headerObj.WPSH_ApprovedBy =  headerObj.WPSH_ApprovedBy_Name || "";

      headerObj.WPSH_PreparedBy_Name = headerObj.WPSH_PreparedBy_Name || "";
      headerObj.PQR_SignaturePrepared = headerObj.PQR_SignaturePrepared || "";

      
          if (Band === "P" || Band === "T") {
       
        headerObj.PQR_SignatureApproved = headerObj.PQR_SignatureApproved;
        headerObj.WPSH_ApprovedBy_Name = headerObj.WPSH_ApprovedBy_Name;
        headerObj.WPSH_ApprovedBy =  headerObj.WPSH_ApprovedBy_Name
      } else if (Band !== "T" || Band !== "P") {
       
        headerObj.WPSH_PreparedBy_Name = headerObj.WPSH_PreparedBy_Name;
        headerObj.PQR_SignaturePrepared = headerObj.PQR_SignaturePrepared;
      }

      let StatusPQR = {
        Wld_createdon_year: headerObj.Wld_createdon_year,
        wld_WPSType: headerObj.wld_WPSType,
        PQR_RecordNo: headerObj.PQR_RecordNo,
        WPSH_Status: approvalstatus,
        WPSH_Approval_Status: headerObj.WPSH_Approval_Status,

      };

      const postAdd = [StatusPQR];
      
      let res = await UpdatePQRHeaderComplete(postAdd,postList)
        .catch((e) => {
          result = false;
        })
        .then(async () => {
          setaction(Action.ActionEdit);
          result = true;
 
        });
      return Promise.resolve(result);
    };


    const checkIfUsernameExists = async () => {
      let res = await IsUserMasterBand();
      if (res !== undefined) return res.data.message;
      else return false;
    };



    const handleSubmitTemp = async () => {
      var res = false;
      if (action === Action.ActionEdit) {
        if (IsEdited) {
          setIsEdited(false);
          statuss = Status.StatusTempSaved
          res = await handleAdd(Status.StatusTempSaved);

          if (res) {
            return MessageTypes.Temp;
          } else {
            return MessageTypes.Fail;
          }
        }

        return MessageTypes.None;
      } else {
        statuss = Status.StatusTempSaved
        res = handleAdd(Status.StatusTempSaved);


        if (res) {
          return MessageTypes.Temp;
        } else {
          return MessageTypes.Required;
        }
      }
    };

    const handleSubmit = async () => {
      setIsEdited(false);
  
      var result = false;

      const isUsernamePresent = await checkIfUsernameExists();

      if (!isUsernamePresent) {
        statuss = Status.StatusTempSaved
        headerObj.WPSH_Status = Status.StatusTempSaved;

      } else {
        statuss = Status.StatusFreezed
        headerObj.WPSH_Status = Status.StatusFreezed;

      }
    
      var detaildata = detailObj.filter((item) => item.PQR_Process !== "");

      if (!(await validate(detaildata))) {
        headerObj.WPSH_Status = Status.StatusFreezed
        result = handleAdd(Status.StatusFreezed);

        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.RequiredAll;
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext2: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp2: async () => {
        var res = false;
        res = await handleSubmitTemp();
        return res;
      },
    }));


    const handleChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangeTensileTestarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Tensile_Test[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
      
    };

    const handleChangeGuidedBandTestarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      //alert(Index,key)
      updatedheadertestObj.GuidedBend_Test[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeGuidedBandTestOverlayarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.GuidedBend_Test_Overlay[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeToughnessTestarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Toughness_Test[Index][key] = newValue;
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeHadnesstestarray = (Index, key, newValue) => {
      // alert("hhdd")
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Hardness_Test_Groove[Index][key] = newValue;
      // alert(Index,key)
      setheaderObj(updatedheadertestObj);
    };

    const handleChangeOther_Testarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Other_Test[Index][key] = newValue;
     
      setheaderObj(updatedheadertestObj);
    };
    const handleCertificationarray = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.Certification[Index][key] = newValue;
     
      setheaderObj(updatedheadertestObj);
    };
    const handleCertificationLabRef = (Index, key, newValue) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const updatedheadertestObj = { ...headerObj };
      updatedheadertestObj.CertificationLabRef[Index][key] = newValue;
     
      setheaderObj(updatedheadertestObj);
    };

    const handleChangedetail = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangeHeader = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };


    const fetchbyuserprofile = async (userprofile) => {
      debugger
      try {
        if (userprofile) {
          const result = await getPresignedURL(folderwld, userprofile);

          const geturl = result.url;
          setPreviewuser(geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };


    const fetchapproveUserProfile = async (userprofile) => {
      
      try {
        if (userprofile) {
          const result = await getPresignedURL(folderwld, userprofile);
          const geturl = result.url;
          setPreviewapproved(geturl);
        }
      } catch (error) {
        if (error.request) {
          console.log(error.request);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };


    
    useEffect(() => {
      
            if(action === Action.ActionEdit && headerObj?.PQR_SignaturePrepared !== ""){
         
              fetchbyuserprofile(headerObj?.PQR_SignaturePrepared)
            }
      
            if(action === Action.ActionEdit && headerObj?.PQR_SignatureApproved !== ""){
           
            fetchapproveUserProfile(headerObj?.PQR_SignatureApproved)
            }
      
          }, []);

    return (
      <Row className="font">
        <PQRHeader headerObj={headerObj}></PQRHeader>

        {!isoverlay ? (
          <Row style={{ padding: "20px 60px" }}>
            <Row className="mb-1">
              <Col md={12}>
                <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Tensile Test (QW-150) </p>

               
                <PQR_Page3_Table_ColumnN
                  columns={7}
                  sessionName={sessionName}
                  maxLength={[20,6,6,6,6,6,10]}
                  width= {140}
                  width1={140}
                  width2={140}
                  width3={140}
                  array={Tensile_Test}
                  setheaderObj={setheaderObj}
                  disabled={Tensile_Test_Disabled}
                  numberofrowsdisabled={4}
                  setError={setError}
                  title={["Specimen No.","Width (mm)","Thickness(mm)","Area (mm^2)","Ultimate Total Load (N)","Ultimate unit Stress (Mpa)","Type of Failure and Location"]}
                  name={["PQR_Tensile_SpecimenNo","PQR_Tensile_Width","PQR_Tensile_Thickness",
                         "PQR_Tensile_Area", "PQR_Tensile_UltimateLoad", "PQR_Tensile_UltimateStress", "PQR_Tensile_FailureLocation"]}
                  status={[error.PQR_Tensile_SpecimenNo.status, error.PQR_Tensile_Width.status,
                           error.PQR_Tensile_Thickness.status, error.PQR_Tensile_Area.status,
                           error.PQR_Tensile_UltimateLoad.status,error.PQR_Tensile_UltimateStress.status,
                           error.PQR_Tensile_FailureLocation.status]}
                  message={[error.PQR_Tensile_SpecimenNo.message, error.PQR_Tensile_Width.message,
                            error.PQR_Tensile_Thickness.message,error.PQR_Tensile_Area.message,
                            error.PQR_Tensile_UltimateLoad.message,   error.PQR_Tensile_UltimateStress.message,
                            error.PQR_Tensile_FailureLocation.message]}
                  handleChangetestarray={handleChangeTensileTestarray}
                />
              </Col>

              <Col md={12}>
                <Single_Input
                  disabled={copydata}
                  name="PQR_Tensile_RemarksHorizontal"
                  type="text"
                  maxLength={90}
                  status={error.PQR_Tensile_RemarksHorizontal.status}
                  value={headerObj?.PQR_Tensile_RemarksHorizontal}
                  handleChange={handleChangeHeader}
                  label="Remarks"
                  message={error.PQR_Tensile_RemarksHorizontal.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQR_Tensile_RemarksHorizontal: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={12}>
                <Single_Input
                  disabled={copydata}
                  name="PQR_Tensile_ReportNumHorizontal"
                  type="text"
                  maxLength={90}
                  status={error.PQR_Tensile_ReportNumHorizontal.status}
                  value={headerObj?.PQR_Tensile_ReportNumHorizontal}
                  handleChange={handleChangeHeader}
                  label="Report Number"
                  message={error.PQR_Tensile_ReportNumHorizontal.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQR_Tensile_ReportNumHorizontal: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
          </Row>
        ) : (
          ""
        )}

        <Row style={{ padding: "20px 60px" }}  className="PQR-Page-row-padding">
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>GUIDED BEND TEST (QW-160) </p>
    

              {isoverlay ? (
                // <PQR_Page2_Table
                <PQR_Page3_Table_ColumnN
                  sessionName={sessionName}
                  columns={7}
                  width= {143}
                  width1={143}
                  width2={143}
                  width3={143}
                  maxLength={[32,4,8,8,5,10,12]}
                  numberofrowsdisabled={1}
                  array={GuidedBend_Test_Overlay}
                  setheaderObj={setheaderObj}
                  disabled={GuidedBend_Test_Overlay_Disabled}
                  setError={setError}
                  title={["Nos. of specimen","Thk (mm)","Breadth (mm)","Former Diameter (mm)","Bend Angle (degree)","Acceptance Criteria","Remarks"]}
                  name={["PQR_GBV_SpecimenNo","PQR_GBV_Thickness","PQR_GBV_BM_WeldOverlay","PQR_GBV_FormerDiam","PQR_GBV_BendAngle","PQR_GBV_Acceptance_Criteria","PQR_GBV_Remarks"]}
                  status={[error.PQR_GBV_SpecimenNo.status,error.PQR_GBV_Thickness.status,
                           error.PQR_GBV_BM_WeldOverlay.status, error.PQR_GBV_FormerDiam.status,
                           error.PQR_GBV_BendAngle.status, error.PQR_GBV_Acceptance_Criteria.status,
                           error.PQR_GBV_Remarks.status
                          ]}
                  message={[error.PQR_GBV_SpecimenNo.message,error.PQR_GBV_Thickness.message,
                           error.PQR_GBV_BM_WeldOverlay.message,error.PQR_GBV_FormerDiam.message,
                           error.PQR_GBV_BendAngle.message, error.PQR_GBV_Acceptance_Criteria.message,
                           error.PQR_GBV_Remarks.message
                          ]}
                 
                  handleChangetestarray={handleChangeGuidedBandTestOverlayarray}
                />
              ) : (
                <PQR_Page3_Table_ColumnN
                  columns={4}
                  sessionName={sessionName}
                  maxLength={[25,15,15,15]}
                  width= {240}
                  width1={240}
                  width2={240}
                  width3={240}
                  array={GuidedBend_Test}
                  disabled={GuidedBend_Test_Disabled}
                  setheaderObj={setheaderObj}
                  setError={setError}
                  title={["Type of Test", "Acceptance Criteria", "Results","Remarks"]}
                  name={["PQR_GB_TypeofTest","PQR_GB_Acceptance_Criteria","PQR_GB_Results","PQR_GB_Remarks"]}
                  status={[error.PQR_GB_TypeofTest.status,error.PQR_GB_Acceptance_Criteria.status,error.PQR_GB_Results.status,error.PQR_GB_Remarks.status]}
                  message={[error.PQR_GB_TypeofTest.message,error.PQR_GB_Acceptance_Criteria.message,error.PQR_GB_Results.message,error.PQR_GB_Remarks.message]}
                  handleChangetestarray={handleChangeGuidedBandTestarray}
                />
              )}
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_GB_RemarksHorizontal"
                type="text"
                maxLength={90}
                status={error.PQR_GB_RemarksHorizontal.status}
                value={headerObj?.PQR_GB_RemarksHorizontal}
                handleChange={handleChangeHeader}
                label="Remarks"
                message={error.PQR_GB_RemarksHorizontal.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_GB_RemarksHorizontal: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_GB_ReportNumHorizontal"
                type="text"
                maxLength={90}
                status={error.PQR_GB_ReportNumHorizontal.status}
                value={headerObj?.PQR_GB_ReportNumHorizontal}
                handleChange={handleChangeHeader}
                label="Report Number"
                message={error.PQR_GB_ReportNumHorizontal.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_GB_ReportNumHorizontal: error,
                  }));
                }}
              ></Single_Input>
            </Col>
          </Row>
        </Row>

        {!isoverlay ? (
          <Row style={{ padding: "20px 60px" }}>
            <Row className="mb-1">
              <Col md={12}>
                <div className="checkboxproject">
                  <input
                    type="checkbox"
                    placeholder=" "
                    className="form-check-input"
                    id="chkcode"
                    onClick={(e) => {
                      if(e.target.checked === false){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Toughness_Test:
                          prevState.Toughness_Test.map((test,i) =>
                          {
                            
                            if(i==0){
                    
                              return {
                                ...test,
                                PQR_TT_SpecimenNo: "Not Applicable",
                                PQR_TT_NotchLocation: "Not Applicable",
                                PQR_TT_Avg: "Not Applicable",
                                PQR_TT_ImpactValues: "Not Applicable",
                                PQR_TT_LateralExpansion: "Not Applicable",
                                PQR_TT_NotchType: "Not Applicable",
                                PQR_TT_SpecimenSize: "Not Applicable",
                                PQR_TT_TestTemp: "Not Applicable",
                                PQR_TT_Shear: "Not Applicable",  
                                errormessage:"",
                                error:false,

                              }
                            }
                            else{
                              return {
                                ...test,
                                PQR_TT_SpecimenNo: "",
                                PQR_TT_NotchLocation: "",
                                PQR_TT_Avg: "",
                                PQR_TT_ImpactValues: "",
                                PQR_TT_LateralExpansion: "",
                                PQR_TT_NotchType: "",
                                PQR_TT_SpecimenSize: "",
                                PQR_TT_TestTemp: "",
                                PQR_TT_Shear: "",  
                                errormessage:"",
                                error:false,
                              }

                            }
                            
                        
                          })                       
                                }));
                      }
                      if(e.target.checked === true){
                        setheaderObj((prevState) => ({
                          ...prevState,
                          Toughness_Test:
                          prevState.Toughness_Test.map((test,i) =>
                          {
                           
                                return {
                                ...test,
                                PQR_TT_SpecimenNo: "",
                                PQR_TT_NotchLocation: "",
                                PQR_TT_Avg: "",
                                PQR_TT_ImpactValues: "",
                                PQR_TT_LateralExpansion: "",
                                PQR_TT_NotchType: "",
                                PQR_TT_SpecimenSize: "",
                                PQR_TT_TestTemp: "",  
                                PQR_TT_Shear:"",
                                errormessage:"",
                                error:false
                               }
                              
                          
                        
                          })                       
                                }));
                      }
                    }}
                    onChange={handleCheckboxChange}

                    checked={headerObj.Toughness_Test && headerObj.Toughness_Test[0] &&  headerObj?.Toughness_Test[0]?.PQR_TT_SpecimenNo ?  headerObj?.Toughness_Test[0]?.PQR_TT_SpecimenNo === "Not Applicable" ? false  : fieldToughness : fieldToughness}

                  />{" "}
                  <span className="WPS-blue-text" style={{position:"relative",bottom:"2px", left:"5px"}}>Toughness Test (QW-170)</span>
                </div>
                <br />
                <PQR_Page3_Table_ColumnN
                  columns={9}
                  sessionName={sessionName}
                  fieldToughness={fieldToughness}
                  maxLength={[30,8,10,6,3,6,6,6,4]}
                  width= {110}
                  width1={110}
                  width2={110}
                  width3={110}
                  array={Toughness_Test}
                  Inputdisabled={Toughness_Test_Disabled}
                  setheaderObj={setheaderObj}
                  setError={setError}
                  title={["Specimen No","Notch Location","Notch Type","Specimen Size(mm)","Test Temp(C)","Impact Values In J (Values)","Avg","Lateral Expansion (mm)","% of Shear"]}
                  name={["PQR_TT_SpecimenNo","PQR_TT_NotchLocation","PQR_TT_NotchType","PQR_TT_SpecimenSize","PQR_TT_TestTemp","PQR_TT_ImpactValues","PQR_TT_Avg","PQR_TT_LateralExpansion","PQR_TT_Shear"]}
                  status={[error.PQR_TT_SpecimenNo.status,error.PQR_TT_NotchLocation.status,error.PQR_TT_NotchType.status,error.PQR_TT_SpecimenSize.status,error.PQR_TT_TestTemp.status,error.PQR_TT_ImpactValues.status,error.PQR_TT_Avg.status,error.PQR_TT_LateralExpansion.status,error.PQR_TT_Shear.status]}
                  message={[error.PQR_TT_SpecimenNo.message,error.PQR_TT_NotchLocation.message,error.PQR_TT_NotchType.message,error.PQR_TT_SpecimenSize.message,error.PQR_TT_TestTemp.message,error.PQR_TT_ImpactValues.message,error.PQR_TT_Avg.message,error.PQR_TT_LateralExpansion.message,error.PQR_TT_Shear.message]}
                  handleChangetestarray={handleChangeToughnessTestarray}
                  disabled={headerObj?.Toughness_Test  && headerObj?.Toughness_Test[0] && headerObj?.Toughness_Test[0].PQR_TT_SpecimenNo ? headerObj?.Toughness_Test[0]?.PQR_TT_SpecimenNo == "Not Applicable" ? true : false : !fieldToughness}

                />
              </Col>
<br />
              <Col md={12} >
                <Single_Input
                  disabled={copydata}
                  name="PQR_TT_RemarksHorizontal"
                  type="text"
                  maxLength={90}
                  status={error.PQR_TT_RemarksHorizontal.status}
                  value={headerObj?.PQR_TT_RemarksHorizontal}
                  handleChange={handleChangeHeader}
                  label="Remarks"
                  message={error.PQR_TT_RemarksHorizontal.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQR_TT_RemarksHorizontal: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
              <Col md={12} >
                <Single_Input
                  disabled={copydata}
                  name="PQR_TT_ReportNumHorizontal"
                  type="text"
                  maxLength={90}
                  status={error.PQR_TT_ReportNumHorizontal.status}
                  value={headerObj?.PQR_TT_ReportNumHorizontal}
                  handleChange={handleChangeHeader}
                  label="Report Number"
                  message={error.PQR_TT_ReportNumHorizontal.message}
                  onInput={(e) => {
                    let error = isAlphanumericAllSpecialCharacters(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      PQR_TT_ReportNumHorizontal: error,
                    }));
                  }}
                ></Single_Input>
              </Col>
            </Row>
          </Row>
        ) : (
          ""
        )}

        {isoverlay ? ""  :
        <Row style={{ padding: "20px 60px", position:"relative", bottom:"30px" }}  className="PQR-Page-row-padding">
          <Row className="mb-1">
            <Col md={12}>
              <div className="checkboxproject">
                <input
                  type="checkbox"
                  placeholder=" "
                  id="chkcode"
                  className="form-check-input"
                  onClick={(e) => {

                    setFieldHardness(e.target.checked);
              
                    if(e.target.checked === false){
                      setheaderObj((prevState) => ({
                        ...prevState,
                        Hardness_Test_Groove:
                        prevState.Hardness_Test_Groove.map((test,i) =>
                        {
                         
                          if(i==0){
                           
                            return {
                              ...test,
                              PQR_HT_Type: "Not Applicable",
                              PQR_HT_Location: "Not Applicable",
                              PQR_HT_ParentMetal_Left: "Not applicable",
                              PQR_HT_HAZ_Left: "Not Applicable",
                              PQR_HT_Weld: "Not Applicable",
                              PQR_HT_HAZ_Right: "Not Applicable",
                              PQR_HT_ParentMetal_Right: "Not Applicable",
                              PQR_HT_Remarks: "Not Applicable",
                            }
                            
                          }
                          else{ 
                            return {
                              ...test,
                              PQR_HT_Type: "",
                              PQR_HT_Location: "",
                              PQR_HT_ParentMetal_Left: "",
                              PQR_HT_HAZ_Left: "",
                              PQR_HT_Weld: "",
                              PQR_HT_HAZ_Right: "",
                              PQR_HT_ParentMetal_Right: "",
                              PQR_HT_Remarks: "",
                            }
                          }
                          
                        })                       
                              }));
                    }
                    else{
                      setheaderObj((prevState) => ({
                        ...prevState,
                        Hardness_Test_Groove:
                        prevState.Hardness_Test_Groove.map((test,i) =>
                        {
                       
                            return {
                              ...test,
                              PQR_HT_Type: "",
                              PQR_HT_Location: "",
                              PQR_HT_ParentMetal_Left: "",
                              PQR_HT_HAZ_Left: "",
                              PQR_HT_Weld: "",
                              PQR_HT_HAZ_Right: "",
                              PQR_HT_ParentMetal_Right: "",
                              PQR_HT_Remarks: "",
                             }
                          
                        
                      
                        })                       
                              }));
                    }
                  }}
                  onChange={handleCheckboxChangeHardness}

                  defaultChecked={headerObj?.Hardness_Test_Groove && headerObj?.Hardness_Test_Groove[0]?.PQR_HT_Type == "Not Applicable" ? false : fieldHardness}
                />{" "}
                <span className="WPS-blue-text" style={{position:"relative",bottom:"2px", left:"5px"}}>Hardness Test</span>
              </div>
<br />
              <PQR_Page3_Table_ColumnN
                columns={8}
                sessionName={sessionName}
                maxLength={[25,10,6,6,6,6,6,15]}
                width= {120}
                width1={120}
                width2={120}
                width3={120}
                array={Hardness_Test_Groove}
                disabled={Hardness_Test_Groove_Disabled}
                setheaderObj={setheaderObj}
                setError={setError}
                numberofrowsdisabled={1}
                title={["Type (Scale)","Location","Parent Metal (Left)","HAZ (Left)","Weld","HAZ (Right)","Parent Metal (Right)","Remarks"]}
                name={["PQR_HT_Type","PQR_HT_Location","PQR_HT_ParentMetal_Left","PQR_HT_HAZ_Left","PQR_HT_Weld","PQR_HT_HAZ_Right","PQR_HT_ParentMetal_Right","PQR_HT_Remarks"]}
                status={[error.PQR_HT_Type.status,error.PQR_HT_Location.status,error.PQR_HT_ParentMetal_Left.status,
                         error.PQR_HT_HAZ_Left.status,error.PQR_HT_Weld.status,error.PQR_HT_HAZ_Right.status,
                         error.PQR_HT_ParentMetal_Right.status,error.PQR_HT_Remarks.status]}
                message={[error.PQR_HT_Type.message,error.PQR_HT_Location.message,error.PQR_HT_ParentMetal_Left.message,
                         error.PQR_HT_HAZ_Left.message,error.PQR_HT_Weld.message,error.PQR_HT_HAZ_Right.message,
                         error.PQR_HT_ParentMetal_Right.message,error.PQR_HT_Remarks.message]}
                readOnly={isReadonly}
                // disabled={headerObj?.Hardness_Test_Groove && headerObj?.Hardness_Test_Groove[0]?.PQR_HT_Type == "Not Applicable" ? true : !fieldHardness}
                handleChangetestarray={handleChangeHadnesstestarray}
              />
            </Col>
<br />
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_HT_RemarksHorizontal"
                type="text"
                maxLength={90}
                status={error.PQR_HT_RemarksHorizontal.status}
                value={headerObj?.PQR_HT_RemarksHorizontal}
                handleChange={handleChangeHeader}
                label="Remarks"
                message={error.PQR_HT_RemarksHorizontal.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_HT_RemarksHorizontal: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_HT_ReportNumHorizontal"
                type="text"
                maxLength={90}
                status={error.PQR_HT_ReportNumHorizontal.status}
                value={headerObj?.PQR_HT_ReportNumHorizontal}
                handleChange={handleChangeHeader}
                label="Report Number"
                message={error.PQR_HT_ReportNumHorizontal.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_HT_ReportNumHorizontal: error,
                  }));
                }}
              ></Single_Input>
            </Col>
          </Row>
        </Row>}
{isoverlay ? 
        <Row style={{ padding: "20px 60px", position:"relative", bottom:"35px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Other Test </p>


              <PQR_Page3_Table_ColumnN
                columns={4}
                sessionName={sessionName}
                maxLength={[10,14,35,35]}
                array={Other_Test}
                width= {243}
                width1={243}
                width2={243}
                width3={243}
                setheaderObj={setheaderObj}
                disabled={Other_Test_Disabled}
                setError={setError}
                numberofrowsdisabled={4}
                title={["Type of Test","Acceptance Criteria","Results","Remarks"]}
                name={["PQR_OT_TypeofTest","PQR_OT_Acceptance_Criteria","PQR_OT_Results","PQR_OT_Remarks"]}
                status={[error.PQR_OT_TypeofTest.status,error.PQR_OT_Acceptance_Criteria.status,error.PQR_OT_Results.status,error.PQR_OT_Remarks.status]}
                message={[error.PQR_OT_TypeofTest.message,error.PQR_OT_Acceptance_Criteria.message,error.PQR_OT_Results.message,error.PQR_OT_Remarks.message]}
                
                handleChangetestarray={handleChangeOther_Testarray}
              />
            </Col>

            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_OT_RemarksHorizontal"
                type="text"
                maxLength={90}
                status={error.PQR_OT_RemarksHorizontal.status}
                value={headerObj?.PQR_OT_RemarksHorizontal}
                handleChange={handleChangeHeader}
                label="Remarks"
                message={error.PQR_OT_RemarksHorizontal.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_OT_RemarksHorizontal: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_OT_ReportNumHorizontal"
                type="text"
                maxLength={90}
                status={error.PQR_OT_ReportNumHorizontal.status}
                value={headerObj?.PQR_OT_ReportNumHorizontal}
                handleChange={handleChangeHeader}
                label="Report Number"
                message={error.PQR_OT_ReportNumHorizontal.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                              PQR_OT_ReportNumHorizontal: error,
                            }));
                          }}
                        ></Single_Input>
                      </Col>
                   
          
                    </Row>
          </Row>
          :
          <Row style={{ padding: "20px 60px", position:"relative", bottom:"35px" }}>
          <Row className="mb-1">
            <Col md={12}>
              <p className="WPS-blue-text " style={{position:"relative", top:"10px"}}>Other Test </p>
          
          
              <PQR_Page3_Table_ColumnN
                columns={4}
                sessionName={sessionName}
                maxLength={[10,14,35,35]}
                width= {243}
                width1={243}
                width2={243}
                width3={243}
                array={Other_Test}
                setheaderObj={setheaderObj}
                disabled={Other_Test_Disabled}
                setError={setError}
                numberofrowsdisabled={4}
                title={["Type of Test","Acceptance Criteria","Results","Remarks"]}
                name={["PQR_OT_TypeofTest","PQR_OT_Acceptance_Criteria","PQR_OT_Results","PQR_OT_Remarks"]}
                status={[error.PQR_OT_TypeofTest.status,error.PQR_OT_Acceptance_Criteria.status,error.PQR_OT_Results.status,error.PQR_OT_Remarks.status]}
                message={[error.PQR_OT_TypeofTest.message,error.PQR_OT_Acceptance_Criteria.message,error.PQR_OT_Results.message,error.PQR_OT_Remarks.message]}
                
                handleChangetestarray={handleChangeOther_Testarray}
              />
            </Col>
          
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_OT_RemarksHorizontal"
                type="text"
                maxLength={90}
                status={error.PQR_OT_RemarksHorizontal.status}
                value={headerObj?.PQR_OT_RemarksHorizontal}
                handleChange={handleChangeHeader}
                label="Remarks"
                message={error.PQR_OT_RemarksHorizontal.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_OT_RemarksHorizontal: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={12}>
              <Single_Input
                disabled={copydata}
                name="PQR_OT_ReportNumHorizontal"
                type="text"
                maxLength={90}
                status={error.PQR_OT_ReportNumHorizontal.status}
                value={headerObj?.PQR_OT_ReportNumHorizontal}
                handleChange={handleChangeHeader}
                label="Report Number"
                message={error.PQR_OT_ReportNumHorizontal.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_OT_ReportNumHorizontal: error,
                  }));
                }}
              ></Single_Input>
            </Col>
          
          
          </Row>
          </Row>}




          <Row style={{ padding: "20px 60px", position:"relative", bottom:"45px",height:"451px"}}  className="PQR-Page-row-padding">
      
        <Row className="mb-1" style={{width:"55%"}}>

           
            <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Certification</span>
           
            <PQR_Page3_Table_ColumnN
                columns={3}
                width1={160}
                width2={140}
                width3={130}
                sessionName={sessionName}
                maxLength={[25,10,20]}
                array={Certification}
                setheaderObj={setheaderObj}
                disabled={Certification_Disabled}
                setError={setError}
                // numberofrowsdisabled={4}
                title={["Welder's Name","ID","Process"]}
                name={["PQR_Certification_Welder_Name","PQR_Certification_ID","PQR_Certification_Process"]}
                status={[error.PQR_Certification_Welder_Name.status,error.PQR_Certification_ID.status,error.PQR_Certification_Process.status]}
                message={[error.PQR_Certification_Welder_Name.message,error.PQR_Certification_ID.message,error.PQR_Certification_Process.message]}
                
                handleChangetestarray={handleCertificationarray}
              />
          
<span style={{    position: "relative",left: "102%",bottom: "46.6%",gap:"5px"}}>
            <Col md={3}>
              <Single_Input
                disabled={copydata}
                style={{width:"500px"}}
                name="PQR_Certification_ConductedBy"
                type="text"
                maxLength={30}
                status={error.PQR_Certification_ConductedBy.status}
                value={headerObj?.PQR_Certification_ConductedBy}
                handleChange={handleChange}
                label="Tests Conducted by"
                message={error.PQR_Certification_ConductedBy.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_Certification_ConductedBy: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={3} style={{position:"relative",bottom:"7px"}}>
              <Single_Input
                disabled={copydata}
                name="PQR_Certification_Mechanical_TestsBy"
                style={{width:"500px"}}
                type="text"
                maxLength={30}
                status={error.PQR_Certification_Mechanical_TestsBy.status}
                value={headerObj?.PQR_Certification_Mechanical_TestsBy}
                handleChange={handleChange}
                label="Mechanical Tests by"
                message={error.PQR_Certification_Mechanical_TestsBy.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_Certification_Mechanical_TestsBy: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            {/* <Col md={3}>
              <Single_Input
                disabled={copydata}
                name="PQR_Certification_Lab_RefNo"
                style={{width:"500px"}}
                type="text"
                maxLength={30}
                status={error.PQR_Certification_Lab_RefNo.status}
                value={headerObj?.PQR_Certification_Lab_RefNo}
                handleChange={handleChange}
                label="Laboratory Ref.Number"
                message={error.PQR_Certification_Lab_RefNo.message}
                onInput={(e) => {
                  let error = isAlphanumericAllSpecialCharacters(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    PQR_Certification_Lab_RefNo: error,
                  }));
                }}
              ></Single_Input>
            </Col>  */}
            <Col md={3} style={{width:"80%", position:"relative",bottom:"11px"}}>
            <PQR_Page3_Table_ColumnN
                columns={1}
                width1={480}
                sessionName={sessionName}
                maxLength={[30]}
                array={CertificationLabRef}
                setheaderObj={setheaderObj}
                setError={setError}
                // numberofrowsdisabled={4}
                title={["Laboratory Ref.Number"]}
                name={["PQR_Certification_Lab_RefNo"]}
                status={[error.PQR_Certification_Lab_RefNo.status]}
                message={[error.PQR_Certification_Lab_RefNo.message]}
                
                handleChangetestarray={handleCertificationLabRef}
              />
              </Col>
            </span>
            </Row>
           
            </Row>
          

          {/* -------------------------prepared by --------------------------------*/}

          <Row style={{ padding: "20px 60px", position:"relative", bottom:"40px"}}>
            <Row className="mb-4">
              <Col>
                <span className="WPS-blue-text">Welding Engineer</span>
              </Col>
              <Col>
                <span className="WPS-blue-text">Witness By</span>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                     <SearchSelectProject
                      id="WPSH_PreparedBy_Name"
                      name="WPSH_PreparedBy_Name"
                      className={
                        error.WPSH_PreparedBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_PreparedBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      stylenormal={normalstyle}
                      valueField={"um_name"}
                      value={headerObj?.WPSH_PreparedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(ProjectList) => {
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: ProjectList.value,
                        }));
                 

                        let a = users.filter((b) => {
                          return b.um_name === ProjectList.value;
                        });
                 
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_PreparedBy_Name: a[0].um_name,
                          PQR_SignaturePrepared: a[0].wld_profile,
                          WPSH_WeldingEnggBy: a[0].SK,
                        }));

                        fetchbyuserprofile( a[0].wld_profile
                          );

                      }}
                      onInput={(e) => {
                        let error = isAlphanumericAllSpecialCharacters(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_WeldingEnggBy_Name: error,
                        }));
                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_WeldingEnggBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_WeldingEnggBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_PreparedBy_Date"
                  sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                  // todayDate={todayDate}
                  // maxDate={maxDate}
                  //  disabled={Readonly}
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_PreparedBy_Date.status}
                  message={error.WPSH_PreparedBy_Date.message}
                  // datevalue={datevalue}
                />
              </Col>

              <Col md={3}>
                <Form.Group className="pro-input-select">
                  <div className="search-select">
                     <SearchSelectProject
                      id="WPSH_ApprovedBy_Name"
                      name="WPSH_ApprovedBy_Name"
                      className={
                        error.WPSH_ApprovedBy_Name.status ? "err-input" : ""
                      }
                      stylegreen={
                        error.WPSH_ApprovedBy_Name.status
                          ? errorstyle
                          : normalstyle
                      }
                      data={users}
                      stylenormal={normalstyle}
                      valueField={"um_name"}
                      value={headerObj.WPSH_ApprovedBy_Name}
                      labelField={"um_name"}
                      placeholder=""
                      onChange={(ProjectList) => {
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: ProjectList.value,
                        }));
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_ApprovedBy_Name: {},
                        }));

                        let a = users.filter((b) => {
                          return b.um_name === ProjectList.value;
                        });
                        setheaderObj((prev) => ({
                          ...prev,
                          WPSH_ApprovedBy_Name: a[0].um_name,
                          PQR_SignatureApproved: a[0].wld_profile,
                          WPSH_ApprovedBy: a[0].SK,
                        }));

                        fetchapproveUserProfile( a[0].wld_profile
                        );
                      }}
                      onInput={(e) => {
                        let error = isAlphanumericAllSpecialCharacters(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          WPSH_ApprovedBy_Name: error,
                        }));
                      }}
                    ></SearchSelectProject>
                  </div>
                  <label>Name</label>
                  <div className="required-text-select">
                    {error.WPSH_ApprovedBy_Name.status && (
                      <Form.Text className="text-danger">
                        {error.WPSH_ApprovedBy_Name.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>

              <Col md={3}>
              <Date_InputUser
                  divClass="T-input w-100"
                  label="Date"
                  className="groove_datecolor"
                  name="WPSH_ApprovedBy_Date"
                  sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                  // todayDate={todayDate}
                  // maxDate={maxDate}
                  //  disabled={Readonly}
                  obj={headerObj}
                  setobj={setheaderObj}
                  setError={setError}
                  status={error.WPSH_ApprovedBy_Date.status}
                  message={error.WPSH_ApprovedBy_Date.message}
                  // datevalue={datevalue}
                />
              </Col>

              <Row className="mb-4" >
                <Col style={{position:"relative", left:"13px"}}>
                  <span>Signature Image</span>
                </Col>
                <Col style={{position:"relative", left:"20px"}}>
                  <span>Signature Image</span>
                </Col>
              </Row>

              <Row style={{position:"relative", bottom:"10px"}}>
                <Col md={6}style={{position:"relative", left:"13px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewuser} />
                    </div>
                  </div>
                </Col>

                <Col md={6} style={{position:"relative", left:"20px"}}>
                  <div className="signature-box">
                    <div className="imgbox">
                      <img className="imgsizebig1" src={previewapproved} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Row>
          </Row>
        </Row>

     
    );
  }
);

export default PQR_Page2;