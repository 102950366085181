import { Row, Col, Table, Form } from "react-bootstrap";
import axios from "axios";
import { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  DisableFields,
  DisableFieldsWPQ,
} from "../WPSTransaction/WPSObjects/WPS_common";
import defaultimg from "../../icons/noimage.png";
import {
  MessageTypes,
  WPSH_PWHeatTre_Type,
  Action,
  Status,
  Tubesheet_WPSPage1,
  Tubesheet_WPSPage2,
  WPS_Tubesheet_Detail,
  WeldingProcess,
} from "../WPSTransaction/WPSObjects/WPSGroove_entity";
import { WPSHeader } from "../WPSTransaction/WPSObjects/WPSHeader";
import {
  WPS_Process_Grid_Input,
  WPS_Process_Grid_Input_2,
  General_Single_Input_select,
  Single_Input,
  WPS_Process_Grid_Select,
  WPS_Grid_Input_2,
  WPS_Process_Grid_Input2_tube,
  textarea_Input,
} from "../WPQTransaction/WPSObjects/WPQ_Grid_Tubesheet";
import activeimg from "../../icons/activetick.svg"

import {
  isAlphanumericWithSpaceDotDash,
  isAlphanumericWithSpaceDotDashComma,
  isAlphanumericWithDotSpaceDashCommaBracketsSlash,
  isAlphanumericWithDashDot,
  isAlphanumericWithSpaceDotDashAmpersand,
  isAlphanumeric,
  isAlphanumericWithSpaceDotDashBracketPercentPlus,
  isAlphanumericWithSpaceDotDashSlashBracketColonAmpersand,
  isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace
} from "../../functions/validations";
// import { AddNewCount, AddNewGroove } from "../../Services/wps_groove-services";
import { AddNewDetail } from "../../Services/wps_details-service";
// import { AddLogs } from "../../Services/wps-logs-services";
import { IsUserMasterBand } from "../../Services/user-service";
import { WPQHeader } from "./WPSObjects/WPQHeader";
import { AddNewDetailTubesheet } from "../../Services/wpq_details-service";
import { WPQ_tubesheet_page2, WPQ_Tube_Page2 } from "./WPSObjects/WPQ_entity";
import { SignedHttpRequest } from "@azure/msal-browser";
import { AddNewCountWPQ, AddNewGrooveWPQ } from "../../Services/wpq_groove-services";
import { Single_Input_textaraNotes } from "../WPSTransaction/WPSObjects/WPS_Process_Grid";

const WPQ_Tubesheet_Page2 = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      copydata,
      logObj,
      setKey,
      tabno,
      setlogObj,
      setIsEditedPage2,
    },
    ref
  ) => {
    const location = useLocation();
    const avatar = { defaultimg };

    let userprofile = sessionStorage.getItem("UserProfile");
    let Band = sessionStorage.getItem("Band");
    let username = sessionStorage.getItem("UserName");

    let initiator = Band !== "P" && Band !== "T";
    console.log("initiator", initiator);

    let finalApprover = Band === "P" || Band === "T";
    console.log("finalApprover", finalApprover);

    var disableButton =
      initiator && headerObj?.WPSH_Status === Status.StatusPending
        ? true
        : false;
    const [isDisabled, setIsDisabled] = useState(disableButton);

    const [previewapproved, setPreviewapproved] = useState(avatar.defaultimg);

    var readonly =
      location.state?.action === Action.ActionCopy ||
      (initiator && headerObj?.WPSH_Status === Status.StatusPending)
        ? true
        : false;
    const [isReadonly, setIsReadonly] = useState(readonly);

    const [IsEdited, setIsEdited] = useState(false);
    const [action, setaction] = useState(location.state?.action);
    const [Readonly, setReadonly] = useState("");

    const [previewUrl, setPreviewUrl] = useState([]);
    const [previewuser, setPreviewuser] = useState(avatar.defaultimg);

    const [previewapproveduser, setPreviewapproveduser] = useState(
      avatar.defaultimg
    );

    const [profile, setProfile] = useState("");
    const [today, setToday] = useState("");

    const [degLayerObj, setdegLayerObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [fillerMetalObj, setFillerMetalObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [itemsObj, setItemsObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [batchObj, setBatchObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [amperesObj, setAmperesObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentPulseObj, setCurrentPulseObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentOthersObj, setcurrentOthersObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [voltsObj, setVoltsObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [travelSObj, setTravelSObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [heatObj, setHeatObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [currentPObj, setcurrentPObj] = useState(
      Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => ""))
    );

    const [error, setError] = useState({
      WPSH_WPS_No: {},
      WPSH_WPS_Revi_No: {},
      WPS_QualifiedTo: {},
      WPSH_WPS_Date: {},
      WPSH_SupportingPQR: {},
      WPQD_WP_WeldingProcess: {},
      WPQD_WP_Type: {},
      WPQD_WP_MinimumPreheatTemp: {},
      WPQD_WP_MaximumInterpassTemp: {},
      WPQD_WP_PostHeating: {},
      WPQD_WP_TungstenType: {},
      WPQD_WP_WeldPosition: {},
      WPQD_WP_WeldProgression: {},
      WPQD_WP_NoofPasses: {},
      WPQD_WP_LayerNumber: {},
      WPQD_WP_FillerMetalSIze: {},
      WPQD_WP_ItemCode: {},
      WPQD_WP_BatchNumber: {},
      WPQD_WP_Amperes: {},
      WPQD_WP_Automatic_Arc_Voltage: {},
      WPQD_WP_Pulsing_current: {},
      WPQD_WP_Pulsing_current_others: {},
      WPQD_WP_Volts: {},
      WPQD_WP_TravelSpeed: {},
      WPQD_WP_HeatInput: {},
      WPQD_WP_CurrentPolarity: {},
      WPQD_WP_ArcVoltageControl: {},
      WPQD_WP_ShieldingGasType: {},
      WPQD_WP_ShieldingFlowRate: {},
      WPQD_WP_BackingGasType: {},
      WPQD_WP_BackingFlowRate: {},
      WPQD_WP_Auxiliarygas: {},
      WPQ_OTV_Expansion: {},
      WPQ_OTV_MethodofCleaning: {},
      WPQ_OTV_OrifceCupSize: {},
      WPQ_OTV_Oscillation: {},

      WPSH_PWHeatTre_Temperature: {},
      WPQ_Post_WHT_Time: {},
      WPSH_PWHeatTre_Type: {},
      WPQ_Post_WHT_Heating_Rate: {},
      WPQ_Post_WHT_Cooling_Rate: {},
      WPSH_Notes: {},
      WPSH_PreparedBy_Name: {},
      WPSH_PreparedBy_Date: {},
      WPSH_ApprovedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_PreparedBy_Remark: {},
      WPSH_ApprovedBy_Remark: {},

      WPQD_WP_LayerNo: [{ Value_A: [""] }, { Value_B: [""] }],
      WPQD_WP_Filler_Metal_Size: [{ Value_A: [""] }, { Value_B: [""] }],

      WPQD_WP_Amperes: [{ Value_A: [""] }, { Value_B: [""] }],
      WPQD_WP_DC_Pulsing_current: [{ Value_A: [""] }, { Value_B: [""] }],
      WPQD_WP_DC_Pulsing_current_Others: [{ Value_A: [""] }, { Value_B: [""] }],
      WPQD_WP_Volatage_Volts: [{ Value_A: [""] }, { Value_B: [""] }],
      WPQD_WP_Travel_Speed: [{ Value_A: [""] }, { Value_B: [""] }],
      WPQD_WP_Heat_Input: [{ Value_A: [""] }, { Value_B: [""] }],
      WPQD_WP_PolarityOrCurrent: [{ Value_A: [""] }, { Value_B: [""] }],

      WPQ_Post_WHT_Temp: {},
      WPQ_Post_WHT_Time: {},
      WPQ_Post_WHT_Type: {},
      WPQ_Post_WHT_Heating_Rate: {},
      WPQ_Post_WHT_Cooling_Rate: {},

      WPQ_Page2_Notes: {},

      WPQ_Certification_Welder_Name: {},
      WPQ_Certification_ID: {},
      WPQ_Certification_ConductedBy: {},
      WPQ_Certification_Mechanical_TestsBy: {},
      WPQ_Certification_Lab_RefNo: {},
    });

    console.log("de", detailObj);

  

    const validate = async () => {
      
      console.log("detaiiiiil", detailObj);

      let tubePage1 = Object.entries(WPQ_Tube_Page2);

      let isError = false;

      tubePage1.map(([key, val]) => {
        if (detailObj?.[key] === "") {
          isError = true;
          error[key] = {
            status: true,
            message: `This field is required`,
          };
        }

        if (error[key].message === "Special Characters not allowed") {
          
          isError = true;
          error[key] = {
            status: true,
            message: `Enter Valid Characters`,
          };
        }
      });

      var errortype = WPQ_tubesheet_page2;

      errortype.map((cols) => {
        console.log("entrytube", cols.Column, cols.Type, cols.Data);

        let data = { ...cols.Data };
        let dataa = { ...data[0] };
        let dataaa = { ...dataa.Actual_Value };
        let dataaaa = { ...dataaa[0] };

        // console.log("entry111", dataaa[0]);
        var value = "";
        var value1 = "";

        var msg = "";
        if (cols.Type === "S") {
          value = detailObj[cols.Column][0].Value_A[0];
          value1 = detailObj[cols.Column][1].Value_B[0];

          msg = `This field is required`;

        }


        if (
          value === undefined ||
          value === "" ||
          value === null ||
          value1 === undefined ||
          value1 === "" ||
          value1 === null
        ) {
          isError = true;
          // setIsError(true);
          

          setError((prevState) => ({
            ...error,
            ...prevState,

            [cols.Column]: [
              {
                Value_A: [
                  {
                    status: true,
                    message: msg,
                  },
                ],
              },
              {
                Value_B: [
                  {
                    status: true,
                    message: msg,
                  },
                ],
              },
            ],
            
          }));
        } else {
          setError((prevState) => ({
            ...prevState,
            // ...error,
            [cols.Column]: [
              {
                Value_A: [
                  {
                    status: false,
                    message: "",
                  },
                ],
              },
              {
                Value_B: [
                  {
                    status: false,
                    message: "",
                  },
                ],
              },
            ],
          }));
        }
        var regExp = /^[-A-Za-z0-9\s.+-/_()*@!,°;:#$&]*$/;

        if (
          error[cols.Column][0].Value_A[0].message ===
            "Special Characters not allowed" ||
          !value.match(regExp) ||
          !value1.match(regExp)
        ) {
          
          isError = true;

          setError((prevState) => ({
            ...prevState,

            [cols.Column]: [
              {
                Value_A: [
                  {
                    status: true,
                    message: "Enter Valid Characters",
                  },
                ],
              },
              {
                Value_B: [
                  {
                    status: true,
                    message: "Enter Valid Characters",
                  },
                ],
              },
            ],
         
          }));
        }
      });

      return isError;

    };

    console.log("eeeerr", error);

    const handleChangedetailsarrayselect = (e, i) => {
      
   
      const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };

    const handle_2Input_ChangeA = (key, id, valueIndex, newValue) => {
      
      const updatedProcessObj = { ...detailObj };
      console.log("adfv", headerObj);
      updatedProcessObj[key][id].Value_A[valueIndex] = newValue;
      setdetailObj(updatedProcessObj);
    };

    const handle_2Input_ChangeB = (key, id, valueIndex, newValue) => {
      
      const updatedProcessObj = { ...detailObj };
      console.log("adfv", headerObj);
      updatedProcessObj[key][id].Value_B[valueIndex] = newValue;
      setdetailObj(updatedProcessObj);
    };

    const handleCount = () => {
      
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj?.WPSH_Status === Status.StatusApproved) {
          console.log("edxisting_status", headerObj?.WPSH_Status);
          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          console.log("headerObj.WPS_Change_No", headerObj?.WPS_Change_No);
          result = AddNewCountWPQ(headerObj);
        }
      }
    };

    const handleAdd = async (approvalstatus) => {
      
      let result = false;
      console.log("handleAdd_headerObj?.WPSH_Status", headerObj?.WPSH_Status);
      console.log(
        "handleAdd_headerObj?.WPSH_Approval_Status",
        headerObj?.WPSH_Approval_Status
      );
      console.log("handleAdd_approvalstatus", approvalstatus);
      
      headerObj.WPQD_WP_WeldingProcess = "GTAW"

      if (headerObj?.WPSH_Status === Status.StatusApproved) {
        handleCount();
      }
      if (
        headerObj?.WPSH_Approval_Status !== "" &&
        headerObj?.WPSH_Approval_Status !== undefined
      ) {
        headerObj.WPSH_Status = headerObj?.WPSH_Approval_Status;
      } else {
        headerObj.WPSH_Status = approvalstatus;
      }

      console.log("now_in", headerObj);

      // var WpsDetail = detailObj.filter(
      //   (i) => i.WPQ_Process !== "NA" && i.WPQ_Process !== ""
      // );
      // console.log("WpsDetail......", WpsDetail);
      // 

      console.log("AddNewGroove_headerObj", headerObj);

    //   const filteredDetails = detailObj.filter(
    //     (item) => item.WPQ_Process !== "NA" && item.WPQ_Process !== ""
    // );
      console.log("ddddeee", detailObj);

      headerObj.WPQD_WP_Amperes = detailObj.WPQD_WP_Amperes;
      headerObj.WPQD_WP_NoofPasses = detailObj.WPQD_WP_NoofPasses;
      headerObj.WPQD_WP_WeldPosition = detailObj.WPQD_WP_WeldPosition;
      headerObj.WPQD_WP_WeldProgression = detailObj.WPQD_WP_WeldProgression;
      headerObj.WPQD_WP_PolarityOrCurrent = detailObj.WPQD_WP_PolarityOrCurrent;
      headerObj.WPQD_WP_Automatic_Arc_Voltage = detailObj.WPQD_WP_Automatic_Arc_Voltage;
      headerObj.WPSD_FM_FNo = detailObj.WPSD_FM_FNo;
      headerObj.WPSD_FM_Ano = detailObj.WPSD_FM_Ano;
      headerObj.WPQD_WP_Type = detailObj.WPQD_WP_Type;


    
  

      let res = await AddNewGrooveWPQ(headerObj)
        .catch((e) => {
          result = false;
        })
        .then(async () => {
          result = true;

          
          await AddNewDetailTubesheet(detailObj)
            .catch((err) => {
              console.log("error" + err);
            })
            .then((r) => {
              console.log("first  done detail", r);
              setaction(Action.ActionEdit);
              result = true;
            });
          if (
            action === Action.ActionEdit &&
            approvalstatus !== Status.StatusTempSaved
          ) {
            // await AddLogs(logObj);
            result = true;

          }
        });
      return Promise.resolve(result);
    };

    const checkIfUsernameExists = async () => {
      let res = await IsUserMasterBand();
      if (res !== undefined) return res.data.message;
      else return false;
    };

    useEffect(() => {
      console.log("IsEdited", IsEdited);
    }, [IsEdited]);

    const handleSubmitTemp = async () => {
      
      var res = false;
      console.log("handleSubmitTemp_IsEdited", IsEdited);
      if (action === Action.ActionEdit) {
        if (IsEdited) {
          setIsEdited(false);
          // setIsEditedPage2(false);
          console.log("2nd page Submit no ");

          res = await handleAdd(Status.StatusTempSaved);

          if (res) {
            console.log("2nd Page Tab", tabno);
            return MessageTypes.Temp;
          } else {
            return MessageTypes.Fail;
          }
        }

        return MessageTypes.None;
      } else {
        res = handleAdd(Status.StatusTempSaved);

        if (res) {
          return MessageTypes.Temp;
        } else {
          return MessageTypes.Required;
        }
      }
    };

    const handleSubmit = async () => {
      
      setIsEdited(false);
      if ((await validate())) {
        setIsEdited(true);
      }
      console.log("headerObj_handleSubmit", headerObj);
      console.log("headerObj_handleSubmit_WPSH_Status", headerObj.WPSH_Status);
      console.log(
        "headerObj_handleSubmit_WPSH_Approval_Status",
        headerObj?.WPSH_Approval_Status
      );

      var result = false;
      const isUsernamePresent = await checkIfUsernameExists();
      

      if (!isUsernamePresent) {
        console.log("!isUsernamePresent", headerObj?.WPSH_Status);
        headerObj.WPSH_Status = Status.StatusTempSaved;
      } else {
        console.log("else before", headerObj.WPSH_Status);
        headerObj.WPSH_Status = Status.StatusPending;
      }
      console.log("bfr decide", headerObj.WPSH_Status);

      console.log("after decide", headerObj.WPSH_Status);

      if (!(await validate())) {
        result = handleAdd(Status.StatusTempSaved);
        console.log("result_pending", result);
        
        if (result) {
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.RequiredAll;
      }
    };

    useImperativeHandle(ref, () => ({
      funcOnNext2: async () => {
        var res = false;
        res = await handleSubmit();
        return res;
      },

      funcOnNextTemp2: async () => {
        
        var res = false;
        console.log("is temporary", res);
        res = await handleSubmitTemp();
        console.log("is temporary return", res);
        return res;
      },
    }));

    useEffect(() => {
      
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      setToday(formattedDate);
      console.log("here", headerObj.WPSH_PreparedBy, headerObj.WPSH_Status);
      
      setReadonly(
        DisableFieldsWPQ(headerObj, Status, Action, action) ? "disabled" : ""
      );
      console.log("here", Readonly, "ddd");
    }, [headerObj]);

    const handleSelectChange = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      // setIsEditedPage2(true);
      const { name, value } = e.target;
      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };

    const handleChange = (e) => {
    
      const { name, value } = e.target;

      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangedetail = (e) => {
     
      const { name, value } = e.target;

      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleChangeTube = (row, col, key, obj, setfunction, e) => {
      

      if (action === Action.ActionEdit) setIsEdited(true);
      // setIsEditedPage2(true);
      let temp = [...obj];
      temp[row][col] = e.target.value;
      setfunction(temp);
      let onChangeVal = [...detailObj];
      onChangeVal[row][key] = temp[row];
      setdetailObj(onChangeVal);
    };

    const handleChangedetailsarray = (e, i) => {
            const { name, value } = e.target;
      setdetailObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };


    const [valueNotes, setValueNotes] = useState("");
    const maxNewLines = 5; // Maximum allowed new lines

    const handleChangeNotes = (e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      // setIsEditedPage3(true);
    
      const { name, value } = e.target;
      
      // Split the input text into lines based on existing newlines
      const lines = value.split('\n');
      
      // Limit each line to 100 characters
      const formattedLines = lines.map(line => {
        if (line.length > 100) {
          // Break the line into chunks of 100 characters
          return line.match(/.{1,100}/g).join('\n');
        }
        return line;
      });
    
      // Join the lines back together
      let formattedValue = formattedLines.join('\n'); // Use 'let' here instead of 'const'
      
      // Count the number of new lines
      const newLinesCount = (formattedValue.match(/\n/g) || []).length;
      
      if (newLinesCount <= maxNewLines) {
        setValueNotes(formattedValue);
    
        // Update the header object state with the new value
        setdetailObj((prevState) => ({
          ...prevState,
          [name]: formattedValue,
        }));
      } else {
        e.preventDefault(); // Prevent further input if the new line limit is reached
      }
    };


    return (
      //style="width:70%"
      <fieldset disabled={isReadonly}>
        <Row className="font">
    
          <WPQHeader headerObj={headerObj}></WPQHeader>
          <br />
          <Row style={{ padding: "0px 60px" }}>
            <span className="WPS-blue-text" style={{position:"relative",top:"20px"}}>Welding Procedure</span>

            <Row className="mb-4" style={{ padding: "10px 25px" }}>
          <div className="statusbg">
            <span className="statusborder float-start">Status &nbsp; 
            <img style={{width:"14px", height:"14px"}}src={activeimg} />{" "}
            <span style={{color:"var(--godrej-green-alt)"}}>Active
                          {/* {item?.remainingday} */}
                          </span>

            </span>
            <span className=" statusborder float-end">
              Last Weld Date &nbsp;
              <span style={{color:"var(--godrej-green-alt)"}}> {headerObj?.wld_createdon}
             
              </span>{" "}
            </span>
          </div>
        </Row>

            <Table
              bordered
              responsive="sm"
              // className="text-center same-col-widths align-middle"
              className="text-center tube-col-widths"
              style={{position:"relative", bottom:"10px"}}
            >
              <tbody>
                <WPS_Process_Grid_Select
                  detailObj={detailObj}
                  ColumnHeader="Welding Process"
                  status={error.WPQD_WP_WeldingProcess.status}
                  message={error.WPQD_WP_WeldingProcess.message}
                  name="WPQD_WP_WeldingProcess"
                  // array={WeldingProcess}
                  handleChangedetailsarray={handleChangedetailsarrayselect}
                ></WPS_Process_Grid_Select>

                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={10}
                  //readOnly={isReadonly}
                  ColumnHeader="Type"
                  // Columndimension="°C"
                  status={error.WPQD_WP_Type.status}
                  message={error.WPQD_WP_Type.message}
                  type="text"
                  name="WPQD_WP_Type"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_Type: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={18}
                  //readOnly={isReadonly}
                  ColumnHeader="Minimunm Preheat Temp"
                  Columndimension="°C"
                  status={error.WPQD_WP_MinimumPreheatTemp.status}
                  message={error.WPQD_WP_MinimumPreheatTemp.message}
                  type="text"
                  name="WPQD_WP_MinimumPreheatTemp"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_MinimumPreheatTemp: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>
                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={18}
                  //readOnly={isReadonly}
                  ColumnHeader=" Maximum Interpass Temp"
                  Columndimension="°C"
                  status={error.WPQD_WP_MaximumInterpassTemp.status}
                  message={error.WPQD_WP_MaximumInterpassTemp.message}
                  type="text"
                  name="WPQD_WP_MaximumInterpassTemp"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_MaximumInterpassTemp: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>
                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={48}
                  //readOnly={isReadonly}
                  ColumnHeader="Post Heating/DHT"
                  status={error.WPQD_WP_PostHeating.status}
                  message={error.WPQD_WP_PostHeating.message}
                  type="text"
                  name="WPQD_WP_PostHeating"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_PostHeating: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>
                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={48}
                  //readOnly={isReadonly}
                  ColumnHeader="Tungsten Type and Size"
                  Columndimension="mm"
                  status={error.WPQD_WP_TungstenType.status}
                  message={error.WPQD_WP_TungstenType.message}
                  type="text"
                  name="WPQD_WP_TungstenType"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                      e.target.value
                    );
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_TungstenType: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={48}
                  //readOnly={isReadonly}
                  ColumnHeader="Welding Position"
                  status={error.WPQD_WP_WeldPosition.status}
                  message={error.WPQD_WP_WeldPosition.message}
                  type="text"
                  name="WPQD_WP_WeldPosition"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_WeldPosition: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>
                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={48}
                  //readOnly={isReadonly}
                  ColumnHeader="Progression"
                  status={error.WPQD_WP_WeldProgression.status}
                  message={error.WPQD_WP_WeldProgression.message}
                  type="text"
                  name="WPQD_WP_WeldProgression"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_WeldProgression: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>
                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={4}
                  //readOnly={isReadonly}
                  ColumnHeader="No of Passes"
                  status={error.WPQD_WP_NoofPasses.status}
                  message={error.WPQD_WP_NoofPasses.message}
                  type="text"
                  name="WPQD_WP_NoofPasses"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_NoofPasses: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>
              </tbody>
            </Table>

            <Table
              bordered
              responsive="sm"
              // className="text-center same-col-widths align-middle"
              className="text-center tube-col-widths"
              style={{ position: "relative", bottom: "30px" }}
            >
              <tbody>
                <WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="Layer Number"
                  status={error.WPQD_WP_LayerNo[0].Value_A[0].status}
                  message={error.WPQD_WP_LayerNo[0].Value_A[0].message}
                  status1={error.WPQD_WP_LayerNo[1].Value_B[0].status}
                  message1={error.WPQD_WP_LayerNo[1].Value_B[0].message}
                  type="text"
                  name="WPQD_WP_LayerNo"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_LayerNo: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>

                <WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="Filler Metal Size"
                  Columndimension="mm"
                  status={error.WPQD_WP_Filler_Metal_Size[0].Value_A[0].status}
                  message={
                    error.WPQD_WP_Filler_Metal_Size[0].Value_A[0].message
                  }
                  status1={error.WPQD_WP_Filler_Metal_Size[1].Value_B[0].status}
                  message1={
                    error.WPQD_WP_Filler_Metal_Size[1].Value_B[0].message
                  }
                  type="text"
                  name="WPQD_WP_Filler_Metal_Size"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_Filler_Metal_Size: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>

                <WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="Amperes Amps"
                  status={error.WPQD_WP_Amperes[0].Value_A[0].status}
                  message={error.WPQD_WP_Amperes[0].Value_A[0].message}
                  status1={error.WPQD_WP_Amperes[1].Value_B[0].status}
                  message1={error.WPQD_WP_Amperes[1].Value_B[0].message}
                  type="text"
                  name="WPQD_WP_Amperes"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_Amperes: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>

                <WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="DC Pulsing Current"
                  Columndimension="Amps"
                  status={error.WPQD_WP_DC_Pulsing_current[0].Value_A[0].status}
                  message={
                    error.WPQD_WP_DC_Pulsing_current[0].Value_A[0].message
                  }
                  status1={
                    error.WPQD_WP_DC_Pulsing_current[1].Value_B[0].status
                  }
                  message1={
                    error.WPQD_WP_DC_Pulsing_current[1].Value_B[0].message
                  }
                  type="text"
                  name="WPQD_WP_DC_Pulsing_current"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_DC_Pulsing_current: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>


<WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="DC Pulsing Current-Others"
                  Columndimension=""
                  status={error.WPQD_WP_DC_Pulsing_current_Others[0].Value_A[0].status}
                  message={
                    error.WPQD_WP_DC_Pulsing_current_Others[0].Value_A[0].message
                  }
                  status1={
                    error.WPQD_WP_DC_Pulsing_current_Others[1].Value_B[0].status
                  }
                  message1={
                    error.WPQD_WP_DC_Pulsing_current_Others[1].Value_B[0].message
                  }
                  type="text"
                  name="WPQD_WP_DC_Pulsing_current_Others"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_DC_Pulsing_current_Others: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>
                <WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="Voltage Volts"
                  Columndimension="Kj/mm"
                  status={error.WPQD_WP_Volatage_Volts[0].Value_A[0].status}
                  message={error.WPQD_WP_Volatage_Volts[0].Value_A[0].message}
                  status1={error.WPQD_WP_Volatage_Volts[1].Value_B[0].status}
                  message1={error.WPQD_WP_Volatage_Volts[1].Value_B[0].message}
                  type="text"
                  name="WPQD_WP_Volatage_Volts"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_Volatage_Volts: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>

                <WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="Travel Speed"
                  Columndimension="mm/min"
                  status={error.WPQD_WP_Travel_Speed[0].Value_A[0].status}
                  message={error.WPQD_WP_Travel_Speed[0].Value_A[0].message}
                  status1={error.WPQD_WP_Travel_Speed[1].Value_B[0].status}
                  message1={error.WPQD_WP_Travel_Speed[1].Value_B[0].message}
                  type="text"
                  name="WPQD_WP_Travel_Speed"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_Travel_Speed: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>

                <WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="Heat Input"
                  Columndimension="Kj/mm"
                  status={error.WPQD_WP_Heat_Input[0].Value_A[0].status}
                  message={error.WPQD_WP_Heat_Input[0].Value_A[0].message}
                  status1={error.WPQD_WP_Heat_Input[1].Value_B[0].status}
                  message1={error.WPQD_WP_Heat_Input[1].Value_B[0].message}
                  type="text"
                  name="WPQD_WP_Heat_Input"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_Heat_Input: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>

                <WPS_Process_Grid_Input2_tube
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="Polarity or Current type"
                  // Columndimension="Kj/mm"
                  status={error.WPQD_WP_PolarityOrCurrent[0].Value_A[0].status}
                  message={
                    error.WPQD_WP_PolarityOrCurrent[0].Value_A[0].message
                  }
                  status1={error.WPQD_WP_PolarityOrCurrent[1].Value_B[0].status}
                  message1={
                    error.WPQD_WP_PolarityOrCurrent[1].Value_B[0].message
                  }
                  type="text"
                  name="WPQD_WP_PolarityOrCurrent"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_PolarityOrCurrent: [
                        { Value_A: [error] },
                        { Value_B: [error] },
                      ],
                    }));
                  }}
                  handle_2Input_ChangeA={handle_2Input_ChangeA}
                  handle_2Input_ChangeB={handle_2Input_ChangeB}
                ></WPS_Process_Grid_Input2_tube>
              </tbody>
            </Table>

            <Table
              bordered
              responsive="sm"
              // className="text-center same-col-widths align-middle"
              className="text-center tube-col-widths"
              style={{ position: "relative", bottom: "50px" }}
            >
              <tbody>
                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={20}
                  //readOnly={isReadonly}
                  ColumnHeader="Automatic Arc Voltage Control"
                  status={error.WPQD_WP_Automatic_Arc_Voltage.status}
                  message={error.WPQD_WP_Automatic_Arc_Voltage.message}
                  type="text"
                  name="WPQD_WP_Automatic_Arc_Voltage"
                  onInput={(e) => {
                    let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_Automatic_Arc_Voltage: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>

                <WPS_Process_Grid_Input_2
                  detailObj={detailObj}
                  maxLength={48}
                  //readOnly={isReadonly}
                  mainheading="Shielding"
                  ColumnHeader="Gas Type - % comp"
                  ColumnHeader1="Flow Type"
                  Columndimension="I/min"
                  status={
                    error.WPQD_WP_ShieldingGasType.status ||
                    error.WPQD_WP_ShieldingFlowRate.status
                  }
                  message={
                    error.WPQD_WP_ShieldingGasType.message ||
                    error.WPQD_WP_ShieldingFlowRate.message
                  }
                  type="text"
                  name="WPQD_WP_ShieldingGasType"
                  name1="WPQD_WP_ShieldingFlowRate"
                  onInput={(e) => {
                    let error =
                      isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_ShieldingGasType: error,
                    }));
                  }}
                  onInput1={(e) => {
                    let error =
                      isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_ShieldingFlowRate: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input_2>

                <WPS_Process_Grid_Input_2
                  detailObj={detailObj}
                  maxLength={48}
                  //readOnly={isReadonly}
                  mainheading="Backing / Trailing"
                  ColumnHeader="Gas Type - % comp"
                  ColumnHeader1="Flow Type"
                  Columndimension="I/min"
                  status={
                    error.WPQD_WP_BackingGasType.status ||
                    error.WPQD_WP_BackingFlowRate.status
                  }
                  message={
                    error.WPQD_WP_BackingGasType.message ||
                    error.WPQD_WP_BackingFlowRate.message
                  }
                  type="text"
                  name="WPQD_WP_BackingGasType"
                  name1="WPQD_WP_BackingFlowRate"
                  onInput={(e) => {
                    let error =
                      isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_BackingGasType: error,
                    }));
                  }}
                  onInput1={(e) => {
                    let error =
                      isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_BackingFlowRate: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input_2>

                <WPS_Process_Grid_Input
                  detailObj={detailObj}
                  maxLength={48}
                  //readOnly={isReadonly}
                  ColumnHeader="Auxiliarygas shield system"
                  status={error.WPQD_WP_Auxiliarygas.status}
                  message={error.WPQD_WP_Auxiliarygas.message}
                  type="text"
                  name="WPQD_WP_Auxiliarygas"
                  onInput={(e) => {
                    let error =
                      isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQD_WP_Auxiliarygas: error,
                    }));
                  }}
                  handleChangedetailsarray={handleChangedetailsarray}
                ></WPS_Process_Grid_Input>
              </tbody>
            </Table>

            <Row style={{ position: "relative", bottom: "50px", left:"25px" }}>
              <span className="WPS-blue-text" style={{position:"relative",right:"11px", top:"10px"}}>Other Variables</span>
              <Row className="mb-1" >
                <Col md={3}>
                  <Single_Input
                    disabled={copydata}
                    name="WPQ_OTV_Expansion"
                    type="text"
                    maxLength={48}
                    status={error.WPQ_OTV_Expansion.status}
                    value={detailObj?.WPQ_OTV_Expansion}
                    //readOnly={isReadonly}
                    handleChange={handleChangedetail}
                    label="Expansion"
                    message={error.WPQ_OTV_Expansion.message}
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_OTV_Expansion: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>
                <Col md={3}>
                  <Single_Input
                    disabled={copydata}
                    name="WPQ_OTV_MethodofCleaning"
                    type="text"
                    maxLength={48}
                    status={error.WPQ_OTV_MethodofCleaning.status}
                    value={detailObj?.WPQ_OTV_MethodofCleaning}
                    //readOnly={isReadonly}
                    handleChange={handleChangedetail}
                    label=" Method of Cleaning"
                    message={error.WPQ_OTV_MethodofCleaning.message}
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_OTV_MethodofCleaning: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>
                <Col md={3}>
                  <Single_Input
                    disabled={copydata}
                    name="WPQ_OTV_OrifceCupSize"
                    type="text"
                    maxLength={48}
                    status={error.WPQ_OTV_OrifceCupSize.status}
                    value={detailObj?.WPQ_OTV_OrifceCupSize}
                    //readOnly={isReadonly}
                    handleChange={handleChangedetail}
                    label="Orifce Cup Size"
                    message={error.WPQ_OTV_OrifceCupSize.message}
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_OTV_OrifceCupSize: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>
                <Col md={3}>
                  <Single_Input
                    disabled={copydata}
                    name="WPQ_OTV_Oscillation"
                    type="text"
                    maxLength={20}
                    status={error.WPQ_OTV_Oscillation.status}
                    value={detailObj?.WPQ_OTV_Oscillation}
                    //readOnly={isReadonly}
                    handleChange={handleChangedetail}
                    label="Oscillation"
                    message={error.WPQ_OTV_Oscillation.message}
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_OTV_Oscillation: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>
              </Row>
              
            </Row>

            <Row style={{ position: "relative", left:"25px", bottom: "50px" }}>
              <span className="WPS-blue-text" style={{ position: "relative", right:"11px", top:"10px" }}>
                Post Weld Heat Treatment Tubesheet
              </span>
              <Row className="mb-1">
                <Col className="mb-1" md={3}>
                  <Single_Input
                    disabled={copydata}
                    name="WPQ_Post_WHT_Temp"
                    type="text"
                    maxLength={25}
                    status={error.WPQ_Post_WHT_Temp.status}
                    value={detailObj?.WPQ_Post_WHT_Temp}
                    //readOnly={isReadonly}
                    handleChange={handleChangedetail}
                    label="Post Weld H/T Temp(°C)"
                    message={error.WPQ_Post_WHT_Temp.message}
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_Post_WHT_Temp: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>

                <Col className="mb-1" md={3}>
                  <div class="T-input w-100">
                    <input
                      type="text"
                      id="WPQ_Post_WHT_Time"
                      name="WPQ_Post_WHT_Time"
                      maxLength={20}
                      //readOnly={isReadonly}
                      disabled={copydata}
                      placeholder=" "
                      className={
                        error.WPQ_Post_WHT_Time.status
                          ? " T-input placeHolderInput inputText text-field-with-error"
                          : "T-input placeHolderInput inputText"
                      }
                      value={detailObj?.WPQ_Post_WHT_Time}
                      onChange={handleChangedetail}
                      onInput={(e) => {
                        let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                          e.target.value
                        );

                        setError((prevState) => ({
                          ...prevState,
                          WPQ_Post_WHT_Time: error,
                        }));
                      }}
                    />
                    <label for="WPQ_Post_WHT_Time">Time (Minutes)</label>
                  </div>
                  {detailObj?.WPQ_Post_WHT_Temp !== "" ? (
                    <div className="required-text1">
                      {error.WPQ_Post_WHT_Time.status && (
                        <Form.Text className="text-danger">
                          {error.WPQ_Post_WHT_Time.message}
                        </Form.Text>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                <Col className="mb-1" md={3}>
                 

                  <Single_Input
                    disabled={copydata}
                    name="WPQ_Post_WHT_Type"
                    type="text"
                    maxLength={20}
                    status={error.WPQ_Post_WHT_Type.status}
                    value={detailObj?.WPQ_Post_WHT_Type}
                    //readOnly={isReadonly}
                    handleChange={handleChangedetail}
                    label="Type"
                    message={error.WPQ_Post_WHT_Type.message}
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_Post_WHT_Type: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>
                <Col className="mb-1" md={3}>
                  <div class="T-input w-100">
                    <input
                      type="text"
                      id="WPQ_Post_WHT_Heating_Rate"
                      name="WPQ_Post_WHT_Heating_Rate"
                      //readOnly={isReadonly}
                      maxLength={25}
                      disabled={copydata}
                      placeholder=" "
                      className={
                        error.WPQ_Post_WHT_Heating_Rate.status
                          ? " T-input placeHolderInput inputText text-field-with-error"
                          : "T-input placeHolderInput inputText"
                      }
                      value={detailObj?.WPQ_Post_WHT_Heating_Rate}
                      onChange={handleChangedetail}
                      onInput={(e) => {
                        let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                          e.target.value
                        );
                        setError((prevState) => ({
                          ...prevState,
                          WPQ_Post_WHT_Heating_Rate: error,
                        }));
                      }}
                    />
                    <label for="WPQ_Post_WHT_Heating_Rate">
                      Heating Rate (C/Hr)
                    </label>
                  </div>
                  {detailObj?.WPQ_Post_WHT_Temp !== "" ? (
                    <div className="required-text1">
                      {error.WPQ_Post_WHT_Heating_Rate.status && (
                        <Form.Text className="text-danger">
                          {error.WPQ_Post_WHT_Heating_Rate.message}
                        </Form.Text>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className="mb-1">
                <Col className="mb-1" md={3}>
                  <Single_Input
                    disabled={copydata}
                    name="WPQ_Post_WHT_Cooling_Rate"
                    type="text"
                    maxLength={20}
                    status={error.WPQ_Post_WHT_Cooling_Rate.status}
                    value={detailObj?.WPQ_Post_WHT_Cooling_Rate}
                    handleChange={handleChangedetail}
                    label=" Cooling Rate (C/Hr)"
                    message={error.WPQ_Post_WHT_Cooling_Rate.message}
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_Post_WHT_Cooling_Rate: error,
                      }));
                    }}
                  ></Single_Input>
                </Col>
              </Row>
            </Row>
          </Row>
          <Row  className="WPQ-Page-row-padding"
            style={{
              padding: "20px 60px",
              position: "relative",
              bottom: "70px",
            }}
          >
            <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Notes</span>
            {/* <Col md={12}>
              <div class="T-input w-100">
                <textarea
                  type="text"
                  name="WPQ_Page2_Notes"
                  style={{ height: "100px" }}
                  id="WPQ_Page2_Notes"
                  readOnly={isReadonly}
                  maxLength={48}
                  placeholder=" "
                  className={
                    error.WPQ_Page2_Notes.status
                      ? "T-input placeHolderInput inputText text-field-with-error"
                      : "T-input placeHolderInput inputText"
                  }
                  value={detailObj?.WPQ_Page2_Notes}
                  onChange={handleChange}
                  onInput={(e) => {
                    let error =
                      isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                    setError((prevState) => ({
                      ...prevState,
                      WPQ_Page2_Notes: error,
                    }));
                  }}
                />
                <label for="WPQ_Page2_Notes">Notes</label>
              </div>
              <div className="required-text1">
                {error.WPQ_Page2_Notes.status && (
                  <Form.Text className="text-danger">
                    {error.WPQ_Page2_Notes.message}
                  </Form.Text>
                )}
              </div>
            </Col> */}

            <Col md={12}>
              <Single_Input_textaraNotes
                name="WPQ_Page2_Notes"
                type="text"
                style={{ height: "250px", resize: "none" }}
                maxLength={1500}
                className="textarea-notes"
                status={error.WPQ_Page2_Notes.status}
                // value={headerObj.WPSH_Notes}
                value={detailObj?.WPQ_Page2_Notes ? detailObj?.WPQ_Page2_Notes : valueNotes}
                handleChange={handleChangeNotes}
                // onKeyDown={handleKeyDown}
                label="Notes"
                message={error.WPQ_Page2_Notes.message}
                // onInput={(e) => {
                //   let error = isAlphanumericAllCharactersextra(e.target.value);
                //   setError((prevState) => ({
                //     ...prevState,
                //     WPSH_Notes: error,
                //   }));
                // }}
              ></Single_Input_textaraNotes>
            </Col>
            <span className="WPS-blue-text" style={{position:"relative",top:"10px"}}>Certification</span>
            <Col md={6}>
              <Single_Input
                disabled={copydata}
                name="WPQ_Certification_Welder_Name"
                type="text"
                maxLength={20}
                //readOnly={isReadonly}
                status={error.WPQ_Certification_Welder_Name.status}
                value={detailObj?.WPQ_Certification_Welder_Name}
                handleChange={handleChangedetail}
                label="Welder's Name"
                message={error.WPQ_Certification_Welder_Name.message}
                onInput={(e) => {
                  let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Certification_Welder_Name: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={3}>
              <Single_Input
                disabled={copydata}
                name="WPQ_Certification_ID"
                type="text"
                maxLength={20}
                //readOnly={isReadonly}
                status={error.WPQ_Certification_ID.status}
                value={detailObj?.WPQ_Certification_ID}
                handleChange={handleChangedetail}
                label="ID"
                message={error.WPQ_Certification_ID.message}
                onInput={(e) => {
                  let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Certification_ID: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={3}>
              <Single_Input
                disabled={copydata}
                name="WPQ_Certification_ConductedBy"
                type="text"
                maxLength={25}
                //readOnly={isReadonly}
                status={error.WPQ_Certification_ConductedBy.status}
                value={detailObj?.WPQ_Certification_ConductedBy}
                handleChange={handleChangedetail}
                label="Tests Conducted by"
                message={error.WPQ_Certification_ConductedBy.message}
                onInput={(e) => {
                  let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Certification_ConductedBy: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={3}>
              <Single_Input
                disabled={copydata}
                name="WPQ_Certification_Mechanical_TestsBy"
                type="text"
                maxLength={25}
                //readOnly={isReadonly}
                status={error.WPQ_Certification_Mechanical_TestsBy.status}
                value={detailObj?.WPQ_Certification_Mechanical_TestsBy}
                handleChange={handleChangedetail}
                label="Mechanical Tests by"
                message={error.WPQ_Certification_Mechanical_TestsBy.message}
                onInput={(e) => {
                  let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Certification_Mechanical_TestsBy: error,
                  }));
                }}
              ></Single_Input>
            </Col>
            <Col md={6}>
              <Single_Input
                disabled={copydata}
                name="WPQ_Certification_Lab_RefNo"
                type="text"
                maxLength={60}
                //readOnly={isReadonly}
                status={error.WPQ_Certification_Lab_RefNo.status}
                value={detailObj?.WPQ_Certification_Lab_RefNo}
                handleChange={handleChangedetail}
                label="Laboratory Ref.Number"
                message={error.WPQ_Certification_Lab_RefNo.message}
                onInput={(e) => {
                  let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(e.target.value);
                  setError((prevState) => ({
                    ...prevState,
                    WPQ_Certification_Lab_RefNo: error,
                  }));
                }}
              ></Single_Input>
            </Col>
          </Row>
        </Row>
      </fieldset>
    );
  }
);

export default WPQ_Tubesheet_Page2;
