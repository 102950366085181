import { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Tabs, Tab, Button } from "react-bootstrap";
// import WeldingProcedure from "./WeldingProcedure";
// import General from "./General";
// import WeldingProcedureSpec from "./WeldingProcedureSpec";
import { useLocation } from "react-router-dom";
import {
  getallgroove,
  getbyprojectno,
} from "../../Services/wpq_groove-services";
import {
  a,
  AV,
  Checkbox_array,
  chk,
  Chk_Inp_RQ,
  detailprocess,
  Field_array,
  MessageTypes,
  Oth,
  RQ,
  WPQHeader,
  WPQHeader_Tube,
  WPQ_Detail_Backing,
  WPQ_Detail_Type,
  WPQ_Fillet_610,
  WPQ_Fillet_73,
  WPQ_Fillet_73to610,
  WPQ_FM_Arc_transfermode,
  WPQ_FM_Automatic_AVC_System,
  WPQ_FM_Automatic_jointTracking,
  WPQ_FM_Backing_Gas,
  WPQ_FM_Classification,
  WPQ_FM_Consumable_Insert,
  WPQ_FM_Current_Polarity,
  WPQ_FM_Direct_VisualControl,
  WPQ_FM_filler_Wire,
  WPQ_FM_Notes,
  WPQ_FM_No_of_LayerDeposit,
  WPQ_FM_Product_Form,
  WPQ_FM_Progression,
  WPQ_FM_Single_Pass_PerSide,
  WPQ_FM_Specification,
  WPQ_FM_Thickness_Limit,
  WPQ_FM_Welding_Position,
  WPQ_FM_Weld_Deposit_Thickness,
  WPQ_Groove_610,
  WPQ_Groove_73,
  WPQ_Groove_73to610,
  WpsDetail,
  WPSD_FM_SFA,
  WPSHeader,
} from "./WPSObjects/WPQ_entity";
import { ToastContainer, toast } from "react-toastify";
import { Action, Status } from "./WPSObjects/WPQ_entity";
import WPQ_General from "./WPQ_General";
import WPQ_GetDetails from "./WPQ_GetDetails";
import {
  getdetailsById,
  getdetailsByIdTube,
} from "../../Services/wpq_details-service";
import Test_and_Certificate from "./Test_and_Certificate";
import WPQTubesheetPage1 from "./WPQTubesheetPage1";
import WPQ_Tubesheet_Page2 from "./WPQTubesheetPage2";
import WPQ_Tubesheet_Page3 from "./WPQTubesheetPage3";
import WPQ_Tubesheet_Page4 from "./WPQTubesheetPage4";
import { GridLoader } from "react-spinners";
const WPQTubeSheetTransaction = () => {
  const [key, setKey] = useState(1);

  const firstChildRef = useRef();
  const secondChildRef = useRef();
  const thirdChildRef = useRef();
  const fourthChildRef = useRef();

  const location = useLocation();
  const [Groove, setGroove] = useState(null);

  let companyname = "Godrej & Boyce Mfg. Co. Ltd";
  let UserName = sessionStorage.getItem("UserName");
  let UserId = sessionStorage.getItem("UserId");

  const [copydata, setcopydata] = useState("");
  const [action, setaction] = useState(location.state?.action);
  const [originalaction, setoriginalaction] = useState(location.state?.action);
  const [WPStype, setWPStype] = useState(location.state?.WPStype);


  const [loading, setLoading] = useState(true);


  const height = "200px";
  const [headerObj, setheaderObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : WPQHeader_Tube(WPStype)
  );
  console.log("Groove Type", WPStype);

  let Band = sessionStorage.getItem("Band");
  console.log("ddddd", location.state?.data);

  console.log("dddddhhh", headerObj);

  let initiator = Band !== "P" && Band !== "T";
  console.log("initiator", initiator);

  let finalApprover = Band === "P" || Band === "T";
  console.log("finalApprover", finalApprover);

  var readonly =
    location.state?.action === Action.ActionCopy ||
    (initiator && headerObj?.WPSH_Status === Status.StatusPending)
      ? true
      : false;

  var disableButton =
    initiator && headerObj?.WPSH_Status === Status.StatusPending ? true : false;
  const [isDisabled, setIsDisabled] = useState(disableButton);

  const [isReadonly, setIsReadonly] = useState(readonly);

  let Message = MessageTypes.Message;

  const [detailObj, setdetailObj] = useState(
    action === Action.ActionEdit || action === Action.ActionCopy
      ? location.state?.data
      : {
          SK: "",
          PK: "",
          WPSH_Project_No: "",
          WPSH_WPS_No: "",
          WPQ_Process: "GTAW",
          WPSD_FM_SFA: "",
          WPSD_FM_Classification: "",
          WPSD_FM_FNo: "",
          WPSD_FM_Ano: "",
          WPSD_FM_ChemicalAnalysis_TradeName: "",
          wld_createdby: "",
          wld_createdon: "",
          wld_updatedby: "",
          wld_updatedon: "",
          WPQD_WP_WeldingProcess: "GTAW",
          WPQD_WP_Type: "",
          WPQD_WP_MinimumPreheatTemp: "",
          WPQD_WP_MaximumInterpassTemp: "",
          WPQD_WP_PostHeating: "",
          WPQD_WP_TungstenType: "",
          WPQD_WP_WeldPosition: "",
          WPQD_WP_WeldProgression: "",
          WPQD_WP_NoofPasses: "",

          WPQD_WP_LayerNo: [{ Value_A: [""] }, { Value_B: [""] }],
          WPQD_WP_Filler_Metal_Size: [{ Value_A: [""] }, { Value_B: [""] }],

          WPQD_WP_Amperes: [{ Value_A: [""] }, { Value_B: [""] }],
          WPQD_WP_DC_Pulsing_current: [{ Value_A: [""] }, { Value_B: [""] }],
          WPQD_WP_DC_Pulsing_current_Others: [
            { Value_A: [""] },
            { Value_B: [""] },
          ],
          WPQD_WP_Volatage_Volts: [{ Value_A: [""] }, { Value_B: [""] }],
          WPQD_WP_Travel_Speed: [{ Value_A: [""] }, { Value_B: [""] }],
          WPQD_WP_Heat_Input: [{ Value_A: [""] }, { Value_B: [""] }],
          WPQD_WP_PolarityOrCurrent: [{ Value_A: [""] }, { Value_B: [""] }],

          WPQD_WP_ShieldingGasType: "",
          WPQD_WP_ShieldingFlowRate: "",

          WPQD_WP_BackingGasType: "",
          WPQD_WP_BackingFlowRate: "",

          WPQD_WP_Auxiliarygas: "",

          WPQD_WP_Automatic_Arc_Voltage: "",

          WPQ_OTV_Expansion: "",
          WPQ_OTV_StringWave: "",
          WPQ_OTV_MethodofCleaning: "",
          WPQ_OTV_OrifceCupSize: "",
          WPQ_OTV_SingleMultiElectrode: "",
          WPQ_OTV_Oscillation: "",
          WPQ_OTV_Peening: "",
          WPQ_OTV_ClosedtooutChamber: "",
          WPQ_OTV_UseOfThermalProcess: "",
          WPQ_OTV_Others: "",

          WPQ_Post_WHT_Temp: "",
          WPQ_Post_WHT_Time: "",
          WPQ_Post_WHT_Type: "",
          WPQ_Post_WHT_Heating_Rate: "",
          WPQ_Post_WHT_Cooling_Rate: "",

          WPQ_Page2_Notes: "",

          WPQ_Certification_Welder_Name: "",
          WPQ_Certification_ID: "",
          WPQ_Certification_ConductedBy: "",
          WPQ_Certification_Mechanical_TestsBy: "",
          WPQ_Certification_Lab_RefNo: "",

          WPQ_Post_WHT_Temp: "",
          WPQ_Post_WHT_Time: "",
          WPQ_Post_WHT_Type: "",
          WPQ_Post_WHT_Heating_Rate: "",
          WPQ_Post_WHT_Cooling_Rate: "",

          WPQ_Certification_Welder_Name: "",
          WPQ_Certification_ID: "",
          WPQ_Certification_ConductedBy: "",
          WPQ_Certification_Mechanical_TestsBy: "",
          WPQ_Certification_Lab_RefNo: "",

          WPQ_VEX_Report: "",
          WPQ_LPEX_Report: "",
          WPQ_LPEX_Notes: "",
          WPQ_VEX_Notes: "",
          WPQ_MEX_Result: "",

          WPQ_Radio_Notes: "",
          WPQ_OT_Notes: "",

          WPQ_MLP_Result: "",
          WPQ_MEX_Observation:"",
          WPQ_MLP_Observation:"",
        }
  );

  const [testObj, setTestObj] = useState([]);

  const temparray = [];

  // let abc = [{page:temparray},
  // {page2:[""]}]

  const Visual_Examination = [];
  const Macro_Examination5 = [];
  const Minimum_Leak_Path5 = [];
  const Liquid_Penetrant_Examination = [];
  const Macro_Examination = [
    { Table1: [], Table2: []}
    // { Table1: [], Table2: [], Table3: [], Table4: [], Table5: [] },
  ];
  const Other_Test = [];
  const Radiography_Test = [];
  const Minimum_Leak_Path = [
    { Table1: [], Table2: []}
    // { Table1: [], Table2: [], Table3: [], Table4: [], Table5: [] },
  ];

 

  useEffect(() => {
    if (action === Action.ActionAdd) {
      let Visual_Examination = Visual_Examination_Obj(1);
      let Liquid_Penetrant_Examination = Liquid_Penetrant_Examination_Obj(1);
      let Macro_Examination = Macro_Examination_Obj(1);
      let Macro_Examination5 = Macro_Examination5_Obj(1);
      let Other_Test = Other_Test_Obj(1);
      let Radiography_Test = Radiography_Test_Obj(1);
      let Minimum_Leak_Path = Minimum_Leak_Path_Obj(1);
      let Minimum_Leak_Path5 = Minimum_Leak_Path5_Obj(1);

      setdetailObj({
        ...detailObj,
        Visual_Examination,
        Liquid_Penetrant_Examination,
        Macro_Examination,
        Macro_Examination5,
        Other_Test,
        Radiography_Test,
        Minimum_Leak_Path,
        Minimum_Leak_Path5,
      });
    }
  }, []);

  console.log("GrooveType", headerObj);

  console.log("Groov", detailObj);


  let WPSD_WP_FillerMetalSIze = [
    { Actual_Value: ["", ""] },
    { Range_Qualified: ["Range_M1", "Range_M2"] },
  ];

  let WPSD_WP_LayerNumber = [
    { Actual_Value: ["Actual_L1", "Actual_L2"] },
    { Range_Qualified: ["Range_L1", "Range_L2"] },
  ];

 

  const [processObj, setprocessObj] = useState([]);

  

  function Visual_Examination_Obj(start) {
    for (let i = start; i <= 2; i++) {
      Visual_Examination.push({
        WPQ_VEX_WeldPass: "",
        WPQ_VEX_Acceptance_Criteria: "",
        WPQ_VEX_Observation: "",
        WPQ_VEX_Results: "",
      });
    }

    return Visual_Examination;
  }
  
  function Macro_Examination5_Obj(start) {
    for (let i = start; i <= 3; i++) {
      Macro_Examination5.push({
        WPQ_MEX_Magnification: "",
        WPQ_MEX_Acceptance_Criteria: "",
        // WPQ_MEX_Observation: "-",
      });
    }

    return Macro_Examination5;
  }
  function Minimum_Leak_Path5_Obj(start) {
    for (let i = start; i <= 3; i++) {
      Minimum_Leak_Path5.push({
        WPQ_MLP_Magnification: "",
        WPQ_MLP_Acceptance_Criteria: "",
        // WPQ_MLP_Observation: "",
      });
    }

    return Minimum_Leak_Path5;
  }

  function Liquid_Penetrant_Examination_Obj(start) {
    for (let i = start; i <= 2; i++) {
      Liquid_Penetrant_Examination.push({
        WPQ_LPEX_WeldPass: "",
        WPQ_LPEX_Acceptance_Criteria: "",
        WPQ_LPEX_Observation: "",
        WPQ_LPEX_Results: "",
      });
    }

    return Liquid_Penetrant_Examination;
  }

  function Other_Test_Obj(start) {
    for (let i = start; i <= 4; i++) {
      Other_Test.push({
        WPQ_OT_TypeofTest: "",
        WPQ_OT_Acceptance_Criteria: "",
        WPQ_OT_Observation: "",
        WPQ_OT_Results: "",
      });
    }

    return Other_Test;
  }

  function Radiography_Test_Obj(start) {
    for (let i = start; i <= 4; i++) {
      Radiography_Test.push({
        WPQ_Radio_TubeNo: "",
        WPQ_Radio_Acceptance_Criteria: "",
        WPQ_Radio_Observation: "",
        WPQ_Radio_Results: "",
      });
    }

    return Radiography_Test;
  }

  function Macro_Examination_Obj(start) {
    for (let i = start; i <= 5; i++) {
      Macro_Examination[0].Table1.push({
        WPQ_MEX_Tube_No: "",
        WPQ_MEX_LocA: "",
        WPQ_MEX_LocB: "",
        WPQ_MEX_LocC: "",
        WPQ_MEX_LocD: "",
      });
      Macro_Examination[0].Table2.push({
        WPQ_MEX_Tube_No: "",
        WPQ_MEX_LocA: "",
        WPQ_MEX_LocB: "",
        WPQ_MEX_LocC: "",
        WPQ_MEX_LocD: "",
      });
    
    }

    return Macro_Examination;
  }

  function Minimum_Leak_Path_Obj(start) {
    for (let i = start; i <= 5; i++) {
      Minimum_Leak_Path[0].Table1.push({
        WPQ_MLP_Tube_No: "",
        WPQ_MLP_LocA: "",
        WPQ_MLP_LocB: "",
        WPQ_MLP_LocC: "",
        WPQ_MLP_LocD: "",
      });
      Minimum_Leak_Path[0].Table2.push({
        WPQ_MLP_Tube_No: "",
        WPQ_MLP_LocA: "",
        WPQ_MLP_LocB: "",
        WPQ_MLP_LocC: "",
        WPQ_MLP_LocD: "",
      });
    
    }

    return Minimum_Leak_Path;
  }
  

  const [logObj, setlogObj] = useState({
    WPSH_Project_No_log:
      headerObj != undefined ? headerObj.WPSH_Project_No : "",
    WPSH_WPS_No_log: headerObj != undefined ? headerObj.WPSH_WPS_No : "",
    WPSH_User_Name_log: UserName,
    WPSH_User_Id_log: UserId,
    WPSH_Status_log: "",
  });
  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--bg-alt-2)",
      ZIndex: 10,
    }),
  });
  const [blyestyle, setBlue] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-purple)",
    }),
  });
  const [errorstyle, seterrorstyle] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--godrej-red-alt)",
    }),
  });

  const fetchprojectbyid = async (IdprojectPK, IdprojectSK) => {
    
    const result = await getbyprojectno(WPStype, IdprojectPK, IdprojectSK);
    console.log("header333", result);
    setheaderObj(result[0]);
  };

  const fetchprojectbyid1 = async (IdprojectPK, IdprojectSK) => {
    
    const result = await getbyprojectno(IdprojectPK, IdprojectSK);
    console.log("header333", result);
    setheaderObj(result[0]);
  };

  const fetchbyid = async (IdprojectPK, IdprojectSK, IdPK, IdSK) => {
    console.log(
      "getdetailsByIdTube_fetchbyid",
      IdprojectPK,
      IdprojectSK,
      IdPK,
      IdSK
    );
    
    const result = await getdetailsByIdTube(
      IdprojectPK,
      IdprojectSK,
      IdPK,
      IdSK
    );
    console.log("detailbyid", result[0]);
    setdetailObj(result[0]);

  };
 


  useEffect(() => {
    
    // let Iddetail = location.state?.data.PK + "#" + location.state?.data.SK;
    if (action !== Action.ActionAdd) {
      let IdprojectPK = location.state?.data.WPQ_WelderId;
      let IdprojectSK = location.state?.data.WPQ_RecordNo;

      let IdPK = location.state?.data.WPSH_WPQ_Process;
      let IdSK = location.state?.data.WPSH_WPQ_Type;

      fetchprojectbyid(IdprojectPK, IdprojectSK);

      // fetchprojectbyid1(IdprojectPK, IdprojectSK);

      console.log("ididididproject", IdprojectPK, IdprojectSK);

      fetchbyid(IdprojectPK, IdprojectSK, IdPK, IdSK);

      console.log("IdSK", IdPK, IdSK);

      // fetchbyid1(IdprojectPK, IdprojectSK);
    }

    if (action === Action.ActionCopy) {
      // let Iddetail = location.state?.data.PK + "#" + location.state?.data.SK;
      setcopydata(true);
      // fetchGroove();
    }


    setTimeout(() => {
      setLoading(false);
    }, 500);
    
  }, []);

  const next = async (e) => {
    var res = false;
    var val = false;

    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNext();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNext2();
        break;
      case 3:
        res = await thirdChildRef.current.funcOnNext3();
        break;
      case 4:
        res = await fourthChildRef.current.funcOnNext4();
        break;
      default:
        break;
    }
    console.log("ddddddd1333", res);

    if (res == MessageTypes.Success) {
      toast.success("WPQ Data Added ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (key !== 4) setKey(+key + 1);
    } else if (res == MessageTypes.Fail) {
      toast.warning("Failed, WPQ Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
  
    if (res[0] == MessageTypes.RequiredAll) {
      
      toast.warning(
        "Required Fields not filled.Please check and Fill Required Fields for all 3 Pages.",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
    if (res[1] == "wrong wps") {
      
      toast.warning(
        `Enter Approved Existing WpsRecordNo from WPS ${res[2]} Transaction`,
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
    if (res == MessageTypes.Band) {
      toast.warning(
        "Data Temporary Saved but P and T band welding engineers not present in User Master. Cannot sent for approval",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  const handleSelect = (e) => {
    console.log("tab", e);
    Temp(e, "tab");
    //next(k)
    // setKey(+k)
  };
  const Temp = async (e, tabaction) => {
    var res = false;
    var val = false;
    switch (+key) {
      case 1:
        res = await firstChildRef.current.funcOnNextTemp();
        break;
      case 2:
        res = await secondChildRef.current.funcOnNextTemp2();
        break;
      case 3:
        res = await thirdChildRef.current.funcOnNextTemp3();
        break;
      case 4:
        res = await fourthChildRef.current.funcOnNextTemp4();
        break;
      default:
        break;
    }
    console.log("temp +3333", res, e, key);

    if (res == MessageTypes.Temp) {
      toast.success("Temporary Saved WPQ Data Successfully", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
      if (tabaction == "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction == "next") {
        if (key !== 4) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res == MessageTypes.None) {
      if (tabaction == "prev") {
        if (key !== 1) setKey(+key - 1);
      } else if (tabaction == "next") {
        if (key !== 4) setKey(+key + 1);
      } else {
        setKey(+e);
      }
    } else if (res == MessageTypes.Fail) {
      toast.warning("Failed, WPQ Data could not be saved", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Required) {
      toast.warning("Required Fields not filled ", {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    }
    if (res == MessageTypes.Band) {
      toast.warning(
        " Cannot sent for approval, P and T band welding engineers not present, data saved temporarily",
        {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        }
      );
    }
  };

  return (
    <>

{loading && (
<div className="loader-overlay">
<GridLoader size={10} className="pageloader" />
</div>
)}
      <div className="position-absolute ms-3" style={{ top: "15px" }}>
        <strong>Add New</strong>
        <span className="ms-1 warning-text" style={{ fontSize: "small" }}>
          (All fields are mandatory)
        </span>
      </div>

      <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <div className="mt-4">
      {/* {action == Action.ActionAdd ? <>
        <div style={{borderBottom : "2px solid var(--col-ddd)",position:"relative",top:"34px", zIndex:"-2"}}></div></>
        :""} */}
         <div style={{borderBottom : "2px solid var(--col-ddd)",position:"relative",top:"34px", zIndex:"-2"}}></div>

        <Tabs
          activeKey={key}
          onSelect={(e) => handleSelect(e)}
          className="mb-2 w-100 border-end"
          fill
        >
          <Tab
            eventKey={1}
            title="WPQ Page 1(WPQ Tube to Tubesheet)"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <WPQTubesheetPage1
              ref={firstChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              // createDetailObj={createDetailObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              normalstyle={normalstyle}
              errorstyle={errorstyle}
              blyestyle={blyestyle}
              WPStype={WPStype}
              setKey={setKey}
              tabno={key}
              toast={toast}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
           
          </Tab>
          <Tab
            eventKey={2}
            title="WPQ Page 2"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action == Action.ActionAdd ? true : false}

            // disabled={action == Action.ActionAdd ? true : false}
          >
            <WPQ_Tubesheet_Page2
              ref={secondChildRef}
              processObj={processObj}
              setprocessObj={setprocessObj}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              // wps_detail={wps_detail}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
          <Tab
            eventKey={3}
            title="WPQ Page 3"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action == Action.ActionAdd ? true : false}

            // disabled={action == Action.ActionAdd ? true : false}
          >
            <WPQ_Tubesheet_Page3
              ref={thirdChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              testObj={testObj}
              setTestObj={setTestObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
          <Tab
            eventKey={4}
            title="WPQ Page 4"
            style={{
              height: window.innerHeight - 180,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            disabled={action == Action.ActionAdd ? true : false}

            // disabled={action == Action.ActionAdd ? true : false}
          >
            <WPQ_Tubesheet_Page4
              ref={fourthChildRef}
              headerObj={headerObj}
              setheaderObj={setheaderObj}
              detailObj={detailObj}
              setdetailObj={setdetailObj}
              testObj={testObj}
              setTestObj={setTestObj}
              copydata={copydata}
              WPStype={WPStype}
              logObj={logObj}
              setKey={setKey}
              tabno={key}
              setlogObj={setlogObj}
              isDisabled={isDisabled}
              isReadonly={isReadonly}
              setIsReadonly={setIsReadonly}
            />
          </Tab>
        </Tabs>

        <Row style={{ padding: "5px 90px" }}>
          <div>
            {isDisabled ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary"
                className="me-2"
                disabled={isDisabled}
                style={{ display: key === 1 ? "none" : "  " ,
                width: "100px",
                height: "38px",}}
                onClick={(e) => Temp(e, "prev")}
              >
                Prev
              </Button>
            )}

            {isDisabled ? (
              ""
            ) : (
              <Button
                size="sm"
                className="float-end  me-2 btn-primary"
                onClick={(e) => next(e)}
                  style={{
                    width: "100px",
                    height: "38px",
                  }}


                // onClick={() => setKey(+key + 1)}
              >
                {key === 4 ? "Done" : "Next"}
              </Button>
            )}

            {isDisabled ? (
              ""
            ) : originalaction !== Action.ActionAdd ? (
              ""
            ) : (
              <Button
                size="sm"
                variant="outline-primary "
                className="float-end  me-2"
                onClick={(e) => Temp(e, "next")}
                style={{ width: "100px", height: "38px" }}

                // onClick={() => setKey(+key + 1)}
              >
                {key === 4 ? "Temp Save" : "Temp Save"}
              </Button>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};

export default WPQTubeSheetTransaction;
