import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import {
  isAlphanumericWithSpaceDotDash,
  isAlphanumericWithSpaceDotDashPlus,
   isAlphanumericAllCharacters,
} from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
export const WPS_Base_metals = ({
  headerObj,
  error,
  readOnly,
  handleChange,
  setError,
}) => {
  return (
    <Table className="text-center">
      <thead>
        <tr className="border thead-color" >
          <th className="thickness_title" style={{width:"150px"}}></th>
          <th className="thickness_title">Product Form</th>
          <th className="thickness_title">Spec (Type of Grade)</th>
          <th className="thickness_title">P No. / A No.</th>
          <th className="thickness_title">G. No.</th>
          <th className="thickness_title">Thk.mm</th>
          <th className="thickness_title">Dia.mm</th>
        </tr>
      </thead>
      <tbody>
        <tr
          className={
            error.WPSH_BM_BaseMetal1_ProductForm &&
            (error.WPSH_BM_BaseMetal1_ProductForm.status ||
              error.WPSH_BM_BaseMetal1_Spec.status ||
              error.WPSH_BM_BaseMetal1_PNo.status ||
              error.WPSH_BM_BaseMetal1_GNo.status ||
              error.WPSH_BM_BaseMetal1_Thick.status ||
              error.WPSH_BM_BaseMetal1_Diam.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              error.WPSH_BM_BaseMetal1_ProductForm &&
              (error.WPSH_BM_BaseMetal1_ProductForm.status ||
                error.WPSH_BM_BaseMetal1_Spec.status ||
                error.WPSH_BM_BaseMetal1_PNo.status ||
                error.WPSH_BM_BaseMetal1_GNo.status ||
                error.WPSH_BM_BaseMetal1_Thick.status ||
                error.WPSH_BM_BaseMetal1_Diam.status)
                ? "text-start error-head"
                : "text-start"
            }
          >
            <div>
              <label className="Groove_green">Base Metal 1</label>
              {error.WPSH_BM_BaseMetal1_ProductForm &&
                (error.WPSH_BM_BaseMetal1_ProductForm.status ||
                  error.WPSH_BM_BaseMetal1_Spec.status ||
                  error.WPSH_BM_BaseMetal1_PNo.status ||
                  error.WPSH_BM_BaseMetal1_GNo.status ||
                  error.WPSH_BM_BaseMetal1_Thick.status ||
                  error.WPSH_BM_BaseMetal1_Diam.status) && (
                  <Tooltip
                    content={
                      error.WPSH_BM_BaseMetal1_ProductForm.message ||
                      error.WPSH_BM_BaseMetal1_Spec.message ||
                      error.WPSH_BM_BaseMetal1_PNo.message ||
                      error.WPSH_BM_BaseMetal1_GNo.message ||
                      error.WPSH_BM_BaseMetal1_Thick.message ||
                      error.WPSH_BM_BaseMetal1_Diam.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td className="Thickness_data">
            <input
              style={{width:"152px"}}
              type="text"
              className={
                error.WPSH_BM_BaseMetal1_ProductForm &&
                error.WPSH_BM_BaseMetal1_ProductForm.status
                  ? "error-input"
                  : ""
              }
              name={"WPSH_BM_BaseMetal1_ProductForm"}
              maxLength={15}
              value={headerObj.WPSH_BM_BaseMetal1_ProductForm}
              readOnly={readOnly}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_BaseMetal1_ProductForm: error,
                }));
              }}
            />
          </td>
          <td >
            <input
              type="text"
              style={{width:"335px"}}
              className={
                error.WPSH_BM_BaseMetal1_Spec &&
                error.WPSH_BM_BaseMetal1_Spec.status
                  ? "error-input"
                  : ""
              }
              name={"WPSH_BM_BaseMetal1_Spec"}
              maxLength={35}
              value={headerObj.WPSH_BM_BaseMetal1_Spec}
              readOnly={readOnly}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_BaseMetal1_Spec: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"100px"}}
              className={
                error.WPSH_BM_BaseMetal1_PNo &&
                error.WPSH_BM_BaseMetal1_PNo.status
                  ? "error-input"
                  : ""
              }
              name={"WPSH_BM_BaseMetal1_PNo"}
              maxLength={10}
              value={headerObj.WPSH_BM_BaseMetal1_PNo}
              readOnly={readOnly}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_BaseMetal1_PNo: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPSH_BM_BaseMetal1_GNo &&
                error.WPSH_BM_BaseMetal1_GNo.status
                  ? "error-input"
                  : ""
              }
              name={"WPSH_BM_BaseMetal1_GNo"}
              maxLength={5}
              value={headerObj.WPSH_BM_BaseMetal1_GNo}
              readOnly={readOnly}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_BaseMetal1_GNo: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPSH_BM_BaseMetal1_Thick &&
                error.WPSH_BM_BaseMetal1_Thick.status
                  ? "error-input"
                  : ""
              }
              name={"WPSH_BM_BaseMetal1_Thick"}
              maxLength={6}
              value={headerObj.WPSH_BM_BaseMetal1_Thick}
              readOnly={readOnly}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_BaseMetal1_Thick: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPSH_BM_BaseMetal1_Diam &&
                error.WPSH_BM_BaseMetal1_Diam.status
                  ? "error-input"
                  : ""
              }
              name={"WPSH_BM_BaseMetal1_Diam"}
              maxLength={6}
              value={headerObj.WPSH_BM_BaseMetal1_Diam}
              readOnly={readOnly}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_BaseMetal1_Diam: error,
                }));
              }}
            />
          </td>
        </tr>
        <tr
          className={
            error.WPSH_BM_WeldBaseMetal2_ProductForm &&
            (error.WPSH_BM_WeldBaseMetal2_ProductForm.status ||
              error.WPSH_BM_WeldBaseMetal2_Spec.status ||
              error.WPSH_BM_WeldBaseMetal2_PNo.status ||
              error.WPSH_BM_WeldBaseMetal2_GNo.status ||
              error.WPSH_BM_WeldBaseMetal2_Thick.status ||
              error.WPSH_BM_WeldBaseMetal2_Diam.status)
              ? "border WpsInput-md error"
              : "border WpsInput-md"
          }
        >
          <th
            className={
              error.WPSH_BM_WeldBaseMetal2_ProductForm &&
              (error.WPSH_BM_WeldBaseMetal2_ProductForm.status ||
                error.WPSH_BM_WeldBaseMetal2_Spec.status ||
                error.WPSH_BM_WeldBaseMetal2_PNo.status ||
                error.WPSH_BM_WeldBaseMetal2_GNo.status ||
                error.WPSH_BM_WeldBaseMetal2_Thick.status ||
                error.WPSH_BM_WeldBaseMetal2_Diam.status)
                ? "text-start error-head"
                : "text-start"
            }
          >
            <div>
              Welded To - Base Metal 2
              {error.WPSH_BM_WeldBaseMetal2_ProductForm &&
                (error.WPSH_BM_WeldBaseMetal2_ProductForm.status ||
                  error.WPSH_BM_WeldBaseMetal2_Spec.status ||
                  error.WPSH_BM_WeldBaseMetal2_PNo.status ||
                  error.WPSH_BM_WeldBaseMetal2_GNo.status ||
                  error.WPSH_BM_WeldBaseMetal2_Thick.status ||
                  error.WPSH_BM_WeldBaseMetal2_Diam.status) && (
                  <Tooltip
                    content={
                      error.WPSH_BM_WeldBaseMetal2_ProductForm.message ||
                      error.WPSH_BM_WeldBaseMetal2_Spec.message ||
                      error.WPSH_BM_WeldBaseMetal2_PNo.message ||
                      error.WPSH_BM_WeldBaseMetal2_GNo.message ||
                      error.WPSH_BM_WeldBaseMetal2_Thick.message ||
                      error.WPSH_BM_WeldBaseMetal2_Diam.message
                    }
                  >
                    <img src={Error} alt="Error" className="ms-1" />
                  </Tooltip>
                )}
            </div>
          </th>
          <td>
            <input
              type="text"
              style={{width:"152px"}}

              className={
                error.WPSH_BM_WeldBaseMetal2_ProductForm &&
                error.WPSH_BM_WeldBaseMetal2_ProductForm.status
                  ? "error-input"
                  : ""
              }
              readOnly={readOnly}
              name={"WPSH_BM_WeldBaseMetal2_ProductForm"}
              maxLength={15}
              value={headerObj.WPSH_BM_WeldBaseMetal2_ProductForm}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_WeldBaseMetal2_ProductForm: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"335px"}}

              className={
                error.WPSH_BM_WeldBaseMetal2_Spec &&
                error.WPSH_BM_WeldBaseMetal2_Spec.status
                  ? "error-input"
                  : ""
              }
              readOnly={readOnly}
              name={"WPSH_BM_WeldBaseMetal2_Spec"}
              maxLength={35}
              value={headerObj.WPSH_BM_WeldBaseMetal2_Spec}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_WeldBaseMetal2_Spec: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              style={{width:"100px"}}
              className={
                error.WPSH_BM_WeldBaseMetal2_PNo &&
                error.WPSH_BM_WeldBaseMetal2_PNo.status
                  ? "error-input"
                  : ""
              }
              readOnly={readOnly}
              name={"WPSH_BM_WeldBaseMetal2_PNo"}
              maxLength={10}
              value={headerObj.WPSH_BM_WeldBaseMetal2_PNo}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_WeldBaseMetal2_PNo: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPSH_BM_WeldBaseMetal2_GNo &&
                error.WPSH_BM_WeldBaseMetal2_GNo.status
                  ? "error-input"
                  : ""
              }
              readOnly={readOnly}
              name={"WPSH_BM_WeldBaseMetal2_GNo"}
              maxLength={5}
              value={headerObj.WPSH_BM_WeldBaseMetal2_GNo}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_WeldBaseMetal2_GNo: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPSH_BM_WeldBaseMetal2_Thick &&
                error.WPSH_BM_WeldBaseMetal2_Thick.status
                  ? "error-input"
                  : ""
              }
              readOnly={readOnly}
              name={"WPSH_BM_WeldBaseMetal2_Thick"}
              maxLength={6}
              value={headerObj.WPSH_BM_WeldBaseMetal2_Thick}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_WeldBaseMetal2_Thick: error,
                }));
              }}
            />
          </td>
          <td>
            <input
              type="text"
              className={
                error.WPSH_BM_WeldBaseMetal2_Diam &&
                error.WPSH_BM_WeldBaseMetal2_Diam.status
                  ? "error-input"
                  : ""
              }
              readOnly={readOnly}
              name={"WPSH_BM_WeldBaseMetal2_Diam"}
              maxLength={6}
              value={headerObj.WPSH_BM_WeldBaseMetal2_Diam}
              onChange={handleChange}
              onInput={(e) => {
                let error =  isAlphanumericAllCharacters(e.target.value);
                setError((prevState) => ({
                  ...prevState,
                  WPSH_BM_WeldBaseMetal2_Diam: error,
                }));
              }}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
