import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import { Form } from "react-bootstrap";
import { SearchSelect } from "../../utility/search-select";

export const WPS_Process_Grid_Input_3 = ({
  status,
  
  message,

  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,

  handleChange,
  maxLength,
  onInput,
}) => {

 
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail && wps_detail.map((item, id, k) => {
       // console.log("3array",id,item[name][0],name)
        var disabled = wps_detail[id]?.PQR_Process === ""
        var val1 = item[name] == undefined ? "" : item[name][0] == undefined ? "" : item[name][0]
        var val2 =  item[name] == undefined  ? "" : item[name][1] == undefined ? "" : item[name][1]
        var val3 = item[name] == undefined  ? "" : item[name][2] == undefined ? "" : item[name][2]
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                name={name + id + "_1"}
                disabled={disabled}
                value={val1}
                onChange={(e) => handleChange(id, 0, name,  e)}
                onInput={onInput}
              />

              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                name={name + id + "_2"}
                value={val2}
                disabled={disabled}
                onChange={(e) => handleChange(id, 1, name,  e)}
                onInput={onInput}
              />
              <input
                type={type}
                maxLength={maxLength}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                name={name + id + "_3"}
                value={val3}
                disabled={disabled}
                onChange={(e) => handleChange(id, 2, name,  e)}
                onInput={onInput}
              />
              
            </div>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Input_3Filermetal = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChange,
  handleChangeFillermetal,
  maxLength,
  obj,
  setfunction,
  onInput,
  fieldfiller,
  conditions,
  processCount,
  metal,
  objbatch,
  setfunctionb,
  fields,
}) => {


  
      
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span style={{textAlign:"right"}}>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id, k) => {
        console.log("iiiieiie",item[fieldfiller])
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="dInput">
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px", marginLeft: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}
                // disabled={item[fieldfiller]?.[0] === ""}

                // disabled={field  && conditions?.isFillerMetalEmpty1}
                name={name + "1"}
                value={item[name][0]}
                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[0] === ""}
                onChange={(e) => {handleChangeFillermetal(id, 0, name, obj, setfunction, e, metal) 
                  // handleChange(id, 0, fields, objbatch, setfunctionb, e, metal)
                }}
                onInput={onInput}
                // onInput={(e) => {
                //   let error = isAlphanumericAllCharacters(e.target.value);
                //   setError((prevState) => ({
                //     ...prevState,
                //     [name]: error,
                //   }));
                // }}
              />

              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}
                // disabled={field  && conditions?.isFillerMetalEmpty2}

                name={name + "2"}
                value={item[name][1]}
                // value={value[1]}

                // disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze[1] === ""}

                onChange={(e) => {handleChangeFillermetal(id, 1, name, obj, setfunction, e, metal) 
                }}               
                onInput={onInput}
              />
              <input
                type={type}
                //   maxLength={7}
                maxLength={maxLength}
                readOnly={readOnly}
                style={{ marginRight: "10px" }}
                className={status ? "error-input" : ""}
                //   name="WPSD_WP_FillerMetalSIze1"
                //   value={item.WPSD_WP_FillerMetalSIze[0]}

                name={name + "3"}
                value={item[name][2]}
                //   disabled={ location.state?.action === "Edit" && item.WPSD_WP_FillerMetalSIze === ""}

                onChange={(e) => {handleChangeFillermetal(id, 2, name, obj, setfunction, e, metal) 
                }}    
                onInput={onInput}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Input_2 = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  mainheading,
  onInput1,
  handleChangedetailsarray,
  maxLength1,
  maxLength2,
  width1,
  onInput,
  ColumnHeader1,
  name1,
  thstyle2
}) => {
  let textwidth1 = width1 != undefined ? width1 : ""
  return (
    <tr
      style={{ height: "100px" }}
      className={status ? "border WpsInput-md error" : "border WpsInput-md"}
    >
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div style={thstyle2}>
          <div>{mainheading}</div>
          <div className="d-flex flex-column pt-3">
            <span className="mb-3">{ColumnHeader}</span>
            <span>{ColumnHeader1}</span>
          </div>
          <span className="align-self-center">{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        var disabled = wps_detail[id]?.PQR_Process === ""
        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <div className="d-flex flex-column align-items-center">
              <input
                type={type}
                className={status ? "error-input mb-3 mt-2" : "mb-3 mt-2"}
                name={name}
                style={{width : textwidth1, }}
                value={item[name]}
                disabled={disabled}
                maxLength={maxLength1}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput}
              />

              <input
                type={type}
                className={status ? "error-input mt-2" : "mt-2"}
                name={name1}
                value={item[name1]}
                disabled={disabled}
                style={{width : textwidth1,}}
                maxLength={maxLength2}
                onChange={(e) => {
                  handleChangedetailsarray(e, id);
                }}
                onInput={onInput1}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export const Single_Input = ({
  status,
  type,
  name,
  error,
  value,
  handleChange,
  typeclass,
  onChange,
  style,
  readOnly,
  label,
  message,
  onInput,
  maxLength,
  disabled,
  label1,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <input
          disabled={disabled}
          type={type}
          typeclass={typeclass}
          name={name}
          style={style}
          id={name}
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder=" "
          className={
            status
              ? "T-input placeHolderInput inputText text-field-with-error"
              : "T-input placeHolderInput inputText"
          }
          value={value}
          onChange={handleChange}
          onInput={onInput}
        />
        <label html={name}>{label} {""}<span className="italic">{label1}</span></label>
      </div>
      <div className="required-text1">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};

export const General_Single_Input_select = ({
  status,
  type,
  name,
  value,
  handleSelectChange,
  readOnly,
  label,
  message,
  array,
}) => {
  return (
    <>
      <div class="T-input w-100">
        <select
          id={name}
          name={name}
          readOnly={readOnly}
          placeholder=" "
          value={value}
          className={status ? "err-input" : ""}
          onChange={handleSelectChange}
        >
          {array &&
            array.map((e, id) => {
              return (
                <option key={id} value={e.value}>
                  {e.name}
                </option>
              );
            })}
        </select>
        <label for={name}>{label}</label>
      </div>
      <div className="required-text">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};
export const PQR_Process_Grid_Select = ({
  status,
  message,
  wps_detail,
  ColumnHeader,
  Columndimension,
  name,
  readOnly,
  array,
  handleChangedetailsarray,
}) => {
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          {ColumnHeader}
          <span>{Columndimension}</span>

          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>
      {wps_detail.map((item, id) => {
        
        var disabled = wps_detail[id]?.PQR_Process === ""
        return (
          
          <td
            className={status ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <select
              style={{
                width: "272px",
                height: "38px",
                // borderColor: "#D8D8D8",
                borderRadius: "3px",
              }}
              className={status ? "error-input" : ""}
              readOnly={readOnly}
              disabled={disabled}
              name={name}
              value={item[name]}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
              }}
            >
              {array &&
                array.map((e, id) => {
                  return (
                    <option key={id} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}

            </select>
          </td>
        );
      })}
    </tr>
  );
};
export const PQR_FillerMetal_Input = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  width,
  setError,
  error,
  value,
  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  maxLength,
  handleOnInput,
  TypeofCheck,
  onBlur,
}) => {
  let textwidth = width != undefined ? width : ""
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="fillermetalheader">{ColumnHeader} </div>

          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        var disabled = wps_detail[id]?.PQR_Process === ""
        var val = item[name] == undefined ? "" : item[name]
        return (  
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              className={status ? "error-input" : ""}
              type={type}
              style={{width : textwidth, margin:"5px"}}
              name={name}
              value={val}
              disabled={disabled}
              maxLength={maxLength}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
              }}
              onBlur={onBlur}
              onInput={(e) => {
                var err = handleOnInput(e, id, name, TypeofCheck, wps_detail);
                setError((prevState) => ({
                  ...prevState,
                  [name]: err,
                }));
              }}
            />
          </td>
        );
      })}
    </tr>
  );
};

export const PQR_FillerMetal_Select = ({
  status,
  message,
  wps_detail,
  ColumnHeader,
  Columndimension,
  name,
  setError,
  setdetailObj,
  ActiveData,
  detailObj,
}) => {
  return (
    <tr className={status ? "border PQRInput-md error" : "border PQRInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="fillermetalheader">{ColumnHeader} </div>

          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {
        var disabled = wps_detail[id]?.PQR_Process === ""
        var val = item[name] == undefined ? "" : item[name]
        return (  
          <td
            className={message ? "border PQRSelectProcess error" : "border PQRSelectProcess"}
            key={id}
          >
             <SearchSelect
              required
              data={ActiveData}
              // stylegreen={error.PQR_BM_BM_Pno.status ? errorstyle : blyestyle}
              disabled={disabled}
              valueField={"wld_code"}
              value={val}
              labelField={"wld_code"}
              placeholder=""
              className={status ? "error-input" : ""}
           
              onChange={(e) => {
                item[name] = e.value;
                setdetailObj([...detailObj]);
                setError((prevState) => ({
                  ...prevState,
                  [name]: { status: false, message: "" },
                }));
                item.name !== ""
                    ? (detailObj[id].error = false)
                    : (detailObj[id].error = true)

              }}
            ></SearchSelect>
          </td>
        );
      })}
    </tr>
  );
};
export const WPS_Process_Grid_Input = ({
  status,
  item,
  message,
  Error,
  wps_detail,
  ColumnHeader,
  Columndimension,
  type,
  name,
  setError,
  error,
  value,
  readOnly,
  handleChangedetailsarray,
  onInput,
  disabled,
  maxLength,
  handleOnInput,
  TypeofCheck,
}) => {
  
  return (
    <tr className={status ? "border WpsInput-md error" : "border WpsInput-md"}>
      <th
        className={status ? "text-start thead-color" : "text-start thead-color"}
      >
        <div>
          <div className="headwidth">{ColumnHeader} </div>
          <span>{Columndimension}</span>
          {status && (
            <Tooltip content={message}>
              <img src={Img} alt="Error" className="ms-1" />
            </Tooltip>
          )}
        </div>
      </th>

      {wps_detail.map((item, id) => {

        return (
          <td
            className={message ? "border WPSSelect error" : "border WPSSelect"}
            key={id}
          >
            <input
              key={id}
              className={status ? "error-input" : ""}
              type={type}
              name={name}
              value={item[name]}
              maxLength={maxLength}
              onChange={(e) => {
                handleChangedetailsarray(e, id);
              }}
              onInput={(e) => {
                var err = handleOnInput(e, id, name, TypeofCheck, wps_detail);
                setError((prevState) => ({
                  ...prevState,
                  [name]: err,
                }));
              }}
            />
          </td>
        );
      })}
    </tr>
  );
};
