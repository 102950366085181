import Tooltip_detail from "../../utility/Tooltip_detail";
import Img from "../../../icons/ErrorIcon.svg";
import { Row } from "react-bootstrap";
import { todayDate } from "../../utility/CommonComponents/TodayDate";
export const MessageTypes = {
  Success: "Success",
  Fail: "Failure",
  Required: "Required",
  RequiredAll: "RequiredAll",
  Temp: "Temp",
  Band: "Band",
  None: "None",
};

export var WPS_Groove_Detail = [
  { Column: "WPQ_Detail_Backing", Type: "S" },

  { Column: "WPQ_FM_Specification", Type: "S" },

  { Column: "WPQ_FM_Classification", Type: "S" },

  { Column: "WPQ_FM_filler_Wire", Type: "S" },

  { Column: "WPQ_FM_F_No", Type: "S" },
  { Column: "WPQ_FM_Arc_transfermode", Type: "S" },
  { Column: "WPQ_FM_Consumable_Insert", Type: "S" },
  { Column: "WPQ_FM_Product_Form", Type: "S" },
  { Column: "WPQ_FM_No_of_LayerDeposit", Type: "S" },
  { Column: "WPQ_FM_Thickness_Limit", Type: "S" },
  { Column: "WPQ_FM_Welding_Position", Type: "S" },

  { Column: "WPQ_FM_Progression", Type: "S" },
  { Column: "WPQ_FM_Current_Polarity", Type: "S" },
  { Column: "WPQ_FM_Backing_Gas", Type: "S" },

  { Column: "WPQ_FM_Direct_VisualControl", Type: "S" },
  { Column: "WPQ_FM_Automatic_jointTracking", Type: "S" },
  { Column: "WPQ_FM_Single_Pass_PerSide", Type: "S" },

  { Column: "WPQ_FM_Automatic_AVC_System", Type: "S" },
  // { Column: "WPQ_FM_Notes", Type: "S" },

  { Column: "WPQ_FM_Weld_Deposit_Thickness", Type: "SA" },
];

export var WPQ_Test = [
  { array: "testerror", Column: "WPQ_WP_TypeOfTest", Type: "S" },
  { array: "testerror", Column: "WPQ_WP_Acceptance_Criteria", Type: "S" },
  { array: "testerror", Column: "WPQ_WP_Result", Type: "S" },
  { array: "testerror", Column: "WPQ_WP_Comments", Type: "S" },
  { array: "testerror", Column: "WPQ_WP_Report_Nummber", Type: "S" },
];

export var WPS_Overlay_Detail = [
  { Column: "WPSD_WP_MinimumPreheatTemp", Type: "S" },
  { Column: "WPSD_WP_MaximumInterpassTemp", Type: "S" },
  { Column: "WPSD_WP_FillerMetalProductForm", Type: "S" },
  { Column: "WPSD_WP_FillerMetalSIze", Type: "A" },
  { Column: "WPSD_WP_WeldConsumable", Type: "S" },
  { Column: "WPSD_WP_BatchNumber", Type: "S" },
  { Column: "WPSD_WP_PositionOfOverlay", Type: "A" },
  { Column: "WPSD_WP_WeldProgression", Type: "A" },
  { Column: "WPSD_WP_CurrentPolarity", Type: "A" },
  { Column: "WPSD_WP_Amperes", Type: "A" },
  { Column: "WPSD_WP_Volts", Type: "A" },
  { Column: "WPSD_WP_TravelSpeed", Type: "A" },
  { Column: "WPSD_WP_MinimumBeadLength", Type: "S" },
  { Column: "WPSD_WP_MaximumHeatInput", Type: "A" },
  { Column: "WPSD_WP_TungstenSIze", Type: "S" },
  { Column: "WPSD_WP_TungstenType", Type: "S" },
  { Column: "WPSD_WP_ShieldingFlowRate", Type: "S" },
  { Column: "WPSD_WP_ShieldingGasType", Type: "S" },
  { Column: "WPSD_WP_TrailingFlowRate", Type: "S" },
  { Column: "WPSD_WP_TrailingGasType", Type: "S" },
  { Column: "WPSD_WP_ArcTransferMode", Type: "S" },
  { Column: "WPSD_WP_StringOrWeave", Type: "S" },
  { Column: "WPSD_WP_Orifice_GasCupSize", Type: "S" },
  { Column: "WPSD_WP_CTWD", Type: "S" },
  { Column: "WPSD_WP_Multiple_SinglePassPerSide", Type: "S" },
  { Column: "WPSD_WP_Multiple_SingleLayer", Type: "S" },
  { Column: "WPSD_WP_Multi_SingleElectrode", Type: "S" },
  { Column: "WPSD_WP_ElectrodeSpacing_Late", Type: "S" },
  { Column: "WPSD_WP_ElectrodeSpacing_Logit", Type: "S" },
  { Column: "WPSD_WP_ElectrodeAngleDegree", Type: "S" },
  { Column: "WPSD_WP_Weld_Bead_Overlap", Type: "S" },

  // { Column: "WPSD_WP_Pulsing_current", Type: "S" },
  // { Column: "WPSD_WP_PositionOfFillet", Type: "S" },
  // { Column: "WPSD_WP_Elec_char_others", Type: "S" },
  // { Column: "WPSD_WP_Max_Pass_Thickness", Type: "S" },
  // { Column: "WPSD_WP_LayerNumber", Type: "A" },
];

export var WpqDetail = {
  SK: "",
  PK: "",
  WPQ_WelderId: "",
  WPQ_RecordNo: "",
  WPQ_Process: "",
  WPQ_Type: "",

  WPSH_WeldLayer: "",
  WPSD_FM_SFA: "",
  WPSD_FM_FNo: "",
  WPSD_FM_Ano: "",
  WPSD_FM_Classification: "",
  WPSD_FM_ChemicalAnalysis_TradeName: "",
  // WPSD_FM_Make: "",
  WPSD_FM_TRQ_Max: "",
  WPSD_FM_TRQ_Min: "",
  wld_createdby: "",
  wld_createdon: "",
  wld_updatedby: "",
  wld_updatedon: "",
  WPSH_Project_No: "",
  WPSH_WPS_No: "",
  WPSD_WP_MinimumPreheatTemp: "",
  WPSD_WP_MaximumInterpassTemp: "",
  WPSD_WP_MinimumBeadLength: "",
  WPSD_WP_TungstenSIze: "",
  WPSD_WP_TungstenType: "",
  WPSD_WP_FillerMetalProductForm: "",
  WPSD_WP_FillerMetalSIze: [],
  WPSD_WP_LayerNumber: [],
  WPSD_WP_PositionOfGroove: [],
  WPSD_WP_WeldProgression: [],
  WPSD_WP_CurrentPolarity: [],
  WPSD_WP_Amperes: [],
  WPSD_WP_Volts: [],
  WPSD_WP_TravelSpeed: [],
  WPSD_WP_MaximumHeatInput: [],
  WPSD_WP_ShieldingFlowRate: "",
  WPSD_WP_ShieldingGasType: "",
  WPSD_WP_TrailingFlowRate: "",
  WPSD_WP_TrailingGasType: "",
  WPSD_WP_ArcTransferMode: "",
  WPSD_WP_StringOrWeave: "",
  WPSD_WP_Orifice_GasCupSize: "",
  WPSD_WP_CTWD: "",
  WPSD_WP_Multiple_SinglePassPerSide: "",
  WPSD_WP_Multiple_SingleLayer: "",
  WPSD_WP_Multi_SingleElectrode: "",
  WPSD_WP_ElectrodeSpacing_Late: "",
  WPSD_WP_ElectrodeSpacing_Logit: "",
  WPSD_WP_Max_Pass_Thickness: "",
  WPSD_WP_ElectrodeAngleDegree: "",
  WPSD_WP_Weld_Bead_Overlap: "",
};

export const WPQHeader = (wpstype) => {
  return {
    wld_WPSType: wpstype,
    WPQ_WelderId: "",
    WPS_Key: "",
    WPQ_WelderName: "",
    WPQ_Date: todayDate,
    WPQ_RecordNo: "",
    WPQ_Wps_RecordNo: "",
    WPQ_ReviNo: "",
    WPQ_Inspection: "",
    WPQ_DOB: todayDate,
    WPQ_Qualificationcode: "",
    WPQ_Last_UpdatedDate: todayDate,
    WPQ_Last_UpdatedBy: "",
    WPQ_Welded_on_coupon: "",
    WPQ_CompanyName: "Godrej & Boyce Mfg. Co. Ltd",
    WPQ_Division: "",
    WPQ_PlantLocation: "",
    WPQ_specialNote: "",
    WPQ_Status: "Active",
    wld_createdon: "",

    WPQ_BM_BM_ProductForm: "",
    WPQ_BM_BM_Spec: "",
    WPQ_BM_BM_Pno: "",
    WPQ_BM_BM_Gno: "",
    // WPQ_BM_BM_Size: "",
    // WPQ_BM_BM_Sch: "",
    WPQ_BM_BM_Thk: "",
    WPQ_BM_BM_Dia: "",

    WPQ_BM_WTBM_ProductForm: "",
    WPQ_BM_WTBM_Spec: "",
    WPQ_BM_WTBM_Pno: "",
    WPQ_BM_WTBM_Gno: "",
    // WPQ_BM_WTBM_Size: "",
    // WPQ_BM_WTBM_Sch: "",
    WPQ_BM_WTBM_Thk: "",
    WPQ_BM_WTBM_Dia: "",

    WPQ_VAR_TypeofJoint_actualValue: "",
    WPQ_VAR_TypeofJoint_RangeQualified: "",
    WPQ_VAR_BaseM_actualValue: "",
    WPQ_VAR_BaseM_RangeQualified: "",
    WPQ_VAR_Pcategory_actualValue: "",
    WPQ_VAR_Pcategory_RangeQualified: "",

    WPQ_BMT_Plate_thickness_ActualV_Groove: "",
    WPQ_BMT_Plate_thickness_ActualV_Fillet: "",
    WPQ_BMT_Plate_thickness_ActualV_Overlay: "",

    WPQ_BMT_Pipe_thickness_ActualV_Groove: "",
    WPQ_BMT_Pipe_thickness_ActualV_Fillet: "",
    WPQ_BMT_Pipe_thickness_ActualV_Overlay: "",

    WPQ_BMT_Pipe_Diameter_ActualV_Groove: "73.00",
    WPQ_BMT_Pipe_Diameter_ActualV_Fillet: "",
    WPQ_BMT_Pipe_Diameter_ActualV_Overlay: "",

    WPQ_BMT_Plate_thickness_RangeQ_Groove: "",
    WPQ_BMT_Plate_thickness_RangeQ_Fillet: "",
    WPQ_BMT_Plate_thickness_RangeQ_Overlay: "",

    WPQ_BMT_Pipe_thickness_RangeQ_Groove: "",
    WPQ_BMT_Pipe_thickness_RangeQ_Fillet: "",
    WPQ_BMT_Pipe_thickness_RangeQ_Overlay: "",

    WPQ_BMT_Pipe_Diameter_RangeQ_Groove: "73.00",
    WPQ_BMT_Pipe_Diameter_RangeQ_Fillet: "",
    WPQ_BMT_Pipe_Diameter_RangeQ_Overlay: "",

    WPQ_WP_Process_Process: "",
    WPQ_WP_Process_Type: "",
    WPQ_WP_Type_Process: "",
    WPQ_WP_Type_Type: "",

    WPQ_Notes: "",

    WPQ_Certi_test_conductedby: "",
    WPQ_Certi_Mechanical_Tesstby: "",
    WPQ_Certi_RT_Film_Rvaluatedby: "",

    // WPQ_3Page:[
    // {WPQ_WP_TypeOfTest: [""] },
    // {WPQ_WP_Acceptance_Criteria: [""]},
    // {WPQ_WP_Result: [""]},
    // {WPQ_WP_Comments: [""]},
    // {WPQ_WP_Report_Nummber: [""]}],

    WPSH_Status: Status.StatusTempSaved,
    WPSH_Approval_Status: Status.StatusTempSaved,

    WPQ_DocImage: "",
    WPQ_DocImageOriginal: "",

    WPQ_DocImageUrl: "",

    WPQ_DocImageData: {
      pdfsize: "",
      pdfname: "",
      pdftype: "",
    },

    wpq_QualificationDueIn: "",
    WPQ_Process_Category: "",

    WPSH_PreparedBy_Date: todayDate,
    WPSH_PreparedBy_Name: "",
    WPSH_ApprovedBy_Date: todayDate,
    WPSH_ApprovedBy_Name: "",
    WPSH_ApprovedBy_Remark: "",
    WPSH_PreparedBy_Remark: "",
    WPSH_PreparedBy: sessionStorage.getItem("User"),
    WPSH_UpdatedBy: "",
    WPSH_UpdatedOn: "",
    WPSH_ApprovedBy: sessionStorage.getItem("UserName"),

    wpq_signaturePrepared: "",
    wpq_signatureApproved: "",

    WQC_Arc:"",
    WQC_Process:"",
    WQC_Type:"",
    WQC_Pcat:"",
    WQC_DepThick:"",
    WQC_DetBack:"",
    WQC_Prog:"",
    WQC_BackGas:"",
    WQC_DirVisCon:"",
    WQC_AutJoiTrac:"",
    WQC_FillWire:"",
    WQC_AVCSys:"",
    WQC_Fno:"",
    WQC_ConIns:"",
    WQC_Fmproform:"",
    WQC_CurrPol:"",
    WQC_SinPerside:"",
    WQC_remainday:"",
    WQC_Gv610:"",
    WQC_Gv73to610:"",
    WQC_Gv73:"",
    WQC_Oy610:"",
    WQC_Oy73to610:"",
    WQC_Oy73:"",
    WQC_Fl610:"",
    WQC_Fl73to610:"",
    WQC_Fl73:"",

    Process1:"",
    Process2:"",
    Process3:"",




  };
};

export const WPQHeader_Tube = (wpstype) => {

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  // const formattedDate = `${year}-${month}-${day}`;

  const formattedDate = `${day}-${month}-${year}`;
  return {
    wld_WPSType: wpstype,
    WPSH_WPQ_Process: "",
    WPSH_WPQ_Type: "",
    WPQ_WelderId: "",
    WPQ_WelderName: "",
    WPQ_Date: todayDate,
    WPQ_RecordNo: "",
    WPQ_Wps_RecordNo: "",
    WPQ_ReviNo: "",
    WPQ_Inspection: "",
    WPQ_DOB: todayDate,
    WPQ_Qualificationcode: "",
    wld_createdon:formattedDate,
    WPQ_Last_UpdatedDate: todayDate,
    WPQ_Last_UpdatedBy: "",
    WPQ_Welded_on_coupon: "",
    WPQ_CompanyName: "Godrej & Boyce Mfg. Co. Ltd",
    WPQ_Division: "",
    WPQ_PlantLocation: "",
    WPQ_specialNote: "",
    WPQ_Status: "Active",

    WPQ_BM_BM_ProductForm: "",
    WPQ_BM_BM_Spec: "",
    WPQ_BM_BM_Pno: "",
    WPQ_BM_BM_Gno: "",
    WPQ_BM_BM_Thk: "",
    WPQ_BM_BM_Dia: "",

    WPQ_BM_WTBM_ProductForm: "",
    WPQ_BM_WTBM_Spec: "",
    WPQ_BM_WTBM_Pno: "",
    WPQ_BM_WTBM_Gno: "",
    WPQ_BM_WTBM_Thk: "",
    WPQ_BM_WTBM_Dia: "",

    WPQ_VAR_TypeofJoint_actualValue: "",
    WPQ_VAR_TypeofJoint_RangeQualified: "",
    WPQ_VAR_BaseM_actualValue: "",
    WPQ_VAR_BaseM_RangeQualified: "",

    WPQ_FM_PreFillerMetal: "",
    WPQ_FM_WithFillerMetal: "",
    WPQ_FM_FillerProduct: "",
    WPQ_FM_Inserts: "",
    // WPQ_FM_FillerFlux: "",
    WPQ_FM_FillerOthers: "",

    WPQ_WP_Process_Process: "",
    WPQ_WP_Process_Type: "",
    WPQ_WP_Type_Process: "",
    WPQ_WP_Type_Type: "",

    WPQ_Notes: "",

    WPQ_Certi_test_conductedby: "",
    WPQ_Certi_Mechanical_Tesstby: "",
    WPQ_Certi_RT_Film_Rvaluatedby: "",

    WPQ_BM_RangeQualified_Thk: "",
    WPQ_BM_RangeQualified_Dia: "",

    WPQ_Joint_Type: [{ Actual_Value: ["Tube to Tubesheet"] }, { Range_Qualified: ["Tube to Tubesheet"] }],
    WPQ_Hole_Id: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
    WPQ_Tube_Pitch: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
    WPQ_Groove_Depth: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_Tube_Projection: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
    WPQ_Width_Ligament: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
    WPQ_Pattern: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
    WPQ_Groove_Angle: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    // WPQ_3Page:[
    // {WPQ_WP_TypeOfTest: [""] },
    // {WPQ_WP_Acceptance_Criteria: [""]},
    // {WPQ_WP_Result: [""]},
    // {WPQ_WP_Comments: [""]},
    // {WPQ_WP_Report_Nummber: [""]}],

    WPSH_Status: Status.StatusTempSaved,
    WPSH_Approval_Status: Status.StatusTempSaved,


    wld_pdf_image: [
      { Image1: [""] },
      { Image2: [""] },
      { Image3: [""] },
    ],

    WPQ_DocImage: "",
    WPQ_DocImageOriginal: "",

    wpq_QualificationDueIn: "",

    WPQ_DocImageUrl: "",

    WPQ_DocImageData: {
      pdfsize: "",
      pdfname: "",
      pdftype: "",
    },

    Pdf_images: [
      { tube_Hole_ligament: [""] },
      { tube_Hole: [""] },
      { tube_Weld_Joint: [""] },
    ],

    wpq_signaturePrepared: "",
    wpq_signatureApproved: "",

    WPSH_PreparedBy_Date: todayDate,
    WPSH_PreparedBy_Name: "",
    WPSH_ApprovedBy_Date: todayDate,
    WPSH_ApprovedBy_Name: "",
    WPSH_ApprovedBy_Remark: "",
    WPSH_PreparedBy_Remark: "",
    WPSH_PreparedBy: sessionStorage.getItem("User"),
    WPSH_UpdatedBy: "",
    WPSH_UpdatedOn: "",
    WPSH_ApprovedBy: sessionStorage.getItem("UserName"),
  };
};

export var WPSPage1_Overlay = {
  WPSH_JointDesc: "",
  WPSH_Project_No: "",
  WPSH_RefDoc: "",
  WPSH_Scope: "",
  WPSH_CompanyName: "Godrej & Boyce Mfg. Co. Ltd",
  WPSH_SupportingPQR: "",
  WPSH_WPS_Date: "",
  WPSH_WPS_No: "",
  WPSH_WPS_Revi_No: "",
  WPS_QualifiedTo: "",
  WPSH_Flux_ANo: "",
  WPSH_BM_Type_Type: "",
  WPSH_BM_Type_GNo: "",
  WPSH_BM_Type_PNo: "",

  WPSH_BM_WeldingTo_Type: "",
  WPSH_BM_WeldingTo_PNo: "",
  WPSH_BM_WeldingTo_GNo: "",
  WPSH_BM_Backing_Material: "",

  WPSH_BM_Retainer: "",
  WPSH_BM_Notes: "",

  WPSH_TRQ_BaseMetalCRO_Min: "",
  WPSH_TRQ_BaseMetalCRO_Max: "",
  WPSH_TRQ_BaseMetalHRO_Max: "",
  WPSH_TRQ_BaseMetalHRO_Min: "",

  WPSH_FM_Add_Del_orchange: "",
  WPSH_FM_AlloyElement: "",

  WPSH_FM_FilerMetal: "",
  WPSH_FM_FluxFromRecrush: "",
  WPSH_FM_FluxItemBatch: "",
  WPSH_FM_FluxItemCode: "",
  WPSH_FM_FluxType: "",

  WPSH_consumRadio: "Required",
  WPSH_filterRadio: "Required",
  WPSH_fluxRadio: "Required",
  wld_Approval_Type: "Pending For Approval",
  wld_Joints_Type: "Joint section",
  wld_TRQ_Type: "Welded",
};

export var WPQ_Tube_Page2 = {
  WPQD_WP_WeldingProcess: "",
  WPQD_WP_Type: "",
  WPQD_WP_MinimumPreheatTemp: "",
  WPQD_WP_MaximumInterpassTemp: "",
  WPQD_WP_PostHeating: "",
  WPQD_WP_TungstenType: "",
  WPQD_WP_WeldPosition: "",
  WPQD_WP_WeldProgression: "",
  WPQD_WP_NoofPasses: "",

  WPQD_WP_ShieldingGasType: "",
  WPQD_WP_ShieldingFlowRate: "",

  WPQD_WP_BackingGasType: "",
  WPQD_WP_BackingFlowRate: "",
  WPQD_WP_Automatic_Arc_Voltage: "",
  WPQD_WP_Auxiliarygas: "",

  WPQ_OTV_Oscillation: "",
  WPQ_OTV_Expansion: "",
  // WPQ_OTV_StringWave: "",
  WPQ_OTV_MethodofCleaning: "",
  WPQ_OTV_OrifceCupSize: "",
  // WPQ_OTV_SingleMultiElectrode: "",
  // WPQ_OTV_Peening: "",
  // WPQ_OTV_ClosedtooutChamber: "",
  // WPQ_OTV_UseOfThermalProcess: "",
  // WPQ_OTV_Others: "",

  WPQ_Post_WHT_Temp: "",
  WPQ_Post_WHT_Time: "",
  WPQ_Post_WHT_Type: "",
  WPQ_Post_WHT_Heating_Rate: "",
  WPQ_Post_WHT_Cooling_Rate: "",

  WPQ_Page2_Notes: "",

  WPQ_Certification_Welder_Name: "",
  WPQ_Certification_ID: "",
  WPQ_Certification_ConductedBy: "",
  WPQ_Certification_Mechanical_TestsBy: "",
  WPQ_Certification_Lab_RefNo: "",
};

export var WPQ_tubesheet_page2 = [
  { Column: "WPQD_WP_LayerNo", Type: "S" },
  { Column: "WPQD_WP_Filler_Metal_Size", Type: "S" },
  { Column: "WPQD_WP_Amperes", Type: "S" },
  { Column: "WPQD_WP_DC_Pulsing_current", Type: "S" },
  { Column: "WPQD_WP_DC_Pulsing_current_Others", Type: "S" },
  { Column: "WPQD_WP_Volatage_Volts", Type: "S" },
  { Column: "WPQD_WP_Travel_Speed", Type: "S" },
  { Column: "WPQD_WP_Heat_Input", Type: "S" },
  { Column: "WPQD_WP_PolarityOrCurrent", Type: "S" },
];

export var WPQPage1 = {
  WPQ_WelderId: "",
  WPQ_WelderName: "",
  WPQ_Date: "",
  WPQ_RecordNo: "",
  WPQ_Wps_RecordNo: "",
  WPQ_ReviNo: "",
  WPQ_Inspection: "",
  WPQ_DOB: "",
  WPQ_Qualificationcode: "",
  WPQ_Last_UpdatedDate: "",

  WPQ_PlantLocation: "",
  WPQ_specialNote: "",

  WPQ_BM_BM_ProductForm: "",
  WPQ_BM_BM_Spec: "",
  WPQ_BM_BM_Pno: "",
  WPQ_BM_BM_Gno: "",
  // WPQ_BM_BM_Size: "",
  // WPQ_BM_BM_Sch: "",
  WPQ_BM_BM_Thk: "",
  WPQ_BM_BM_Dia: "",

  WPQ_BM_WTBM_ProductForm: "",
  WPQ_BM_WTBM_Spec: "",
  WPQ_BM_WTBM_Pno: "",
  WPQ_BM_WTBM_Gno: "",
  // WPQ_BM_WTBM_Size: "",
  // WPQ_BM_WTBM_Sch: "",
  WPQ_BM_WTBM_Thk: "",
  WPQ_BM_WTBM_Dia: "",

  // WPQ_FM_PreFillerMetal: {},
  // WPQ_FM_WithFillerMetal: {},
  // WPQ_FM_FillerProduct: {},
  // WPQ_FM_Inserts: {},
  // WPQ_FM_FillerFlux: {},
  // WPQ_FM_FillerOthers: {},

  WPQ_VAR_TypeofJoint_actualValue: "",
  WPQ_VAR_TypeofJoint_RangeQualified: "",
  WPQ_VAR_BaseM_actualValue: "",
  WPQ_VAR_BaseM_RangeQualified: "",

  WPQ_BMT_Plate_thickness_ActualV_Groove: "",
  WPQ_BMT_Plate_thickness_ActualV_Fillet: "",
  WPQ_BMT_Plate_thickness_ActualV_Overlay: "",

  WPQ_BMT_Pipe_thickness_ActualV_Groove: "",
  WPQ_BMT_Pipe_thickness_ActualV_Fillet: "",
  WPQ_BMT_Pipe_thickness_ActualV_Overlay: "",

  WPQ_BMT_Pipe_Diameter_ActualV_Groove: "",
  WPQ_BMT_Pipe_Diameter_ActualV_Fillet: "",
  WPQ_BMT_Pipe_Diameter_ActualV_Overlay: "",

  WPQ_BMT_Plate_thickness_RangeQ_Groove: "",
  WPQ_BMT_Plate_thickness_RangeQ_Fillet: "",
  WPQ_BMT_Plate_thickness_RangeQ_Overlay: "",

  WPQ_BMT_Pipe_thickness_RangeQ_Groove: "",
  WPQ_BMT_Pipe_thickness_RangeQ_Fillet: "",
  WPQ_BMT_Pipe_thickness_RangeQ_Overlay: "",

  WPQ_BMT_Pipe_Diameter_RangeQ_Groove: "",
  WPQ_BMT_Pipe_Diameter_RangeQ_Fillet: "",
  WPQ_BMT_Pipe_Diameter_RangeQ_Overlay: "",
};

export var WPQ_tubePage1 = {
  WPQ_WelderId: "",
  WPQ_WelderName: "",
  WPQ_DOB: "",
  WPQ_Date: "",
  WPQ_RecordNo: "",
  WPQ_Wps_RecordNo: "",
  WPQ_Qualificationcode: "",

  WPQ_PlantLocation: "",
  WPQ_specialNote: "",

  WPQ_BM_BM_ProductForm: "",
  WPQ_BM_BM_Spec: "",
  WPQ_BM_BM_Pno: "",
  WPQ_BM_BM_Gno: "",
  WPQ_BM_BM_Thk: "",
  WPQ_BM_BM_Dia: "",

  WPQ_BM_WTBM_ProductForm: "",
  WPQ_BM_WTBM_Spec: "",
  WPQ_BM_WTBM_Pno: "",
  WPQ_BM_WTBM_Gno: "",
  WPQ_BM_WTBM_Thk: "",
  WPQ_BM_WTBM_Dia: "",

  // WPQ_FM_PreFillerMetal: {},
  // WPQ_FM_WithFillerMetal: {},
  // WPQ_FM_FillerProduct: {},
  // WPQ_FM_Inserts: {},
  // WPQ_FM_FillerFlux: {},
  // WPQ_FM_FillerOthers: {},

  WPQ_BM_RangeQualified_Thk: {},
  WPQ_BM_RangeQualified_Dia: {},

  WPQ_Joint_Type: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
  WPQ_Hole_Id: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
  WPQ_Tube_Pitch: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
  WPQ_Groove_Depth: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

  WPQ_Tube_Projection: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
  WPQ_Width_Ligament: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
  WPQ_Pattern: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],
  WPQ_Groove_Angle: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

  // WPQ_VAR_TypeofJoint_actualValue: "",
  // WPQ_VAR_TypeofJoint_RangeQualified: "",
  // WPQ_VAR_BaseM_actualValue: "",
  // WPQ_VAR_BaseM_RangeQualified: "",
};

export var WPQ_tubePage3 = {
  WPQ_VEX_Report: "",
  WPQ_LPEX_Report: "",
  WPQ_LPEX_Notes: "",
  WPQ_VEX_Notes: "",
  WPQ_MEX_Result: "",
};

export var WPQ_tubePage4 = {
  WPQ_MLP_Result: "",
  WPQ_OT_Notes: "",
  WPQ_Radio_Notes: "",
};


export var errorTubepage3 = {
  WPQ_VEX_WeldPass: {},
  WPQ_VEX_Acceptance_Criteria: {},
  WPQ_VEX_Observation: {},
  WPQ_VEX_Results: {},

  WPQ_LPEX_WeldPass: {},
  WPQ_LPEX_Acceptance_Criteria: {},
  WPQ_LPEX_Observation: {},
  WPQ_LPEX_Results: {},

  WPQ_MEX_Tube_No: {},
  WPQ_MEX_LocA: {},
  WPQ_MEX_LocB: {},
  WPQ_MEX_LocC: {},
  WPQ_MEX_LocD: {},

  WPQ_MEX_Tube_No1: {},
  WPQ_MEX_LocA1: {},
  WPQ_MEX_LocB1: {},
  WPQ_MEX_LocC1: {},
  WPQ_MEX_LocD1: {},

  WPQ_MEX_Tube_No2: {},
  WPQ_MEX_LocA2: {},
  WPQ_MEX_LocB2: {},
  WPQ_MEX_LocC2: {},
  WPQ_MEX_LocD2: {},

  WPQ_MEX_Tube_No3: {},
  WPQ_MEX_LocA3: {},
  WPQ_MEX_LocB3: {},
  WPQ_MEX_LocC3: {},
  WPQ_MEX_LocD3: {},

  WPQ_MEX_Magnification: {},
  WPQ_MEX_Acceptance_Criteria: {},
  WPQ_MEX_Observation: {},

};



export var errorTubepage4 = {



  WPQ_Radio_TubeNo: {},
  WPQ_Radio_Acceptance_Criteria: {},
  WPQ_Radio_Observation: {},
  WPQ_Radio_Results: {},

  WPQ_OT_TypeofTest: {},
  WPQ_OT_Acceptance_Criteria: {},
  WPQ_OT_Observation: {},
  WPQ_OT_Results: {},


  WPQ_MLP_Tube_No: {},
  WPQ_MLP_LocA: {},
  WPQ_MLP_LocB: {},
  WPQ_MLP_LocC: {},
  WPQ_MLP_LocD: {},

      WPQ_MLP_Tube_No1: {},
      WPQ_MLP_LocA1: {},
      WPQ_MLP_LocB1: {},
      WPQ_MLP_LocC1: {},
      WPQ_MLP_LocD1: {},

      WPQ_MLP_Tube_No2: {},
      WPQ_MLP_LocA2: {},
      WPQ_MLP_LocB2: {},
      WPQ_MLP_LocC2: {},
      WPQ_MLP_LocD2: {},

      WPQ_MLP_Tube_No3: {},
      WPQ_MLP_LocA3: {},
      WPQ_MLP_LocB3: {},
      WPQ_MLP_LocC3: {},
      WPQ_MLP_LocD3: {},


      WPQ_MLP_Magnification: {},
      WPQ_MLP_Acceptance_Criteria: {},
      WPQ_MLP_Observation: {},

      WPSH_PWHeatTre_Temperature: {},

      // WPQ_Radio_Notes: {},
      // WPQ_OT_Notes: {},

      // WPQ_MLP_Result: {},

      WPSH_PreparedBy_Name: {},
      WPSH_PreparedBy_Date: {},
      WPSH_PreparedBy_Remark: {},

      WPSH_ApprovedBy_Name: {},
      WPSH_ApprovedBy_Date: {},
      WPSH_Approval_Status: {},
      WPSH_ApprovedBy_Remark: {},


};

export var WPQ_tubePage4_Header = {
  WPSH_PreparedBy_Remark: "",
};

export var WPQPage1Date = {
  WPQ_Date: "",
  WPQ_DOB: "",
  WPQ_Last_UpdatedDate: "",
};

export var WPQPage3 = {
  WPQ_Notes: "",
  WPQ_Certi_test_conductedby: "",
  WPQ_Certi_Mechanical_Tesstby: "",
  WPQ_Certi_RT_Film_Rvaluatedby: "",
  WPSH_PreparedBy_Remark: "",
  // WPSH_ApprovedBy_Remark:"",
};

export var WPSPage3_Overlay = {
  // WPSH_PHT_Root_spacing: "",
  // WPSH_Tech_Oscillation:"",
  // WPSH_PHT_ApplicationStd: "",
  // WPSH_PHT_Desc: "",
  // WPSH_PWHeatTre_Temperature: "",
  WPSH_PWHeatTre_Time: "",
  WPSH_PWHeatTre_Heating_Rate: "",
  WPSH_PWHeatTre_Heating_Method: "",
  // WPSH_PWHeatTre_Cooling_Rate: "",
  WPSH_PWHeatTre_Cooling_Method: "",
  // WPSH_PWHeatTre_Type: "",
  // WPSH_PWHeatTre_Notes: "",
  // WPSH_PWHeatTre_Post_Heat_temp: "",
  // WPSH_PWHeatTre_Post_Heat_time: "",
  // WPSH_PWHeatTre_Wrap_insulation_material: "",
  // WPSH_PWHeatTre_Weld_Notes: "",
  // WPSH_PWHeatTre_ISR_Temp: "",
  // WPSH_PWHeatTre_ISR_Time: "",
  // WPSH_Tech_BackGougingMethod: "",
  // WPSH_Tech_ClosedtooutChamber: "",
  // WPSH_Tech_GroovePreparation: "",
  // WPSH_Tech_Inital_InterpassCleaning: "",
  // WPSH_Tech_Peening: "",
  // WPSH_Tech_SupplementaryMFControl: "",
  // WPSH_Tech_SurfacePreparation: "",
  // WPSH_Tech_UseOfThermalProcess: "",
  // WPSH_Tech_Weld_deposit_chemistry: "",
  // WPSH_Notes: "",
  // WPSH_PWHeatTre_Weld_Time: "",

  // WPSH_Tech_Change_Electrode_dia:"",
  // WPSH_PreparedBy_Date: "",
  // WPSH_PreparedBy_Name: "",
  // WPSH_ApprovedBy_Date: "",
  // WPSH_ApprovedBy_Name: "",
  // WPSH_ApprovedBy_Remark: "",
  // WPSH_PreparedBy_Remark: "",
  // wld_createdby: "",
  // wld_createdon: "",
  // wld_updatedby: "",
  // wld_updatedon: "",
  // WPSH_consumRadio: "Required",
  // WPSH_fluxRadio: "Required",
  // WPSH_filterRadio: "Required",
  // wld_TRQ_Type: "Welded",
  // wld_Joints_Type: "Joint section",
  // wld_Approval_Type: "Pending For Approval",
  // wld_WPSType: wpstype,
  // WPSH_Tech_Filler_Metal_Del: "",
};

export var WPQ_Tube_Page1_Process = {
  WPSD_Process: "GTAW",
  WPSD_WP_Type: "",
  WPSD_FM_SFA: "",
};
export var Filler_Metal_Product_Form = [
  { value: "NA", name: "Na" },
  { value: "No Value", name: "No Value" },
];

export var Plant_Location = [
  { value: "", name: "Select" },
  { value: "Vikhroli", name: "Vikhroli" },
  { value: "Dahej", name: "Dahej" },
];

export var Plant_Location_GO = [
  { value: "", name: "Select" },
  { value: "PED-Vikhroli", name: "PED-Vikhroli" },
  { value: "PED-Dahej", name: "PED-Dahej" },
  { value: "PED", name: "PED" },

];
export var Layer_No = [
  { value: "NA", name: "NA" },
  { value: "As Req.", name: "As Req." },
  { value: "Root", name: "Root" },
  { value: "Hot Pass", name: "Hot Pass" },
  { value: "Fill Up", name: "Fill Up" },
  { value: "Capping", name: "Capping" },
];

export var Position_of_groove = [
  { value: "Na", name: "NA" },
  { value: "All", name: "All" },
  { value: "F", name: "F" },
  { value: "F/H", name: "F/H" },
  { value: "F/V", name: "F/V" },
  { value: "F/H/V", name: "F/H/V" },
  { value: "F/H/O", name: "F/H/O" },
];

export var Tungsten_size = [
  { value: "Na", name: "NA" },
  { value: "1", name: "1" },
  { value: "1.6", name: "1.6" },
  { value: "2.4", name: "2.4" },
  { value: "3.2", name: "3.2" },
  { value: "2.4/3,2", name: "2.4/3.2" },
];

export var Tungsten_type = [
  { value: "Na", name: "NA" },
  { value: "EWTh-2", name: "EWTh-2" },
  { value: "EWCe-2", name: "EWCe-2" },
  { value: "EWLa-2", name: "EWLa-2" },
  { value: "EWZr-8", name: "EWZr-8" },
];

export var Weld_Progression = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Uphill", name: "Uphill" },
  { value: "Downhill", name: "Downhill" },
];

export var Current_Polarity = [
  { value: "", name: "Select" },
  { value: "AC", name: "Ac" },
  { value: "Any", name: "Any" },
  { value: "DC", name: "DC" },
  { value: "DCEP", name: "DCEP" },
  { value: "DCEN", name: "DCEN" },
];

export var Backing = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "With", name: "With" },
  { value: "Without", name: "Without" },
  { value: "With/Without", name: "With/Without" },
];

export var Backing_Gas = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "With", name: "With" },
  { value: "With/Without", name: "With/Without" },
  { value: "Without", name: "Without" },
];

export var Direct_Visual = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Direct", name: "Direct" },
  { value: "Remote", name: "Remote" },
];

export var Single_Pass = [
  { value: "", name: "Select" },
  { value: "Single", name: "Single" },
  { value: "Multi", name: "Multi" },
  {
    value: "Single/Multi",
    name: "Single/Multi",
  },
  { value: "NA", name: "NA" },
];

export var Arc_Transfer = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Spray ", name: "Spray " },
  { value: "Short Circuit ", name: "Short Circuit " },
  { value: "Globular", name: "Globular" },
];

export var Backing_Material = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  {
    value: "Base Metal and/or Weld metal",
    name: "Base Metal and/or Weld metal",
  },
];

export var Retainer = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Not used", name: "Not used" },
  {
    value: "Use of non-metallic retainers",
    name: "Use of non-metallic retainers",
  },
  {
    value: "Use of non fusing metal retainers",
    name: "Use of non fusing metal retainers",
  },
];

export var Base_HT = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "NACT", name: "NACT" },
  { value: "QT", name: "QT" },
  { value: "NT", name: "NT" },
  { value: "N", name: "N" },
  { value: "NACT/NT/QT", name: "NACT/NT/QT" },
  { value: "Solution annealing", name: "Solution annealing" },
];

export var Flux_type = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Neutral", name: "Neutral" },
  { value: "Active", name: "Active" },
];

export var yesno = [
  { value: "NA", name: "NA" },
  { value: "Yes", name: "Yes" },
  { value: "No", name: "No" },
];

export var Welding_Position = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "No value", name: "No value" },
];

export var Consumable_Insert = [
  { value: "", name: "Select" },
  { value: "Required", name: "Required" },
  { value: "Optional", name: "Optional" },
  { value: "None", name: "None" },
];

export var FM_Product_Form = [
  { value: "", name: "Select" },
  { value: "Not Applicable", name: "Not Applicable" },
  { value: "Solid", name: "Solid" },
  { value: "Fluxcored", name: "Fluxcored" },
  { value: "Metalcored", name: "Metalcored" },
];

export var WPSH_Tech_BackGougingMethod = [
  { value: "NA", name: "NA" },
  { value: "Gas cutting", name: "Gas cutting" },
  { value: "Machining", name: "Machining" },
  { value: "Grinding", name: "Grinding" },
  {
    value: "Gas cutting & Machining/Grinding",
    name: "Gas cutting & Machining/Grinding",
  },
];

export var WPSH_PWHeatTre_Type = [
  { value: "NA", name: "NA" },
  { value: "Gas", name: "Gas" },
  { value: "Electric", name: "Electric" },
  { value: "Gas/Electric", name: "Gas/Electric" },
  { value: "Induction", name: "Induction" },
];

export var wire_brush = [
  { value: "NA", name: "NA" },
  { value: "Wire-brush", name: "Wire-brush" },
  { value: "Grinding", name: "Grinding" },
  { value: "Polishing", name: "Polishing" },
  {
    value: "Wire-brush/Grinding/Polishing",
    name: "Wire-brush/Grinding/Polishing",
  },
];

export var TypeData = [
  { wld_type: "Automatic" },
  { wld_type: "Machine" },
  { wld_type: "Manual" },
  { wld_type: "SemiAuto" },
];

export var WeldLayer = [
  { WeldLayer: "Barrier" },
  { WeldLayer: "Subsequent" },
  { WeldLayer: "Single" },
];

export const Action = {
  ActionEdit: "Edit",

  ActionCopy: "Copy",

  ActionAdd: "Add",
};

export const Status = {
  StatusPending: "Pending For Approval",
  StatusApproved: "Approved",
  StatusRejected: "Rejected",
  StatusTempSaved: "Temporary Saved",
};

export var company_name = [
  { value: "Godrej & Boyce Mfg. Co. Ltd", name: "WPSH_CompanyName" },
];

export var division = [{ value: "PED" }];

export var Process = [{ value: "GTAW", name: "WPSD_Process" }];

export var WeldedCoupon = [
  { WPQ_Welded_on_coupon: "WPQ Test Coupon" },
  { WPQ_Welded_on_coupon: "PQR Test Coupon" },
];

export var Wps_Page1_error = {
  WPSH_Project_No: {},
  WPSH_WPS_No: {},
  WPSH_WPS_Revi_No: {},
  WPS_QualifiedTo: {},
  WPSH_SupportingPQR: {},
  WPSH_WPS_Date: {},
  WPSH_RefDoc: {},
  WPSH_Scope: {},
  WPSH_JointDesc: {},
  WPSH_BM_Type_Type: {},
  WPSH_BM_WeldingTo_Type: {},
  WPSH_BM_Type_GNo: {},
  WPSH_BM_Type_PNo: {},
  WPSH_BM_WeldingTo_PNo: {},
  WPSH_BM_WeldingTo_GNo: {},
  WPSH_BM_Backing_Backing: {},
  WPSH_BM_Backing_GNo: {},
  WPSH_BM_Backing_PNo: {},
  WPSH_BM_Retainer: {},
  WPSH_BM_Backing_Material: {},
  WPSH_ConsuInsert_SFA: {},
  WPSH_ConsuInsert_Classification: {},
  WPSH_ConsuInsert_FNo: {},
  WPSH_ConsuInsert_ANo: {},
  WPSH_ConsuInsert_ChemicalAnalysis_TradeName: {},
  WPSH_Flux_SFA: {},
  WPSH_Flux_Classification: {},
  WPSH_Flux_FNo: {},
  WPSH_Flux_ANo: {},
  WPSH_Flux_ChemicalAnalysis_TradeName: {},
  WPSH_SupFiller_SFA: {},
  WPSH_SupFiller_Classification: {},
  WPSH_SupFiller_FNo: {},
  WPSH_SupFiller_ANo: {},
  WPSH_SupFiller_ChemicalAnalysis_TradeName: {},

  WPSH_BM_Notes: {},
  WPSH_TRQ_GroovewithoutImpact_Min: {},
  WPSH_TRQ_GroovewithoutImpact_Max: {},
  WPSH_TRQ_GroovewithImpact_Min: {},
  WPSH_TRQ_GroovewithImpact_Max: {},
  WPSH_TRQ_FilletwithoutImpact_Min: {},
  WPSH_TRQ_FilletwithoutImpact_Max: {},
  WPSH_TRQ_FilletwithImpact_Min: {},
  WPSH_TRQ_FilletwithImpact_Max: {},
  // WPSH_DRQ_PipeTubeDRQ_Min: {},
  // WPSH_DRQ_PipeTubeDRQ_Max: {},
  WPSH_FM_FluxType: {},

  WPSH_FM_AlloyFlux: {},
  WPSH_FM_AlloyElement: {},
  WPSH_FM_Add_Del_Filler: {},
  WPSH_FM_Add_Del_Flux: {},

  WPSH_FM_FluxItemBatch: {},
  WPSH_FM_FluxFromRecrush: {},
  WPSH_FM_FluxItemCode: {},
  WPSH_FM_FilerMetal: {},
  WPSD_FM_Classification: {},
  WPSH_BM_Type_HT_Con: {},
  WPSH_BM_WeldingTo_HT_Con: {},
  WPSD_FM_ChemicalAnalysis_TradeName: {},
  WPSD_FM_TRQ_Min: {},
  WPSD_FM_TRQ_Max: {},
  WPSH_ConsuInsert: [],
  WPSH_Flux: [],
  WPSH_SupFiller: [],

  WPSD_Process: {},
  WPSH_TRQ_BaseMetalCRO_Min: {},
  WPSH_TRQ_BaseMetalCRO_Max: {},
  WPSH_TRQ_BaseMetalHRO_Min: {},
  WPSH_TRQ_BaseMetalHRO_Max: {},
};

export var process_name = [{ value: "GTAW", name: "WPSD_Process" }];

export const Field_array = [{ Actual_Value: [""] }, { Range_Qualified: [""] }];

export const WPSD_FM_SFA = [{ Actual_Value: [""] }, { Range_Qualified: [""] }];
export const WPQ_Detail_Type = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_Detail_Backing = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Specification = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Classification = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_filler_Wire = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];

export const WPQ_FM_Arc_transfermode = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Consumable_Insert = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Product_Form = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_No_of_LayerDeposit = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Thickness_Limit = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Welding_Position = [
  { Actual_Value: [""] },
  { Range_Qualified: ["--"] },
];
export const WPQ_FM_Progression = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Current_Polarity = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Backing_Gas = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Direct_VisualControl = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Automatic_jointTracking = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Single_Pass_PerSide = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
export const WPQ_FM_Automatic_AVC_System = [
  { Actual_Value: [""] },
  { Range_Qualified: [""] },
];
// export const WPQ_FM_Notes = [{ Actual_Value: [""] }, { Range_Qualified: [""] }];

export const WPQ_FM_Weld_Deposit_Thickness = [
  { Actual_Value: [""] },
  {
    Range_Qualified: [
      { Range_Qualified1: [[""]] },
      { Range_Qualified3: [["OR"]] },
      { Range_Qualified2: [["", "true", "Checked", "No Limit"]] },
    ],
  },
];

export let AV = { Actual_Value: [""] };

export let RQ = { Range_Qualified: [""] };

export let Oth = { Other: [""] };

export let Chk_Inp_RQ = {
  Range_Qualified: [
    { Range_Qualified1: [[""]] },
    { Range_Qualified3: [["OR"]] },
    { Range_Qualified2: [["", "true", "Checked", "No Limit"]] },
  ],
};

export let F = ["", "false", "Unchecked", "F"];
export let H = ["", "false", "Unchecked", "H"];
export let V = ["", "false", "Unchecked", "V"];
export let O = ["", "false", "Unchecked", "O"];
export let SP = ["", "false", "Unchecked", "SP"];
export let G6 = ["", "false", "Unchecked", "6G"];
export let G5 = ["", "false", "Unchecked", "5G"];

export let chk = {
  Range_Qualified: [F, H, V, O, SP, G6, G5],
};

export const WPQ_Groove_610 = [
  { Actual_Value: [""] },
  {
    Range_Qualified: [
      ["", "false", "Unchecked", "F"],
      ["", "false", "Unchecked", "H"],
      ["", "false", "Unchecked", "V"],
      ["", "false", "Unchecked", "O"],
      ["", "false", "Unchecked", "SP"],
      ["", "false", "Unchecked", "6G"],
      ["", "false", "Unchecked", "5G"],
    ],
  },
];
export const WPQ_Groove_73to610 = [
  { Actual_Value: [""] },
  {
    Range_Qualified: [
      ["", "false", "Unchecked", "F"],
      ["", "false", "Unchecked", "H"],
      ["", "false", "Unchecked", "V"],
      ["", "false", "Unchecked", "O"],
      ["", "false", "Unchecked", "SP"],
      ["", "false", "Unchecked", "6G"],
      ["", "false", "Unchecked", "5G"],
    ],
  },
];
export const WPQ_Groove_73 = [
  { Actual_Value: [""] },
  {
    Range_Qualified: [
      ["", "false", "Unchecked", "F"],
      ["", "false", "Unchecked", "H"],
      ["", "false", "Unchecked", "V"],
      ["", "false", "Unchecked", "O"],
      ["", "false", "Unchecked", "SP"],
      ["", "false", "Unchecked", "6G"],
      ["", "false", "Unchecked", "5G"],
    ],
  },
];
export const WPQ_Fillet_610 = [
  { Actual_Value: [""] },
  {
    Range_Qualified: [
      ["", "false", "Unchecked", "F"],
      ["", "false", "Unchecked", "H"],
      ["", "false", "Unchecked", "V"],
      ["", "false", "Unchecked", "O"],
      ["", "false", "Unchecked", "SP"],
      ["", "false", "Unchecked", "6G"],
      ["", "false", "Unchecked", "5G"],
    ],
  },
];
export const WPQ_Fillet_73to610 = [
  { Actual_Value: [""] },
  {
    Range_Qualified: [
      ["", "false", "Unchecked", "F"],
      ["", "false", "Unchecked", "H"],
      ["", "false", "Unchecked", "V"],
      ["", "false", "Unchecked", "O"],
      ["", "false", "Unchecked", "SP"],
      ["", "false", "Unchecked", "6G"],
      ["", "false", "Unchecked", "5G"],
    ],
  },
];
export const WPQ_Fillet_73 = [
  { Actual_Value: [""] },
  {
    Range_Qualified: [
      ["", "false", "Unchecked", "F"],
      ["", "false", "Unchecked", "H"],
      ["", "false", "Unchecked", "V"],
      ["", "false", "Unchecked", "O"],
      ["", "false", "Unchecked", "SP"],
      ["", "false", "Unchecked", "6G"],
      ["", "false", "Unchecked", "5G"],
    ],
  },
];

export const ToolError = ({ title }) => {
  return (
    <Tooltip_detail
      content="All Fields are Mandatory"
      id="tooltip-tab1"
      className="front"
    >
      <img src={Img} alt="Error" className="ms-1 front" />
      {title}
    </Tooltip_detail>
  );
};

export const ProcessError = ({ title }) => {
  return (
    <Row className="m" style={{ padding: "0px 43px 0px 20px" }}>
      <div className="errorstatusbg">
        <span className="errorstatusborder float-start">
          All Fields are Mandatory of {title}{" "}
        </span>
      </div>
    </Row>
  );
};

export const checkboxComponent = (valueIndex, Value, booleanIndex) => {
  
  if (valueIndex === 0 && booleanIndex === "false") {
    Value = "F";
  } else if (valueIndex === 0 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 1 && booleanIndex === "false") {
    Value = "H";
  } else if (valueIndex === 1 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 2 && booleanIndex === "false") {
    Value = "V";
  } else if (valueIndex === 2 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 3 && booleanIndex === "false") {
    Value = "O";
  } else if (valueIndex === 3 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 4 && booleanIndex === "false") {
    Value = "SP";
  } else if (valueIndex === 4 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 5 && booleanIndex === "false") {
    Value = "6G";
  } else if (valueIndex === 5 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 6 && booleanIndex === "false") {
    Value = "5G";
  } else if (valueIndex === 6 && booleanIndex === "true") {
    Value = "";
  }

  return { Value, booleanIndex };
};

export const checkboxComponent2 = (
  inputvalue,
  valueIndex,
  valueIndex2,
  Value,
  booleanIndex
) => {
  
  if (inputvalue === 0 && valueIndex2 === 0 && booleanIndex === "false") {
    Value = "9.999.00";
  } else if (valueIndex === 0 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 1 && booleanIndex === "false") {
    Value = "H";
  } else if (valueIndex === 1 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 2 && booleanIndex === "false") {
    Value = "V";
  } else if (valueIndex === 2 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 3 && booleanIndex === "false") {
    Value = "O";
  } else if (valueIndex === 3 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 4 && booleanIndex === "false") {
    Value = "SP";
  } else if (valueIndex === 4 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 5 && booleanIndex === "false") {
    Value = "6G";
  } else if (valueIndex === 5 && booleanIndex === "true") {
    Value = "";
  }

  if (valueIndex === 6 && booleanIndex === "false") {
    Value = "5G";
  } else if (valueIndex === 6 && booleanIndex === "true") {
    Value = "";
  }

  return { Value, booleanIndex };
};

export var detailprocess = (resultdata) => {
  console.log("reeeessu", resultdata);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  // const formattedDate = `${year}-${month}-${day}`;

  const formattedDate = `${day}-${month}-${year}`;

  return {
    SK: "",
    PK: "",
    WPQ_WelderId: "",
    WPQ_RecordNo: "",
    WPQ_WelderName: "",
    // wld_process_status:"Active",
    wld_createdon: formattedDate,
    // process_remainingDates:"",
    wld_remainingdayStatus: "",
    WPQ_Process: "",
    ProcessElement:"",
    ProcessElementNew: "",

    WPQ_Process_Category: "",

    WPQ_Type: "",

    WPQ_BM_WTBM_Pno: "",
    WPQ_BMT_Plate_thickness_RangeQ_Overlay: "",
    WPQ_BMT_Pipe_Diameter_RangeQ_Overlay: "",

    WPSD_WP_FillerMetalSIze: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPSD_WP_LayerNumber: Field_array,

    WPSD_FM_SFA: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_Detail_Type: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_Detail_Backing: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Specification: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Classification: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_filler_Wire: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_F_No: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
      { Other: [""] },
    ],

    WPQ_FM_Arc_transfermode: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Consumable_Insert: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Product_Form: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_No_of_LayerDeposit: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Thickness_Limit: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Welding_Position: [
      { Actual_Value: [""] },
      { Range_Qualified: ["--"] },
    ],

    WPQ_FM_Progression: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Current_Polarity: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Backing_Gas: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Direct_VisualControl: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Automatic_jointTracking: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Single_Pass_PerSide: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Automatic_AVC_System: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    // WPQ_FM_Notes: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Weld_Deposit_Thickness: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          { Range_Qualified1: [[""]] },
          { Range_Qualified3: [["OR"]] },
          { Range_Qualified2: [["", "true", "Checked", "No Limit"]] },
        ],
      },
    ],

    WPQ_Groove_610: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],

    WPQ_Groove_73to610: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],

    WPQ_Groove_73: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],

    WPQ_Fillet_610: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],

    WPQ_Fillet_73to610: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],

    WPQ_Fillet_73: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],

    WPQ_Overlay_610: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],

    WPQ_Overlay_73to610: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],

    WPQ_Overlay_73: [
      { Actual_Value: [""] },
      {
        Range_Qualified: [
          ["", "false", "Unchecked", "F"],
          ["", "false", "Unchecked", "H"],
          ["", "false", "Unchecked", "V"],
          ["", "false", "Unchecked", "O"],
          ["", "false", "Unchecked", "SP"],
          ["", "false", "Unchecked", "6G"],
          ["", "false", "Unchecked", "5G"],
        ],
      },
    ],
    status: "Active",
    // wld_WPSType: WPStype,
  };
};

export var errorprocess = () => {
  return {
    // WPQ_FM_Classification: [
    //   { Actual_Value: [""]},
    //   { Range_Qualified: [""]},
    // ],
    // WPQ_FM_No_of_LayerDeposit: [
    //   { Actual_Value: [""] },
    //   { Range_Qualified: [""] },
    // ],
    // WPQ_FM_Thickness_Limit: [
    //   { Actual_Value: [""] },
    //   { Range_Qualified: [""] },
    // ],
    // WPQ_FM_Welding_Position: [
    //   { Actual_Value: [""] },
    //   { Range_Qualified: [""] },
    // ],
    // WPQ_FM_Automatic_AVC_System: [
    //   { Actual_Value: [""] },
    //   { Range_Qualified: [""] },
    // ],
    // WPQ_FM_Notes: [
    //   { Actual_Value: [""] },
    //   { Range_Qualified: [""] },
    // ],

    WPSD_FM_SFA: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_Detail_Type: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_Detail_Backing: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Specification: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Classification: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_filler_Wire: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_F_No: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
      { Other: [""] },
    ],

    WPQ_FM_Arc_transfermode: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Consumable_Insert: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Product_Form: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_No_of_LayerDeposit: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Thickness_Limit: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Welding_Position: [
      { Actual_Value: [""] },
      { Range_Qualified: ["--"] },
    ],

    WPQ_FM_Progression: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Current_Polarity: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Backing_Gas: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Direct_VisualControl: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Automatic_jointTracking: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Single_Pass_PerSide: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    WPQ_FM_Automatic_AVC_System: [
      { Actual_Value: [""] },
      { Range_Qualified: [""] },
    ],

    // WPQ_FM_Notes: [{ Actual_Value: [""] }, { Range_Qualified: [""] }],

    WPQ_FM_Weld_Deposit_Thickness: [
      { Actual_Value: [""] },
      { Range_Qualified: [{ Range_Qualified1: [[""]] }] },
    ],
  };
};
