import axios from "axios";



if (process.env.REACT_APP_NODE_ENV === "local") {
  var INTERFACE_URL = process.env.REACT_APP_INTERFACE_API_URL + "PEDUsers/";
  var MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  var INTERFACE_URL = process.env.REACT_APP_API_URL + "interface/PEDUsers/";
  var MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}


export async function getAllWelder() {
  const response = await axios
    .get(MASTER_API_URL + "/Welder/get")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getActiveWelder() {
  const response = await axios
    .get(MASTER_API_URL + "/welder/active")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}



export const AddNewWelder = async (Welder) => {
  let url = `${MASTER_API_URL}/Welder`;
  const response = await axios
    .post(url, Welder)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};


export const UpdateWelder = async (Welder) => {
  let url = `${MASTER_API_URL}/Welder/update`;
  const response = await axios
    .post(url, Welder)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getPresignedURL = async (folderName, wld_profile) => {
  let url = `${MASTER_API_URL}/file/images/wld_Images/${folderName}/${wld_profile}`;
  console.log("getPresignedURL_url", url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      console.log("getPresignedURL_result");
      return resp;
    })
    .catch(function (error) {
      console.log("getPresignedURL_error");
      return { message: "Unable to find request", status: false };
    });
  console.log("getPresignedURL_response.data", response.data);
  return response.data;
};

export const getPresignedURLDev = async (
  folderName,
  subfolder,
  wld_profile
) => {
  let url = `${MASTER_API_URL}/file/images/wld_Images/${folderName}/${subfolder}/${wld_profile}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function DeleteWelder(Welder) {
  let url = `${MASTER_API_URL}/Welder/delete`;
  const resp = await axios
    .post(url, Welder)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}



export async function getRequiredActiveWelder() {
  const response = await axios
    .get(MASTER_API_URL + "/welder/reqActive")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


//download file from s3
export async function downloadAttachment(downloadObj) {  
  debugger
  let url = `${MASTER_API_URL}/file/downloadFile/${downloadObj.foldername}/${downloadObj.filename}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to fetch File", status: false };
    });  
  let preSignedUrl = response.data;
  const link = document.createElement("a");
  link.setAttribute("href", preSignedUrl);
  link.setAttribute("download", downloadObj.filename);
  link.click();
  link.remove();
}