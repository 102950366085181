//TABLE WITH 2 ROWS AND 2 COLUMNS (WPQ- VARIABLES AND WELDING PROCESS)

import { Table } from "react-bootstrap";
import Tooltip from "../../utility/Tooltip";
import Img from "../../../icons/ErrorIcon.svg";
import {
  isAlphanumeric,
  isAlphanumericWithDashDot,
  isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace,
  isAlphanumericWithSpaceDotDash,
  isAlphanumericWithSpaceDotDashSlash,
} from "../../../functions/validations";
import Error from "../../../icons/ErrorIcon.svg";
import { TypeData } from "../../WPSTransaction/WPSObjects/WPSGroove_entity";
import { SearchSelect } from "../search-select";
import { Action } from "../../WPQTransaction/WPSObjects/WPQ_entity";
import { useState } from "react";
import { useLocation } from "react-router-dom";
export const CommonTable5 = ({
  array,
  status,
  status1,
  status2,
  status3,
  status4,
  readOnly,
  onInput,
  WPQ_WP_TypeOfTest,
  error,
  message,
  message1,
  message2,
  message3,
  message4,
  setError,
  headerObj,
  setheaderObj,
  handleChangetestarray,
  setTestObj,
  ProcessActive,
  isReadonly,
}) => {
  const location = useLocation();

  const [classError, setClassError] = useState([]);

  const [action, setaction] = useState(location.state?.action);

  let imgerror = status && (
    <Tooltip content={message}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );
  let imgerror1 = status1 && (
    <Tooltip content={message1}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror2 = status2 && (
    <Tooltip content={message2}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror3 = status3 && (
    <Tooltip content={message3}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  let imgerror4 = status4 && (
    <Tooltip content={message4}>
      <img src={Img} alt="Error" className="ms-1" />
    </Tooltip>
  );

  //console.log("arraya", array);

  return (
    <Table className="text-center tablewidth">
      <thead>
        <tr className="border thead-color ">
          {/* {rowhead.map((head) => (
          <th  className="thickness_title" key={head}>{head}</th>
        ))} */}
          <th className="thickness_title border" style={{ width: "25%" }}>
            {imgerror} &nbsp; Type Of Test
          </th>
          <th className="thickness_title border" style={{ width: "18%" }}>
            {" "}
            {imgerror1} &nbsp; Acceptance Criteria
          </th>
          <th className="thickness_title border" style={{ width: "15%" }}>
            {" "}
            {imgerror2} &nbsp; Result
          </th>
          <th className="thickness_title border" style={{ width: "25%" }}>
            {" "}
            {imgerror3} &nbsp; Comments
          </th>
          <th className="thickness_title " style={{ width: "22%" }}>
            {" "}
            {imgerror4} &nbsp; Report Number
          </th>
        </tr>
      </thead>

      <tbody>
        {array &&
          array.map((item, i) => {
            console.log("array", array);
            return (
              <tr className={array[i]?.error ? "border error" : "border "}>
                <td className="WpsInput-md  border" key={i}>
                  {array[i]?.error && (
                    <Tooltip content={array[i]?.errormessage}>
                      <img
                        src={Img}
                        alt="Error"
                        className="ms-1"
                        style={{ position: "relative", right: "10px" }}
                      />
                    </Tooltip>
                  )}

                  <input
                    style={{ width: "200px" }}
                    type="text"
                    id="WPQ_WP_TypeOfTest"
                    name="WPQ_WP_TypeOfTest"
                    placeholder=" "
                    maxLength={23}
                    readOnly={readOnly}
                    // className={status ? "error-input" : ""}
                    value={item?.WPQ_WP_TypeOfTest}
                    onChange={(e) =>
                      handleChangetestarray(
                        i,
                        "WPQ_WP_TypeOfTest",
                        e.target.value
                      )
                    }
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_WP_TypeOfTest: error,
                      }));
                    }}

                    // onChange={(e)=>{handleChangetestarray(e,i)}}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "120px" }}
                    type="text"
                    id="WPQ_WP_Acceptance_Criteria"
                    name="WPQ_WP_Acceptance_Criteria"
                    placeholder=" "
                    maxLength={15}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item?.WPQ_WP_Acceptance_Criteria}
                    onChange={(e) =>
                      handleChangetestarray(
                        i,
                        "WPQ_WP_Acceptance_Criteria",
                        e.target.value
                      )
                    }
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_WP_Acceptance_Criteria: error,
                      }));
                    }}
                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "100px" }}
                    type="text"
                    id="WPQ_WP_Result"
                    name="WPQ_WP_Result"
                    placeholder=" "
                    maxLength={10}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item?.WPQ_WP_Result}
                    onChange={(e) =>
                      handleChangetestarray(i, "WPQ_WP_Result", e.target.value)
                    }
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_WP_Result: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "180px" }}
                    type="text"
                    id="WPQ_WP_Comments"
                    name="WPQ_WP_Comments"
                    placeholder=" "
                    maxLength={25}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item?.WPQ_WP_Comments}
                    onChange={(e) =>
                      handleChangetestarray(
                        i,
                        "WPQ_WP_Comments",
                        e.target.value
                      )
                    }
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_WP_Comments: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
                <td
                  className="WpsInput-md  border"
                  style={{ paddingLeft: "2%" }}
                >
                  <input
                    style={{ width: "150px" }}
                    type="text"
                    id="WPQ_WP_Report_Nummber"
                    name="WPQ_WP_Report_Nummber"
                    placeholder=" "
                    maxLength={20}
                    readOnly={readOnly}
                    // className={error.WPQ_WelderName.status ? "err-input" : ""}
                    value={item?.WPQ_WP_Report_Nummber}
                    onChange={(e) =>
                      handleChangetestarray(
                        i,
                        "WPQ_WP_Report_Nummber",
                        e.target.value
                      )
                    }
                    onInput={(e) => {
                      let error = isAlphanumericplusDashDotStrPlusColnBrktExclcomaSpace(
                        e.target.value
                      );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_WP_Report_Nummber: error,
                      }));
                    }}
                    // onChange={(e)=>{handleChangetestarray(e,i)}}

                    // onChange={(e) => {
                    //   handleChange(e);

                    //   // for (var i in array) {
                    //   //   array[i].WPQ_WelderName = e.target.value;
                    //   // }
                    //   if (action === Action.ActionCopy) {
                    //     setIsReadonly(false);
                    //   }
                    // }}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
