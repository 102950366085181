import axios from "axios";
let GROOVE_API_URL;
let MASTER_API_URL;

if (process.env.REACT_APP_NODE_ENV === "local") {
  GROOVE_API_URL = process.env.REACT_APP_WPQ_GROOVE_API_URL;
  MASTER_API_URL = process.env.REACT_APP_MASTER_API_URL;
} else {
  GROOVE_API_URL = process.env.REACT_APP_API_URL + "wpq";
  MASTER_API_URL = process.env.REACT_APP_API_URL + "masters";
}

export async function getallgroove(type) {
  const response = await axios
    .get(GROOVE_API_URL + `/WpqHeaderMast/${type}`)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getGridHeaderData(type, band, user, year) {
  console.log("getGridHeaderData_amazeww", type, band, user, year);
  let reqbody = { type: type, band: band, user: user, year: year };
  const response = await axios
    .post(GROOVE_API_URL + `/WpqHeaderMast/grid`, reqbody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const getbyprojectno = async (groove, IdprojectPK, IdprojectSK) => {
  let reqbody = { Groove: groove, WelderId: IdprojectPK, RecordNo: IdprojectSK };

  let url = `${GROOVE_API_URL}/WpqHeaderMast/getprojectno`;
  console.log("UrlProjectNo", url);
  const response = await axios
    .post(url, reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getbyprojectnoWPQ_WI = async (
  groove,
  IdprojectPK,
  IdprojectSK
) => {
  let url = `${GROOVE_API_URL}/WpqHeaderMast/getprojectnowi`;
  console.log("UrlProjectNo", url);
  let reqbody = { Groove: groove, PK: IdprojectPK, SK: IdprojectSK };

  const response = await axios
    .post(url, reqbody)
    // .then(function (resp) {
    //   return resp;
    // })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getPresignedURLgrid = async (image) => {
  let url = `${MASTER_API_URL}/file/wpq_groove`;
  let reqbody = { image: image };
  const response = await axios
    .post(url, reqbody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const getPresignedURLgriddelete = async (image) => {
  let url = `${MASTER_API_URL}/file/wpq_groove_delete`;
  let reqbody = { image: image };
  const response = await axios
    .post(url, reqbody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};
export const AddNewGrooveWPQ = async (WPQ_Header_Master) => {
  let url = `${GROOVE_API_URL}/WpqHeaderMast`;
  const response = await axios
    .post(url, WPQ_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const UpdateRecord = async (WPQ_Header_Master) => {
  let url = `${GROOVE_API_URL}/WpqHeaderMast/update`;
  const response = await axios
    .post(url, WPQ_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};




export const AddNewCountWPQ = async (WPQ_Header_Master) => {
  let url = `${GROOVE_API_URL}/WpqHeaderMast`;
  const response = await axios
    .post(url, WPQ_Header_Master)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getselectedwelder(Welder) {
  console.log("wwweeelll", Welder);
  let url = `${GROOVE_API_URL}/wi/logic/getwelder`;
  console.log("welderrget", url);
  const resp = await axios
    .post(url, Welder)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("response_getselectedwelder", resp);

  return resp;
}

//is it used
export async function getactiveprocess(Welder) {
  let url = `${GROOVE_API_URL}/wi/logic/getactiveprocess`;
  console.log("getactiveprocess", url);
  const resp = await axios
    .post(url, Welder)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("activeeee", resp);

  return resp;
}

export async function getForFilters(wcsheader) {
  let url = `${GROOVE_API_URL}/WpqHeaderMast/inReport`;
  console.log("getForFilters_url", url);
  console.log("getForFilters_wcsheader", wcsheader);
  const resp = await axios
    .post(url, wcsheader)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("response_getForFilters", resp);

  return resp;
}

export async function WQCexportreport(wi) {
  console.log("WQCexportreport", wi);

  let url = `${GROOVE_API_URL}/WpqHeaderMast/WQCexportreport`;
  console.log("WQCexportreport_then", url);
  const resp = await axios
    .post(url, wi)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("response_WQCexportreport", resp);

  return resp;
}

export async function getRequiredWPQNo() {
  console.log(
    "GgetRequiredWPQNo_url",
    GROOVE_API_URL + "/WpqHeaderMast/WPQField"
  );
  const response = await axios
    .get(GROOVE_API_URL + "/WpqHeaderMast/WPQField")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
    console.log("reesssssssp",response.data)
  return response.data;
}


export const UpdateWPQHeaderRecord = async (UpdateWPQHeaderRecord) => {
  let url = `${GROOVE_API_URL}/WpqHeaderMast/UpdateWPQHeaderRecord`;
  console.log("UpdateWPQHeaderRecord", url);
  console.log(
    "UpdateWPQHeaderRecord",
    UpdateWPQHeaderRecord
  );
  const response = await axios
    .post(url, UpdateWPQHeaderRecord)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
    return response.data;
  }