import { useState, useEffect, forwardRef, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { BiSelectMultiple } from "react-icons/bi";
import dayjs from "dayjs";
import Delete from "../../icons/DeleteIcon.svg";
import MultiValuePopUp from "../utility/MultiValuePopUp";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllWiProjects,
  getWIweldingprocess,
} from "../../Services/WI-services";
import { getAllUsersByRole } from "../../Services/user-service";
import {
  addWeldingRepairAnalysis,
  getProjectReqCountProjectWise,
  getClosureRepairWorkflow,
  getRepairWorkflowProjectWise,
  sortByPropertyInDescending,
} from "../../Services/weldingRepairAnalysis-service";
import { getAllInspectionAgency } from "../../Services/inspectionagency-service";
import {
  getCurrentDateTime,
  RenderIf,
} from "../../functions/welding-functions";
import {
  isExperience,
  isNumberWith2digits,
  isAlphanumericWithDotSpaceDashCommaBracketsAmpersand,
  isAlphanumericWithSpace,
  isAlphanumericWithSpaceDotDashAmpersand,
  isAlphanumericWithSpaceDashComma,
} from "../../functions/validations";
import ClosureRemarksWeldingRepair from "./ClosureRemarksWeldingRepair";
import Select from "react-select";
import { downloadAttachment, getPresignedURL } from "../../Services/Welder-services";
import axios from "axios";
import DeleteGrey from "../../icons/Delete_gray.svg";


const WeldingRepairAnalysisEntry = forwardRef((props, ref) => {
  const [currentrole, setCurrentrole] = useState("");
  const [projectNumbers, setProjectNumbers] = useState([]);
  const [projNums, setProjNums] = useState([]);
  const [jointNumbers, setJointNumbers] = useState([]);
  const [inspectionAgency, setInspectionAgency] = useState([]);
  const [weldingProcessResult, setWeldingProcessResult] = useState([]);
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [closureWeldRepairObj, setClosureWeldRepairObj] = useState({});
  const [previewUrl, setPreviewUrl] = useState("");
  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });

  const navigate = useNavigate();
  let userName = sessionStorage.getItem("UserName");
  let userId = sessionStorage.getItem("UserId")?.split("@")[0];
  let userRole = sessionStorage.getItem("Role");
  var todayDate = getCurrentDateTime("-");
  let action = location.state?.action;
  let SupervisorRole = process.env.REACT_APP_ROLE_SUPERVISOR;
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [weldRepairObj, setWeldRepairObj] = useState(
    location.state?.action === "Edit" || location.state?.action === "View"
      ? location.state?.data
      : {
          PK: "weldRepairAnalysis#year#",
          SK: "",
          reportNumber: "",
          initiatedDate: "",
          projectNumber: "",
          client: "",
          equipmentNumber: "",
          inspectionBy: "",
          drawingNumber: "",
          sheetNumber: "",
          revisionNumber: "",
          wld_file: "",
          orginatingDept: "Manufacturing",
          jointNumber: "",
          spotNumber: "",
          repairRevision: "",
          ndtMethod: "",
          material: "",
          weldingProcess: "",
          welderNumber: "",
          welderName: "",
          welderArrData: [],
          repairOrDefectType: "",
          repairLocationMarkedBy: "",
          repairLengthSpotwise: "",
          repairLengthActualwise: "",
          repairRemovedByAfterRepairRemoval: "",
          repairFoundAfterRepairRemoval: "",
          repairDepthAfterRepairRemoval: "",
          defectiveProcessAfterRepairRemoval: "",
          weldingJointDetailAfterRepairRemoval: "",
          rootCauseAnalysisWhy1: "",
          rootCauseAnalysisWhy2: "",
          rootCauseAnalysisWhy3: "",
          rootCauseAnalysisWhy4: "",
          rootCauseAnalysisWhy5: "",
          rootCauseAnalysisOthers: "",
          rootCauseAnalysisRootCause: "",
          rootCauseAnalysisCorrectiveAction: "",
          rootCauseAnalysisPreventionAction: "",
          rootCauseAnalysisKaizenIdea: "",
          rootCauseAnalysisCounterMeasure: "",
          rootCauseAnalysisCostOfRepair: "",
        }
  );

  const [error, setError] = useState({
    projectNumber: {},
    client: {},
    equipmentNumber: {},
    reportNumber: {},
    inspectionBy: {},
    drawingNumber: {},
    sheetNumber: {},
    revisionNumber: {},
    jointNumber: {},
    spotNumber: {},
    repairRevision: {},
    ndtMethod: {},
    material: {},
    welderNumber: {},
    weldingProcess: {},
    repairOrDefectType: {},
    repairLocationMarkedBy: {},
    repairLengthSpotwise: {},
    repairLengthActualwise: {},
    repairRemovedByAfterRepairRemoval: {},
    repairFoundAfterRepairRemoval: {},
    defectiveProcessAfterRepairRemoval: {},
    repairDepthAfterRepairRemoval: {},
    weldingJointDetailAfterRepairRemoval: {},
    rootCauseAnalysisWhy1: {},
    rootCauseAnalysisWhy2: {},
    rootCauseAnalysisWhy3: {},
    rootCauseAnalysisWhy4: {},
    rootCauseAnalysisWhy5: {},
    rootCauseAnalysisOthers: {},
    rootCauseAnalysisRootCause: {},
    rootCauseAnalysisCorrectiveAction: {},
    rootCauseAnalysisPreventionAction: {},
    rootCauseAnalysisKaizenIdea: {},
    rootCauseAnalysisCounterMeasure: {},
    rootCauseAnalysisCostOfRepair: {},
    repairReqApprover1Comment: {},
    repairReqApprover1Status: {},
    repairReqApprover2Comment: {},
    repairReqApprover2Status: {},
    repairReqApprover3Comment: {},
    repairReqApprover3Status: {},
  });

  let folderName = "weld_repair_analysis_files";

  const fetchWeldFile = async () => {
    try {
      if (weldRepairObj.wld_file !== "") {
        console.log("getimage", weldRepairObj.wld_file);
        const result = await getPresignedURL(folderName, weldRepairObj.wld_file);
        console.log(result.url);
        const geturl = result.url;
        setPreviewUrl(geturl);
        console.log("img", geturl);
        //await axios.get(geturl)
      }
    } catch (error) {
      if (error.request) {
        console.log("hii", error.request);
      } else {
        console.log("hiiiii", `Error: ${error.message}`);
      }
    }
  };
  useEffect(() => {
    fetchWeldFile();
  }, []);


  const handleOndragOver = (event) => {
    event.preventDefault();
  };
  const handleOndrop = (event) => {
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
    console.log("drop", imageFile);
  };

  const [file, setFile] = useState("");
  const fileInputRef = useRef(null);
  const handleFile = (file) => {
    console.log("FILEEe", file);

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      alert("File size exceeds the allowed limit. Add File Less Than 5 Mb");
      file = null;
      return;
    }

    let uploadextension = file.name.split(".");
    if (
      uploadextension[1] !== "docs" &&
      uploadextension[1] !== "xlsx" &&
      uploadextension[1] !== "ppt" &&
      uploadextension[1] !== "zip" &&
      uploadextension[1] !== "jpeg" &&
      uploadextension[1] !== "png" &&
      uploadextension[1] !== "pdf" &&
      uploadextension[1] !== "csv" &&
      uploadextension[1] !== "jpg"
    ) {
      alert("1.Please Upload docx, xlsx, ppt, jpeg, jpg, png, pdf, zip files. 2.please rename the file without special characters + .(extension)");
      file = null;
      return;
    }

    setFile(file);
    setPreviewUrl(URL.createObjectURL(file));

    let ImageName = file.name.replace(/ /g, '_');
    let finalname = ImageName
    weldRepairObj.wld_file = finalname;

  };

  const fetchWiProjectNumbers = async () => {
    const result = await getAllWiProjects();
    setProjectNumbers(result);
    let tempProject = [];
    for (let item of result) {
      if (tempProject.includes(item.WI_Project_No) === false) {
        tempProject.push(item.WI_Project_No);
      }
    }
    setProjNums(tempProject);
    if (action === "Edit") {
      let temp = [];
      for (let item of result) {
        if (item.WI_Project_No === weldRepairObj.projectNumber) {
          temp.push(item);
        }
      }
      setJointNumbers(temp);
    }
  };
  console.log("papapapapapw", projectNumbers);

  const fetchInspectionAgency = async () => {
    const result = await getAllInspectionAgency();
    setInspectionAgency(result);
  };

  const fetchWIWeldingProcesses = async () => {
    const result = await getWIweldingprocess(
      weldRepairObj.projectNumber,
      weldRepairObj.jointNumber
    );
    if (result?.length > 0) {
      let temp = [];
      for (let i of result) {
        let chk = temp.filter((x) => x.value === i.WI_Process);
        if (chk.length === 0) {
          temp.push({ value: i.WI_Process, label: i.WI_Process });
        }
      }
      setWeldingProcessResult(temp);
    }
  };

  const fetchClosureData = async () => {
    if (location.state?.data?.reportNumber !== "") {
      let tempObj1 = {
        fieldValue: location.state?.data?.reportNumber,
        flag: "All",
      };
      let closureresult = await getClosureRepairWorkflow(tempObj1);

      setClosureWeldRepairObj(closureresult[0]);
    }
  };

  const showPopUp = (name, type, key, value) => {
    if (weldRepairObj.weldingProcess === "") {
      error.weldingProcess = {
        status: true,
        message: "Kindly select Welding Process first.",
      };

      setError((prevState) => ({
        ...prevState,
        weldingProcess: error,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        projectNumber: {},
      }));
      console.log("InsideShowPopUp", value, name);
      setPopUp({ name, type, key, value });
      setShowForm(true);
    }
  };

  const setPopUpData = (data) => {
    if (data.name === "Welding Repair- WI Welding Numbers") {
      let temp = [];
      let temp1 = [];
      for (let item of data.result) {
        temp.push(item.WI_Welder_Id.trim());
        temp1.push(item.WI_Welder_Name.trim());
      }
      setWeldRepairObj((prevState) => ({
        ...prevState,
        welderNumber: String(temp),
        welderName: String(temp1),
      }));
    }
  };


 

  useEffect(() => {
    if (userRole === "WE") {
      setCurrentrole("Initiator");
    }
    if (userRole === SupervisorRole) {
      setCurrentrole(SupervisorRole);
    }

    fetchWiProjectNumbers();
    fetchInspectionAgency();

    // if (action === "Edit") {
    //   fetchWIWeldingProcesses();
    //   fetchClosureData();
    //   let welprocess = location.state?.data.weldingProcess.split(",");
    //   let temp = [];
    //   for (let i of welprocess) {
    //     temp.push({ value: i, label: i });
    //   }
    //   setSelectedOptions(temp);
    // }

    if (action === "Edit") {
      if (SupervisorRole === "Supervisor") {
        fetchWIWeldingProcesses();
      } else {
        let welprocess = location.state?.data.weldingProcess.split(",");

        let temp = [];
        if (welprocess?.length > 0) {
          for (let i of welprocess) {
            temp.push({ value: i, label: i });
          }
        }
        setSelectedOptions(temp);
      }
      fetchClosureData();
    }
  }, []);

  const handleDropDownChange = (event) => {
    const { name, value } = event.target;
    if (name === "projectNumber") {
      let temp = [];
      setWeldRepairObj((prevState) => ({
        ...prevState,
        projectNumber: value,
      }));
      for (let item of projectNumbers) {
        if (item.WI_Project_No === value) {
          let chk = temp.filter((x) => x.WI_Joint_No === item.WI_Joint_No);
          if (chk.length === 0) {
            temp.push(item);
          }
        }
      }
      setJointNumbers(temp);
    } else {
      setWeldRepairObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setWeldRepairObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (value, name) => {
    //
    let yyyy = value.getFullYear();
    let mm = value.getMonth() + 1; // Months start at 0!
    setWeldRepairObj((prevState) => ({
      ...prevState,
      Date: value,
      CreatedDate: value,
      Month: mm,
      Year: yyyy,
    }));
  };

  const validateRepairAnalysis = () => {
    let isError = false;

    if (action === "Add") {
      if (weldRepairObj.projectNumber === "") {
        weldRepairObj.projectNumber = "";
        isError = true;
        error.projectNumber = {
          status: true,
          message: "Project Number is required.",
        };
      }
      if (weldRepairObj.jointNumber === "") {
        weldRepairObj.jointNumber = "";
        isError = true;
        error.jointNumber = {
          status: true,
          message: "Joint Number is required.",
        };
      }
    }

    if (action === "Edit" && userRole === SupervisorRole) {
      if (weldRepairObj.repairOrDefectType === "") {
        weldRepairObj.repairOrDefectType = "";
        isError = true;
        error.repairOrDefectType = {
          status: true,
          message: "Repair defect type is required.",
        };
      } else {
        error.repairOrDefectType = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.inspectionBy === "") {
        weldRepairObj.inspectionBy = "";
        isError = true;
        error.inspectionBy = {
          status: true,
          message: "Inspection By is required.",
        };
      } else {
        error.inspectionBy = {
          status: false,
          message: "",
        };
      }


      if (weldRepairObj.spotNumber === "") {
        weldRepairObj.spotNumber = "";
        isError = true;
        error.spotNumber = {
          status: true,
          message: "spotNumber  is required.",
        };
      } else {
        error.spotNumber = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.repairRevision === "") {
        weldRepairObj.repairRevision = "";
        isError = true;
        error.repairRevision = {
          status: true,
          message: "repairRevision is required.",
        };
      } else {
        error.repairRevision = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.ndtMethod === "") {
        weldRepairObj.ndtMethod = "";
        isError = true;
        error.ndtMethod = {
          status: true,
          message: "ndtMethod  is required.",
        };
      } else {
        error.ndtMethod = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.material === "") {
        weldRepairObj.material = "";
        isError = true;
        error.material = {
          status: true,
          message: "material  is required.",
        };
      } else {
        error.material = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.weldingProcess === "") {
        weldRepairObj.weldingProcess = "";
        isError = true;
        error.weldingProcess = {
          status: true,
          message: "welding Process  is required.",
        };
      } else {
        error.weldingProcess = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.welderNumber === "") {
        weldRepairObj.welderNumber = "";
        isError = true;
        error.welderNumber = {
          status: true,
          message: "welding Number  is required.",
        };
      } else {
        error.welderNumber = {
          status: false,
          message: "",
        };
      }
      
      if (weldRepairObj.repairLocationMarkedBy === "") {
        weldRepairObj.repairLocationMarkedBy = "";
        isError = true;
        error.repairLocationMarkedBy = {
          status: true,
          message: "Repair location marked by is required.",
        };
      }
      else {
        error.repairLocationMarkedBy = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.repairLengthSpotwise === "") {
        weldRepairObj.repairLengthSpotwise = "";
        isError = true;
        error.repairLengthSpotwise = {
          status: true,
          message: "Repair length spot wise is required.",
        };
      } else {
        error.repairLengthSpotwise = {
          status: false,
          message: "",
        };
      }
      if (weldRepairObj.repairLengthActualwise === "") {
        weldRepairObj.repairLengthActualwise = "";
        isError = true;
        error.repairLengthActualwise = {
          status: true,
          message: "Repair length actual wise is required.",
        };
      } else {
        error.repairLengthActualwise = {
          status: false,
          message: "",
        };
      }
      if (weldRepairObj.defectiveProcessAfterRepairRemoval === "") {
        weldRepairObj.defectiveProcessAfterRepairRemoval = "";
        isError = true;
        error.defectiveProcessAfterRepairRemoval = {
          status: true,
          message: "Defective process is required.",
        };
      } else {
        error.defectiveProcessAfterRepairRemoval = {
          status: false,
          message: "",
        };
      }
      if (weldRepairObj.weldingJointDetailAfterRepairRemoval === "") {
        weldRepairObj.weldingJointDetailAfterRepairRemoval = "";
        isError = true;
        error.weldingJointDetailAfterRepairRemoval = {
          status: true,
          message: "Welding Joint details is required.",
        };
      } else {
        error.weldingJointDetailAfterRepairRemoval = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.repairRemovedByAfterRepairRemoval === "") {
        weldRepairObj.repairRemovedByAfterRepairRemoval = "";
        isError = true;
        error.repairRemovedByAfterRepairRemoval = {
          status: true,
          message: "Welding Joint details is required.",
        };
      } else {
        error.repairRemovedByAfterRepairRemoval = {
          status: false,
          message: "",
        };
      }


      if (weldRepairObj.repairFoundAfterRepairRemoval === "") {
        weldRepairObj.repairFoundAfterRepairRemoval = "";
        isError = true;
        error.repairFoundAfterRepairRemoval = {
          status: true,
          message: "Welding Joint details is required.",
        };
      } else {
        error.repairFoundAfterRepairRemoval = {
          status: false,
          message: "",
        };
      }

      if (weldRepairObj.repairDepthAfterRepairRemoval === "") {
        weldRepairObj.repairDepthAfterRepairRemoval = "";
        isError = true;
        error.repairDepthAfterRepairRemoval = {
          status: true,
          message: "Welding Joint details is required.",
        };
      } else {
        error.repairDepthAfterRepairRemoval = {
          status: false,
          message: "",
        };
      }





      if (weldRepairObj.rootCauseAnalysisWhy1 === "") {
        weldRepairObj.rootCauseAnalysisWhy1 = "";
        isError = true;
        error.rootCauseAnalysisWhy1 = {
          status: true,
          message: "Root cause analysis is required.",
        };
      } else {
        error.rootCauseAnalysisWhy1 = {
          status: false,
          message: "",
        };
      }
    } else if (action === "Edit" && userRole === "WE") {
      if (closureWeldRepairObj.repairReqApprover1Comment === "") {
        closureWeldRepairObj.repairReqApprover1Comment = "";
        isError = true;
        error.repairReqApprover1Comment = {
          status: true,
          message: "Comment is required.",
        };
      } else {
        error.repairReqApprover1Comment = {
          status: false,
          message: "",
        };
      }
      if (closureWeldRepairObj.repairReqApprover1Status === "") {
        closureWeldRepairObj.repairReqApprover1Status = "";
        isError = true;
        error.repairReqApprover1Status = {
          status: true,
          message: "Status is required.",
        };
      } else {
        error.repairReqApprover1Status = {
          status: false,
          message: "",
        };
      }
    } else if (action === "Edit" && userRole === "QCE") {
      if (closureWeldRepairObj.repairReqApprover2Comment === "") {
        closureWeldRepairObj.repairReqApprover2Comment = "";
        isError = true;
        error.repairReqApprover2Comment = {
          status: true,
          message: "Comment is required.",
        };
      } else {
        error.repairReqApprover2Comment = {
          status: false,
          message: "",
        };
      }
      if (closureWeldRepairObj.repairReqApprover2Status === "") {
        closureWeldRepairObj.repairReqApprover2Status = "";
        isError = true;
        error.repairReqApprover2Status = {
          status: true,
          message: "Status is required.",
        };
      } else {
        error.repairReqApprover2Status = {
          status: false,
          message: "",
        };
      }
    } else if (action === "Edit" && userRole === "NDE") {
      if (closureWeldRepairObj.repairReqApprover3Comment === "") {
        isError = true;
        error.repairReqApprover3Comment = {
          status: true,
          message: "Comment is required.",
        };
      } else {
        error.repairReqApprover3Comment = {
          status: false,
          message: "",
        };
      }
      if (closureWeldRepairObj.repairReqApprover3Status === "") {
        isError = true;
        error.repairReqApprover3Status = {
          status: true,
          message: "Status is required.",
        };
      } else {
        error.repairReqApprover3Status = {
          status: false,
          message: "",
        };
      }
    }
    setError((prevState) => ({
      ...prevState,
      ...error,
    }));

    return isError;
  };

  const handlecancel = () => {
    navigate("/weldingRepair/WeldingRepairAnalysis", {
      state: { data: "", action: "Add" },
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //
    if (e.nativeEvent.submitter.name === "tempsave") {
      //
      if (
        weldRepairObj.client === "" &&
        weldRepairObj.equipmentNumber === "" &&
        weldRepairObj.inspectionBy === "" &&
        weldRepairObj.drawingNumber === "" &&
        weldRepairObj.sheetNumber === "" &&
        weldRepairObj.revisionNumber === "" &&
        weldRepairObj.spotNumber === "" &&
        weldRepairObj.repairRevision === "" &&
        weldRepairObj.ndtMethod === "" &&
        weldRepairObj.material === "" &&
        weldRepairObj.weldingProcess === "" &&
        weldRepairObj.welderNumber === "" &&
        weldRepairObj.welderName === "" &&
        weldRepairObj.repairOrDefectType === "" &&
        weldRepairObj.repairLocationMarkedBy === "" &&
        weldRepairObj.repairLengthSpotwise === "" &&
        weldRepairObj.repairLengthActualwise === "" &&
        weldRepairObj.repairRemovedByAfterRepairRemoval === "" &&
        weldRepairObj.repairFoundAfterRepairRemoval === "" &&
        weldRepairObj.repairDepthAfterRepairRemoval === "" &&
        weldRepairObj.defectiveProcessAfterRepairRemoval === "" &&
        weldRepairObj.weldingJointDetailAfterRepairRemoval === "" &&
        weldRepairObj.rootCauseAnalysisWhy1 === "" &&
        weldRepairObj.rootCauseAnalysisWhy2 === "" &&
        weldRepairObj.rootCauseAnalysisWhy3 === "" &&
        weldRepairObj.rootCauseAnalysisWhy4 === "" &&
        weldRepairObj.rootCauseAnalysisWhy5 === "" &&
        weldRepairObj.rootCauseAnalysisOthers === "" &&
        weldRepairObj.rootCauseAnalysisRootCause === "" &&
        weldRepairObj.rootCauseAnalysisCorrectiveAction === "" &&
        weldRepairObj.rootCauseAnalysisPreventionAction === "" &&
        weldRepairObj.rootCauseAnalysisKaizenIdea === "" &&
        weldRepairObj.rootCauseAnalysisCounterMeasure === "" &&
        weldRepairObj.rootCauseAnalysisCostOfRepair === ""
      ) {
        toast.error("Atleast fill any one field to temp save.", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        return;
      } else {
        weldRepairObj.tempFlag = true;
        weldRepairObj.approvalPendingWith = SupervisorRole;
        let result = await addWeldingRepairAnalysis(weldRepairObj);
        // if (weldRepairObj.wld_file !== "") {
        //   console.log("insideUrl", weldRepairObj.wld_file);
        //   const result = await getPresignedURL(folderName, weldRepairObj.wld_file);
        //   console.log(result.url);
        //   const posturl = result.url;
        //   setPreviewUrl(posturl);
        //   console.log("img", posturl);
        //   await axios.put(posturl, file);
        //   // console.log("putt",posturl,fileInput.files[0])
        //   console.log("putweldfile", file);
        // }
        if (result?.status) {
          // toast.success(result.message, {
            toast.warning("Weld Repair is Temp saved", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
          setTimeout(async () => {
            navigate("/weldingRepair/WeldingRepairAnalysis", {
              state: { data: "", action: "Add" },
            });
          }, 2000);
        }
      }
    } else {
      console.log("noootemp")
      let roles = [];
      let msg = "";
      if (userRole === SupervisorRole) {
        roles = [{ role: process.env.REACT_APP_ROLE_SUPERVISOR }];
        msg =
          "No welding engineer maintained in User Master. Cannot submit for approval.";
      } else if (userRole === "WE" && action !== "Add") {
        roles = [{ role: process.env.REACT_APP_ROLE_QCENGINEER }];
        msg =
          "No QC engineer maintained in User Master. Cannot submit for approval.";
      } else if (userRole === "QCE") {
        roles = [{ role: process.env.REACT_APP_ROLE_NDENGINEER }];
        msg =
          "No NDE engineer maintained in User Master. Cannot submit for approval.";
      } else if (userRole === "NDE") {
        roles = [{ role: process.env.REACT_APP_ROLE_SUPERVISOR }];
        msg =
          "No supervisor engineer maintained in User Master. Cannot submit for rejection.";
      }
      let usermasterresult = await getAllUsersByRole(roles);
      if (
        usermasterresult?.length === 0 &&
        action !== "Add" &&
        userRole !== "NDE"
      ) {
        toast.error(msg, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        return;
      } else if (
        usermasterresult?.length === 0 &&
        closureWeldRepairObj.repairReqApprover3Status === "Rejected" &&
        userRole === "NDE"
      ) {
        toast.error(msg, {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        return;
      }

      if (!validateRepairAnalysis()) {
        if (action === "Add") {
          
          weldRepairObj.createdOn = getCurrentDateTime("-");
          weldRepairObj.createdBy = userId;
          weldRepairObj.updatedBy = userId;
          weldRepairObj.updatedByName = userName;
          weldRepairObj.createdByName = userName;
          weldRepairObj.updatedOn = getCurrentDateTime("-");
          weldRepairObj.initiatedDate = getCurrentDateTime("-");
          let newDate = new Date();
          let month = newDate.getMonth() + 1;
          let year = newDate.getFullYear();
          weldRepairObj.month = month;
          weldRepairObj.year = year;
          weldRepairObj.repairAnalysisStatus = "In Progress";
          //This needs to maintained in count per project
          //year PK/SK month count
          
          if(userRole === "WE"){
          let maxArr = await getProjectReqCountProjectWise({
            year: year,
            projectNumber: weldRepairObj.projectNumber,
          });
          console.log("mamamamx", maxArr)
          
          let maxSeqNo = 0;
          if (maxArr.length === 0) {
            maxSeqNo = 1;
          } else {
            maxSeqNo = maxArr[maxArr.length - 1].RaisedCount;
          }
        
          weldRepairObj.SeqNo = maxSeqNo;
        }
          weldRepairObj.reportNumber =
            weldRepairObj.projectNumber + "/" + weldRepairObj.SeqNo;
          weldRepairObj.approvalPendingWith = SupervisorRole;
          weldRepairObj.workflowRevisionNumber = 0;
          weldRepairObj.PK = "weldRepairAnalysis#year#" + weldRepairObj.year;
          weldRepairObj.SK =
            "ProjectNumber#" +
            weldRepairObj.projectNumber +
            "#RaisedBy#" +
            userId +
            "#SeqNo#" +
            weldRepairObj.SeqNo;
            debugger
          let result = await addWeldingRepairAnalysis(weldRepairObj);
         
          if (result?.status) {
            toast.success(result.message, {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            });
            setTimeout(async () => {
              navigate("/weldingRepair/WeldingRepairAnalysis", {
                state: { data: "", action: "Add" },
              });
            }, 2000);
          }
        }
        if (
          action === "Edit" &&
          weldRepairObj.approvalPendingWith === SupervisorRole
        ) {
          let result;
          weldRepairObj.updatedBy = userId;
          weldRepairObj.updatedByName = userName;
          weldRepairObj.updatedOn = getCurrentDateTime("-");
          if (weldRepairObj.repairAnalysisStatus === "Rejected") {
            let temprev = 0;
            let tmpObj = {
              fieldValue: weldRepairObj.reportNumber,
              flag: "All",
            };
            let closureRevisionNumberResult = await getClosureRepairWorkflow(
              tmpObj
            );
            let maxRevisionNo = 0;
            if (closureRevisionNumberResult?.length > 0) {
              let sortedArr = closureRevisionNumberResult.sort(
                sortByPropertyInDescending("revisionNumber")
              );
              maxRevisionNo = sortedArr[0].revisionNumber;
            }
            temprev = maxRevisionNo + 1;
            weldRepairObj.workflowRevisionNumber = temprev;
          }
          weldRepairObj.repairAnalysisStatus = "In Progress";
          weldRepairObj.approvalPendingWith = "WE";
          weldRepairObj.tempFlag = false;
          result = await addWeldingRepairAnalysis(weldRepairObj);

          if (result?.status) {
            let tempObj = {
              PK: "reportNumber#" + weldRepairObj.reportNumber,
              SK: "revisionNumber#" + weldRepairObj.workflowRevisionNumber,
              projectNumber: weldRepairObj.projectNumber,
              revisionNumber: weldRepairObj.workflowRevisionNumber,
              reportNumber: weldRepairObj.reportNumber,
              approvalPendingWith: weldRepairObj.approvalPendingWith,
              repairSupervisorName: userName,
              repairSupervisorEmailId: userId,
              repairSupervisorCreatedOn: todayDate,
              repairReqApprover1: "WE",
              repairReqApprover1Comment: "",
              repairReqApprover1Status: "",
              repairReqApprover1CreatedOn: "",
              repairReqApprover2: "QCE",
              repairReqApprover2Comment: "",
              repairReqApprover2Status: "",
              repairReqApprover2CreatedOn: "",
              repairReqApprover3: "NDE",
              repairReqApprover3Comment: "",
              repairReqApprover3Status: "",
              repairReqApprover3CreatedOn: "",
            };
            setClosureWeldRepairObj(tempObj);
            let closureresult = await addWeldingRepairAnalysis(tempObj);
            toast.success(result.message, {
              position: toast.POSITION.TOP_LEFT,
              className: "toast-message",
            });
            setTimeout(async () => {
              navigate("/weldingRepair/WeldingRepairAnalysis", {
                state: { data: "", action: "Add" },
              });
            }, 2000);
          }
        }
        if (userRole === "WE" && weldRepairObj.approvalPendingWith === "WE") {
          let result;
          if (closureWeldRepairObj.repairReqApprover1Status === "Rejected") {
            weldRepairObj.updatedBy = userId;
            weldRepairObj.updatedByName = userName;
            weldRepairObj.repairAnalysisStatus = "Rejected";
            weldRepairObj.approvalPendingWith = SupervisorRole;
            result = await addWeldingRepairAnalysis(weldRepairObj);
            if (result?.status) {
              closureWeldRepairObj["approvalPendingWith"] =
                weldRepairObj.approvalPendingWith;
              let closureresult = await addWeldingRepairAnalysis(
                closureWeldRepairObj
              );
              toast.success(result.message, {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
              setTimeout(async () => {
                navigate("/weldingRepair/WeldingRepairAnalysis", {
                  state: { data: "", action: "" },
                });
              }, 2000);
            }
          } else {
            weldRepairObj.updatedBy = userId;
            weldRepairObj.updatedByName = userName;
            weldRepairObj.repairAnalysisStatus = "In Progress";
            weldRepairObj.approvalPendingWith = "QCE";
            result = await addWeldingRepairAnalysis(weldRepairObj);
            if (result?.status) {
              action = "";
              closureWeldRepairObj["approvalPendingWith"] =
                weldRepairObj.approvalPendingWith;
              let closureresult = await addWeldingRepairAnalysis(
                closureWeldRepairObj
              );
              toast.success(result.message, {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
              setTimeout(async () => {
                navigate("/weldingRepair/WeldingRepairAnalysis", {
                  state: { data: "", action: "" },
                });
              }, 2000);
            }
          }
        }
        if (userRole === "QCE" && weldRepairObj.approvalPendingWith === "QCE") {
          let result;
          if (closureWeldRepairObj.repairReqApprover2Status === "Rejected") {
            weldRepairObj.updatedBy = userId;
            weldRepairObj.updatedByName = userName;
            weldRepairObj.repairAnalysisStatus = "Rejected";
            weldRepairObj.approvalPendingWith = SupervisorRole;
            result = await addWeldingRepairAnalysis(weldRepairObj);

            if (result?.status) {
              closureWeldRepairObj["approvalPendingWith"] =
                weldRepairObj.approvalPendingWith;
              let closureresult = await addWeldingRepairAnalysis(
                closureWeldRepairObj
              );
              toast.success(result.message, {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
              setTimeout(async () => {
                navigate("/weldingRepair/WeldingRepairAnalysis", {
                  state: { data: "", action: "" },
                });
              }, 2000);
            }
          } else {
            weldRepairObj.updatedBy = userId;
            weldRepairObj.updatedByName = userName;
            weldRepairObj.repairAnalysisStatus = "In Progress";
            weldRepairObj.approvalPendingWith = "NDE";
            result = await addWeldingRepairAnalysis(weldRepairObj);
            if (result?.status) {
              action = "";
              closureWeldRepairObj["approvalPendingWith"] =
                weldRepairObj.approvalPendingWith;
              let closureresult = await addWeldingRepairAnalysis(
                closureWeldRepairObj
              );
              toast.success(result.message, {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
              setTimeout(async () => {
                navigate("/weldingRepair/WeldingRepairAnalysis", {
                  state: { data: "", action: "Add" },
                });
              }, 2000);
            }
          }
        }
        if (userRole === "NDE" && weldRepairObj.approvalPendingWith === "NDE") {
          let result;
          if (closureWeldRepairObj.repairReqApprover3Status === "Rejected") {
            weldRepairObj.updatedBy = userId;
            weldRepairObj.updatedByName = userName;
            weldRepairObj.repairAnalysisStatus = "Rejected";
            weldRepairObj.approvalPendingWith = SupervisorRole;
            result = await addWeldingRepairAnalysis(weldRepairObj);

            if (result?.status) {
              action = "";
              closureWeldRepairObj["approvalPendingWith"] = SupervisorRole;
              let closureresult = await addWeldingRepairAnalysis(
                closureWeldRepairObj
              );
              toast.success(result.message, {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
              setTimeout(async () => {
                navigate("/weldingRepair/WeldingRepairAnalysis", {
                  state: { data: "", action: "" },
                });
              }, 2000);
            }
          } else {
            weldRepairObj.updatedBy = userId;
            weldRepairObj.updatedByName = userName;
            weldRepairObj.repairAnalysisStatus = "Approved";
            weldRepairObj.approvalPendingWith = "Closed";
            result = await addWeldingRepairAnalysis(weldRepairObj);

            if (result?.status) {
              action = "";
              closureWeldRepairObj["approvalPendingWith"] =
                weldRepairObj.approvalPendingWith;
              let closureresult = await addWeldingRepairAnalysis(
                closureWeldRepairObj
              );
              toast.success(result.message, {
                position: toast.POSITION.TOP_LEFT,
                className: "toast-message",
              });
              setTimeout(async () => {
                navigate("/weldingRepair/WeldingRepairAnalysis", {
                  state: { data: "", action: "Add" },
                });
              }, 2000);
            }
          }
        }
      }
    }
    debugger
    if (weldRepairObj.wld_file !== "") {
      console.log("insideUrl", weldRepairObj.wld_file);
      // let ImageName = weldRepairObj.wld_file.replace(/ /g, "_");

      const result = await getPresignedURL(folderName, weldRepairObj.wld_file);
      console.log("ddd",result);
      const posturl = result.url;
      setPreviewUrl(posturl);
      console.log("img", posturl);
      await axios.put(posturl, file);
      // console.log("putt",posturl,fileInput.files[0])
      console.log("putweldfile", file);
    }
  };

  const callReload = () => {
    // alert("dd")

    props.onHide();
    props.setMainReload(true);
  };

  const getRemarks = (e, currentClosureObj, currentWeldObj, levelname) => {
    if (e.target.name.includes("Comment")) {
      currentClosureObj["repairReqApprover" + levelname] = userName;
      currentClosureObj["repairReqApprover" + levelname + "Comment"] =
        e.target.value;
      currentClosureObj["repairReqApprover" + levelname + "CreatedOn"] =
        todayDate;
      currentWeldObj.updatedBy = userId;
      currentWeldObj.updatedByName = userName;
      currentWeldObj.updatedOn = todayDate;
    }
    if (e.target.name.includes("Status")) {
      currentClosureObj["repairReqApprover" + levelname + "Status"] =
        e.target.value;
    }
    setClosureWeldRepairObj(currentClosureObj);
  };

  const handleDropDownProcess = (e) => {
    let temp = [];
    let temp1 = [];
    if (weldRepairObj.weldingProcess !== "") {
      temp1.push(weldRepairObj.weldingProcess);
      for (let dt of e) {
        if (!weldRepairObj.weldingProcess.includes(dt.value)) {
          temp1.push(dt.value);
        }
        temp.push({ value: dt.value, label: dt.value });
      }
      setSelectedOptions(temp);
    } else {
      temp.push({ value: e[0].value, label: e[0].value });
      setSelectedOptions(temp);
      temp1.push(e[0].value);
    }
    setWeldRepairObj((prevState) => ({
      ...prevState,
      weldingProcess: String(temp1),
      defectiveProcessAfterRepairRemoval: String(temp1),
    }));
  };

  
  const handleDeleteDocEvent = async (fileName) => {
    setWeldRepairObj((prevState) => ({
      ...prevState,
      wld_file: "",
    }));
  };

  const handleDownloadDocument = async (fileName) => {
    debugger
    downloadAttachment(
      { foldername: "weld_repair_analysis_files", 
      // subfoldername: weldRepairObj.wld_file,
       filename: fileName }).catch((err) => {
      toast.error(`Error in fetching file.`, {
        position: toast.POSITION.TOP_LEFT,
        className: "toast-message",
      });
    });
  };

  return (
    <>
      <div className="position-absolute ms-3" style={{ top: "15px" }}>
        <strong>Enter Weld Repair</strong>
      </div>
      <div style={{ border: "1px solid var(--col-ddd)" }}>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <div
          className="mt-2"
          style={{
            height: window.innerHeight - 160,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="weldRepairFirstDiv">
            <div className="weldRepairSecondDiv">
              <Row style={{
                position:"relative",
                alignItems: "center",
              }}>
                <Col lg={3} className="mb-2">
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="ReportNumber"
                        name="ReportNumber"
                        type="text"
                        maxLength="50"
                        readOnly
                        placeholder=" "
                        value={weldRepairObj.reportNumber}
                        onChange={handleChange}
                      />
                      <label htmlFor="reportNumber">Report Number</label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <div className="groove_date">
                    <div className="Date-input w-100">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Date"
                            className="groove_datecolor"
                            value={
                              weldRepairObj.initiatedDate
                                ? dayjs(weldRepairObj.initiatedDate)
                                : dayjs(todayDate)
                            }
                            disabled={true}
                            onChange={(newValue) => {
                              let date = dayjs(newValue).format("YYYY-MM-DD");
                              handleDateChange(date, "lastUpdatedOn");
                            }}
                            format="DD-MM-YYYY"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                {/* // TODO: Add functionality */}
                <Form.Group>
                    <form encType="multipart/form-data">
                      { weldRepairObj.wld_file === "" && currentrole === SupervisorRole ? (
                    <div className="userpic_upload">
                    <div
                      className="drop_zone"
                      onDragOver={handleOndragOver}
                      onDrop={handleOndrop}
                    >
                      <label
                        className="userpic_text_black"
                        for="fileInput"
                      >
                        Upload image here or
                      </label>{" "}
                      <label
                        className="userpic_text_blue"
                        for="fileInput"
                      >
                        Select a file
                      </label>
                      <br></br>
                      <label className="pic_text1" >
                        Supports: .docx, .xlsx, .ppt, .jpeg, .jpg, .png, .zip, Size:Max 5MB
                      </label>
                      <br></br>
                      <label className="imgtext">
                        {weldRepairObj.wld_file}
                      </label>
                      <input
                        id="fileInput"
                        name="fileInput"
                        type="file"
                        accept="*"
                        hidden
                        ref={fileInputRef}
                        onChange={(e) => handleFile(e.target.files[0])}
                      />
                    </div>
                  </div>
                      ) : (

                        <>
                        
                        <label className="fileuploadTitle">Upload Image</label>
                      <Col lg={12} md={12} xs={12}>
                        <label
                          className="add-new-link"
                          onClick={() =>
                            handleDownloadDocument(
                              weldRepairObj.wld_file
                            )
                          }
                        >
                          { weldRepairObj.wld_file === ""
                            ? "No file chosen"
                            :  weldRepairObj.wld_file}
                          &nbsp;&nbsp;&nbsp;
                        </label>
                       
                          <img
                            onMouseOver={(e) =>
                              (e.currentTarget.src = Delete)
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.src = DeleteGrey)
                            }
                            src={DeleteGrey}
                            alt="Delete"
                            onClick={() =>
                              handleDeleteDocEvent(
                                weldRepairObj.wld_file
                              )
                            }
                          />
                      </Col>
                      </>
                        
                      )} 
                    </form>
                    &nbsp;&nbsp;
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3} className="mb-2">
                  <Form.Group className="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="projectNumber"
                        name="projectNumber"
                        type="text"
                        placeholder=" "
                        value={weldRepairObj.projectNumber}
                        disabled={action === "Edit"}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        {projNums
                          ? projNums.map((projnum) => (
                              <option key={projnum} value={projnum}>
                                {projnum}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                      <label htmlFor="projectNumber">Project Number</label>
                    </div>
                    <div className="required-text-selectWeldRepair">
                      {error.projectNumber.status && (
                        <Form.Text className="text-danger">
                          {error.projectNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="client"
                        name="client"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={weldRepairObj.client}
                        readOnly={currentrole !== SupervisorRole}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isAlphanumericWithSpaceDotDashAmpersand(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            client: error,
                          }));
                        }}
                      />
                      <label htmlFor="client">Client</label>
                      {error.client.status && (
                        <Form.Text className="text-danger">
                          {error.client.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="equipmentNumber"
                        name="equipmentNumber"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={weldRepairObj.equipmentNumber}
                        readOnly={currentrole !== SupervisorRole}
                        onChange={handleChange}
                      />
                      <label htmlFor="equipmentNumber">Equipment No.</label>
                      {error.equipmentNumber.status && (
                        <Form.Text className="text-danger">
                          {error.equipmentNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="inspectionBy"
                        name="inspectionBy"
                        type="text"
                        value={weldRepairObj.inspectionBy}
                        disabled={currentrole !== SupervisorRole}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        {inspectionAgency
                          ? inspectionAgency.map((inspect) => (
                              <option
                                key={inspect.wld_description}
                                value={inspect.wld_description}
                              >
                                {inspect.wld_description}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                      <label htmlFor="inspectionBy">Inspection By</label>
                    </div>
                    <div className="required-text-selectWeldRepair">
                      {error.inspectionBy.status && (
                        <Form.Text className="text-danger">
                          {error.inspectionBy.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3} className="mb-2">
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="drawingNumber"
                        name="drawingNumber"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={weldRepairObj.drawingNumber}
                        readOnly={currentrole !== SupervisorRole}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isAlphanumericWithSpaceDotDashAmpersand(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            drawingNumber: error,
                          }));
                        }}
                      />
                      <label htmlFor="drawingNumber">Drawing Number</label>
                      {error.drawingNumber.status && (
                        <Form.Text className="text-danger">
                          {error.drawingNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="sheetNumber"
                        name="sheetNumber"
                        type="text"
                        maxLength="50"
                        readOnly={currentrole !== SupervisorRole}
                        placeholder=" "
                        value={weldRepairObj.sheetNumber}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isAlphanumericWithSpace(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            sheetNumber: error,
                          }));
                        }}
                      />
                      <label htmlFor="sheetNumber">Sheet Number</label>
                      {error.sheetNumber.status && (
                        <Form.Text className="text-danger">
                          {error.sheetNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="revisionNumber"
                        name="revisionNumber"
                        type="text"
                        maxLength="50"
                        readOnly={currentrole !== SupervisorRole}
                        placeholder=" "
                        value={weldRepairObj.revisionNumber}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isNumberWith2digits(e.target.value);
                          currentrole === SupervisorRole
                            ? setError((prevState) => ({
                                ...prevState,
                                revisionNumber: error,
                              }))
                            : setError((prevState) => ({
                                ...prevState,
                                revisionNumber: {},
                              }));
                        }}
                      />
                      <label htmlFor="revisionNumber">Revision No.</label>
                      {error.revisionNumber.status && (
                        <Form.Text className="text-danger">
                          {error.revisionNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="orginatingDept"
                        name="orginatingDept"
                        type="text"
                        readOnly
                        placeholder=" "
                        value={weldRepairObj.orginatingDept}
                      />
                      <label htmlFor="orginatingDept">Originating Dept</label>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3} className="mb-2">
                  <Form.Group className="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="jointNumber"
                        name="jointNumber"
                        type="text"
                        placeholder=" "
                        value={weldRepairObj.jointNumber}
                        disabled={
                          action === "Edit" ||
                          currentrole === SupervisorRole ||
                          currentrole === "NDE" ||
                          currentrole === "QCE"
                        }
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        {jointNumbers
                          ? jointNumbers.map((jointnum) => (
                              <option
                                key={jointnum.WI_Joint_No}
                                value={jointnum.WI_Joint_No}
                              >
                                {jointnum.WI_Joint_No}
                              </option>
                            ))
                          : null}
                      </Form.Select>
                      <label htmlFor="jointNumber">Joint Number</label>
                    </div>
                    <div className="required-text-selectWeldRepair">
                      {error.jointNumber.status && (
                        <Form.Text className="text-danger">
                          {error.jointNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="spotNumber"
                        name="spotNumber"
                        type="text"
                        maxLength="50"
                        readOnly={currentrole !== SupervisorRole}
                        placeholder=" "
                        value={weldRepairObj.spotNumber}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isAlphanumericWithSpaceDashComma(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            spotNumber: error,
                          }));
                        }}
                      />
                      <label htmlFor="spotNumber">Spot No.</label>
                      </div>
                      <div className="required-text-selectWeldRepair">
                      {error.spotNumber.status && (
                        <Form.Text className="text-danger">
                          {error.spotNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {/* // TODO: Does submit data? */}
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="repairRevision"
                        name="repairRevision"
                        type="text"
                        maxLength="5"
                        readOnly={currentrole !== SupervisorRole}
                        placeholder=" "
                        value={weldRepairObj.repairRevision}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isAlphanumericWithSpaceDashComma(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            repairRevision: error,
                          }));
                        }}
                      />
                      <label htmlFor="repairRevision">Repair Revision</label>
                      </div>
                      <div className="required-text-selectWeldRepair">
                      {error.repairRevision.status && (
                        <Form.Text className="text-danger">
                          {error.repairRevision.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="ndtMethod"
                        name="ndtMethod"
                        type="text"
                        placeholder=" "
                        value={weldRepairObj.ndtMethod}
                        disabled={currentrole !== SupervisorRole}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="RT">RT</option>
                        <option value="TOFD">TOFD</option>
                        <option value="PAUT">PAUT</option>
                        <option value="UT">UT</option>
                      </Form.Select>
                      <label htmlFor="ndtMethod">NDT Method</label>
                    </div>
                    <div className="required-text-selectWeldRepair">
                      {error.ndtMethod.status && (
                        <Form.Text className="text-danger">
                          {error.ndtMethod.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="material"
                        name="material"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={weldRepairObj.material}
                        readOnly={currentrole !== SupervisorRole}
                        onChange={handleChange}
                      />
                      <label htmlFor="material">Material</label>
                      </div>
                      <div className="required-text-selectWeldRepair">
                      {error.material.status && (
                        <Form.Text className="text-danger">
                          {error.material.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                {currentrole === SupervisorRole ?

                <Col lg={3}>
                  <Form.Group className="g-select">
                    <Select
                      className="repairSelectDD"
                      id="weldingProcess"
                      name="weldingProcess"
                      options={weldingProcessResult}
                      isMulti
                      isDisabled={currentrole !== SupervisorRole}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(e) => handleDropDownProcess(e)}
                      // value={selectedOptions}
                      value={action === "View" ? weldRepairObj.weldingProcess : selectedOptions }

                      placeholder="Welding Process"
                    />
                    <div className="required-text-selectWeldRepair">
                      {error.weldingProcess.status && (
                        <Form.Text className="text-danger">
                          {error.weldingProcess.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                :

                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="weldingProcess"
                        name="weldingProcess"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={weldRepairObj.weldingProcess}
                        readOnly={currentrole !== SupervisorRole}
                        onChange={handleChange}
                      />
                      <label htmlFor="Welding Process">Welding Process</label>
                      </div>
                      <div className="required-text-selectWeldRepair">
                      {error.weldingProcess.status && (
                        <Form.Text className="text-danger">
                          {error.weldingProcess.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
}

{currentrole === SupervisorRole ?
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <Form.Control
                      id="welderNumber"
                      name="welderNumber"
                      type="text"
                      maxLength="50"
                      placeholder=" "
                      value={weldRepairObj.welderNumber}
                      disabled={currentrole !== SupervisorRole}
                    ></Form.Control>
                    <label htmlFor="welderNumber" style={{background:"none"}}>Welder No.</label>
                    {action === "Edit" && currentrole === SupervisorRole && (
                      <BiSelectMultiple
                        size={20}
                        onClick={() => {
                          showPopUp(
                            "Welding Repair- WI Welding Numbers",
                            "Multiple",
                            "Project Number",
                            weldRepairObj
                          );
                        }}
                        color={"gray"}
                        style={{ marginLeft: "13rem", marginTop: "-4.3rem" }}
                      />
                    )}
                    <div className="required-text-selectWeldRepair" style={{position:"relative", bottom:"20px"}}>
                    {error.welderNumber.status && (
                      <Form.Text className="text-danger">
                        {error.welderNumber.message}
                      </Form.Text>
                    )}
                    </div>
                  </Form.Group>
                </Col>

                :
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="welderNumber"
                        name="welderNumber"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={weldRepairObj.welderNumber}
                        readOnly={currentrole !== SupervisorRole}
                        onChange={handleChange}
                      />
                      <label htmlFor="Welding Process">Welder No.</label>
                      </div>
                      <div className="required-text-selectWeldRepair">
                      {error.welderNumber.status && (
                        <Form.Text className="text-danger">
                          {error.welderNumber.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
}


                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="welderName"
                        name="welderName"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={weldRepairObj.welderName}
                        readOnly
                      />
                      <label htmlFor="welderName">Welder Name</label>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
          <div className="weldRepairSecondDiv">
            <Row>
              <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>Detail of Repair Removal</span>
              <Col lg={3}>
                <Form.Group className="g-input">
                  <div className="d-grid align-items-center">
                    <Form.Control
                      id="repairOrDefectType"
                      name="repairOrDefectType"
                      type="text"
                      maxLength="50"
                      placeholder=" "
                      value={weldRepairObj.repairOrDefectType}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error = isAlphanumericWithSpaceDashComma(
                          e.target.value
                        );
                        setError((prevState) => ({
                          ...prevState,
                          repairOrDefectType: error,
                        }));
                      }}
                    />
                    <label htmlFor="repairOrDefectType">
                      Repair Type/ Type of Defect
                    </label>
                  </div>
                  <div className="required-text-selectWeldRepair">
                    {error.repairOrDefectType.status && (
                      <Form.Text className="text-danger">
                        {error.repairOrDefectType.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="g-select">
                  <div className="d-grid align-items-center">
                    <Form.Select
                      id="repairLocationMarkedBy"
                      name="repairLocationMarkedBy"
                      type="text"
                      placeholder=" "
                      value={weldRepairObj.repairLocationMarkedBy}
                      disabled={currentrole !== SupervisorRole}
                      onChange={(e) => {
                        handleDropDownChange(e);
                      }}
                    >
                      <option key="select" value="">
                        Select
                      </option>
                      <option key="RT Tracing" value="RT Tracing">
                        RT Tracing
                      </option>
                      <option key="UT" value="UT">
                        UT
                      </option>
                    </Form.Select>
                    <label htmlFor="repairLocationMarkedBy">
                      Repair Location Marked By
                    </label>
                  </div>
                  <div className="required-text-selectWeldRepair">
                    {error.repairLocationMarkedBy.status && (
                      <Form.Text className="text-danger">
                        {error.repairLocationMarkedBy.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="g-input">
                  <div className="d-grid align-items-center">
                    <Form.Control
                      id="repairLengthSpotwise"
                      name="repairLengthSpotwise"
                      type="text"
                      maxLength="50"
                      placeholder=" "
                      value={weldRepairObj.repairLengthSpotwise}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error = isExperience(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          repairLengthSpotwise: error,
                        }));
                      }}
                    />
                    <label htmlFor="repairLengthSpotwise">
                      Repair Length(Spotwise) in mm
                    </label>
                    </div>
                    <div className="required-text-selectWeldRepair">
                    {error.repairLengthSpotwise.status && (
                      <Form.Text className="text-danger">
                        {error.repairLengthSpotwise.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className="g-input">
                  <div className="d-grid align-items-center">
                    <Form.Control
                      id="repairLengthActualwise"
                      name="repairLengthActualwise"
                      type="text"
                      maxLength="50"
                      placeholder=" "
                      value={weldRepairObj.repairLengthActualwise}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error = isExperience(e.target.value);
                        setError((prevState) => ({
                          ...prevState,
                          repairLengthActualwise: error,
                        }));
                      }}
                    />
                    <label htmlFor="repairLengthActualwise">
                      Repair Length(Actual size) in mm
                    </label>
                    </div>
                    <div className="required-text-selectWeldRepair">
                    {error.repairLengthActualwise.status && (
                      <Form.Text className="text-danger">
                        {error.repairLengthActualwise.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="weldRepairFirstDiv">
            <div className="weldRepairSecondDiv">
              <Row>
                <span className="WPS-blue-text" style={{position:"relative", top:"10px"}}>After Repair Removal</span>
                <Col lg={3}>
                  <Form.Group className="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="repairRemovedByAfterRepairRemoval"
                        name="repairRemovedByAfterRepairRemoval"
                        type="text"
                        placeholder=" "
                        value={weldRepairObj.repairRemovedByAfterRepairRemoval}
                        disabled={currentrole !== SupervisorRole}
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                      >
                        <option value="select">Select</option>
                        <option key="Grouping" value="Grouping">
                          Gouging
                        </option>
                        <option key="Grinding" value="Grinding">
                          Grinding
                        </option>
                      </Form.Select>
                      <label htmlFor="repairRemovedByAfterRepairRemoval">
                        Repair Removed By
                      </label>
                    </div>
                    <div className="required-text-selectWeldRepair">
                    {error.repairRemovedByAfterRepairRemoval.status && (
                      <Form.Text className="text-danger">
                        {error.repairRemovedByAfterRepairRemoval.message}
                      </Form.Text>
                    )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-select">
                    <div className="d-grid align-items-center">
                      <Form.Select
                        id="repairFoundAfterRepairRemoval"
                        name="repairFoundAfterRepairRemoval"
                        type="text"
                        placeholder=" "
                        value={weldRepairObj.repairFoundAfterRepairRemoval}
                        disabled={
                          currentrole === "Initiator" ||
                          currentrole !== SupervisorRole
                        }
                        onChange={(e) => {
                          handleDropDownChange(e);
                        }}
                      >
                        <option value="">Select</option>
                        <option key="Yes" value="Yes">
                          Yes
                        </option>
                        <option key="No" value="No">
                          No
                        </option>
                        <option key="Partial" value="Partially Found">
                          Partially Found
                        </option>
                      </Form.Select>
                      <label htmlFor="repairFoundAfterRepairRemoval">
                        Repair Found
                      </label>
                    </div>
                    <div className="required-text-selectWeldRepair">
                      {error.repairFoundAfterRepairRemoval.status && (
                        <Form.Text className="text-danger">
                          {error.repairFoundAfterRepairRemoval.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="repairDepthAfterRepairRemoval"
                        name="repairDepthAfterRepairRemoval"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={weldRepairObj.repairDepthAfterRepairRemoval}
                        readOnly={currentrole !== SupervisorRole}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isExperience(e.target.value);
                          setError((prevState) => ({
                            ...prevState,
                            repairDepthAfterRepairRemoval: error,
                          }));
                        }}
                      />
                      <label htmlFor="repairDepthAfterRepairRemoval">
                        Repair Depth in mm
                      </label>
                      </div>
                      <div className="required-text-selectWeldRepair">
                      {error.repairDepthAfterRepairRemoval.status && (
                        <Form.Text className="text-danger">
                          {error.repairDepthAfterRepairRemoval.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="defectiveProcessAfterRepairRemoval"
                        name="defectiveProcessAfterRepairRemoval"
                        type="text"
                        maxLength="100"
                        placeholder=" "
                        value={weldRepairObj.defectiveProcessAfterRepairRemoval}
                        // readOnly={currentrole !== SupervisorRole}
                        readOnly
                        onChange={handleChange}
                      />
                      <label htmlFor="defectiveProcessAfterRepairRemoval">
                        Defective Process
                      </label>
                      </div>
                      <div className="required-text-selectWeldRepair">                      
                      {error.defectiveProcessAfterRepairRemoval.status && (
                        <Form.Text className="text-danger">
                          {error.defectiveProcessAfterRepairRemoval.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="g-input">
                    <div className="d-grid align-items-center">
                      <Form.Control
                        id="weldingJointDetailAfterRepairRemoval"
                        name="weldingJointDetailAfterRepairRemoval"
                        type="text"
                        maxLength="50"
                        placeholder=" "
                        value={
                          weldRepairObj.weldingJointDetailAfterRepairRemoval
                        }
                        readOnly={currentrole !== SupervisorRole}
                        onChange={handleChange}
                        onBlur={(e) => {
                          let error = isAlphanumericWithSpaceDotDashAmpersand(
                            e.target.value
                          );
                          setError((prevState) => ({
                            ...prevState,
                            weldingJointDetailAfterRepairRemoval: error,
                          }));
                        }}
                      />
                      <label htmlFor="weldingJointDetailAfterRepairRemoval">
                        Welding Joint Detail
                      </label>
                      </div>
                      <div className="required-text-selectWeldRepair">
                      {error.weldingJointDetailAfterRepairRemoval.status && (
                        <Form.Text className="text-danger">
                          {error.weldingJointDetailAfterRepairRemoval.message}
                        </Form.Text>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
          <div className="weldRepairSecondDiv">
            <Row>
              <span className="WPS-blue-text" style={{position:"relative", top:"10px"}} >Root Cause Analysis</span>
              <Col md={12}>
                <div className="T-input w-100">
                  <Form.Control
                    id="rootCauseAnalysisWhy1"
                    name="rootCauseAnalysisWhy1"
                    style={{ height: "100px" }}
                    type="text"
                    as="textarea"
                    rows={3}
                    maxLength={500}
                    placeholder=" "
                    value={weldRepairObj.rootCauseAnalysisWhy1}
                    readOnly={currentrole !== SupervisorRole}
                    onChange={handleChange}
                    onBlur={(e) => {
                      let error =
                        isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        rootCauseAnalysisWhy1: error,
                      }));
                    }}
                  />
                  <label htmlFor="rootCauseAnalysisWhy1">Why-1</label>
                  </div>
                  <div className="required-text-selectWeldRepair" style={{position:"relative",bottom:"8px"}}>
                  {error.rootCauseAnalysisWhy1.status && (
                    <Form.Text className="text-danger">
                      {error.rootCauseAnalysisWhy1.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col md={12}>
                <div className="T-input w-100">
                  <Form.Control
                    id="rootCauseAnalysisWhy2"
                    name="rootCauseAnalysisWhy2"
                    type="text"
                    as="textarea"
                    rows={3}
                    style={{ height: "100px" }}
                    maxLength={500}
                    placeholder=" "
                    value={weldRepairObj.rootCauseAnalysisWhy2}
                    readOnly={currentrole !== SupervisorRole}
                    onChange={handleChange}
                    onBlur={(e) => {
                      let error =
                        isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        rootCauseAnalysisWhy2: error,
                      }));
                    }}
                  />
                  <label htmlFor="rootCauseAnalysisWhy2">Why-2</label>
                </div>
              </Col>
              <Col md={12}>
                <div className="T-input w-100">
                  <Form.Control
                    id="rootCauseAnalysisWhy3"
                    name="rootCauseAnalysisWhy3"
                    style={{ height: "100px" }}
                    type="text"
                    as="textarea"
                    rows={3}
                    maxLength={500}
                    placeholder=" "
                    value={weldRepairObj.rootCauseAnalysisWhy3}
                    readOnly={currentrole !== SupervisorRole}
                    onChange={handleChange}
                    onBlur={(e) => {
                      let error =
                        isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        WPQ_Page2_Notes: error,
                      }));
                    }}
                  />
                  <label htmlFor="rootCauseAnalysisWhy3">Why-3</label>
                </div>
              </Col>
              <Col md={12}>
                <div className="T-input w-100">
                  <Form.Control
                    id="rootCauseAnalysisWhy4"
                    name="rootCauseAnalysisWhy4"
                    style={{ height: "100px" }}
                    type="text"
                    as="textarea"
                    rows={3}
                    maxLength={500}
                    placeholder=" "
                    value={weldRepairObj.rootCauseAnalysisWhy4}
                    readOnly={currentrole !== SupervisorRole}
                    onChange={handleChange}
                    onBlur={(e) => {
                      let error =
                        isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        rootCauseAnalysisWhy4: error,
                      }));
                    }}
                  />
                  <label htmlFor="rootCauseAnalysisWhy4">Why-4</label>
                </div>
              </Col>
              <Col md={12}>
                <div className="T-input w-100">
                  <Form.Control
                    id="rootCauseAnalysisWhy5"
                    name="rootCauseAnalysisWhy5"
                    style={{ height: "100px" }}
                    type="text"
                    as="textarea"
                    rows={3}
                    maxLength={500}
                    placeholder=" "
                    value={weldRepairObj.rootCauseAnalysisWhy5}
                    readOnly={currentrole !== SupervisorRole}
                    onChange={handleChange}
                    onBlur={(e) => {
                      let error =
                        isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                          e.target.value
                        );
                      setError((prevState) => ({
                        ...prevState,
                        rootCauseAnalysisWhy5: error,
                      }));
                    }}
                  />
                  <label htmlFor="rootCauseAnalysisWhy5">Why-5</label>
                </div>
              </Col>
            </Row>
          </div>
          <div className="weldRepairFirstDiv">
            <div className="weldRepairSecondDiv">
              <Row>
                <Col md={12}>
                  <div className="T-input w-100">
                    <Form.Control
                      id="rootCauseAnalysisOthers"
                      name="rootCauseAnalysisOthers"
                      type="text"
                      as="textarea"
                      rows={3}
                      style={{ height: "100px" }}
                      maxLength={500}
                      placeholder=" "
                      value={weldRepairObj.rootCauseAnalysisOthers}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error =
                          isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                            e.target.value
                          );
                        setError((prevState) => ({
                          ...prevState,
                          rootCauseAnalysisOthers: error,
                        }));
                      }}
                    />
                    <label htmlFor="rootCauseAnalysisOthers">Others</label>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="T-input w-100">
                    <Form.Control
                      id="rootCauseAnalysisRootCause"
                      name="rootCauseAnalysisRootCause"
                      type="text"
                      as={"textarea"}
                      rows={3}
                      style={{ height: "100px" }}
                      maxLength={500}
                      placeholder=" "
                      value={weldRepairObj.rootCauseAnalysisRootCause}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error =
                          isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                            e.target.value
                          );
                        setError((prevState) => ({
                          ...prevState,
                          rootCauseAnalysisOthers: error,
                        }));
                      }}
                    />
                    <label htmlFor="rootCauseAnalysisRootCause">
                      Root Cause
                    </label>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="T-input w-100">
                    <Form.Control
                      id="rootCauseAnalysisCorrectiveAction"
                      name="rootCauseAnalysisCorrectiveAction"
                      type="text"
                      as={"textarea"}
                      rows={3}
                      style={{ height: "100px" }}
                      maxLength={500}
                      placeholder=" "
                      value={weldRepairObj.rootCauseAnalysisCorrectiveAction}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error =
                          isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                            e.target.value
                          );
                        setError((prevState) => ({
                          ...prevState,
                          rootCauseAnalysisOthers: error,
                        }));
                      }}
                    />
                    <label htmlFor="rootCauseAnalysisCorrectiveAction">
                      Corrective Action
                    </label>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="T-input w-100">
                    <Form.Control
                      id="rootCauseAnalysisPreventionAction"
                      name="rootCauseAnalysisPreventionAction"
                      type="text"
                      as={"textarea"}
                      rows={3}
                      style={{ height: "100px" }}
                      maxLength={500}
                      placeholder=" "
                      value={weldRepairObj.rootCauseAnalysisPreventionAction}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error =
                          isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                            e.target.value
                          );
                        setError((prevState) => ({
                          ...prevState,
                          rootCauseAnalysisOthers: error,
                        }));
                      }}
                    />
                    <label htmlFor="rootCauseAnalysisPreventionAction">
                      Preventive Action
                    </label>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="T-input w-100">
                    <Form.Control
                      id="rootCauseAnalysisKaizenIdea"
                      name="rootCauseAnalysisKaizenIdea"
                      type="text"
                      as={"textarea"}
                      rows={3}
                      style={{ height: "100px" }}
                      maxLength={500}
                      placeholder=" "
                      value={weldRepairObj.rootCauseAnalysisKaizenIdea}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error =
                          isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                            e.target.value
                          );
                        setError((prevState) => ({
                          ...prevState,
                          rootCauseAnalysisOthers: error,
                        }));
                      }}
                    />
                    <label htmlFor="rootCauseAnalysisKaizenIdea">
                      Kaizen Idea
                    </label>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="T-input w-100">
                    <Form.Control
                      name="rootCauseAnalysisCounterMeasure"
                      id="rootCauseAnalysisCounterMeasure"
                      type="text"
                      as={"textarea"}
                      rows={3}
                      style={{ height: "100px" }}
                      maxLength={500}
                      placeholder=" "
                      value={weldRepairObj.rootCauseAnalysisCounterMeasure}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error =
                          isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                            e.target.value
                          );
                        setError((prevState) => ({
                          ...prevState,
                          rootCauseAnalysisCounterMeasure: error,
                        }));
                      }}
                    />
                    <label htmlFor="rootCauseAnalysisCounterMeasure">
                      Counter Measure
                    </label>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="T-input w-100">
                    <Form.Control
                      name="costOfRepair"
                      id="costOfRepair"
                      type="text"
                      as={"textarea"}
                      rows={3}
                      style={{ height: "100px" }}
                      maxLength={500}
                      placeholder=" "
                      value={weldRepairObj.costOfRepair}
                      readOnly={currentrole !== SupervisorRole}
                      onChange={handleChange}
                      onBlur={(e) => {
                        let error =
                          isAlphanumericWithDotSpaceDashCommaBracketsAmpersand(
                            e.target.value
                          );
                        setError((prevState) => ({
                          ...prevState,
                          costOfRepair: error,
                        }));
                      }}
                    />
                    <label htmlFor="costOfRepair">Cost of repair</label>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {(action === "Edit" || action === "View") && (
            <div
              id="closureRem"
              name="closureRem"
              className="weldRepairSecondDiv"
            >
              <Row>
                <label htmlFor="closureRem">Closure Remarks </label>
                <ClosureRemarksWeldingRepair
                  rowData={weldRepairObj}
                  ExperienceNewRev={weldRepairObj.reportNumber}
                  callReload={callReload}
                  setMainReload={props.setMainReload}
                  remarksData={(e, closureparams, weldingparams, levelname) =>
                    getRemarks(e, closureparams, weldingparams, levelname)
                  }
                  errorData={error}
                  action={action}
                />
              </Row>
            </div>
          )}
        </div>
        <div className="weldRepairSecondDiv">
          <Row>
            <Col lg={6}>
              <Button variant="outline-success" type="button" className="me-2"  onClick={() => {
                      handlecancel()
                        }}>
                Cancel
              </Button>
            </Col>
            <Col lg={3}></Col>
            <Col lg={3}>
              <span>
                {action === "Edit" && currentrole === SupervisorRole && (
                  <Button
                    name="tempsave"
                    id="tempsave"
                    variant="outline-success"
                    type="submit"
                    className="me-2"
                  >
                    Temp Save
                  </Button>
                )}
                <Button type="submit" className="btn-add-color float-end  me-2">
                  Save
                </Button>
              </span>
            </Col>
          </Row>
        </div>
        <RenderIf isTrue={popUp.type === "Multiple" && showForm}>
          <MultiValuePopUp
            show={showForm}
            onHide={() => setShowForm(false)}
            setSelected={setPopUpData}
            masterName={popUp.name}
            paramkey={popUp.key}
            paramvalue={popUp.value}
            backdrop="static"
          />
        </RenderIf>
      </Form>
    </>
  );
});

export default WeldingRepairAnalysisEntry;
