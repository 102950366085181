import axios from "axios";

let WI_API_URL;
let PROD_API_URL;
let VALIDATE_API_URL;
let WPQ_Linking_API_URL;


if (process.env.REACT_APP_NODE_ENV === "local") {
  WI_API_URL = process.env.REACT_APP_WI_API_URL;
  PROD_API_URL = process.env.REACT_APP_PROD_API_URL;
  VALIDATE_API_URL = process.env.REACT_APP_VALID_API_URL;
  WPQ_Linking_API_URL = process.env.REACT_APP_LINKING_API_URL;

} else {
  WI_API_URL = process.env.REACT_APP_API_URL + "wi";
  PROD_API_URL = process.env.REACT_APP_API_URL + "wi/prod";
  VALIDATE_API_URL = process.env.REACT_APP_API_URL + "wi/wiValidate";
  WPQ_Linking_API_URL = process.env.REACT_APP_API_URL + "wi/wpqlinking";

}

console.log("WI service", WI_API_URL);



export async function getallwelders() {
  try {
    const response = await axios.get(WI_API_URL + "/getallWelder");
    return response.data;
  } catch (error) {
    throw new Error("Unable to find request");
  }
}

export async function getwiwelders() {
  let url = `${WI_API_URL}/getwidata/excel`;
  console.log("WI service 1---", url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getwiwelderswcr() {
  let url = `${WI_API_URL}/getwidata/wcr/excel`;
  console.log("WI service 1---", url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getallWeldersearch() {
  
  let url = `${WI_API_URL}/getallWelder/search`;
  console.log("WI servhghgj---", url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getallWelderid(id) {
  let url = `${WI_API_URL}/getallWelderid/${id}`;
  console.log(url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getCount() {
  
  const response = await axios
    .get(WI_API_URL + "/getcount")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const addwelder = async (welder) => {
  
  console.log("WI -- ", welder);
  console.log(JSON.stringify(welder));
  let url = `${WI_API_URL}/addwelder`;
  const response = await axios
    .post(url, welder)
    .then(function (resp) {
      console.log("reeee", resp)
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("reeeeeeeeeeeeeee", response.data)
  return response.data;
};

export const UpdateSlip = async (row) => {
  
  let url = `${WI_API_URL}/updateslip`;
  const response = await axios
    .post(url, row)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const CancelSlip = async (slip) => {
  
  let url = `${WI_API_URL}/cancelslip`;
  const response = await axios
    .post(url, slip)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export const DeleteSlip = async (slip) => {
  
  console.log("slippppppp", slip, slip.SK)
  let url = `${WI_API_URL}/deleteslip`;
  const response = await axios
    .post(url, slip)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function WIexportreport(wi) {
  console.log("WIexportreport_wi", wi);
  
  let url = `${WI_API_URL}/exportreport`;
  console.log("widddddddd", url);
  const resp = await axios
    .post(url, wi)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("response12", resp);

  return resp;
}


export async function WCRexportreport(wi) {
  console.log("WCRexportreport", wi);
  
  let url = `${WI_API_URL}/WCRexportreport`;
  console.log("widddddddd", url);
  const resp = await axios
    .post(url, wi)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("response12", resp);

  return resp;
}

//below prod screen and report servises started
export async function getAllOnlySlipNos() {
  
  const response = await axios
    .get(PROD_API_URL + "/getAllSlipNos")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  console.log("getAllOnlySlipNos_response.data", response.data);
  return response.data;
}

export async function getWIDataHeader(wiheader) {
  let url = `${PROD_API_URL}/slipNo`;
  console.log("wcsheader", url);
  const resp = await axios
    .post(url, wiheader)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("response12", resp);
  return resp;
}

export const addNewProductivity = async (prodMeasure) => {
  let url = `${PROD_API_URL}/addNewProductivity`;
  const response = await axios
    .post(url, prodMeasure)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function PRODexportreport(wi) {
  console.log("PRODexportreport_wi", wi);
  
  let url = `${PROD_API_URL}/exportreportPROD`;
  console.log("PRODexportreport_then", url);
  const resp = await axios
    .post(url, wi)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}

export async function getProdMeasure(slipId) {
  let url = `${PROD_API_URL}/slipId`;
  console.log("slipId", url);
  const resp = await axios
    .post(url, slipId)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}

export async function checkJointNoPresent(gridDetail) {
  console.log("checkJointNoPresent", gridDetail);
  let url = `${VALIDATE_API_URL}/checkJointNo`;
  console.log("checkJointNoPresent_url", url);
  const resp = await axios
    .post(url, gridDetail)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  console.log("checkJointNoPresent_resp", resp);
  return resp;
}

export async function getForFilters() {
  let url = `${WI_API_URL}/getForFilters/inReport`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export const addwpqlink = async (link) => {
  let url = `${WPQ_Linking_API_URL}/addlinking`;
  const response = await axios
    .post(url, link)
    .then(function (resp) {
      console.log("reeee", resp)
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
};

export async function getwpqlink() {
  let url = `${WPQ_Linking_API_URL}/getlinking`;
  console.log("getlinking---", url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function linkbyproject(link) {
  
  let url = `${WPQ_Linking_API_URL}/link/project`;
  console.log("linkbyproject", url);
  const resp = await axios
    .post(url, link)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}


export async function linkbyprojecttype(projectno,type) {
  
  let url = `${WPQ_Linking_API_URL}/link/byprojecttype/${projectno}/${type}`;
  console.log("linkbyproject", url);
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}


export async function linkbyprojecttypetube(type) {
  
  let url = `${WPQ_Linking_API_URL}/link/byprojecttypetube/${type}`;
  console.log("linkbyproject", url);
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.message;
    });
  return resp;
}
export async function getAllWiProjects() {
  let url = `${WI_API_URL}/getAllWiProjects`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getWIweldingprocess(projectNumber, jointNumber) {
  let keyObj = {
    projectNumber: projectNumber,
    jointNumber: jointNumber
  }
  let url = `${WI_API_URL}/getWiWeldingProcessProjectJointWise`;
  const response = await axios
    .post(url, keyObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}

export async function getWiWeldingNumbersProjJointProcessWise(projectNumber, jointNumber, processNumber) {
  let keyObj = {
    projectNumber: projectNumber,
    jointNumber: jointNumber,
    processNumber: processNumber
  }

  let url = `${WI_API_URL}/getWiWeldingNumbersProjJointProcessWise`;
  const response = await axios
    .post(url, keyObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export async function getWIReqCount(ReqCount) {
  
  console.log("WIReqCount", ReqCount);
  let url = `${WI_API_URL}/getJointNumbersLength/${ReqCount}`;

  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}


export async function getListofProjectno() {
  console.log(
    "getListofProjectno",
    WI_API_URL + "/getListofProjectno"
  );
  const response = await axios
    .get(WI_API_URL + "/getListofProjectno")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { message: "Unable to find request", status: false };
    });
  return response.data;
}