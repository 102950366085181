import { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { getCurrentDateTime } from "../../functions/welding-functions";
import {
  tableHeader,
  prodReportHeader,
  WQChartHeader,
  WQChartHeaderTTS,
  WQChartHeaderOverlay,
} from "./WQChartExcel_Headers";

export async function ExportToExcel(
  fileName,
  colwidth,
  excelData,
  Data,
  titleCell,
  titleWelderRange,
  tbodyHeadRange,
  tbodyRange,
  blankRow,
  Headings,
  sheetName,
  reporName,
  type
) {
 /*  console.log("fileName", fileName);
  console.log("colwidth", colwidth);
  console.log("excelData", excelData);
  console.log("Data", Data);
  console.log("titleCell", titleCell);
  console.log("titleWelderRange", titleWelderRange);
  console.log("tbodyHeadRange", tbodyHeadRange);
  console.log("tbodyRange", tbodyRange);
  console.log("blankRow", blankRow);
  console.log("Headings", Headings);
  console.log("sheetName", sheetName);
  console.log("reporName", reporName); */
  let bodyRange;

  let wb = XLSX.utils.book_new();
  let data;
  // var todayDate = getCurrentDateTime("-").substring(0, 10);
  if (reporName === "ProdReport") {
    console.log("inside_ProdReport");
    data = [...prodReportHeader, ...Data];
  } else if (reporName === "WQChartReport") {
    console.log("inside_elseif WQChart");
if(type === "Tubesheet"){
  data = [...WQChartHeaderTTS, ...Data];

}else if ( type === "Overlay"){
    data = [...WQChartHeaderOverlay, ...Data];
}
else{
  data = [...WQChartHeader, ...Data];

}
  } else {
    console.log("inside_else");
    data = [...tableHeader, ...Data];
  }

  let alldata = [""]
    .concat([""])
    .concat([""])
    .concat([""])
    .concat([""])
    .concat(data);

  console.log("alldataalldata", alldata);
  let ws = XLSX.utils.json_to_sheet(alldata, { skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  if (reporName === "ProdReport") {
    console.log("ProdReport", tbodyRange, alldata.length);
    bodyRange = `${tbodyRange}:R${alldata.length}`;
  } else if (reporName === "WQChartReport") {
    console.log("reporName === WQChartReport", reporName);
    if(type === "Tubesheet"){
      bodyRange = `${tbodyRange}:AG${alldata.length}`;
    }
    else if(type === "Overlay"){
      bodyRange = `${tbodyRange}:Z${alldata.length}`;
      }
    else{
    bodyRange = `${tbodyRange}:AC${alldata.length}`;
    }
  } else {
    console.log("ListReport", tbodyRange, alldata.length);
    bodyRange = `${tbodyRange}:L${alldata.length}`;
  }
  console.log("bodyRange1111", bodyRange);

  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  const dataInfo1 = {
    titleCell: titleCell,
    titleWelderRange: titleWelderRange,
    tbodyHeadRange: tbodyHeadRange,
    // tbodyRange: `${tbodyRange}:L${alldata.length}`,
    tbodyRange: bodyRange,
    blankRow: blankRow,
  };

  console.log("dataInfo1", dataInfo1);

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    let sheet = workbook.sheets()[0];

    sheet.row(5).style("wrapText", true);

    sheet.gridLinesVisible(false);

    sheet.freezePanes(0, 2);
    //  console.log("Excel",sheet)
    for (var i = 0; i < colwidth.length; i++) {
      console.log("Excel", colwidth[i]);
      sheet.column(colwidth[i].col).width(colwidth[i].width);
    }

    for (var i = 0; i < Headings.length; i++) {
      console.log("Excelllllll", Headings[i]);
      sheet.cell(Headings[i].col).value(Headings[i].value);
    }

    sheet.range(dataInfo1.titleWelderRange).style({
      bold: true,
      underline: true,
    });

    sheet.range(dataInfo1.tbodyHeadRange).style({
      bold: true,
      horizontalAlignment: "center",
      border: "thin",
    });
    sheet.range(dataInfo1.tbodyRange).style({
      border: "thin",
    });

    return workbook
      .outputAsync()
      .then((workbookBlob) => URL.createObjectURL(workbookBlob))
      .then((url) => {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute("download", fileName);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
  });
}

const s2ab = (s) => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length);
  //create a 8 bit integer array
  const view = new Uint8Array(buf);
  //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }
  return buf;
};
