import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import calenderIcon from "../../icons/calendericon.svg";

export var tableHeader = [
  {
    A: "",
    B: "Project",
    C: "Slip Date",
    D: "Plant",
    E: "Slip No ",
    F: "Cancelled",
    G: "Joint",
    H: "WPS No",
    I: "Process",
    J: "Type",
    K: "Welder Code",
    L: "Welder Name",
  },
];

export var prodReportHeader = [
  {
    A: "",
    B: "Project",
    C: "Slip Date",
    D: "Plant",
    E: "Slip No ",
    F: "Cancel Status",
    G: "Joint Type",
    H: "WPS",
    I: "Process",
    J: "Type",
    K: "Welder Code",
    L: "Welder Name",
    M: "Slip Created By",
    N: "Item Code",
    O: "Item Desc",
    P: "Item Group",
    Q: "WCinKgs",
    R: "WMinKgs",
  },
];

export var WQChartHeader = [
  {
    A: "",
    B: "Welder Code",
    C: "WPQ No",
    D: "Welder Name",
    E: "Process",
    F: "Type",
    G: "Valid Upto",
    H: "P No to P No Base Material",
    I: "F No",
    J: "Groove Plate Pipe above 610mm",
    K: "Groove Pipe 73mm to 610mm",
    L: "Groove Pipe less than 73mm",
    M: "Fillet Plate Pipe above 610mm",
    N: "Fillet Pipe 73mm to 610mm",
    O: "Fillet Pipe less than 73mm",
    P: "Max Weld Metal Thickness in mm",
    Q: "Minimum Pipe Diameter in mm",
    R: "Backing",
    S: "Backing Gas",
    T: "Welding Progression",
    U: "Inspection",
    V: "Arc Transfer Mode",
    W: "Direct Remote Visual Control",
    X: "Automatic Joint Tracking",
    Y: "With/Without filler wire",
    Z: "Consumable Insert",
    AA: "Filler Metal Product Form (GTAW)",
    AB: "Current/Polarity",
    AC: "Single Pass/Multiple Pass per side (Machine)",
  },
];

export var WQChartHeaderOverlay = [
  {
    A: "",
    B: "Welder Code",
    C: "WPQ No",
    D: "Welder Name",
    E: "Process",
    F: "Type",
    G: "Valid Upto",
    H: "P No to P No Base Material",
    I: "F No",
    J: "Overlay Plate Pipe above 610mm",
    K: "Overlay Pipe 73mm to 610mm",
    L: "Overlay Pipe less than 73mm",
    M: "Minimum Base Metal thk for overlay in mm",
    N: "Minimum Pipe Diameter in mm",
    O: "Backing",
    P: "Backing Gas",
    Q: "Welding Progression",
    R: "Inspection",
    S: "Arc Transfer Mode",
    T: "Direct Remote Visual Control",
    U: "Automatic Joint Tracking",
    V: "With/Without filler wire",
    W: "Consumable Insert",
    X: "Filler Metal Product Form (GTAW)",
    Y: "Current/Polarity",
    Z: "Single Pass/Multiple Pass per side (Machine)",
  },
];

export var WQChartHeaderTTS = [
  {
    A: "",
    B: "Welder Code",
    C: "WPQ No",
    D: "Welder Name",
    E: "Process",
    F: "Type",
    G: "Valid Upto",
    H: "Width of Ligament",
    I: "Joint Type",
    J: "Tube Diameter in mm",
    K: "Tube Thickness in mm",
    L: "Range Qualified Tube Thickness",
    M: "Range Qualified Tube Diameter",
    N: "Tube Pitch",
    O: "Groove Depth",
    P: "Tube Projection",
    Q: "Width of Ligament",
    R: "Pattern",
    S: "Groove Angle/Radius",
    T: "P no",
    U: "F no",
    V: "A no",
    W: "PrePlaced Filler Metal ",
    X: "With/Without Filler Metal",
    Y: "Filler Metal Product Form",
    Z: "Inserts",
    AA: "No of Passes",
    AB: "Position",
    AC: "Progression",
    AD: "Current and Polarity",
    AE: "Automatic Arc Voltage Control System",
    AF: "Amperage(Range Qualified)-root pass",
    AG: "Amperage(Range Qualified)-final pass",

  },
];

export const Date_Input = ({
  label,
  className,
  name,
  sx,
  value,
  todayDate,
  setobj,
  obj,
  datevalue,
  setdatevalue,
  divClass,
  status,
  message,
  maxDate,
  disabled,
  readOnly,
  setError,
  handleDateChange,
  onChange,
  mindate,
  fromdate,
  todate,
}) => {
  console.log("foaooao", obj);
  console.log("setwiobj11:", setobj);
  console.log("datevalue:", datevalue);

  function MuiIcon() {
    return <Image src={calenderIcon} />;
  }

  return (
    <>
      <div className="groove_date">
        <div class={divClass}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                slots={{ openPickerIcon: MuiIcon }}
                label={label}
                className={className}
                format="DD-MM-YYYY"
                name={name}
                sx={sx}
                disabled={disabled}
                readOnly={readOnly}
                value={
                  datevalue === false || obj[name] === ""
                    ? null
                    : obj && obj[name]
                    ? dayjs(obj[name])
                    : dayjs(todayDate)
                }
                maxDate={dayjs(maxDate)}
                onChange={(e) => {
                  console.log("naaaaame", name);
                  let date = dayjs(e).format("YYYY-MM-DD");

                  setobj((prev) => ({
                    ...prev,
                    [name]: date,
                  }));

                  if (name === "WQC_From_Date") {
                    if (todate) {
                      if (date > todate) {
                        console.log(
                          "dateeeemin",
                          date > todate,
                          obj[name],
                          date,
                          todate
                        );
                        setError((prevState) => ({
                          ...prevState,
                          [name]: {
                            status: true,
                            // message: "Selected date cannot be greater than To date",
                          },
                        }));
                        toast.warning(
                          "Selected date cannot be greater than To date",
                          {
                            position: toast.POSITION.TOP_LEFT,
                            className: "toast-message",
                          }
                        );
                        //  setdatevalue(false);
                        console.log("vall", datevalue);

                        setobj((prev) => ({
                          ...prev,
                          [name]: date,
                        }));
                      }
                    } else {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: false,
                          message: "",
                        },
                      }));
                    }
                  }

                  if (name === "WQC_To_Date") {
                    if (date < fromdate) {
                      console.log(
                        "dateeeemin",
                        date < fromdate,
                        obj[name],
                        date
                      );
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: true,
                          // message: "Selected date cannot be less than From date",
                        },
                      }));
                      toast.warning(
                        "Selected date cannot be less than From date",
                        {
                          position: toast.POSITION.TOP_LEFT,
                          className: "toast-message",
                        }
                      );
                      setdatevalue(false);
                      console.log("vall", datevalue);
                    } else {
                      setError((prevState) => ({
                        ...prevState,
                        [name]: {
                          status: false,
                          message: "",
                        },
                      }));
                    }
                  }

                  if (name !== "WQC_To_Date" && name !== "WQC_From_Date") {
                    setobj((prev) => ({
                      ...prev,
                      [name]: date,
                    }));

                    setError((prevState) => ({
                      ...prevState,
                      [name]: {
                        status: false,
                        message: "",
                      },
                    }));
                  }
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </div>
      <div className="required-text-select mt-2">
        {status && <Form.Text className="text-danger">{message}</Form.Text>}
      </div>
    </>
  );
};
