import { todayDate } from "../../utility/CommonComponents/TodayDate";
let username = sessionStorage.getItem("UserName");

export const MessageTypes = {
  Success: "Success",
  Fail: "Failure",
  Required: "Required",
  RequiredAll: "RequiredAll",
  Temp: "Temp",
  Band: "Band",
  None: "None",
};

export var WPS_Groove_Detail = [
  { Column: "WPSD_WP_MinimumBeadLength", Type: "A" },
  { Column: "WPSD_WP_MinimumPreheatTemp", Type: "S" },

  { Column: "WPSD_WP_MaximumInterpassTemp", Type: "S" },
  { Column: "WPSD_WP_WeldConsumable", Type: "A" },
  { Column: "WPSD_WP_TungstenSIze", Type: "S" },
  { Column: "WPSD_WP_TungstenType", Type: "S" },
  { Column: "WPSD_WP_FillerMetalProductForm", Type: "S" },
  { Column: "WPSD_WP_ShieldingFlowRate", Type: "S" },
  { Column: "WPSD_WP_ShieldingGasType", Type: "S" },

  { Column: "WPSD_WP_BackingFlowRate", Type: "S" },
  { Column: "WPSD_WP_BackingGasType", Type: "S" },

  { Column: "WPSD_WP_TrailingFlowRate", Type: "S" },
  { Column: "WPSD_WP_TrailingGasType", Type: "S" },
  { Column: "WPSD_WP_ArcTransferMode", Type: "S" },
  { Column: "WPSD_WP_StringOrWeave", Type: "S" },
  { Column: "WPSD_WP_Orifice_GasCupSize", Type: "S" },
  { Column: "WPSD_WP_CTWD", Type: "S" },
  { Column: "WPSD_WP_Pulsing_current", Type: "S" },
  // { Column: "WPSD_WP_PositionOfFillet", Type: "S" },
  { Column: "WPSD_WP_Multiple_SinglePassPerSide", Type: "S" },
  { Column: "WPSD_WP_Multiple_SingleLayer", Type: "S" },
  { Column: "WPSD_WP_Multi_SingleElectrode", Type: "S" },
  { Column: "WPSD_WP_ElectrodeSpacing_Late", Type: "S" },
  { Column: "WPSD_WP_ElectrodeSpacing_Logit", Type: "S" },

  // { Column: "WPSD_WP_Elec_char_others", Type: "S" },

  { Column: "WPSD_WP_ElectrodeAngleDegree", Type: "S" },

  { Column: "WPSD_WP_FillerMetalSIze", Type: "A" },
  { Column: "WPSD_WP_BatchNumber", Type: "A" },

  { Column: "WPSD_WP_LayerNumber", Type: "A" },
  { Column: "WPSD_WP_PositionOfGroove", Type: "A" },
  { Column: "WPSD_WP_WeldProgression", Type: "A" },
  { Column: "WPSD_WP_CurrentPolarity", Type: "A" },
  { Column: "WPSD_WP_Volts", Type: "A" },
  { Column: "WPSD_WP_TravelSpeed", Type: "A" },
  { Column: "WPSD_WP_MaximumHeatInput", Type: "A" },
  { Column: "WPSD_WP_Amperes", Type: "A" },


  { Column: "WPSD_WP_Max_Pass_Thickness", Type: "S" },
];

export var wps_field_Val = [
  { Column: "WPSD_WP_FillerMetalSIze", Type: "A" },
  { Column: "WPSD_WP_BatchNumber", Type: "A" },
  { Column: "WPSD_WP_PositionOfFillet", Type: "A" },
  { Column: "WPSD_WP_Amperes", Type: "A" },
  // { Column: "WPSD_WP_Amperes1", Type: "A" },
  { Column: "WPSD_WP_Volts", Type: "A" },
  { Column: "WPSD_WP_TravelSpeed", Type: "A" },
  { Column: "WPSD_WP_MinimumBeadLength", Type: "A" },
  { Column: "WPSD_WP_MaximumHeatInput", Type: "A" },
  // { Column: "WPSD_WP_ElectrodeAngleDegree", Type: "A" }
 
];

export var wps_field_Val_Overlay = [
  { Column: "WPSD_WP_FillerMetalSIze", Type: "A" },
  { Column: "WPSD_WP_BatchNumber", Type: "A" },
  { Column: "WPSD_WP_Amperes", Type: "A" },
  { Column: "WPSD_WP_Amperes1", Type: "A" },
  { Column: "WPSD_WP_Volts", Type: "A" },
  { Column: "WPSD_WP_TravelSpeed", Type: "A" },
  { Column: "WPSD_WP_MinimumBeadLength", Type: "A" },
  { Column: "WPSD_WP_MaximumHeatInput", Type: "A" },
  // { Column: "WPSD_WP_ElectrodeAngleDegree", Type: "A" }
 
];

export var wps_field_Val_single = [
  { Column: "WPSD_WP_MinimumPreheatTemp", Type: "S" },

  { Column: "WPSD_WP_MaximumInterpassTemp", Type: "S" },
  { Column: "WPSD_WP_Pulsing_current", Type: "S" },
  { Column: "WPSD_WP_Pulsing_current_others", Type: "S" },
  { Column: "WPSD_WP_ShieldingFlowRate", Type: "S" },
  { Column: "WPSD_WP_ShieldingGasType", Type: "S" },

  { Column: "WPSD_WP_BackingFlowRate", Type: "S" },
  { Column: "WPSD_WP_BackingGasType", Type: "S" },

  { Column: "WPSD_WP_TrailingFlowRate", Type: "S" },
  { Column: "WPSD_WP_TrailingGasType", Type: "S" },
  { Column: "WPSD_WP_StringOrWeave", Type: "S" },
  { Column: "WPSD_WP_Orifice_GasCupSize", Type: "S" },
  { Column: "WPSD_WP_CTWD", Type: "S" },
  { Column: "WPSD_WP_Multiple_SingleLayer", Type: "S" },
  { Column: "WPSD_WP_Multi_SingleElectrode", Type: "S" },
  { Column: "WPSD_WP_ElectrodeSpacing_Late", Type: "S" },
  { Column: "WPSD_WP_ElectrodeSpacing_Logit", Type: "S" },
  { Column: "WPSD_WP_Max_Pass_Thickness", Type: "S" },


 
];

export var WPS_Overlay_Detail = [
  { Column: "WPSD_WP_MinimumPreheatTemp", Type: "S" },
  { Column: "WPSD_WP_MaximumInterpassTemp", Type: "S" },
  { Column: "WPSD_WP_FillerMetalProductForm", Type: "S" },
  { Column: "WPSD_WP_FillerMetalSIze", Type: "A" },
  { Column: "WPSD_WP_WeldConsumable", Type: "A" },
  { Column: "WPSD_WP_BatchNumber", Type: "A" },
  { Column: "WPSD_WP_PositionOfOverlay", Type: "A" },
  { Column: "WPSD_WP_WeldProgression", Type: "A" },
  { Column: "WPSD_WP_CurrentPolarity", Type: "A" },
  { Column: "WPSD_WP_Amperes", Type: "A" },
  { Column: "WPSD_WP_Amperes1", Type: "A" },
  { Column: "WPSD_WP_Volts", Type: "A" },
  { Column: "WPSD_WP_TravelSpeed", Type: "A" },
  { Column: "WPSD_WP_MinimumBeadLength", Type: "A" },
  { Column: "WPSD_WP_MaximumHeatInput", Type: "A" },
  { Column: "WPSD_WP_TungstenSIze", Type: "S" },
  { Column: "WPSD_WP_TungstenType", Type: "S" },
  { Column: "WPSD_WP_ShieldingFlowRate", Type: "S" },
  { Column: "WPSD_WP_ShieldingGasType", Type: "S" },
  { Column: "WPSD_WP_TrailingFlowRate", Type: "S" },
  { Column: "WPSD_WP_TrailingGasType", Type: "S" },
  { Column: "WPSD_WP_ArcTransferMode", Type: "S" },
  { Column: "WPSD_WP_StringOrWeave", Type: "S" },
  { Column: "WPSD_WP_Orifice_GasCupSize", Type: "S" },
  { Column: "WPSD_WP_CTWD", Type: "S" },
  { Column: "WPSD_WP_Multiple_SinglePassPerSide", Type: "S" },
  { Column: "WPSD_WP_Multiple_SingleLayer", Type: "S" },
  { Column: "WPSD_WP_Multi_SingleElectrode", Type: "S" },
  { Column: "WPSD_WP_ElectrodeSpacing_Late", Type: "S" },
  { Column: "WPSD_WP_ElectrodeSpacing_Logit", Type: "S" },
  { Column: "WPSD_WP_ElectrodeAngleDegree", Type: "S" },
  { Column: "WPSD_WP_Weld_Bead_Overlap", Type: "S" },

  // { Column: "WPSD_WP_Pulsing_current", Type: "S" },
  // { Column: "WPSD_WP_PositionOfFillet", Type: "S" },
  // { Column: "WPSD_WP_Elec_char_others", Type: "S" },
  // { Column: "WPSD_WP_Max_Pass_Thickness", Type: "S" },
  // { Column: "WPSD_WP_LayerNumber", Type: "A" },
];

export const WPSHeader = (wpstype) => {
  console.log("WPSHeader_wpstype", wpstype);

  return {
    WPSH_Status: Status.StatusTempSaved,
    WPSH_Approval_Status: Status.StatusTempSaved,
    WPSH_Project_No: "",
    WPSH_WPS_No: "",
    WPSH_WPS_Revi_No: "",
    WPSH_WPS_Date: todayDate,
    WPS_QualifiedTo: "",
    WPSH_CompanyName: "Godrej & Boyce Mfg. Co. Ltd.",
    WPSH_SupportingPQR: "",
    WPSH_RefDoc: "",
    WPSH_Scope: "",
    WPSH_JointDesc: "",
    WPSH_BM_Type_GNo: "",
    WPSH_BM_Type_PNo: "",
    WPSH_BM_Type_Type: "",
    WPSH_MinOvlFinThick: "",
    WPSH_BM_WeldingTo_GNo: "",
    WPSH_BM_WeldingTo_PNo: "",
    WPSH_BM_WeldingTo_Type: "",
    WPSH_BM_Backing_Backing: "",
    WPSH_BM_Type_HT_Con: "",
    WPSH_BM_WeldingTo_HT_Con: "",
    WPSH_BM_Backing_GNo: "",
    WPSH_BM_Backing_PNo: "",
    WPSH_BM_Notes: "",
    WPSH_BM_Retainer: "",
    WPSH_BM_Backing_Material: "",
    WPSH_TRQ_GroovewithoutImpact_Max: "",
    WPSH_TRQ_GroovewithoutImpact_Min: "",
    WPSH_TRQ_GroovewithImpact_Max: "",
    WPSH_TRQ_GroovewithImpact_Min: "",
    WPSH_TRQ_FilletwithoutImpact_Max: "",
    WPSH_TRQ_FilletwithoutImpact_Min: "",
    WPSH_TRQ_FilletwithImpact_Max: "",
    WPSH_TRQ_FilletwithImpact_Min: "",
    WPSH_TRQ_BaseMetalCRO_Min: "",
    WPSH_TRQ_BaseMetalCRO_Max: "",
    WPSH_TRQ_BaseMetalHRO_Max: "",
    WPSH_TRQ_BaseMetalHRO_Min: "",
    WPSH_FM_FluxItemCode: "",
    WPSH_FM_FluxItemBatch: "",
    WPSH_FM_FilerMetal: "",
    WPSH_FM_FluxFromRecrush: "",
    WPSH_FM_FluxType: "",

    WPSH_FM_AlloyFlux: "",
    WPSH_FM_AlloyElement: "",
    WPSH_FM_Add_Del_Filler: "",
    WPSH_FM_Add_Del_Flux: "",
    WPSH_FM_Add_Del_orchange: "",
    WPSH_FM_NominalFlux:"",
    // wld_pdf_image: [],

    // wld_pdf_image: [],

    wld_pdf_image: [
      { Image1: [""] },
      { Image2: [""] },
      { Image3: [""] },
      { Image1Data: [{lastModified:"", lastModifiedDate:"",name:"",size:"",type:"",webkitRelativePath:""}] },
      { Image2Data: [{lastModified:"", lastModifiedDate:"",name:"",size:"",type:"",webkitRelativePath:""}] },
      { Image3Data: [{lastModified:"", lastModifiedDate:"",name:"",size:"",type:"",webkitRelativePath:""}] },



    ],

    Pdf_images: [
      { tube_Hole_ligament: [""] },
      { tube_Hole: [""] },
      { tube_Weld_Joint: [""] },
    ],

    WPSH_PHT_Root_spacing: "",
    // WPSH_PHT_ApplicationStd: "",
    // WPSH_PHT_Desc: "",
    WPSH_PWHeatTre_Temperature: "",
    WPSH_PWHeatTre_Time: "",
    WPSH_PWHeatTre_Heating_Rate: "",
    WPSH_PWHeatTre_Heating_Method: "",
    WPSH_PWHeatTre_Cooling_Rate: "",
    WPSH_PWHeatTre_Cooling_Method: "",
    WPSH_PWHeatTre_LoadingTemp:"",
    WPSH_PWHeatTre_UnloadingTemp:"",
    WPSH_PWHeatTre_PWHTNotes:"",
    WPSH_PWHeatTre_Type: "NA",
    WPSH_PWHeatTre_Wrap_insulation_material: "",
    WPSH_PWHeatTre_Notes: "",
    WPSH_PWHeatTre_Post_Heat_temp: "",
    WPSH_PWHeatTre_Post_Heat_time: "",
    // WPSH_PWHeatTre_Wrap_insulation_material: "",
    WPSH_PWHeatTre_Weld_Time: "",
    WPSH_PWHeatTre_Weld_Notes: "",
    WPSH_PWHeatTre_ISR_Temp: "",
    WPSH_PWHeatTre_ISR_Time: "",
    WPSH_Tech_BackGougingMethod: "",
    WPSH_Tech_ClosedtooutChamber: "",
    WPSH_Tech_GroovePreparation: "",
    WPSH_Tech_Inital_InterpassCleaning: "",
    WPSH_Tech_Peening: "",
    WPSH_Tech_SupplementaryMFControl: "",
    WPSH_Tech_SurfacePreparation: "",
    WPSH_Tech_UseOfThermalProcess: "",
    WPSH_Tech_Weld_deposit_chemistry: "",
    // WPSH_Tech_Change_Electrode_dia:"",
    WPSH_Tech_Oscillation: "",
    WPSH_Notes: "",
    WPSH_PreparedBy_Date: todayDate,
    WPSH_PreparedBy_Name: "",
    WPSH_ApprovedBy_Date: todayDate,
    WPSH_ApprovedBy_Name: "",
    WPSH_ApprovedBy_Remark: "",
    WPSH_PreparedBy_Remark: "",
    WPSH_Prepared_By: sessionStorage.getItem("User"),
    WPSH_UpdatedBy: "",
    WPSH_UpdatedOn: "",
    WPSH_ApprovedBy: sessionStorage.getItem("UserName"),
    WPSH_consumRadio: "Required",
    WPSH_fluxRadio: "Required",
    WPSH_filterRadio: "Required",
    wld_TRQ_Type: "Welded",
    wld_Joints_Type: "Joint section",
    // wld_Approval_Type: Status.StatusPending,
    wld_WPSType: wpstype,
    WPSH_Tech_Oscillation_Width: "",
    WPSH_Tech_Oscillation_Freq: "",
    WPSH_Tech_Filler_Metal_Del: "",
    WPS_Change_No: "0",
    wps_signaturePrepared: "",
    wps_signatureApproved: "",
    SK: "",
    PK: "",
    WPSD_WP_MinimumPreheatTemp_DataLogger: "",
    WPSD_WP_MaximumInterpassTemp_DataLogger: "",
    WPSD_WP_Maximumtravelspeed_DataLogger: "",
    WPSH_WPS_Process: "",
    WPSH_WPS_Type: "",
    WPSH_WPS_WeldLayer: "",
    //below for tubesheet
    WPSH_WPS_SFA: "",
    WPSH_BM_BaseMetal1_ProductForm: "",
    WPSH_BM_BaseMetal1_Spec: "",
    WPSH_BM_BaseMetal1_PNo: "",
    WPSH_BM_BaseMetal1_GNo: "",
    WPSH_BM_BaseMetal1_Thick: "",
    WPSH_BM_BaseMetal1_Diam: "",
    WPSH_BM_WeldBaseMetal2_ProductForm: "",
    WPSH_BM_WeldBaseMetal2_Spec: "",
    WPSH_BM_WeldBaseMetal2_PNo: "",
    WPSH_BM_WeldBaseMetal2_GNo: "",
    WPSH_BM_WeldBaseMetal2_Thick: "",
    WPSH_BM_WeldBaseMetal2_Diam: "",
    WPSH_JD_JointType: wpstype === "Tubesheet" ? "Tube to Tubesheet" : "",
    WPSH_JD_HoleId: "",
    WPSH_JD_TubePitch: "",
    WPSH_JD_GrooveDepth: "",
    WPSH_JD_TubeProj: "",
    WPSH_JD_WidthLigament: "",
    WPSH_JD_Pattern: "",
    WPSH_JD_GrooveAngle: "",
    WPSH_JD_GrooveType: "",
    //Tubesheet Filler Metal fields
    WPSH_FM_PreFillerMetal: "",
    WPSH_FM_WithFillerMetal: "",
    WPSH_FM_FillerProduct: "",
    WPSH_FM_Inserts: "",
    WPSH_FM_FillerFlux: "",
    //Tubesheet Other Variables fields
    WPSH_Tech_Expansion: "",
    WPSH_Tech_StringWave: "",
    WPSH_Tech_MethodofCleaning: "",
    WPSH_Tech_SingleMultiElectrode: "",
    WPSH_Tech_OrifceCupSize: "",
    WPSH_Tech_Others: "",
  };
};

export var WPSPage1_Overlay = {
  WPSH_JointDesc: "",
  WPSH_Project_No: "",
  WPSH_RefDoc: "",
  WPSH_Scope: "",
  WPSH_CompanyName: "Godrej & Boyce Mfg. Co. Ltd",
  WPSH_SupportingPQR: "",
  WPSH_WPS_Date: "",
  WPSH_WPS_No: "",
  WPSH_WPS_Revi_No: "",
  WPS_QualifiedTo: "",
  WPSH_Flux_ANo: "",
  WPSH_BM_Type_Type: "",
  WPSH_BM_Type_GNo: "",
  WPSH_BM_Type_PNo: "",

  WPSH_BM_WeldingTo_Type: "",
  WPSH_BM_WeldingTo_PNo: "",
  WPSH_BM_WeldingTo_GNo: "",
  WPSH_BM_Backing_Material: "",

  // WPSH_BM_Retainer: "",
  WPSH_BM_Notes: "",

  WPSH_TRQ_BaseMetalCRO_Min: "",
  WPSH_TRQ_BaseMetalCRO_Max: "",
  WPSH_TRQ_BaseMetalHRO_Max: "",
  WPSH_TRQ_BaseMetalHRO_Min: "",

  WPSH_FM_Add_Del_orchange: "",
  WPSH_FM_NominalFlux:"",
  WPSH_FM_AlloyElement: "",

  WPSH_FM_FilerMetal: "",
  WPSH_FM_FluxFromRecrush: "",
  WPSH_FM_FluxItemBatch: "",
  WPSH_FM_FluxItemCode: "",
  WPSH_FM_FluxType: "",

  WPSH_consumRadio: "Required",
  WPSH_filterRadio: "Required",
  WPSH_fluxRadio: "Required",
  //wld_Approval_Type: "Pending For Approval",
  wld_Joints_Type: "Joint section",
  wld_TRQ_Type: "Welded",
};

export var WPSPage1 = {
  WPSH_BM_Backing_Backing: "",
  WPSH_BM_Backing_GNo: "",
  WPSH_BM_Backing_Material: "",
  WPSH_BM_Backing_PNo: "",
  WPSH_BM_Notes: "",
  WPSH_BM_Retainer: "",
  WPSH_BM_Type_GNo: "",
  WPSH_BM_Type_PNo: "",
  WPSH_BM_Type_Type: "",
  WPSH_BM_WeldingTo_GNo: "",
  WPSH_BM_WeldingTo_PNo: "",
  WPSH_BM_WeldingTo_Type: "",
  WPSH_CompanyName: "Godrej & Boyce Mfg. Co. Ltd",
  WPSH_FM_Add_Del_Filler: "",
  WPSH_FM_Add_Del_Flux: "",
  WPSH_FM_AlloyElement: "",
  WPSH_FM_AlloyFlux: "",
  WPSH_FM_FilerMetal: "",
  WPSH_FM_FluxFromRecrush: "",
  WPSH_FM_FluxItemBatch: "",
  WPSH_FM_FluxItemCode: "",
  WPSH_FM_FluxType: "",
  WPSH_JointDesc: "",
  WPSH_Project_No: "",
  WPSH_RefDoc: "",
  WPSH_Scope: "",
  WPSH_SupportingPQR: "",
  WPSH_TRQ_FilletwithImpact_Max: "",
  WPSH_TRQ_FilletwithImpact_Min: "",
  WPSH_TRQ_FilletwithoutImpact_Max: "",
  WPSH_TRQ_FilletwithoutImpact_Min: "",
  WPSH_TRQ_GroovewithImpact_Max: "",
  WPSH_TRQ_GroovewithImpact_Min: "",
  WPSH_TRQ_GroovewithoutImpact_Max: "",
  WPSH_TRQ_GroovewithoutImpact_Min: "",
  WPSH_WPS_Date: "",
  WPSH_WPS_No: "",
  WPSH_WPS_Revi_No: "",
  WPSH_consumRadio: "Required",
  WPSH_filterRadio: "Required",
  WPSH_fluxRadio: "Required",
  WPS_QualifiedTo: "",
  //wld_Approval_Type: "Pending For Approval",
  wld_Joints_Type: "Joint section",
  wld_TRQ_Type: "Welded",
};

export var WPSPage3 = {
  WPSH_PWHeatTre_Heating_Method: "",

  WPSH_PWHeatTre_Cooling_Method: "",
};

export var WPSPage2 = {
  WPSD_WP_Maximumtravelspeed_DataLogger: "",

  WPSD_WP_MaximumInterpassTemp_DataLogger: "",
  WPSD_WP_MinimumPreheatTemp_DataLogger: "",
};

export var Filler_Metal_Product_Form = [
  { value: "", name: "Select" },
  { value: "NA", name: "Na" },
  { value: "No Value", name: "No Value" },
];

export var Layer_No = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "As Req.", name: "As Req." },
  { value: "Root", name: "Root" },
  { value: "Hot Pass", name: "Hot Pass" },
  { value: "Fill Up", name: "Fill Up" },
  { value: "Capping", name: "Capping" },
];

export var Position_of_groove = [
  { value: "", name: "Select" },
  { value: "Na", name: "NA" },
  { value: "All", name: "All" },
  { value: "F", name: "F" },
  { value: "F/H", name: "F/H" },
  { value: "F/V", name: "F/V" },
  { value: "F/H/V", name: "F/H/V" },
  { value: "F/H/O", name: "F/H/O" },
];

export var Tungsten_size = [
  { value: "", name: "Select" },
  { value: "Na", name: "NA" },
  { value: "1", name: "1" },
  { value: "1.6", name: "1.6" },
  { value: "2.4", name: "2.4" },
  { value: "3.2", name: "3.2" },
  { value: "2.4/3,2", name: "2.4/3.2" },
];

export var Tungsten_type = [
  { value: "", name: "Select" },
  { value: "Na", name: "NA" },
  { value: "EWTh-2", name: "EWTh-2" },
  { value: "EWCe-2", name: "EWCe-2" },
  { value: "EWLa-2", name: "EWLa-2" },
  { value: "EWZr-8", name: "EWZr-8" },
];

export var Weld_Progression = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Uphill", name: "Uphill" },
  { value: "Downhill", name: "Downhill" },
];

export var Backing = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "With", name: "With" },
  { value: "With/Without", name: "With/Without" },
];

export var Backing_Material = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  {
    value: "Base Metal and/or Weld metal",
    name: "Base Metal and/or Weld metal",
  },
];

export var Retainer = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Not used", name: "Not used" },
  {
    value: "Use of non-metallic retainers",
    name: "Use of non-metallic retainers",
  },
  {
    value: "Use of non fusing metal retainers",
    name: "Use of non fusing metal retainers",
  },
];

export var Base_HT = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "NACT", name: "NACT" },
  { value: "QT", name: "QT" },
  { value: "NT", name: "NT" },
  { value: "N", name: "N" },
  { value: "NACT/NT/QT", name: "NACT/NT/QT" },
  { value: "Solution annealing", name: "Solution annealing" },
];

export var Flux_type = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Neutral", name: "Neutral" },
  { value: "Active", name: "Active" },
];

export var yesno = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Yes", name: "Yes" },
  { value: "No", name: "No" },
];

export var WPSH_Tech_BackGougingMethod = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Gas cutting", name: "Gas cutting" },
  { value: "Machining", name: "Machining" },
  { value: "Grinding", name: "Grinding" },
  {
    value: "Gas cutting & Machining/Grinding",
    name: "Gas cutting & Machining/Grinding",
  },
];

export var WPSH_PWHeatTre_Type = [
  // { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Gas", name: "Gas" },
  { value: "Electric", name: "Electric" },
  { value: "Gas/Electric", name: "Gas/Electric" },
  { value: "Induction", name: "Induction" },
];


export var WPSH_PWHeatTre_Wrap_insulation_material = [
  // { value: "", name: "Select" },
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Yes", name: "Yes" },
  { value: "No", name: "No" },
];



export var wire_brush = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Wire-brush", name: "Wire-brush" },
  { value: "Grinding", name: "Grinding" },
  { value: "Polishing", name: "Polishing" },
  {
    value: "Wire-brush/Grinding/Polishing",
    name: "Wire-brush/Grinding/Polishing",
  },
];

export var TypeData = [
  { wld_type: "Automatic" },
  { wld_type: "Machine" },
  { wld_type: "Manual" },
  { wld_type: "SemiAuto" },
];
export var LayerData = [
  { wld_layer: "Barrier Layer" },
  { wld_layer: "Subsequent Layer(s)" }
];

export var WeldLayer = [
  { WeldLayer: "Barrier" },
  { WeldLayer: "Subsequent" },
  { WeldLayer: "Single" },
];

export const Action = {
  ActionEdit: "Edit",

  ActionCopy: "Copy",

  ActionAdd: "Add",
};

export const Status = {
  StatusPending: "Pending For Approval",
  StatusApproved: "Approved",
  StatusRejected: "Rejected",
  StatusTempSaved: "Temporary Saved",
  StatusFreezed: "Freezed",
};

export var company_name = [
  { value: "Godrej & Boyce Mfg. Co. Ltd", name: "WPSH_CompanyName" },
];

export var Process = [{ value: "GTAW", name: "WPSD_Process" }];

export var joint_type = [
  { value: "Tube to Tubesheet", name: "WPSH_JD_JointType" },
];

export var Wps_Page1_error = {
  WPSH_Project_No: {},
  WPSH_WPS_No: {},
  WPSH_WPS_Revi_No: {},
  WPS_QualifiedTo: {},
  WPSH_SupportingPQR: {},
  WPSH_WPS_Date: {},
  WPSH_RefDoc: {},
  WPSH_Scope: {},
  WPSH_JointDesc: {},
  WPSH_BM_Type_Type: {},
  WPSH_BM_WeldingTo_Type: {},
  WPSH_BM_Type_GNo: {},
  WPSH_BM_Type_PNo: {},
  WPSH_BM_WeldingTo_PNo: {},
  WPSH_BM_WeldingTo_GNo: {},
  WPSH_BM_Backing_Backing: {},
  WPSH_BM_Backing_GNo: {},
  WPSH_BM_Backing_PNo: {},
  WPSH_BM_Retainer: {},
  WPSH_BM_Backing_Material: {},
  WPSH_ConsuInsert_SFA: {},
  WPSH_ConsuInsert_Classification: {},
  WPSH_ConsuInsert_FNo: {},
  WPSH_ConsuInsert_ANo: {},
  WPSH_ConsuInsert_ChemicalAnalysis_TradeName: {},
  WPSH_Flux_SFA: {},
  WPSH_Flux_Classification: {},
  WPSH_Flux_FNo: {},
  WPSH_Flux_ANo: {},
  WPSH_Flux_ChemicalAnalysis_TradeName: {},
  WPSH_SupFiller_SFA: {},
  WPSH_SupFiller_Classification: {},
  WPSH_SupFiller_FNo: {},
  WPSH_SupFiller_ANo: {},
  WPSH_SupFiller_ChemicalAnalysis_TradeName: {},

  WPSH_BM_Notes: {},
  WPSH_TRQ_GroovewithoutImpact_Min: {},
  WPSH_TRQ_GroovewithoutImpact_Max: {},
  WPSH_TRQ_GroovewithImpact_Min: {},
  WPSH_TRQ_GroovewithImpact_Max: {},
  WPSH_TRQ_FilletwithoutImpact_Min: {},
  WPSH_TRQ_FilletwithoutImpact_Max: {},
  WPSH_TRQ_FilletwithImpact_Min: {},
  WPSH_TRQ_FilletwithImpact_Max: {},
  // WPSH_DRQ_PipeTubeDRQ_Min: {},
  // WPSH_DRQ_PipeTubeDRQ_Max: {},
  WPSH_FM_FluxType: {},

  WPSH_FM_AlloyFlux: {},
  WPSH_FM_AlloyElement: {},
  WPSH_FM_Add_Del_Filler: {},
  WPSH_FM_Add_Del_Flux: {},

  WPSH_FM_FluxItemBatch: {},
  WPSH_FM_FluxFromRecrush: {},
  WPSH_FM_FluxItemCode: {},
  WPSH_FM_FilerMetal: {},
  WPSD_FM_Classification: {},
  WPSH_BM_Type_HT_Con: {},
  WPSH_BM_WeldingTo_HT_Con: {},
  WPSD_FM_ChemicalAnalysis_TradeName: {},
  WPSD_FM_TRQ_Min: {},
  WPSD_FM_TRQ_Max: {},
  WPSH_ConsuInsert: [],
  WPSH_Flux: [],
  WPSH_SupFiller: [],

  WPSD_Process: {},
  WPSH_TRQ_BaseMetalCRO_Min: {},
  WPSH_TRQ_BaseMetalCRO_Max: {},
  WPSH_TRQ_BaseMetalHRO_Min: {},
  WPSH_TRQ_BaseMetalHRO_Max: {},

  ////make seperate for Tubesheet_wpdspage
  WPSH_JD_JointType: "",
  WPSH_JD_HoleId: "",
  WPSH_JD_TubePitch: "",
  WPSH_JD_GrooveDepth: "",
  WPSH_JD_TubeProj: "",
  WPSH_JD_WidthLigament: "",
  WPSH_JD_Pattern: "",
  WPSH_JD_GrooveAngle: "",
  WPSH_JD_GrooveType: "",
  WPSH_PreFillerMetal: "",
  WPSH_WithFillerMetal: "",
  WPSH_FillerProduct: "",
  WPSH_Inserts: "",
  WPSH_FillerFlux: "",
  WPSH_FillerOthers: "",
  WPSH_BM_BaseMetal1_ProductForm: "",
  WPSH_BM_BaseMetal1_Spec: "",
  WPSH_BM_BaseMetal1_PNo: "",
  WPSH_BM_BaseMetal1_GNo: "",
  WPSH_BM_BaseMetal1_Thick: "",
  WPSH_BM_BaseMetal1_Diam: "",
  WPSH_BM_WeldBaseMetal2_ProductForm: "",
  WPSH_BM_WeldBaseMetal2_Spec: "",
  WPSH_BM_WeldBaseMetal2_PNo: "",
  WPSH_BM_WeldBaseMetal2_GNo: "",
  WPSH_BM_WeldBaseMetal2_Thick: "",
  WPSH_BM_WeldBaseMetal2_Diam: "",
};

export var process_name = [{ value: "GTAW", name: "WPSD_Process" }];

export var WeldingProcess = [
  { value: "NA", name: "Na" },
  { value: "No Value", name: "No Value" },
  { value: "SMAW", name: "SMAW" },
];

export var Tubesheet_WPSPage1 = {
  WPSH_Project_No: "",
  WPSH_WPS_No: "",
  WPSH_WPS_Revi_No: "",
  WPSH_WPS_Date: "",
  WPSH_SupportingPQR: "",
  WPSH_RefDoc: "",
  // WPSH_Scope: "",
  WPS_QualifiedTo: "",
  WPSH_CompanyName: "Godrej & Boyce Mfg. Co. Ltd",
  wld_Approval_Type: "Pending For Approval",

  //Base Metal Tubesheet WPSPage1
  WPSH_BM_BaseMetal1_ProductForm: "",
  WPSH_BM_BaseMetal1_Spec: "",
  WPSH_BM_BaseMetal1_PNo: "",
  WPSH_BM_BaseMetal1_GNo: "",
  WPSH_BM_BaseMetal1_Thick: "",
  WPSH_BM_BaseMetal1_Diam: "",
  WPSH_BM_WeldBaseMetal2_ProductForm: "",
  WPSH_BM_WeldBaseMetal2_Spec: "",
  WPSH_BM_WeldBaseMetal2_PNo: "",
  WPSH_BM_WeldBaseMetal2_GNo: "",
  WPSH_BM_WeldBaseMetal2_Thick: "",
  WPSH_BM_WeldBaseMetal2_Diam: "",

  //Joint Detail Tubesheet WPSPage1
  WPSH_JD_JointType: "",
  WPSH_JD_HoleId: "",
  WPSH_JD_TubePitch: "",
  WPSH_JD_GrooveDepth: "",
  WPSH_JD_TubeProj: "",
  WPSH_JD_WidthLigament: "",
  WPSH_JD_Pattern: "",
  WPSH_JD_GrooveAngle: "",
  WPSH_JD_GrooveType: "",

  //Filler Metals (QW-404) Tubesheet WPSPage1
  WPSH_FM_PreFillerMetal: "",
  WPSH_FM_WithFillerMetal: "",
  WPSH_FM_FillerProduct: "",
  WPSH_FM_Inserts: "",
  WPSH_FM_FillerFlux: "",
  WPSH_FM_FillerOthers: "",
};

export var Tubesheet_WPSPage2 = {
  WPSH_Tech_Expansion: "",
  WPSH_Tech_StringWave: "",
  WPSH_Tech_MethodofCleaning: "",
  WPSH_Tech_OrifceCupSize: "",
  WPSH_Tech_SingleMultiElectrode: "",
  WPSH_Tech_Oscillation: "",
  WPSH_Tech_Peening: "",
  WPSH_Tech_ClosedtooutChamber: "",
  WPSH_Tech_UseOfThermalProcess: "",
  // WPSH_Tech_Others: "",
  WPSH_PWHeatTre_Temperature: "",
  WPSH_PWHeatTre_Time: "",
  WPSH_PWHeatTre_Type: "",
  WPSH_PWHeatTre_Wrap_insulation_material: "",
  WPSH_PWHeatTre_Heating_Rate: "",
  WPSH_PWHeatTre_Cooling_Rate: "",
  WPSH_PWHeatTre_LoadingTemp:"",
  WPSH_PWHeatTre_UnloadingTemp:"",
  WPSH_PWHeatTre_PWHTNotes:"",
  WPSH_Notes: "",
};

export var WPS_Tubesheet_Detail = [
  // { Column: "WPSD_WP_WeldingProcess", Type: "S" },
  { Column: "WPSD_WP_Type", Type: "S" },
  { Column: "WPSD_WP_MinimumPreheatTemp", Type: "S" },
  { Column: "WPSD_WP_MaximumInterpassTemp", Type: "S" },
  { Column: "WPSD_WP_PostHeating", Type: "S" },
  { Column: "WPSD_WP_TungstenType", Type: "S" },
  { Column: "WPSD_WP_WeldPosition", Type: "S" },
  { Column: "WPSD_WP_WeldProgression", Type: "S" },
  { Column: "WPSD_WP_NoofPasses", Type: "S" },

  { Column: "WPSD_WP_LayerNumber", Type: "A" },
  { Column: "WPSD_WP_FillerMetalSIze", Type: "A" },
  { Column: "WPSD_WP_ItemCode", Type: "A" },
  { Column: "WPSD_WP_BatchNumber", Type: "A" },
  { Column: "WPSD_WP_Amperes", Type: "A" },
  { Column: "WPSD_WP_Pulsing_current", Type: "A" },
  { Column: "WPSD_WP_Pulsing_current_others", Type: "A" },
  { Column: "WPSD_WP_Volts", Type: "A" },
  { Column: "WPSD_WP_TravelSpeed", Type: "A" },
  { Column: "WPSD_WP_HeatInput", Type: "A" },
  { Column: "WPSD_WP_CurrentPolarity", Type: "A" },

  { Column: "WPSD_WP_ArcVoltageControl", Type: "S" },

  { Column: "WPSD_WP_ShieldingGasType", Type: "S" },
  { Column: "WPSD_WP_ShieldingFlowRate", Type: "S" },
  { Column: "WPSD_WP_BackingGasType", Type: "S" },
  { Column: "WPSD_WP_BackingFlowRate", Type: "S" },

  { Column: "WPSD_WP_AuxiGasShieldSystem", Type: "S" },
];

// export var Arc_Transfer = [
//   { value: "", name: "Select" },
//   { value: "Not Applicable", name: "Not Applicable" },
//   { value: "Spray Transfermode", name: "Spray Transfermode" },
//   { value: "Short Circuit Transfermode", name: "Short Circuit Transfermode" },
//   { value: "Globular Transfermode", name: "Globular Transfermode" },
// ];

export var Arc_Transfer = [
  { value: "", name: "Select" },
  { value: "NA", name: "NA" },
  { value: "Spray ", name: "Spray " },
  { value: "Short Circuit ", name: "Short Circuit " },
  { value: "Globular", name: "Globular" },
];

export var Single_Pass = [
  { value: "", name: "Select" },
  { value: "Single", name: "Single" },
  { value: "Multi", name: "Multi" },
  {
    value: "Single/Multi Pass",
    name: "Single/Multi Pass",
  },
  { value: "NA", name: "NA" },
];

export var Current_Polarity = [
  { value: "", name: "Select" },
  { value: "AC", name: "Ac" },
  { value: "Any", name: "Any" },
  { value: "DC", name: "DC" },
  { value: "DCEP", name: "DCEP" },
  { value: "DCEN", name: "DCEN" },
];

export var FM_Product_Form = [
  { value: "", name: "Select" },
  { value: "Not Applicable", name: "Not Applicable" },
  { value: "Solid", name: "Solid" },
  { value: "Fluxcored", name: "Fluxcored" },
  { value: "Metalcored", name: "Metalcored" },
];

export const maxlength_WPS = {
  WPSH_WPS_No : 20,
  WPSH_WPS_Revi_No: 3,
  WPS_QualifiedTo: 43,
  WPSH_SupportingPQR: 90,
  WPSH_RefDoc: 90,
  WPSH_Scope: 90,
  WPSH_JointDesc: 90,
  WPSH_BM_Type_Type: 38,
  WPSH_BM_Type_GNo: 4,
  WPSH_BM_Type_HT_Con: 13,
  WPSH_BM_WeldingTo_Type: 38,
  WPSH_BM_WeldingTo_GNo: 4,
  WPSH_BM_WeldingTo_HT_Con: 13,
  WPSH_BM_Backing_PNo:4,
  WPSH_BM_Backing_GNo: 4,
  WPSH_BM_Backing_Material: 38,
  WPSH_BM_Retainer: 50,
  WPSH_BM_Notes: 60,
};

