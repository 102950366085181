export const emptyError = (err, seterr) => {
  var entries = Object.keys(err);
  //console.log("new error", entries);

  entries.map((i) => {
    // console.log("new error", i);
    err[i] = {
      status: false,
      message: ``,
    };
    //console.log("new error", err[i], i);
  });
  console.log("new error", err);
  seterr((prevState) => ({
    ...prevState,
    ...err,
  }));
};
