import {
  Row,
  Col,
  Container,
  Table,
  Form,
  Tab,
  Tabs,
  Button,
} from "react-bootstrap";
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import Error from "../../icons/ErrorIcon.svg";
import Tooltip_detail from "../utility/Tooltip_detail";
import { useLocation } from "react-router-dom";
import {
  AddNewDetail,
  getdetailsById,
  Updatedetails,
} from "../../Services/wpq_details-service";
import { isAlphanumericWithDashDot } from "../../functions/validations";
import { WPQHeader } from "./WPSObjects/WPQHeader";
import {
  WPS_Groove_Detail,
  Weld_Progression,
  Tungsten_size,
  Filler_Metal_Product_Form,
  Layer_No,
  Position_of_groove,
  Tungsten_type,
  WPS_Overlay_Detail,
  TypeData,
  Status,
  Action,
  MessageTypes,
  ToolError,
  ProcessError,
  errorprocess,
} from "./WPSObjects/WPQ_entity";
import {
  WPS_Process_Grid_Input,
  WPS_Process_Grid_Input_2,
  WPS_Process_Grid_Input_3,
  WPS_Process_Grid_Select,
  WPS_Process_Grid_Select_3,
  WPS_Process_Grid_Select_master_data,
} from "./WPSObjects/WPS_Process_Grid";

import Img from "../../icons/ErrorIcon.svg";

import { toast } from "react-toastify";
// import { AddNewCount, AddNewGroove } from "../../Services/wps_groove-services";
import { getActiveSfa } from "../../Services/sfa-service";
import { SearchSelect } from "../utility/search-select";
import { getActiveItem } from "../../Services/item-services";
import GrooveTransaction from "./WPQGrooveTransaction";
import WeldingProcedure from "../WPSTransaction/WeldingProcedure";
import WeldingProcedureSpec from "../WPSTransaction/WeldingProcedureSpec";
import WPQGetDetailProcess from "./WPQGetDetailProcess";
import {
  AddNewCountWPQ,
  AddNewGrooveWPQ,
} from "../../Services/wpq_groove-services";
import { UpdateWPQDetailComplete } from "../PQRTransaction/PQRObjects/PQR_Validations";

const WPQ_GetDetails = forwardRef(
  (
    {
      headerObj,
      setheaderObj,
      detailObj,
      setdetailObj,
      processObj,
      setprocessObj,
      logObj,
      setlogObj,
      setKey,
      tabno,
      isDisabled,
      isReadonly,
      setIsReadonly,
      daysRemainingList,
      setDaysRemainingList,
    },
    ref
  ) => {
    const [keyprocess, setKeyprocess] = useState(1);
    const [isError, setIsError] = useState(false);
    const firstChildRef = useRef();
    const secondChildRef = useRef();
    const thirdChildRef = useRef();
    let temp = [];
    const [ItemActive, setItemActive] = useState([]);

    const [bluestyle, setblue] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "blue" : "blue",
      }),
    });

    const [errorstyle, seterrorstyle] = useState({
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "red" : "red",
      }),
    });
    const location = useLocation();
    const [originalaction, setoriginalaction] = useState(
      location.state?.action
    );

    console.log("deeetaaail", detailObj);

    const [WPStype, setWPStype] = useState(location.state?.WPStype);
    const [action, setaction] = useState(location.state?.action);
    const [IsEdited, setIsEdited] = useState(false);

    var isoverlay = WPStype === "Groove" ? false : true;
    var isgroove = WPStype === "Groove" ? true : false;

    var groclass = WPStype === "Groove" ? "row-margin" : "d-none";
    var overclass = WPStype === "Overlay" ? "row-margin" : "d-none";

    const [isOverlay, setOverlay] = useState(isoverlay);
    const [isGroove, setGroove] = useState(isgroove);

    const [grooveclass, setgrooveclass] = useState(groclass);

    const [overlayclass, setoverlayclass] = useState(overclass);

    const fetchSfa = async () => {
      const result = await getActiveItem();
      setItemActive(result);
    };
    useEffect(() => {
      fetchSfa();
    }, []);

    useImperativeHandle(ref, () => ({
      funcOnNext2: async () => {
        return handleSubmit();
      },

      funcOnNextTemp2: async () => {
        return handleSubmitTemp();
      },
    }));

    const handleSubmitTemp = async () => {
      var res = false;
      console.log("handleSubmitTemp_IsEdited", IsEdited);
      if (action === Action.ActionEdit) {
        if (IsEdited) {
          setIsEdited(false);
          // setIsEditedPage2(false);
          console.log("2nd page Submit no ");

          res = await handleAdd(Status.StatusTempSaved);

          if (res) {
            console.log("2nd Page Tab", tabno);
            return MessageTypes.Temp;
          } else {
            return MessageTypes.Fail;
          }
        }

        return MessageTypes.None;
      } else {
        res = handleAdd(Status.StatusTempSaved);

        if (res) {
          return MessageTypes.Temp;
        } else {
          return MessageTypes.Required;
        }
      }
    };
    const handleSubmit = async () => {
      // handleAdd();

      var result = false;

      if (!(await validate(detaildata))) {
        result = await handleAdd("");
        if (result) {
          setKey(+tabno + 1);
          return MessageTypes.Success;
        } else {
          return MessageTypes.Fail;
        }
      } else {
        return MessageTypes.Required;
      }
      return false;
    };

    const [error, setError] = useState(errorprocess);
    const detaObj = [...detailObj];

    let wps_detail = detailObj?.filter((item) => item.WPQ_Process !== "");

    if (wps_detail.length <= 0) {
      wps_detail = detailObj;
    }

    console.log("hahahaha", wps_detail);

    let wpq_detail = [[...wps_detail]];

    var detailempty = detailObj?.filter((item) => item.WPQ_Process === "");

    var detaildata = detailObj?.filter((item) => item.WPQ_Process !== "");

    const validate = async (detaildata) => {
      var errortype =
        WPStype === "Groove" ? WPS_Groove_Detail : WPS_Groove_Detail;
      let entries = Object.entries(detaildata);

      let isError = false;

      errortype.map((cols) => {
        let data = { ...cols.Data };
        let dataa = { ...data[0] };
        let dataaa = { ...dataa.Actual_Value };
        let dataaaa = { ...dataaa[0] };
        entries.forEach(([key, val]) => {
          var value = "";
          var value1 = "";

          var msg = "";
          if (cols.Type === "S") {
            value = detailObj[key][cols.Column][0].Actual_Value[0];
            value1 = detailObj[key][cols.Column][1].Range_Qualified[0];
            msg = `This field is required`;
          }
          if (cols.Type === "SA") {
            console.log("enterrSA");
            value = detailObj[key][cols.Column][0].Actual_Value[0];
            value1 =
              detailObj[key][cols.Column][1].Range_Qualified[0]
                .Range_Qualified1[0];

            msg = `This field is required`;

            if (
              value === undefined ||
              value === "" ||
              value === null ||
              value1 === undefined ||
              value1 === "" ||
              value1 === null
            ) {
              isError = true;
              setIsError(true);

              setError((prevState) => ({
                ...prevState,

                [cols.Column]: [
                  {
                    Actual_Value: [
                      {
                        status: true,
                        message: msg,
                      },
                    ],
                  },
                  {
                    Range_Qualified: [
                      {
                        status: true,
                        message: msg,
                      },
                    ],
                  },
                ],
              }));
            } else {
              setError((prevState) => ({
                ...prevState,
                [cols.Column]: [
                  {
                    Actual_Value: [
                      {
                        status: false,
                        message: "",
                      },
                    ],
                  },
                  {
                    Range_Qualified: [
                      {
                        status: false,
                        message: "",
                      },
                    ],
                  },
                ],
              }));
            }
          }

          if (
            value === undefined ||
            value === "" ||
            value === null ||
            value1 === undefined ||
            value1 === "" ||
            value1 === null
          ) {
            isError = true;
            setIsError(true);

            setError((prevState) => ({
              ...prevState,

              [cols.Column]: [
                {
                  Actual_Value: [
                    {
                      status: true,
                      message: msg,
                    },
                  ],
                },
                {
                  Range_Qualified: [
                    {
                      status: true,
                      message: msg,
                    },
                  ],
                },
              ],
            }));
          } else {
            setError((prevState) => ({
              ...prevState,
              [cols.Column]: [
                {
                  Actual_Value: [
                    {
                      status: false,
                      message: "",
                    },
                  ],
                },
                {
                  Range_Qualified: [
                    {
                      status: false,
                      message: "",
                    },
                  ],
                },
              ],
            }));
          }
        });
      });

      return isError;
    };
    console.log("ereeeeeee", error);

    const handleChange = (row, col, keyprocess, obj, setfunction, e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      let temp = [...obj];
      temp[row][col] = e.target.value;
      setfunction(temp);
      let onChangeVal = [...detailObj];
      onChangeVal[row][keyprocess] = temp[row];
      setdetailObj(onChangeVal);
    };

    const handleChangeselect = (row, col, keyprocess, obj, setfunction, e) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      let temp = [...obj];
      temp[row][col] = e.target.value;
      setfunction(temp);
      let onChangeVal = [...detailObj];
      onChangeVal[row][keyprocess] = temp[row];
      setdetailObj(onChangeVal);
      setError((prevState) => ({
        ...prevState,
        [keyprocess]: { status: false, message: "" },
      }));
    };

    const handleChangeHeader = (e) => {
      const { name, value } = e.target;

      setheaderObj((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const weldconsumerror = () => {
      setError((prevState) => ({
        ...prevState,
        WPSD_WP_WeldConsumable: { status: false, message: "" },
      }));
    };

    const handleChangedetailsarray = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
    };

    const handleChangedetailsarrayselect = (e, i) => {
      if (action === Action.ActionEdit) setIsEdited(true);
      const { name, value } = e.target;
      const onChangeVal = [...detailObj];
      onChangeVal[i][name] = value;
      setdetailObj(onChangeVal);
      setError((prevState) => ({
        ...prevState,
        [name]: { status: false, message: "" },
      }));
    };

    const handleCount = () => {
      let result;

      if (action === Action.ActionEdit) {
        if (headerObj?.wld_Approval_Type === Status.StatusApproved) {
          headerObj.WPS_Change_No = location.state?.WPS_Change_No;
          result = AddNewCountWPQ(headerObj);
        }
      }
    };

    let abc = detaildata.WPQ_Detail_Backing;

    let WPQ_Detail_BackingArray = [
      {
        Actual_Value: [""],
      },
      { Range_Qualified: [""] },
    ];

    const createWPQObject = (item, key) => ({
      wld_createdon: item.wld_createdon || "",
      wld_WPSType: item.wld_WPSType,
      WPQ_WelderId: item.WPQ_WelderId,
      WPQ_RecordNo: item.WPQ_RecordNo,
      WPQ_Process: item.WPQ_Process,
      WPQ_Process_Category:item.WPQ_Process_Category,
      WPQ_Type: item.WPQ_Type,
      [key]: item[key],
    });

    const keys = [
      "WPQ_Detail_Backing",
      "WPQ_FM_Arc_transfermode",
      "WPQ_Detail_Type",
      "WPQ_FM_Automatic_AVC_System",
      "WPQ_FM_Welding_Position",
      "WPQ_FM_Specification",
      "WPQ_FM_Classification",
      "WPQ_FM_filler_Wire",
      "WPQ_FM_F_No",
      "WPQ_FM_Consumable_Insert",
      "WPQ_FM_Product_Form",
      "WPQ_FM_No_of_LayerDeposit",
      "WPQ_FM_Thickness_Limit",
      "WPQ_FM_Progression",
      "WPQ_FM_Current_Polarity",
      "WPQ_FM_Backing_Gas",
      "WPQ_FM_Direct_VisualControl",
      "WPQ_FM_Automatic_jointTracking",
      "WPQ_FM_Single_Pass_PerSide",
      "WPQ_FM_Automatic_AVC_System",
      // "WPQ_FM_Notes",
      "WPQ_FM_Weld_Deposit_Thickness",
      "WPQ_Groove_610",
      "WPQ_Groove_73to610",
      "WPQ_Groove_73",
      "WPQ_Fillet_610",
      "WPQ_Fillet_73to610",
      "WPQ_Fillet_73",
      "WPQ_Overlay_610",
      "WPQ_Overlay_73to610",
      "WPQ_Overlay_73",
    ];

    const postList = [];

    for (const item of detaildata) {
      keys.forEach((key) => {
        postList.push(createWPQObject(item, key));
      });
    }

    console.log(postList);

    const handleAdd = async (savetype) => {
      let result = false;
      if (
        headerObj?.WPSH_Status === Status.StatusApproved ||
        headerObj?.WPSH_Status === Status.StatusRejected
      ) {
        headerObj.WPSH_Status = Status.StatusPending;
        if (headerObj?.WPSH_Status === Status.StatusApproved) handleCount();
      }

      if (detailObj?.wld_process_status === "Active") {
        headerObj.WPQ_Status = "Active";
      } else if (detailObj?.wld_process_status === "Inactive") {
        headerObj.WPQ_Status = "Inactive";
      }

      const filteredDetails = detailObj.filter(
        (item) => item.WPQ_Process !== "NA" && item.WPQ_Process !== ""
      );

      var WQC_Arc = "";
      var WQC_DepThick = "";
      var WQC_DetBack = "";
      var WQC_Prog = "";
      var WQC_BackGas = "";
      var WQC_DirVisCon = "";
      var WQC_AutJoiTrac = "";
      var WQC_FillWire = "";
      var WQC_AVCSys = "";
      var WQC_Fno = "";
      var WQC_ConIns = "";
      var WQC_CurrPol = "";
      var WQC_SinPerside = "";
      var WQC_Fmproform = "";
      var WQC_remainday = "";
      var WQC_Gv610 = "";
      var WQC_Gv73to610 = "";
      var WQC_Gv73 = "";
      var WQC_Oy610 = "";
      var WQC_Oy73to610 = "";
      var WQC_Oy73 = "";
      var WQC_Fl610 = "";
      var WQC_Fl73to610 = "";
      var WQC_Fl73 = "";

      for (const item of filteredDetails) {
        console.log("itemfff", item);

        WQC_Arc += item.WPQ_FM_Arc_transfermode[1].Range_Qualified[0] + "|";
        headerObj.WQC_Arc = WQC_Arc;

        if (
          item.WPQ_FM_Weld_Deposit_Thickness[1].Range_Qualified[0]
            .Range_Qualified1[0][0] !== ""
        ) {
          WQC_DepThick +=
            item.WPQ_FM_Weld_Deposit_Thickness[1].Range_Qualified[0]
              .Range_Qualified1[0];
        } else {
          WQC_DepThick +=
            // item.WPQ_FM_Weld_Deposit_Thickness[1].Range_Qualified[2]
            //   .Range_Qualified2[0][0];
            "No Limit"
        }

        let depthick = (WQC_DepThick += "|");
        headerObj.WQC_DepThick = depthick;

        WQC_DetBack += item.WPQ_Detail_Backing[1].Range_Qualified[0] + "|";
        headerObj.WQC_DetBack = WQC_DetBack;

        WQC_Prog += item.WPQ_FM_Progression[1].Range_Qualified[0] + "|";
        headerObj.WQC_Prog = WQC_Prog;

        WQC_BackGas += item.WPQ_FM_Backing_Gas[1].Range_Qualified[0] + "|";
        headerObj.WQC_BackGas = WQC_BackGas;

        WQC_DirVisCon +=
          item.WPQ_FM_Direct_VisualControl[1].Range_Qualified[0] + "|";
        headerObj.WQC_DirVisCon = WQC_DirVisCon;

        WQC_AutJoiTrac +=
          item.WPQ_FM_Automatic_jointTracking[1].Range_Qualified[0] + "|";
        headerObj.WQC_AutJoiTrac = WQC_AutJoiTrac;

        WQC_FillWire += 
          item.WPQ_FM_filler_Wire[1].Range_Qualified[0] + "|";
        headerObj.WQC_FillWire = WQC_FillWire;

        WQC_AVCSys +=
          item.WPQ_FM_Automatic_AVC_System[1].Range_Qualified[0] + "|";
        headerObj.WQC_AVCSys = WQC_AVCSys;

        WQC_Fno += item.WPQ_FM_F_No[1].Range_Qualified[0] + "|";
        headerObj.WQC_Fno = WQC_Fno;

        WQC_ConIns += item.WPQ_FM_Consumable_Insert[1].Range_Qualified[0] + "|";
        headerObj.WQC_ConIns = WQC_ConIns;

        WQC_Fmproform += item.WPQ_FM_Product_Form[1].Range_Qualified[0] + "|";
        headerObj.WQC_Fmproform = WQC_Fmproform;

        WQC_CurrPol += item.WPQ_FM_Current_Polarity[1].Range_Qualified[0] + "|";
        headerObj.WQC_CurrPol = WQC_CurrPol;

        WQC_SinPerside +=
          item.WPQ_FM_Single_Pass_PerSide[1].Range_Qualified[0] + "|";
        headerObj.WQC_SinPerside = WQC_SinPerside;

        // WQC_remainday += item.remainingday[1].Range_Qualified[0] + "|" ;
        // headerObj.WQC_remainday = WQC_remainday
        
        let GV610 = [
          item.WPQ_Groove_610[1].Range_Qualified[0][0],
          item.WPQ_Groove_610[1].Range_Qualified[1][0],
          item.WPQ_Groove_610[1].Range_Qualified[2][0],
          item.WPQ_Groove_610[1].Range_Qualified[3][0],
          item.WPQ_Groove_610[1].Range_Qualified[4][0],
          item.WPQ_Groove_610[1].Range_Qualified[5][0],
          item.WPQ_Groove_610[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Gv610 = GV610;


        let GV73to610 = [
          item.WPQ_Groove_73to610[1].Range_Qualified[0][0],
          item.WPQ_Groove_73to610[1].Range_Qualified[1][0],
          item.WPQ_Groove_73to610[1].Range_Qualified[2][0],
          item.WPQ_Groove_73to610[1].Range_Qualified[3][0],
          item.WPQ_Groove_73to610[1].Range_Qualified[4][0],
          item.WPQ_Groove_73to610[1].Range_Qualified[5][0],
          item.WPQ_Groove_73to610[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Gv73to610 = GV73to610;


        
        let Gv73 = [
          item.WPQ_Groove_73[1].Range_Qualified[0][0],
          item.WPQ_Groove_73[1].Range_Qualified[1][0],
          item.WPQ_Groove_73[1].Range_Qualified[2][0],
          item.WPQ_Groove_73[1].Range_Qualified[3][0],
          item.WPQ_Groove_73[1].Range_Qualified[4][0],
          item.WPQ_Groove_73[1].Range_Qualified[5][0],
          item.WPQ_Groove_73[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Gv73 = Gv73;

      

        let Oy610 = [
          item.WPQ_Overlay_610[1].Range_Qualified[0][0],
          item.WPQ_Overlay_610[1].Range_Qualified[1][0],
          item.WPQ_Overlay_610[1].Range_Qualified[2][0],
          item.WPQ_Overlay_610[1].Range_Qualified[3][0],
          item.WPQ_Overlay_610[1].Range_Qualified[4][0],
          item.WPQ_Overlay_610[1].Range_Qualified[5][0],
          item.WPQ_Overlay_610[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Oy610 = Oy610;

      

        let Oy73to610 = [
          item.WPQ_Overlay_73to610[1].Range_Qualified[0][0],
          item.WPQ_Overlay_73to610[1].Range_Qualified[1][0],
          item.WPQ_Overlay_73to610[1].Range_Qualified[2][0],
          item.WPQ_Overlay_73to610[1].Range_Qualified[3][0],
          item.WPQ_Overlay_73to610[1].Range_Qualified[4][0],
          item.WPQ_Overlay_73to610[1].Range_Qualified[5][0],
          item.WPQ_Overlay_73to610[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Oy73to610 = Oy73to610;

    
        let Oy73 = [
          item.WPQ_Overlay_73[1].Range_Qualified[0][0],
          item.WPQ_Overlay_73[1].Range_Qualified[1][0],
          item.WPQ_Overlay_73[1].Range_Qualified[2][0],
          item.WPQ_Overlay_73[1].Range_Qualified[3][0],
          item.WPQ_Overlay_73[1].Range_Qualified[4][0],
          item.WPQ_Overlay_73[1].Range_Qualified[5][0],
          item.WPQ_Overlay_73[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Oy73 = Oy73;

        
        let Fl610 = [
          item.WPQ_Fillet_610[1].Range_Qualified[0][0],
          item.WPQ_Fillet_610[1].Range_Qualified[1][0],
          item.WPQ_Fillet_610[1].Range_Qualified[2][0],
          item.WPQ_Fillet_610[1].Range_Qualified[3][0],
          item.WPQ_Fillet_610[1].Range_Qualified[4][0],
          item.WPQ_Fillet_610[1].Range_Qualified[5][0],
          item.WPQ_Fillet_610[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Fl610 = Fl610;


        let Fl73to610 = [
          item.WPQ_Fillet_73to610[1].Range_Qualified[0][0],
          item.WPQ_Fillet_73to610[1].Range_Qualified[1][0],
          item.WPQ_Fillet_73to610[1].Range_Qualified[2][0],
          item.WPQ_Fillet_73to610[1].Range_Qualified[3][0],
          item.WPQ_Fillet_73to610[1].Range_Qualified[4][0],
          item.WPQ_Fillet_73to610[1].Range_Qualified[5][0],
          item.WPQ_Fillet_73to610[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Fl73to610 = Fl73to610;
      


        let Fl73 = [
          item.WPQ_Fillet_73[1].Range_Qualified[0][0],
          item.WPQ_Fillet_73[1].Range_Qualified[1][0],
          item.WPQ_Fillet_73[1].Range_Qualified[2][0],
          item.WPQ_Fillet_73[1].Range_Qualified[3][0],
          item.WPQ_Fillet_73[1].Range_Qualified[4][0],
          item.WPQ_Fillet_73[1].Range_Qualified[5][0],
          item.WPQ_Fillet_73[1].Range_Qualified[6][0]
      ]
        .filter(value => value.trim() !== "")  // Filter out empty strings
        .join(",") + "|";  // Join the remaining values with commas, then add the pipe at the end
      
      headerObj.WQC_Fl73 = Fl73;
      }

      let res = await AddNewGrooveWPQ(headerObj)
        // let res = await UpdateWPQDetailComplete(postList)
        .catch((e) => {
          result = false;
        })
        .then(async () => {
          result = true;
          console.log("detailinside");

          // await AddNewDetailTubesheet(detailObj)
          await UpdateWPQDetailComplete(postList)
            .catch((err) => {
              console.log("error" + err);
            })
            .then((r) => {
              console.log("first  done detail", r);
              setaction(Action.ActionEdit);
              result = true;
            });
          if (
            action === Action.ActionEdit &&
            savetype !== Status.StatusTempSaved
          ) {
            // await AddLogs(logObj);
            result = true;
          }
        });
      return Promise.resolve(result);
    };

    const next = async (e) => {
      var res = false;
      var val = false;

      switch (+keyprocess) {
        case 1:
          res = await firstChildRef.current.funcOnNext();
          break;
        case 2:
          res = await firstChildRef.current.funcOnNext2();
          break;
        case 3:
          res = await firstChildRef.current.funcOnNext();
          break;
        default:
          break;
      }

      if (res == MessageTypes.Success) {
        toast.success("WPQ Data Added ", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        if (keyprocess === 1 && !Twoprocess) setKeyprocess(+keyprocess + 1);
        if (keyprocess === 2 && !Threeprocess) setKeyprocess(+keyprocess + 1);
      } else if (res == MessageTypes.Fail) {
        toast.warning("Failed, WPQ Data could not be saved", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
      if (res == MessageTypes.Required) {
        toast.warning("Required Fields not filled ", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
      if (res == MessageTypes.RequiredAll) {
        toast.warning(
          "Required Fields not filled.Please check and Fill Required Fields for all 3 Pages.",
          {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          }
        );
      }
      if (res == MessageTypes.Band) {
        toast.warning(
          "Data Temporary Saved but P and T band welding engineers not present in User Master. Cannot sent for approval",
          {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          }
        );
      }
    };

    const handleSelect = (e) => {
      Temp(e, "tab");
    };
    const Temp = async (e, tabaction) => {
      var res = false;
      var val = false;
      switch (+keyprocess) {
        case 1:
          res = await firstChildRef.current.funcOnNextTemp();
          break;
        case 2:
          res = await firstChildRef.current.funcOnNextTemp();
          break;
        case 3:
          res = await firstChildRef.current.funcOnNextTemp();
          break;
        default:
          break;
      }
      if (res == MessageTypes.Temp) {
        toast.success("Temporary Saved WPQ Data Successfully", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
        if (tabaction == "prev") {
          if (keyprocess !== 1) setKeyprocess(+keyprocess - 1);
        } else if (tabaction == "next") {
          if (keyprocess === 1 && !Twoprocess) setKeyprocess(+keyprocess + 1);
          if (keyprocess === 2 && !Threeprocess) setKeyprocess(+keyprocess + 1);
        } else {
          setKeyprocess(+e);
        }
      } else if (res == MessageTypes.None) {
        if (tabaction == "prev") {
          if (keyprocess !== 1) setKeyprocess(+keyprocess - 1);
        } else if (tabaction == "next") {
          if (keyprocess !== 3) setKeyprocess(+keyprocess + 1);
        } else {
          setKeyprocess(+e);
        }
      } else if (res == MessageTypes.Fail) {
        toast.warning("Failed, WPQ Data could not be saved", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
      if (res == MessageTypes.Required) {
        toast.warning("Required Fields not filled ", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
      if (res == MessageTypes.Band) {
        toast.warning(
          " Cannot sent for approval, P and T band welding engineers not present, data saved temporarily",
          {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          }
        );
      }
    };

    let one = [...wpq_detail[0]];

    let Oneprocess =
      one[0]?.WPQ_Process === "" || one[0]?.WPQ_Process === undefined;
    let Twoprocess =
      one[1]?.WPQ_Process === "" || one[1]?.WPQ_Process === undefined;
    let Threeprocess =
      one[2]?.WPQ_Process === "" || one[2]?.WPQ_Process === undefined;

    console.log("remadays", daysRemainingList);

    var WpqDetail = detailObj?.filter(
      (i) => i.WPQ_Process !== "NA" && i.WPQ_Process !== ""
    );

    const Processstatus = async (data) => {
      let Process_Status = [];
      let detail_SK = [];

      data.forEach((item) => {
        Process_Status.push(item.remainingday);
      });
      data.forEach((item) => {
        detail_SK.push(item.WPQ_Process + "#" + item.WPQ_Type);
      });
      setheaderObj({ ...headerObj, Process_Status, detail_SK });
    };

    useEffect(() => {
      if (WpqDetail.length > 0) {
        Processstatus(WpqDetail);
      }
    }, []);

    return (
      <Row className="font">
        <WPQHeader headerObj={headerObj}></WPQHeader>
        <div className="mt-3">
          {wpq_detail &&
            wpq_detail.map((wpq_process, Index) => {
              let wpq_detailprocess = [[...wpq_process]];

              let remainingdays = [[...daysRemainingList]];

              console.log("reda", remainingdays);

              console.log("reda11", remainingdays[0][0]);

              console.log("reda1", wpq_detailprocess);

              let detailprocess = [...wpq_detailprocess];
              let detailp = [...detailprocess[0]];
              let detailpro = { ...detailp[1] };

              const arrayFromObject = Object.values(wpq_detailprocess[0]);
              const filteredArray0 = wpq_detailprocess.map((innerArray) =>
                innerArray
                  .filter((_, index3) => index3 === 0)
                  .map((i) => {
                    return [i, 0, 0];
                  })
              );

              const remainingdays0 = remainingdays.map((innerArray) =>
                innerArray
                  .filter((_, index3) => index3 === 0)
                  .map((i) => {
                    return [i, 0, 0];
                  })
              );

              console.log("reda2", remainingdays0[0][0]);

              const filteredArray1 = wpq_detailprocess.map((innerArray) =>
                innerArray
                  .filter((_, index2) => index2 === 1)
                  .map((i) => {
                    return [0, i];
                  })
              );

              const remainingdays1 = remainingdays.map((innerArray) =>
                innerArray
                  .filter((_, index2) => index2 === 1)
                  .map((i) => {
                    return [0, i];
                  })
              );

              const filteredArray2 = wpq_detailprocess.map((innerArray) =>
                innerArray
                  .filter((_, index3) => index3 === 2)
                  .map((i) => {
                    return [0, 0, i];
                  })
              );

              const remainingdays2 = remainingdays.map((innerArray) =>
                innerArray
                  .filter((_, index3) => index3 === 2)
                  .map((i) => {
                    return [0, 0, i];
                  })
              );

              return (
                <>
                  <div
                    style={{
                      borderBottom: "2px solid var(--col-ddd)",
                      position: "relative",
                      top: "42px",
                      zIndex: "-2",
                    }}
                  ></div>

                  <Tabs
                    activeKey={keyprocess}
                    onSelect={(e) => handleSelect(e)}
                    className="mb-3 w-100 border-end"
                    fill
                  >
                    <Tab
                      eventKey={1}
                      title={
                        <div>
                          {isError && keyprocess === 1 ? (
                            <ToolError title="Get WPQ Detail - Process 1" />
                          ) : (
                            "Get WPQ Detail - Process 1"
                          )}
                        </div>
                      }
                      style={{
                        height: window.innerHeight - 180,
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                      disabled={Oneprocess ? true : false}
                    >
                      <WPQGetDetailProcess
                        wpq_process={filteredArray0[0][0]}
                        remainingdays={remainingdays0[0][0]}
                        ref={firstChildRef}
                        processObj={processObj}
                        setprocessObj={setprocessObj}
                        headerObj={headerObj}
                        daysRemainingList={daysRemainingList}
                        setDaysRemainingList={setDaysRemainingList}
                        setheaderObj={setheaderObj}
                        detailObj={detailObj}
                        setdetailObj={setdetailObj}
                        WPStype={WPStype}
                        logObj={logObj}
                        setKeyprocess={setKeyprocess}
                        tabno={keyprocess}
                        setlogObj={setlogObj}
                        error={error}
                        setError={setError}
                        isDisabled={isDisabled}
                        isReadonly={isReadonly}
                        setIsReadonly={setIsReadonly}
                      />
                    </Tab>

                    <Tab
                      eventKey={2}
                      title={
                        <div>
                          {isError && keyprocess === 2 ? (
                            <ToolError title="Get WPQ Detail - Process 2" />
                          ) : (
                            "Get WPQ Detail - Process 2"
                          )}
                        </div>
                      }
                      style={{
                        height: window.innerHeight - 180,
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                      disabled={Twoprocess ? true : false}
                    >
                      <WPQGetDetailProcess
                        wpq_process={filteredArray1[0][0]}
                        remainingdays={remainingdays1[0][0]}
                        ref={firstChildRef}
                        processObj={processObj}
                        setprocessObj={setprocessObj}
                        headerObj={headerObj}
                        setheaderObj={setheaderObj}
                        daysRemainingList={daysRemainingList}
                        setDaysRemainingList={setDaysRemainingList}
                        detailObj={detailObj}
                        setdetailObj={setdetailObj}
                        WPStype={WPStype}
                        logObj={logObj}
                        setKeyprocess={setKeyprocess}
                        tabno={keyprocess}
                        setlogObj={setlogObj}
                        error={error}
                        setError={setError}
                        isDisabled={isDisabled}
                        isReadonly={isReadonly}
                        setIsReadonly={setIsReadonly}
                      />
                    </Tab>

                    <Tab
                      eventKey={3}
                      title={
                        <div>
                          {isError && keyprocess === 3 ? (
                            <ToolError title="Get WPQ Detail - Process 3" />
                          ) : (
                            "Get WPQ Detail - Process 3"
                          )}
                        </div>
                      }
                      style={{
                        height: window.innerHeight - 180,
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                      disabled={Threeprocess ? true : false}
                    >
                      <WPQGetDetailProcess
                        wpq_process={filteredArray2[0][0]}
                        remainingdays={remainingdays2[0][0]}
                        ref={firstChildRef}
                        processObj={processObj}
                        setprocessObj={setprocessObj}
                        headerObj={headerObj}
                        setheaderObj={setheaderObj}
                        daysRemainingList={daysRemainingList}
                        setDaysRemainingList={setDaysRemainingList}
                        detailObj={detailObj}
                        setdetailObj={setdetailObj}
                        WPStype={WPStype}
                        logObj={logObj}
                        setKeyprocess={setKeyprocess}
                        tabno={keyprocess}
                        setlogObj={setlogObj}
                        error={error}
                        setError={setError}
                        isDisabled={isDisabled}
                        isReadonly={isReadonly}
                        setIsReadonly={setIsReadonly}
                      />
                    </Tab>
                  </Tabs>
                </>
              );
            })}
        </div>
      </Row>
    );
  }
);

export default WPQ_GetDetails;
